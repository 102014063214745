var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col pb-48 h-initial min-h-full" },
    [
      _vm._l(_vm.timeSlots, function (timeOfDay) {
        return _c(
          "div",
          {
            key: timeOfDay.name,
            staticClass:
              "flex flex-col justify-center items-center bg-charcoal mt-16 mx-16 p-8 rounded-xl bg-opacity-5",
            attrs: {
              id: _vm.isNowWithinInterval(
                timeOfDay.starttime,
                timeOfDay.endtime
              )
                ? "scrollHere"
                : "",
            },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "w-max rounded-lg shadow-lg px-12 py-5 text-3xl bg-white font-semibold",
                class: _vm.isNowWithinInterval(
                  timeOfDay.starttime,
                  timeOfDay.endtime
                )
                  ? `bg-${_vm.moduleColor} text-white`
                  : "",
              },
              [
                _c("fa-icon", {
                  staticClass: "pr-1",
                  attrs: { icon: ["far", `${_vm.icon[timeOfDay.icon]}`] },
                }),
                _vm._v("\n      " + _vm._s(timeOfDay.name) + "\n    "),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "flex flex-wrap pt-12 pb-6 space-between justify-center grid-cols-3 flex-grow",
              },
              _vm._l(
                _vm.filteredWorkSchedule(
                  timeOfDay.starttime,
                  timeOfDay.endtime
                ),
                function (shift, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "relative m-2" },
                    [
                      _c("img", {
                        staticClass:
                          "w-36 h-36 rounded-full border-8 shadow-m border-white object-cover",
                        class:
                          _vm.isShiftActive(
                            timeOfDay.starttime,
                            timeOfDay.endtime,
                            shift.start,
                            shift.end
                          ) && !shift.cancelled
                            ? ""
                            : "filter grayscale",
                        attrs: {
                          src: shift.employee.displayImage,
                          alt: "Employee",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.showSingleShift(
                              index,
                              timeOfDay.starttime,
                              timeOfDay.endtime,
                              _vm.icon[timeOfDay.icon],
                              timeOfDay.name
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      (_vm.isNowWithinInterval(
                        timeOfDay.starttime,
                        timeOfDay.endtime
                      ) &&
                        _vm.isShiftToday(shift.end) &&
                        !shift.cancelled) ||
                      _vm.isNowWithinInterval(shift.start, shift.end)
                        ? _c("div", {
                            staticClass:
                              "absolute right-0 bottom-1 inline-flex rounded-full h-12 w-12 border-4 border-white",
                            class: `bg-${_vm.moduleColor}`,
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isNowWithinInterval(shift.start, shift.end) &&
                      _vm.isNowWithinInterval(shift.start, shift.end) &&
                      shift.cancelled
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "bg-warning absolute w-12 h-12 rounded-full flex items-center justify-center right-0 bottom-1 border-4 border-white",
                            },
                            [
                              _c("fa-icon", {
                                staticClass: "text-white text-xl",
                                attrs: { icon: ["fas", "times"] },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                }
              ),
              0
            ),
          ]
        )
      }),
      _vm._v(" "),
      _c("daySelector", {
        staticClass: "fixed bottom-72",
        on: { "handle-selected-day": _vm.handleSelectedDay },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }