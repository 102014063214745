<template>
  <div class="widget" :style="backgroundStyles">
    {{ widget.name }}
  </div>
</template>

<script>

export default {
  props: {
    widget: {
      type: Object,
      'default': (() => {})
    },
    backgroundStyles: {
      type: String,
      'default': ''
    }
  },
  computed: {

  }
}
</script>