<template>
  <div class="relative label mb-14 flex items-center w-full">
    <fa-icon
      class="mr-6"
      :icon="['fal', icon]"
    />
    <span class="font-light">{{ institutionName }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    icon: {
      type: String,
      'default': 'hotel'
    }
  },

  computed: {
    ...mapGetters({
      institutionName: 'institution/name'
    })
  }
}
</script>