var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "bookingsWrapper",
      class: {
        "verticalCenter flex items-center flex-col justify-center items-stretch":
          _vm.verticalCenterGrid || _vm.verticalCenterList,
      },
    },
    [
      _c(
        "div",
        { staticClass: "bookings bg-white" },
        [
          _vm.activeContentView.name === "list"
            ? _c("list-view", {
                attrs: {
                  "day-items": _vm.bookingDays,
                  "width-percent": 80,
                  "sort-by-day": true,
                },
                on: {
                  "item-selected": function ($event) {
                    return _vm.showSingleBooking($event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.activeContentView.name === "grid"
            ? _c("grid-view", {
                attrs: { items: _vm.bookings, "item-type": "booking" },
                on: {
                  "item-selected": function ($event) {
                    return _vm.showSingleBooking($event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.activeContentView.name === "category"
            ? _c("category-selector", {
                attrs: {
                  items: _vm.allBookingResources,
                  "active-item-index": _vm.selectedResourceIndex,
                },
                on: {
                  "category-selected": function ($event) {
                    return _vm.resourceSelected($event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.bookings.length && _vm.activeContentView.name !== "category"
        ? _c("empty-views", {
            attrs: { "view-type": "Booking", color: _vm.moduleColor },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }