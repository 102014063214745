var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "carousel",
    _vm._b(
      {
        directives: [
          {
            name: "touch",
            rawName: "v-touch",
            value: _vm.slideClicked,
            expression: "slideClicked",
          },
        ],
        ref: _vm.refName,
        staticClass: "h-full w-full",
        attrs: {
          "autoplay-timeout": _vm.slideDuration,
          autoplay: _vm.autoplay,
          "pagination-enabled": _vm.pagination,
          loop: _vm.loop,
        },
        on: { "page-change": _vm.slideChange },
      },
      "carousel",
      _vm.sliderOptions,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }