import gql from 'graphql-tag';

export default gql`
query meetingRange ($dateFrom: Date!, $dateTo: Date!, $filter: Filter!, $timezone: TimeZone!) {
  meetingRange (dateFrom: $dateFrom, dateTo: $dateTo, filter: $filter, timezone: $timezone) {
    ... on SingleMeeting {
      cancelled
      category
      coverImage {
          id
          source
      }
      id
      location
      startDate
      endDate
      title
    }
    ... on RepeatingMeeting {
      cancelled
      category
      coverImage {
        id
        source
      }
      id
      location
      startDate
      title
    }
  }
}`;