import gql from 'graphql-tag';

export default gql`
query activityRange ($filter: Filter!, $dateFrom: Date!, $dateTo: Date!, $timezone: TimeZone!) {
    activityRange (filter: $filter, dateFrom: $dateFrom, dateTo: $dateTo, timezone: $timezone) {
        ... on SingleActivity {
            id
            title
            coverImage {
                id
                source
            }
            startDate
            endDate
            description
            location
            cancelled
            institution {
                name
                color
                id
            }
            participants {
                ... on Employee {
                    id
                    displayImage
                    displayName
                    showImage
                }
                ... on Resident {
                    id
                    displayImage
                    displayName
                    showImage
                }
            }
        }
        ... on RepeatingActivity {
            repeat {
                description
                rrule
                rruleText
            }
            id
            title
            coverImage {
                id
                source
            }
            startDate
            endDate
            description
            cancelled
            institution {
                name
                color
                id
            }
            participants {
                ... on Employee {
                    id
                    displayImage
                    displayName
                    showImage
                }
                ... on Resident {
                    id
                    displayImage
                    displayName
                    showImage
                }
            }
        }
    }
}`