<template>
    <div class="date flex-none text-lg leading-none flex flex-col justify-center">
        <p class="dayOfWeek h500">
            {{ dayOfWeek }}
        </p>
        <p class="date h500">
            {{ date }}
        </p>
    </div>
</template>

<script>

import { format } from '@/utils/date-fns';
import { capitalize } from 'lodash';

export default {
  computed: {
    dayOfWeek() {
      return capitalize(format(new Date(), 'EEEE'));
    },
    date() {
      return format(new Date(), 'd MMMM');
    }
  }
}
</script>