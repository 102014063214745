var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mediaIcons absolute -top-28 left-10 h-20 flex justify-end",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "rounded-full bg-white w-20 h-20 flex justify-center items-center relative duration-300 transition",
          class: { "bg-orange": _vm.audioPlaying },
          on: { click: _vm.playAudio },
        },
        [
          !_vm.audioPlaying
            ? _c("fa-icon", {
                staticClass: "text-4xl",
                attrs: { icon: ["fal", "volume"] },
              })
            : _c(
                "div",
                { staticClass: "sound-wave flex justify-around items-center" },
                [
                  _c("i", { staticClass: "bar" }),
                  _vm._v(" "),
                  _c("i", { staticClass: "bar" }),
                  _vm._v(" "),
                  _c("i", { staticClass: "bar" }),
                  _vm._v(" "),
                  _c("i", { staticClass: "bar" }),
                  _vm._v(" "),
                  _c("i", { staticClass: "bar" }),
                ]
              ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }