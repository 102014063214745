var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mediaIcons absolute right-10 h-20 flex justify-end w-full",
      class: {
        "-top-28": _vm.iconsPositionType === "route",
        "top-80": _vm.iconsPositionType === "overlay",
      },
    },
    _vm._l(_vm.filteredIcons, function (icon, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass:
            "rounded-full bg-white w-20 h-20 ml-6 flex justify-center items-center relative",
          class: {
            [`bg-${_vm.moduleColor}`]: _vm.mediaType === icon.name,
            ["border-2 border-white"]: true,
          },
        },
        [
          _c("fa-icon", {
            staticClass: "text-4xl",
            class: {
              "text-white": _vm.mediaType === icon.name,
              [`text-${_vm.moduleColor}`]: _vm.mediaType !== icon.name,
            },
            attrs: { icon: ["fal", icon.fa] },
            on: {
              click: function ($event) {
                return _vm.$emit("media-selected", icon.name)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "rounded-full border-white border-2 text-white absolute -right-1 -bottom-1 w-8 h-8 flex items-center justify-center text-xl font-bold",
              class: `bg-${_vm.moduleColor}`,
            },
            [_vm._v("\n      " + _vm._s(icon.amount) + "\n    ")]
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }