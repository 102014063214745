var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "flex justify-between self-center px-20 pb-20 -mt-12 rounded-t-3xl",
      },
      [
        _c("div", { staticClass: "largeListItemHeader" }, [
          _vm._v("\n      " + _vm._s(_vm.$t(_vm.data.title)) + "\n    "),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "w-full px-20 flex justify-center" },
      _vm._l(8, function (day, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "slot mr-3 p-3 flex items-end justify-center",
          },
          [
            index === 0
              ? _c("p")
              : _c(
                  "div",
                  {
                    staticClass: "text-center text-xs font-bold",
                    style: {
                      color: _vm.isPastByIndex(index) ? _vm.mediumGray : "#000",
                    },
                  },
                  [
                    _c("p", [_vm._v(_vm._s(_vm.getDay(index)))]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.getDate(index)))]),
                  ]
                ),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "w-full pb-10 px-20" },
      _vm._l(_vm.rows, function (row, index) {
        return _c(
          "div",
          { key: index, staticClass: "flex justify-center mb-3" },
          [
            _c(
              "div",
              {
                staticClass:
                  "labelSmall font-bold slot mr-3 flex flex-col items-center justify-center",
              },
              [
                _c("p", [_vm._v(_vm._s(_vm.formatTime(row[index].start)))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.formatTime(row[index].end)))]),
              ]
            ),
            _vm._v(" "),
            _vm._l(row, function (slot, index2) {
              return _c(
                "div",
                {
                  key: index2,
                  staticClass: "slot mr-3 p-3 rounded-sm",
                  style: {
                    background: _vm.lightGray,
                    opacity: _vm.isPast(slot) ? "0.3" : "1",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full h-full flex items-center justify-center",
                      class: {
                        "border-4 rounded-full":
                          slot.state !== "LOCKED" && !slot.participants.length,
                      },
                      style: { "border-color": _vm.moduleColor },
                      on: {
                        click: function ($event) {
                          return _vm.slotClicked(slot)
                        },
                      },
                    },
                    [
                      slot.state === "LOCKED"
                        ? _c("fa-icon", {
                            staticClass: "text-5xl",
                            attrs: { icon: ["fas", "lock"] },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      slot.participants.length && slot.state !== "LOCKED"
                        ? _c("img", {
                            staticClass: "w-full h-full bg-cover rounded-full",
                            attrs: {
                              src: slot.participants[0].displayImage,
                              alt: "Participant",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            }),
          ],
          2
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "w-full px-20 mt-8 flex justify-center items-center" },
      [
        _c("button-el", {
          staticClass: "flex",
          attrs: {
            text: "",
            icon: "arrow-left",
            "background-color": _vm.canGoPrevious
              ? "success"
              : "charcoal-light",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.getPreviousWeek.apply(null, arguments)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "text-3xl mx-8 flex flex-col justify-center items-center",
          },
          [
            _c("p", { staticClass: "font-semibold" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("global.week")) +
                  " " +
                  _vm._s(_vm.weekNumber) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-charcoal-light underline",
                on: { click: _vm.getThisWeek },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("global.thisWeek")) + "\n      "
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("button-el", {
          staticClass: "flex",
          attrs: {
            text: "",
            icon: "arrow-right",
            "background-color": "success",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.getNextWeek.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }