var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative label mb-14 flex items-center w-full" },
    [
      _c("fa-icon", {
        staticClass: "mr-6",
        attrs: { icon: ["fal", _vm.icon] },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "font-light" }, [
        _vm._v(_vm._s(_vm.institutionName)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }