var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "widget h-full flex flex-col",
      class: [`bg-${_vm.widget.colorId}`, _vm.widgetSize],
      on: {
        click: function ($event) {
          return _vm.slideClicked()
        },
      },
    },
    [
      _vm.workSchedule.length
        ? _c(
            "div",
            {
              staticClass: "container flex justify-center items-center p-5",
              class: {
                "h-3/4": _vm.widgetSize === "small",
                "h-5/6": _vm.widgetSize === "medium",
                "h-4/5": _vm.widgetSize === "large",
                "h-3/5 mt-12": _vm.widgetSize === "medium-flat",
              },
            },
            _vm._l(_vm.workSchedule, function (shift, index) {
              return _c(
                "div",
                {
                  key: index,
                  class: {
                    "h-4/6":
                      _vm.widgetSize === "small" && _vm.workSchedule.length > 2,
                    "h-3/6":
                      _vm.widgetSize === "small" &&
                      _vm.workSchedule.length <= 2,
                    "h-5/6":
                      _vm.widgetSize === "medium" || _vm.widgetSize === "large",
                    "h-2/4":
                      _vm.widgetSize === "medium-flat" &&
                      _vm.workSchedule.length <= 5,
                    "h-4/5":
                      _vm.widgetSize === "medium-flat" &&
                      _vm.workSchedule.length > 5,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "shift bg-cover bg-center rounded-full mx-1 relative h-full",
                      class: {
                        shiftNow: _vm.isSimpleOrNormalWorkSchedule,
                        shiftLater: _vm.widget.type === "LATER_WORK_SCHEDULE",
                      },
                      style: {
                        backgroundImage: `url(${shift.employee.displayImage})`,
                        width: `${_vm.shiftSize}px`,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-center absolute text-white w-full h-full flex flex-col items-center justify-end pb-4 rounded rounded-full",
                          style: {
                            background:
                              "linear-gradient(0deg, rgba(50,50,50,1) 0%, rgba(50,50,50,0.40) 30%, rgba(0,0,0,0) 50%)",
                          },
                        },
                        [
                          _vm.showName
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "font-semibold overflow-ellipsis overflow-hidden",
                                  class: _vm.nameFontSize,
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(shift.employee.displayName) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showTime
                            ? _c("p", [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.getShiftTimes(shift))),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "flex flex-col items-center mb-8 text-white text-2xl font-semibold mt-auto",
        },
        [
          _c("fa-icon", {
            staticClass: "text-white text-3xl mb-4",
            attrs: { icon: ["fas", "id-card"] },
          }),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(
                _vm.isSimpleOrNormalWorkSchedule
                  ? _vm.$t("scheduleShift.workingNow")
                  : _vm.$t("scheduleShift.workingLater")
              )
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }