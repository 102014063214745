<template>
  <div
    class="media-list"
    :class="{ 'galleries': data.mediaType === 'galleries' || data.mediaType === 'externalMedia' }"
  >
    <div
      class="flex w-full h-12 mb-16 justify-between"
      :class="{ 'px-10' : data.mediaType === 'galleries'}"
    >
      <div class="largeListItemHeader">
        <p v-if="data.mediaType === 'pictures'">
          {{ $t('global.images') }}
        </p>
        <p v-if="data.mediaType === 'galleries'">
          {{ $t('ourHome.galleries') }}
        </p>
        <p v-if="data.mediaType === 'videos'">
          {{ $t('ourHome.videos') }}
        </p>
        <p v-if="data.mediaType === 'documents'">
          {{ $t('ourHome.documents') }}
        </p>
      </div>
      <fa-icon
        v-if="canClose"
        :icon="['fal', 'times']"
        class="text-5xl h-auto font-bold"
        @click="close"
      />
    </div>

    <!-- Pictures -->
    <div
      v-if="data.mediaType === 'pictures'"
      class="grid grid-cols-2 gap-3 object-cover"
    >
      <div
        v-if="!data.items.length"
        class="header"
      >
        {{ $t('media.noImages') }}
      </div>

      <img
        v-for="(image, index) in data.items"
        :key="index"
        :src="image.source"
        class="object-cover h-80 w-full"
        @click="mediaSelected(index)"
      >
    </div>

    <!-- Galleries -->
    <div
      v-if="data.mediaType === 'galleries' || data.mediaType === 'externalMedia'"
    >
      <div
        v-if="!data.items.length"
        class="header"
      >
        {{ $t('media.noImages') }}
      </div>

      <grid-view
        :items="addMetaToGalleries(data.items)"
        item-type="gallery"
        @item-selected="showSingleGallery($event)"
      />
    </div>

    <!-- Videos -->
    <div
      v-else-if="data.mediaType === 'videos'"
      class="w-full"
    >
      <div
        v-if="!data.items.length"
        class="header"
      >
        {{ $t('media.noVideos') }}
      </div>
      <div
        v-for="(video, index) in data.items"
        :key="index"
        class="video w-full mb-3 relative"
        @click="mediaSelected(index)"
      >
        <div class="rounded-full bg-white absolute w-20 h-20 right-10 bottom-10 flex items-center justify-center">
          <fa-icon
            :icon="['fal', 'caret-right']"
            class="text-5xl"
          />
        </div>
        <img
          :src="video.thumbnail"
          class="w-full h-full object-cover"
        >
      </div>
    </div>

    <!-- Documents -->
    <div
      v-else-if="mediaType === 'documents'"
      class="object-cover"
    >
      <div
        v-if="!data.items.length"
        class="header"
      >
        {{ $t('media.noDocuments') }}
      </div>

      <div
        v-for="(document, index) in data.items"
        :key="index"
        class="flex items-center justify-between mb-12"
        @click="mediaSelected(index)"
      >
        <div class="flex items-center">
          <fa-icon
            class="mr-12 text-4xl"
            :icon="['fal', 'file-alt']"
          />
          <p
            class="font-bold"
            :class="`text-${moduleColor}`"
          >
            {{ document.title }}
          </p>
        </div>

        <fa-icon
          class="text-4xl self-end"
          :icon="['fal', 'angle-right']"
        />
      </div>
    </div>

    <!-- Media -->
    <div v-else>
      <div
        v-if="data.mediaType === 'media'"
        class="grid grid-cols-2 gap-3 object-cover"
      >
        <div
          v-if="!data.items.length"
          class="header"
        >
          {{ $t('media.noImages') }}
        </div>

        <div
          v-for="(item, index) in data.items"
          :key="index"
        >
          <img
            v-if="item.__typename === 'GalleryPicture'"
            :src="item.source"
            class="object-cover h-80 w-full"
            @click="mediaSelected(index)"
          >

          <div
            v-else
            class="relative"
          >
            <div
              class="rounded-full bg-white absolute w-20 h-20 right-10 bottom-10 flex items-center justify-center"
            >
              <fa-icon
                :icon="['fal', 'caret-right']"
                class="text-5xl"
              />
            </div>

            <img
              :src="item.thumbnail"
              class="w-full object-cover h-80"
              @click="mediaSelected(index)"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { get, cloneDeep } from 'lodash';
  import GridView from '@/components/shared/GridView';

  export default {
    components: {
      GridView,
    },
    props: {
      items: {
        type: Array,
        'default': () => []
      },
      mediaType: {
        type: String,
        'default': 'pictures'
      },
      canClose: {
        type: Boolean,
        'default': false
      }
    },
    data() {
      return {
        selectedMediaIndex: -1
      }
    },
    computed: {
      ...mapGetters({
        activeOverlay: 'general/activeOverlay',
        institutionSettings: 'institution/settings'
      }),
      showMediaPreview() {
        if (this.$route.path === '/gallery') return false;
        return this.selectedMediaIndex > -1 || this.amount < 2;
      },
      useExternalMedia() {
        return get(this.institutionSettings, 'useExternalMedia', false);
      },
      amount() {
        return this.items.length;
      },
      data() {
        return {
          items: get(this, 'activeOverlay.data.items', this.items),
          mediaType: get(this, 'activeOverlay.data.mediaType', this.mediaType)
        }
      },
      moduleColor() {
        return this.$route?.meta?.color;
      },
    },
    methods: {
      close() {
        this.$matomo.trackEvent('Navigation', 'Clicked', 'CloseOverlayX');
        this.$emit('close');
      },
      mediaSelected(index) {
        this.$emit('item-selected', index);
      },
      addMetaToGalleries(galleries) {
        const clonedGalleries = cloneDeep(galleries);

        return clonedGalleries.map(gallery => {
          return {
            ...gallery,
            meta: {
              icon: 'images',
              text: `${!gallery.videos.length && !this.useExternalMedia ? this.$t('global.images') : this.$t('global.imagesAndVideos')} ${this.useExternalMedia ? gallery.media.length : gallery.pictures.length}`
            },
          }
        })
      },
      showSingleGallery(gallery) {
        this.$store.dispatch('general/setActiveOverlay', { name: 'our-home-gallery', data: { title: gallery.title, gallery: this.useExternalMedia ? gallery.media : gallery} })
      }
    }
  }
</script>
