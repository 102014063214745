var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "carousel",
    {
      attrs: {
        name: "booking",
        "ref-name": "booking",
        "slide-duration": _vm.widgetSlideDuration,
      },
      on: { "slide-clicked": _vm.slideClicked },
    },
    _vm._l(_vm.bookings, function (booking, index) {
      return _c(
        "slide",
        {
          key: index,
          class: [
            _vm.slideStyles,
            _vm.widget.frHeight > 1 ? "justify-center" : "justify-end",
          ],
          style: _vm.getBackgroundStyles(index),
        },
        [
          _c("fa-icon", {
            staticClass: "text-2xl mb-4 text-white",
            attrs: { icon: ["fas", `${_vm.widget.icon}`] },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "text-2xl mb-6" }, [
            _vm._v("\n      " + _vm._s(booking.title) + "\n    "),
          ]),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }