<template>
  <div>
    <div class="flex justify-between self-center px-20 pb-20 -mt-12 rounded-t-3xl">
      <div class="largeListItemHeader">
        {{ $t(data.title) }}
      </div>
    </div>

    <div class="pb-10 pl-20">
      <p class="content bold mb-4">
        {{ $t('global.startDate') }}
      </p>

      <date-selector
        :color="moduleColor"
        :unavailable-dates="unavailableDates"
        @date-selected="startDateSelected"
      />
    </div>

    <div class="w-full pb-10 px-20">
      <p class="content bold mb-4">
        {{ $t('global.startTime') }}
      </p>

      <time-selector
        type="start"
        :selected-date="selected.startDate"
        :start-time="startTimeFrom"
        end-time="23:45"
        :color="moduleColor"
        :time-slots="getTimeSlots()"
        @time-selected="startTimeSelected"
        @deselect="selected.startTime = ''"
      />
    </div>

    <div
      class="pb-10 pl-20"
    >
      <p class="content bold mb-4">
        {{ $t('global.endDate') }}
      </p>

      <date-selector
        :color="moduleColor"
        :end-date="endDateTo"
        :initial-date="startDateObject"
        @date-selected="endDateSelected"
      />
    </div>

    <div
      class="w-full pb-10 px-20"
    >
      <p class="content bold mb-4">
        {{ $t('global.endTime') }}
      </p>

      <time-selector
        type="end"
        :selected-date="selected.endDate"
        :start-time="startTimeTo"
        :end-time="endTimeTo"
        :color="moduleColor"
        :time-slots="getTimeSlots()"
        @time-selected="selected.endTime = $event"
        @deselect="selected.endTime = ''"
      />
    </div>

    <div
      v-if="canGoNext"
      class="flex w-full justify-center"
    >
      <button-el
        text="modals.next"
        icon="arrow-right"
        background-color="success"
        text-color="white"
        class="mr-5 mt-8 shadow-xsm"
        @click.native="next"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import DateSelector from '@/components/shared/DateSelector';
  import TimeSelector from '@/components/shared/TimeSelector';
  import colors from '@/utils/colors';
  import { isToday, addDays, addMinutes, isAfter, areIntervalsOverlapping } from 'date-fns';
  import { format } from '@/utils/date-fns';
  import { cloneDeep } from 'lodash';
  import { stringToMinutes, minutesToString } from '@/utils/helpers';
  import Button from '@/components/shared/Button';
  import isSameDay from 'date-fns/isSameDay';

  export default {
    components: {
      DateSelector,
      TimeSelector,
      ButtonEl: Button,
    },
    data() {
      return {
        selected: {
          startDate: format(new Date(), 'yyyy-MM-dd'),
          startTime: '',
          endDate: format(new Date(), 'yyyy-MM-dd'),
          endTime: ''
        }
      }
    },
    computed: {
      ...mapGetters({
        activeOverlay: 'general/activeOverlay',
        resource: 'bookingResources/selectedBookingResource'
      }),
      data() {
        return this.activeOverlay.data;
      },
      moduleColor() {
        return colors['BOOKING'];
      },
      today() {
        return format(new Date(), 'yyyy-MM-dd');
      },
      startDateObject() {
        return new Date(this.selected.startDate);
      },
      endDateObject() {
        return new Date(this.selected.endDate);
      },
      futureUnavailabilities() {
        return cloneDeep(this.unavailable).filter(item => {
          return isAfter(new Date(item.start), this.selectedStartDateTime);
        });
      },
      slotsStart() {
        const date = new Date();
        const ms = 1000 * 60 * 15;
        const nearestQuarter = format(new Date(Math.floor(date.getTime() / ms) * ms), 'HH:mm');

        return isToday(this.startDateObject, new Date()) ? nearestQuarter : '00:00';
      },
      unavailable() {
        return cloneDeep(this.resource).bookings
          .map(booking => {
            return {
              start: booking.start,
              end: booking.end
            }
        });
      },
      firstAvailableTime() {
        return minutesToString(stringToMinutes(this.selected.startTime) + 15);
      },
      selectedStartDateTime() {
        const selectedStartDate = format(this.startDateObject, 'yyyy, MM, dd');
        return new Date(`${selectedStartDate}, ${this.selected.startTime}`);
      },
      startTimeFrom() {
        const date = new Date();
        const ms = 1000 * 60 * 15;
        const nearestQuarter = format(new Date(Math.floor(date.getTime() / ms) * ms), 'HH:mm');

        return isToday(this.startDateObject) ? nearestQuarter : '00:00';
      },
      startTimeTo() {
        return isSameDay(this.startDateObject, this.endDateObject) ? this.firstAvailableTime : '00:00';
      },
      endDateTo() {
        if (!this.futureUnavailabilities.length) return addDays(new Date(), 14);
        return new Date(this.futureUnavailabilities[0].start);
      },
      endTimeTo() {
        if (this.futureUnavailabilities.length && isSameDay(new Date(this.selected.endDate), new Date(this.futureUnavailabilities[0].start))) {
          return format(new Date(this.futureUnavailabilities[0].start), 'HH:mm');
        }

        return '23:45';
      },
      canGoNext() {
        return this.selected.startDate && this.selected.startTime && this.selected.endDate && this.selected.endTime;
      },
      allTimeSlots() {
        const end = stringToMinutes('23:45');

        const dates = [];

        for ( let i = 0; i < 14; i++ ) {
          dates.push(format(addDays(new Date(), i), 'yyyy-MM-dd'));
        }

        return dates.map(date => {
          const allSlots = Array.from({length: Math.floor((end) / 15) + 1}, (_, i) => minutesToString(i * 15));

          return {
            date,
            slots: allSlots.map(slot => {
              return {
                time: slot,
                available: this.isTimeAvailable(date, slot)
              };
            })
          };
        });
      },
      unavailableDates() {
        return cloneDeep(this.allTimeSlots).filter(slot => slot.slots.filter(slot => slot.available).length < 1 ).map(item => item.date);
      }
    },
    mounted() {
      this.$matomo.trackEvent('NewBooking', 'Viewed', 'NewBookingViewed');
    },
    methods: {
      isTimeAvailable(date, slot) {
        if (!this.unavailable.length) return true;
        const slotDate = format(new Date(date), 'yyyy, MM, dd');
        const slotDateTime = new Date(`${slotDate}, ${slot}`);

        return cloneDeep(this.unavailable).filter(range => {
          const rangeStart = new Date(range.start);
          const rangeEnd = new Date(range.end);

          return areIntervalsOverlapping(
            { start: rangeStart, end: rangeEnd },
            { start: slotDateTime, end: addMinutes(slotDateTime, 15) }
          );
        }).length < 1;
      },
      startDateSelected(amount) {
        const today = new Date();
        this.selected.startDate = format(addDays(today, amount), 'yyyy-MM-dd');
        this.selected.endDate = this.selected.startDate;
      },
      endDateSelected(amount) {
        this.selected.endDate = format(addDays(new Date(this.selected.startDate), amount), 'yyyy-MM-dd');
      },
      startTimeSelected(event) {
        this.selected.startTime = event;
        this.selected.endTime = '';
      },
      async next() {
        await this.$store.dispatch('bookingResources/setNewBooking', {
          selectedStartDate: this.selected.startDate,
          selectedStartTime: this.selected.startTime,
          selectedEndDate: this.selected.endDate,
          selectedEndTime: this.selected.endTime
        });

        this.$store.dispatch('general/setNextActiveOverlay', {
          name: 'new-booking-description'
        });

        this.$emit('close-overlay');
      },
      getTimeSlots() {
        const index = this.allTimeSlots.findIndex(slot => slot.date === this.selected.startDate);

        return cloneDeep(this.allTimeSlots)[index].slots;
      }
    }
  }
</script>
