<template>
  <div
    class="galleriesWrapper"
    :class="{ 'verticalCenter flex items-center flex-col justify-center items-stretch': verticalCenterGrid || verticalCenterList }"
  >
    <div
      v-if="shownGalleries.length"
      class="galleries bg-white"
    >
      <list-view
        v-if="activeContentView.name === 'list'"
        :items="shownGalleries"
        :width-percent="80"
        :sort-by-day="false"
        @item-selected="showSingleGallery($event)"
      />

      <grid-view
        v-if="activeContentView.name === 'grid'"
        :items="shownGalleries"
        item-type="gallery"
        @item-selected="showSingleGallery($event)"
      />
    </div>

    <empty-views
      v-else
      view-type="Galleries"
      :color="moduleColor"
    />
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { cloneDeep, isEmpty, get } from 'lodash';
import ListView from '@/components/shared/ListView';
import GridView from '@/components/shared/GridView';
import EmptyViews from '@/views/shared/EmptyViews';
import PinFlow from '@/utils/pinFlow';

export default {
  components: {
    ListView,
    GridView,
    EmptyViews
  },
  data() {
    return {
      selectedCategoryIndex: -1,
      selectedGallery: {}
    }
  },
  computed: {
    ...mapGetters({
      galleries: 'galleries/all',
      activeViewType: 'general/activeViewType',
      activeContentView: 'general/activeContentView',
      institutionSettings: 'institution/settings',
      pinValidation: 'profiles/pinValidation',
    }),
    moduleColor() {
      return this.$route?.meta?.color;
    },
    verticalCenterList() {
      return this.activeViewType === 'list' && this.galleries.length < 3;
    },
    verticalCenterGrid() {
      return (this.activeViewType === 'grid' && this.galleries.length < 10) || (this.activeContentView.name === 'filter' && this.categories.length < 10);
    },
    shownGalleries() {
      const clonedGalleries = cloneDeep(this.galleries);

      return clonedGalleries.filter(gallery => {
        if (this.useExternalMedia) {
          return !isEmpty(gallery.settings.dreambrokerUrl)
        }
        return true;
      }).map(gallery => {
        return {
          ...gallery,
          meta: this.getMeta(gallery),
          coverImage: this.useExternalMedia ? { source: this.getMediaCoverImage(gallery) } : gallery.coverImage
        }
      });
    },
    categories() {
        const categories = [];

        this.galleries.forEach(gallery => {
          if (gallery.category) {
            categories.push(gallery.category);
          }
        });

        return [ ...new Set(categories) ];
    },
    contentViews() {
      return this.$route?.meta?.contentViews;
    },
    useExternalMedia() {
      return get(this.institutionSettings, 'useExternalMedia', false);
    },
    galleryPasswordProtected() {
      return get(this.institutionSettings, 'screen.galleryPasswordProtected', false);
    },
  },
  watch: {
    pinValidation(pinData) {
      if (!pinData.validPin || !pinData.pin) return;
      this.$router.push({name: 'GALLERY_ENTRY', params: { id: this.selectedGallery.id }});
    }
  },
  async mounted() {
    this.$store.dispatch('galleries/getGalleries');
    this.$store.dispatch('general/setActiveViewType', this.galleries.length > 3 ? 'grid' : 'list');
    this.$store.dispatch('general/setActiveContentView', this.$route.meta?.contentViews[this.galleries.length >= 3 ? 1 : 0]);
  },
  methods: {
    showSingleGallery(gallery) {
      if (this.galleryPasswordProtected) {
        const flow = new PinFlow({
          entity: 'gallery'
        });

        flow.grantAccessToGallery();
        this.selectedGallery = gallery;
      } else {
        this.$router.push({name: 'GALLERY_ENTRY', params: { id: gallery.id }});
      }
    },
    getMeta(gallery) {
      const text = !gallery.videos.length && !this.useExternalMedia ? this.$t('global.images') : this.$t('global.imagesAndVideos');

      const length = this.useExternalMedia ? gallery.media.length : gallery.pictures.length;

      return  {
        icon: 'images',
        text: `${length} ${text}`
      }
    },
    getMediaCoverImage(gallery) {
      const pictures = gallery.media.filter(item => item.__typename === 'GalleryPicture');
      if (!pictures) return require('@assets/img/placeholder.png');

      return pictures[0].source;
    }
  }
}

</script>

<style lang="scss">
  .galleriesWrapper {
    position: relative;
    background: none;
    padding: 130px 0;

    &.gridView {
      padding-top: 15px;
    }
  }
</style>
