
<template>
  <div
    class="mealsWrapper"
    :class="{ 'verticalCenter flex items-center flex-col justify-center items-stretch': verticalCenterGrid || verticalCenterList }"
  >
    <div
      v-if="meals.length"
      class="meals bg-white"
    >
      <list-view
        v-if="activeContentView.name === 'list'"
        :day-items="mealDays"
        :width-percent="80"
        :sort-by-day="true"
        @item-selected="showSingleMeal($event)"
      />

      <grid-view
        v-if="activeContentView.name === 'grid'"
        :items="meals"
        item-type="meal"
        @item-selected="showSingleMeal($event)"
      />
    </div>

    <empty-views
      v-else
      view-type="MealPlan"
      :color="moduleColor"
    />
  </div>
</template>

<script>
import { isFuture, addDays, isBefore } from 'date-fns';
import { mapGetters } from 'vuex';
import { cloneDeep, get } from 'lodash';
import ListView from '@/components/shared/ListView';
import GridView from '@/components/shared/GridView';
import { isItemToday, formatTime } from '@/utils/helpers.js';
import EmptyViews from '@/views/shared/EmptyViews';

export default {
  components: {
    ListView,
    GridView,
    EmptyViews
  },
  data() {
    return {
      selectedClubIndex: -1,
      lastScrollTop: 0,
      topVisibleDay: null,
      singleMealShown: false,
      selectedDate: new Date()
    };
  },
  computed: {
    ...mapGetters({
      allMeals: 'meals/all',
      activeViewType: 'general/activeViewType',
      activeContentView: 'general/activeContentView',
      activeItemFilter: 'general/activeItemFilter',
      externalConnections: 'institution/externalConnections',
      institutionId: 'institution/id',
      institutionSettings: 'institution/settings',
      departmentSettings: 'department/settings'
    }),
    moduleColor() {
      return this.$route?.meta?.color;
    },
    contentViews() {
      return this.$route?.meta?.contentViews;
    },
    useExternalMeals() {
      return get(this.institutionSettings, 'useExternalMeals', false);
    },
    useExternalMedia() {
      return get(this.institutionSettings, 'useExternalMedia', false);
    },
    daysForward() {
      return get(this.departmentSettings.meal, 'daysForward', 7);
    },
    mealDays() {
      return cloneDeep(this.meals).reduce((accumulator, current, index) => {
        const uniqueDate = current.startDate.split('T')[0];

        if (accumulator[uniqueDate] || (accumulator[uniqueDate]=[])) {
          accumulator[uniqueDate].push(current);

          accumulator[uniqueDate].meta = {
            startDate: current.startDate,
            showTodayLabel: index === 0 && isItemToday(current.startDate)
          }
        }

        return accumulator;
      }, {});
    },
    
    meals() {
      return cloneDeep(this.allMeals).filter( meal => {
        return meal.isPcd && this.limitShownMeals(meal) || isFuture(new Date(meal.startDate)) && isFuture(new Date(meal.endDate)) && this.limitShownMeals(meal)
      }).map(meal => {
        return {
          ...meal,
          meta: {
            icon: 'clock',
            text: formatTime(meal.startDate)
          }
        }
      });
    },
    verticalCenterList() {
      return this.activeViewType === 'list' && this.meals.length < 3;
    },
    verticalCenterGrid() {
      return (this.activeViewType === 'grid' && this.meals.length < 10) || (this.activeContentView.name === 'filter');
    }
  },
  async mounted() {
    await this.$store.dispatch('general/setActiveContentView', this.$route.meta?.contentViews[this.meals.length > 3 ? 1 : 0]);

    if (this.useExternalMeals) {
      await this.$store.dispatch('meals/getPcdMeals');
    } else {
      await this.$store.dispatch('meals/getMeals');
    }

    await this.$store.dispatch('general/setActiveViewType', this.meals.length > 3 ? 'grid' : 'list');
  },
  methods: {
    limitShownMeals(meal) {
      return isBefore(new Date(meal.startDate), addDays(new Date(), this.daysForward));
    },
    showSingleMeal(meal) {
      if (meal.cancelled) return;

      const dayLabels = document.getElementsByClassName('dayLabelWrapper');

      for (let item of dayLabels) {
        item.style.zIndex = '0';
      }

      this.$router.push({name: 'MEAL_PLAN_ENTRY', params: { id: meal.id, meal }});
    },
    formatTime(meal) {
      if(meal.cancelled) return this.$t('global.cancelled');
      return formatTime(meal);
    },
  }
};
</script>

<style lang="scss">
  .mealsWrapper {
    position: relative;
    background: none;
    padding: 130px 0;

    &.verticalCenter .dayLabelWrapper {
      padding: 0;
    }

    &.gridView {
      padding-top: 15px;
    }
  }

</style>
