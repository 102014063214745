<template>
  <div class="meeting">
    <cover-view
      v-if="meeting.id"
      :image-url="meeting.coverImage.source"
      :gradient="true"
      :module-color="moduleColor"
      :margin-top="60"
    >
      <div class="meetingContent pt-20">
        <tts
          :entity-id="meeting.id"
          entity-type="MEETING"
        />
        <div>
          <div class="largeListItemHeader pt-24 mb-16">
            {{ meeting.title }}
          </div>

          <div>
            <!-- Category -->
            <div class="relative content pb-10 flex items-center w-full">
              <fa-icon
                class="mr-6"
                :icon="['fal', 'tag']"
              />
              {{ category }}
            </div>

            <!-- Date -->
            <div class="relative content pb-10 flex items-center w-full">
              <fa-icon
                class="mr-6"
                :icon="['fal', 'clock']"
              />
              <div class="flex flex-col">
                <span>{{ formatDate(meeting) }}</span>
                <span>{{ formatTime(meeting) }}</span>
              </div>
            </div>

            <!-- Repeat -->
            <div
              v-if="meeting.repeat && repeatText.length"
              class="relative content pb-10 flex items-center w-full"
            >
              <fa-icon
                class="mr-6"
                :icon="['fal', 'repeat']"
              />
              {{ repeatText }}
            </div>

            <!-- Location -->
            <div
              v-if="meeting.location"
              class="content pb-10 flex items-center w-full"
            >
              <fa-icon
                class="mr-6"
                :icon="['fal', 'map-marker-alt']"
              />
              {{ meeting.location }}
            </div>

            <!-- Agenda -->
            <div
              v-if="meeting.agendaPoints.length"
              class="content pb-10 flex items-center w-full"
              @click="showAgenda"
            >
              <fa-icon
                class="mr-6"
                :icon="['fal', 'list']"
              />
              <p class="font-semibold flex-1">
                {{ $t('meeting.agendaPoints', { amount: meeting.agendaPoints.length }) }}
              </p>
              <fa-icon
                class="text-5xl"
                :icon="['fal', 'angle-right']"
              />
            </div>

            <!-- Suggestions -->
            <div
              class="content pb-10 flex items-center w-full"
              @click="showSuggestions"
            >
              <fa-icon
                class="mr-8"
                :icon="['fal', 'lightbulb']"
              />

              <p class="font-semibold flex-1">
                {{ $tc('meeting.suggestionItems', meeting.suggestions.length) }}
              </p>
              
              <fa-icon
                class="text-5xl"
                :icon="['fal', 'angle-right']"
              />
            </div>

            <people
              v-if="meeting.participants.length"
              text="global.participantsPlural"
              :module-color="moduleColor"
              :profiles="meeting.participants"
              @show-people="showProfiles('participants')"
            />

            <departments
              v-if="meeting.departments.length"
              :departments="meeting.departments"
            />

            <institution />
          </div>
        </div>
      </div>

      <div class="meetingActions flex w-full sticky justify-end">
        <button-el
          v-if="signupPossible"
          text="global.signUp"
          icon="check"
          background-color="success"
          text-color="white"
          class="mr-5 shadow-xsm"
          @click.native="optInOrOut('in')"
        />

        <button-el
          v-if="optoutPossible && signupPossible"
          text="global.optOut"
          icon="minus-circle"
          background-color="error"
          text-color="white"
          class="shadow-xsm"
          @click.native="optInOrOut('out')"
        />
      </div>
    </cover-view>
  </div>
</template>
<script>
import { isFuture } from 'date-fns';
import { format } from '@/utils/date-fns';
import { mapGetters } from 'vuex';
import Button from '@/components/shared/Button';
import { capitalize, get, isEmpty, cloneDeep } from 'lodash';
import PinFlow from '@/utils/pinFlow';
import CoverView from '@/views/shared/CoverView';
import { formatRepeat } from '@/utils/helpers';
import People from '@/components/shared/blocks/People';
import Institution from '@/components/shared/blocks/Institution';
import Departments from '@/components/shared/blocks/Departments';
import Tts from '@/components/shared/Tts';

export default {
  components: {
    ButtonEl: Button,
    CoverView,
    People,
    Institution,
    Departments,
    Tts
  },
  data() {
    return {
      opt: ''
    }
  },
  computed: {
    ...mapGetters({
      allMeetings: 'meetings/all',
      meeting: 'meetings/selected',
      activeProfile: 'profiles/activeProfile',
      pinValidation: 'profiles/pinValidation',
      institutionColor: 'institution/color',
      institutionName: 'institution/name',
      activePinFlow: 'general/activePinFlow'
    }),
    moduleColor() {
      return this.$route?.meta?.color;
    },
    signupPossible() {
      return this.meeting.signupPossible && !this.meeting.cancelled;
    },
    optoutPossible() {
      return isFuture(new Date(this.meeting.startDate));
    },
    category() {
      return capitalize(this.meeting.category);
    },
    repeatText() {
      const frequency = formatRepeat(this.meeting.repeat);
      return frequency ? this.$t(`rrule.${frequency}`) : '';
    },
    isAlreadyOptedInOut() {
      const participantIds = this.meeting.participants.map(participant => participant.id);

      return participantIds.includes(this.activeProfile.id);
    }
  },
  watch: {
    pinValidation(pinData) {
      if (!pinData.validating) return;

      if (this.activePinFlow === 'meeting-suggest') {
        this.suggest(pinData);
        return;
      }
      if (this.activePinFlow === 'meeting-signup') {
        if ((this.opt === 'in' && !this.isAlreadyOptedInOut) || (this.opt === 'out' && this.isAlreadyOptedInOut)) {
          this.signup(pinData);
          return;
        }
      }
    },
    activeProfile(profile) {
      if (this.activePinFlow !== 'meeting-signup') return;
      const participantIds = this.meeting.participants.map(participant => participant.id);

      if (participantIds.includes(profile.id)) {
        // user is already signed up
        this.$store.dispatch('general/setNextActiveOverlay', { name: 'error', data: {
          title: 'global.error',
          descriptionOne: 'meeting.alreadySignedUp',
          buttonText: 'global.close'
        } });
      }
    }
  },
  mounted() {
    this.$matomo.trackEvent('meetingEntry', 'Viewed', 'meetingEntryViewed');
    this.$store.dispatch('meetings/getSelectedMeeting', this.$route.params.id);
  },
  methods: {
    formatTime(meeting) {
      const startTime = format(new Date(meeting.startDate), 'HH:mm');
      const endTime = format(new Date(meeting.endDate), 'HH:mm');
      return `${startTime} - ${endTime}`;
    },
    formatDate(meeting) {
      //TODO: Handle end date being a different day than start (vacations)
      return capitalize(format(new Date(meeting.startDate), 'EEEE dd MMMM'));
    },
    showProfiles(profileType) {
      this.$matomo.trackEvent('ProfilesList', 'Clicked', profileType);
      this.$store.dispatch('general/setActiveOverlay', { name: 'participants-list', data: { title: `global.${profileType}`, profiles: this.meeting[profileType] } });
    },
    showAgenda() {
      this.$matomo.trackEvent('Agenda', 'Clicked');
      this.$store.dispatch('general/setActiveOverlay', { name: 'agenda', data: { title: this.$t( 'meeting.agenda' ), items: this.meeting.agendaPoints } });
    },
    showSuggestions() {
      this.$matomo.trackEvent('Suggestions', 'Clicked');
      this.$store.dispatch('general/setActiveOverlay', {
        name: 'suggestions',
        data: {
          title: this.$t( 'meeting.suggestions' ),
          suggestions: this.meeting.suggestions,
          sharedDepartments: this.meeting.departments
        }
      });
      this.$store.dispatch('general/setActivePinFlow', 'meeting-suggest' );
    },
    async optInOrOut(opt) {
      this.opt = opt;

      const flow = new PinFlow({
        entity: 'meeting',
        text: 'meeting.theMeeting',
        opt,
        title: this.meeting.title,
        sharedDepartments: this.meeting.departments
      });

      flow.startSignupFlow('meeting');
    },
    signup(pinData) {
      const signupData = {
        subscription: {
          id: this.meeting.id
        },
        profile: {
          profileId: this.activeProfile.id,
          profileType: this.activeProfile.type,
          pin: pinData.pin.toString()
        },
        opt: this.opt
      };

      this.$store.dispatch('meetings/meetingSignupScreen', signupData);
    },
    suggest(pinData) {
      const signupData = {
        profile: {
          profileId: this.activeProfile.id,
          profileType: this.activeProfile.type,
          pin: pinData.pin.toString(),
        },
        meetingId: this.meeting.id
      }

      this.$store.dispatch('meetings/meetingSuggest', signupData);
    }
  }
};
</script>

<style lang="scss">
  .meeting {

    .meetingContent {
      padding: 0 100px;
  
      &.participants {
        margin-top: 700px;
      }
    }
  
    .meetingActions {
      bottom: 70px;
      padding-right: 80px;
    }
  }
  
</style>
