<template>
  <div
    class="flex justify-center items-center text-base text-3xl rounded-lg h-18"
    :class="{ 'border' : backgroundColorHex === '#fff' || backgroundColorHex === '#ffffff', 'px-10 py-6' : padding, 'shadow-xsm' : shadow }"
    :style="[{ backgroundColor: backgroundColorHex, color: textColorHex }]"
  >
    <span v-if="icon">
      <fa-icon
        v-if="isSave && isSaving"
        class="mr-3 fa-spin"
        :icon="['fal', 'spinner']"
      />
      <fa-icon
        v-else
        :class="{ 'mr-3': text.length }"
        :icon="['fal', `${icon}`]"
      />
    </span>
    <span>{{ $t(text) }}</span>
  </div>
</template>

<script>

import colors from '@/utils/colors.js';
import { getAATextColor } from '@/utils/helpers.js';
import { mapGetters } from 'vuex';

export default {
    props: {
        text: {
            type: String,
            'default': ''
        },
        icon: {
            type: String,
            'default': ''
        },
        backgroundColor: {
            type: String,
            'default': ''
        },
        textColor: {
            type: String,
            'default': ''
        },
        padding: {
            type: Boolean,
            'default': true
        },
        shadow: {
            type: Boolean,
            'default': false
        },
        isSave: {
            type: Boolean,
            'default': false
        }
    },
    computed: {
        ...mapGetters({
            isSaving: 'general/isSaving'
        }),
        backgroundColorHex() {
            if (!this.backgroundColor) return '';
            if (this.backgroundColor.charAt(0) === '#') return this.backgroundColor;
            return colors[this.backgroundColor];
        },
        textColorHex() {
            if (this.textColor) return colors[this.textColor];

            if (!this.backgroundColor) return getAATextColor(colors.white);
            if (this.textColor.charAt(0) === '#') return this.textColor;
            return getAATextColor(this.backgroundColorHex);
        }
    }
};


</script>