var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "relative content flex items-start w-full",
      class: _vm.margin,
    },
    [
      _c("fa-icon", {
        staticClass: "mr-8",
        attrs: { icon: ["fal", _vm.icon] },
      }),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.description))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }