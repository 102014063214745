<template>
  <div class="module-list text-center flex items-center justify-between px-8">
    <router-link
      v-for="(module, index) in modules" 
      :key="index"
      :to="module.path"
      :class="[activePath === module.path ? `bg-${module.meta.color}` : `bg-${module.meta.color} bg-opacity-5 border-${module.meta.color}`]"
      active-class="active"
      class="module rounded-lg text-white justify-center items-center flex flex-col border border-opacity-25"
      @click.native="routerLinkClicked"
    >
      <fa-icon 
        v-if="!module.meta.isExternal"
        :icon="module.meta.icon"
        class="text-3xl bg-red"
        :class="{ [`text-${module.meta.color}`]: activePath !== module.path }"
      />

      <img
        v-else
        class="w-8 pb-2"
        :src="getModuleData(index).src"
        :alt="getModuleData(index).alt"
      >

      <p
        v-t="getModuleData(index).name"
        class="text-sm mt-1 font-bold"
        :class="{ [`text-${module.meta.color}`]: activePath !== module.path }"
      />
    </router-link>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import { isEmpty, get } from 'lodash';

export default {
  name: 'ModuleList',
  computed: {
    ...mapGetters({
      externalConnections: 'institution/externalConnections',
      loading: 'general/isLoading',
      activeOverlay: 'general/activeOverlay',
      settings: 'department/settings',
      workScheduleType: 'workSchedule/type',
      departmentId: 'department/id'
    }),
    modules() {
      const footerConfig = get(this.settings, 'screenConfig.footerConfig', []);

      const modules = this.$router.options.routes.filter( route => route.path !== '/' && route?.meta?.hideInModuleList !== true );

      const filteredModules = modules.filter(module => {
        if (module.meta.isExternal && !this.externalConnections.length) return false;
        return footerConfig.includes(module.name);
      })

      return this.mapOrder(filteredModules, footerConfig, 'name');
    },
    activePath() {
      return this.modules.filter( module => this.$route.path.indexOf(module.path) > -1 )[0]?.path;
    },
  },
  methods: {
    mapOrder(array, order, key) {
      array.sort((a, b) => {
        const A = a[key];
        const B = b[key];

        return order.indexOf(A) > order.indexOf(B) ? 1 : -1;
      });
      
      return array;
    },
    getModuleData(index) {
      const module = this.modules[index];
      const meta = module.meta;
      const icon = meta.isExternal ? this.externalConnections[meta.connectionIndex].icon : '';
      const isActive = this.activePath === this.modules[index].path;

      return {
        alt: icon,
        src: `../../assets/img/external/${icon}${!isActive ? '-active' : ''}.png`,
        name: meta.isExternal ? this.externalConnections[meta.connectionIndex].name : this.$t(`moduleNames.${module.name}`)
      };
    },
    routerLinkClicked() {
      if (!isEmpty(this.activeOverlay)) {
        this.$store.dispatch('general/setActiveOverlay', {});
        this.$emit('close-overlay');
      }
    }
  }
};
</script>
<style lang="scss">
  .module-list {
    height: 160px;
  }
  .module {
    width: 90px;
    height: 90px;
  }
</style>
