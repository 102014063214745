import { format as formatDateFns, formatDistanceToNow as formatDistanceToNowDateFns } from 'date-fns'
import { da, de } from 'date-fns/locale'
import store from '@/store';

const locales = { da, de };

export function format(date, formatStr, options) {
  const lang = store.getters['institution/locale'];
  return formatDateFns(date, formatStr, {
    ...options,
    locale: locales[lang],
  })
}

export function formatDistanceToNow(date, options) {
  const lang = store.getters['institution/locale'];
  return formatDistanceToNowDateFns(date, {
    ...options,
    locale: locales[lang],
  })
}