const colors = {
  'schedule-dark-default': '#37A2A7',
  'schedule-dark-aa': '#3CADB2',
  'schedule-light': '#F5FAFB',
  'schedule-light-default': '#37A2A7',
  'laterSchedule-light-default': '#008C93',
  'schedule-light-aa': '#2B8084',
  'schedule-light-aaa': '#1E5F62',
  'booking-dark-default': '#ADC301',
  'booking-dark-aa': '#ADC301',
  'booking-dark-aaa': '#B6CE00',
  'booking-light-default': '#ADC301',
  'booking-light-aa': '#6F7D00',
  'booking-light-aaa': '#535D00',
  'about-dark-default': '#58A202',
  'about-dark-aa': '#60B200',
  'about-dark-aaa': '#84D524',
  'about-light-default': '#58A202',
  'about-light-aa': '#468201',
  'about-light-aaa': '#376401',
  'meals-dark-default': '#0086E7',
  'meals-dark-aa': '#199FFF',
  'meals-dark-aaa': '#7CC8FF',
  'meals-light-default': '#0080DC',
  'meals-light-aa': '#0079CF',
  'meals-light-aaa': '#005A9B',
  'activities-dark-default': '#9848FF',
  'activities-dark-aa': '#B881FF',
  'activities-dark-aaa': '#D4B2FF',
  'activities-light-default': '#381466',
  'externalActivities-light-default': '#591EA3',
  'activities-light-aa': '#381466',
  'activities-light-aaa': '#381466',
  'galleries-dark-default': '#D565E0',
  'galleries-dark-aa': '#F273FF',
  'galleries-dark-aaa': '#F7A1FF',
  'galleries-light-default': '#622A67',
  'galleries-light-aa': '#622A67',
  'galleries-light-aaa': '#622A67',
  'meetings-dark-default': '#EA3890',
  'meetings-dark-aa': '#FF5CAD',
  'meetings-dark-aaa': '#FFA5D1',
  'meetings-light-default': '#932C5F',
  'meetings-light-aa': '#932C5F',
  'meetings-light-aaa': '#932C5F',
  'news-dark-default': '#F64800',
  'news-dark-aa': '#FF6B2E',
  'news-dark-aaa': '#FFAC89',
  'news-light-default': '#EA4400',
  'news-light-aa': '#D83F00',
  'news-light-aaa': '#A22F00',
  'calendar-dark-default': '#FF765B',
  'calendar-dark-aa': '#FF765B',
  'calendar-dark-aaa': '#FFAA9A',
  'calendar-light-default': '#FF765B',
  'calendar-light-aa': '#BD5743',
  'calendar-light-aaa': '#904132',
  'journeyPlanner-dark-default': '#363636',
  'journeyPlanner-red': '#F93D45',
  'journeyPlanner-dark-red': '#B41730',
  'journeyPlanner-yellow': '#FFBE32',
  'weather-light-default': '#363636',

  // generic colors
  'charcoal': '#323232',
  'charcoal-light': '#989898',
  'charcoal-xlight': '#E0E0E0',
  'charcoal-xxlight': 'rgba(50, 50, 50, 0.1)',
  'charcoal-opacity': 'rgba(50, 50, 50, 0.9)',
  'black-025': 'rgba(0, 0, 0, 0.25)',
  'white': '#ffffff',
  'transparent-white': '#FFFFFF1A',
  'success': '#69C200',
  'error': '#E20000',
  'orange': '#FF765B',
  'warning': '#E2B100',
  'neutral200': "#EEE",
  'neutral300': "#DFDFDF",
  'neutral400': "#D1D1D1",
  'neutral500': "#A4A4A4",
  'darkOcean100': "#DAF1F4",
  'darkOcean600': '#00434E',

  // colors based on widget type
  'ACTIVITIES': '#381466',
  'BOOKING': '#ADC301',
  'EXTERNAL_ACTIVITIES': '#591EA3',
  'GALLERY': '#622A67',
  'JOURNEY_PLANNER': '#363636',
  'MEAL_PLAN': '#0080DC',
  'MEETINGS': '#932C5F',
  'NEWS': '#EA4400',
  'OUR_HOME': '#000',
  'SIMPLE_WORK_SCHEDULE': '#37A2A7',
  'WAYFINDER': '#000',
  'WEATHER': '#363636',
  'WORK_SCHEDULE': '#37A2A7',
  'LATER_WORK_SCHEDULE': '#008C93'
};

module.exports = colors;