var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "relative h-full overflow-hidden",
      class: `bg-${_vm.widget.colorId}`,
      style: _vm.backgroundStyles,
    },
    [
      _c(
        "div",
        { staticClass: "absolute w-full h-full overflow-y-scroll pb-18" },
        [
          _c(
            "div",
            {
              staticClass:
                "flex flex-col justify-center items-stretch pt-4 pb-32",
            },
            [
              !_vm.isLoading
                ? _c(
                    "TransitionGroup",
                    { attrs: { name: "fade-out-in", tag: "ul" } },
                    _vm._l(_vm.filteredDepartments, function (departure) {
                      return _c(
                        "li",
                        {
                          key: departure.JourneyDetailRef.ref,
                          staticClass: "flex justify-center",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-row h-14 shadow-lg my-1 mx-6 w-full bg-journeyPlanner-dark-default",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex justify-center w-16 rounded-lg",
                                  class: `bg-${_vm.departureColor(
                                    departure.type
                                  )}`,
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "self-center font-bold text-center",
                                      class: [
                                        departure.type === "S"
                                          ? "text-white"
                                          : "",
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(departure.name) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex justify-center w-full rounded-lg text-white bg-gradient-to-l from-transparent-white",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "m-3 mr-0 flex flex-col justify-start w-full text-xs",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "truncate w-44" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(departure.stop) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "font-bold" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(departure.direction) +
                                            "\n                "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  departure.rtTime
                                    ? _c(
                                        "div",
                                        { staticClass: "self-center mr-3" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "font-bold text-2xl leading-none",
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(departure.rtTime) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "bg-error rounded-sm text-center text-xs w-max px-2 mx-auto",
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "journeyPlanner.newTime"
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "self-center mr-3 font-bold text-2xl",
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(departure.time) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade-out-in" } }, [
        _vm.isLoading
          ? _c("div", { staticClass: "isLoading w-full h-full bg-transparent" })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "flex flex-col bg-gradient-to-t from-charcoal via-charcoal absolute bottom-0 w-full pt-12 pb-4 pb-3 pointer-events-none",
        },
        [
          _c(
            "p",
            {
              staticClass:
                "text-center text-white text-3xl font-semibold mb-8 pt-16",
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("travelPlan.moduleName")) + "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "tabs flex items-center justify-between rounded-full max-w-xs mx-auto p-1 text-white text-xs pointer-events-auto",
            },
            _vm._l(_vm.filterOptions, function (filter, index) {
              return _c(
                "p",
                {
                  key: index,
                  staticClass: "day px-4 opacity-100",
                  class: {
                    "bg-white text-black rounded-full":
                      _vm.selectedFilter.value === filter.value,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.selectFilter(index, filter)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t(`${filter.text}`)) + "\n      "
                  ),
                ]
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }