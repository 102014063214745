import gql from "graphql-tag";

export default gql`
query mealRange ($filter: Filter!, $dateFrom: Date!, $dateTo: Date!, $timezone: TimeZone!) {
  mealRange (filter: $filter, dateFrom: $dateFrom, dateTo: $dateTo, timezone: $timezone) {
    cancelled
      coverImage {
        blurhash
        id
        source
      }
      departments {
        color
        icon
        id
        name
      }
      description
      documents {
        description
        id
        title
        url
      }
      endDate
      id
      institution {
        color
        id
        name
      }
      location
      maxSignups
      participants {
        ... on Employee {
          alias
          displayImage
          displayName
          id
        }
        ... on Resident {
          alias
          displayImage
          displayName
          id
        }
      }
      participating
      parts {
          description
          picture {
              blurhash
              description
              id
              source
          }
          title
      }
      pictograms {
          blurhash
          id
          source
          type
      }
      pictures {
          blurhash
          description
          id
          inMyArchive
          source
      }
      signupEnd
      signupPossible
      signupStart
      startDate
      title
      videos {
          blurhash
          id
          provider
          thumbnail
          url
      }
  }
}`