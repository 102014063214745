var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex justify-evenly w-full mt-96" },
    [
      !_vm.isEditing
        ? _c("button-el", {
            staticClass: "self-center",
            attrs: {
              text: "scheduleShift.editShiftButton",
              icon: "edit",
              shadow: true,
              "background-color": "white",
            },
            nativeOn: {
              click: function ($event) {
                _vm.isEditing = !_vm.isEditing
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isEditing && !_vm.confirmation
        ? _c(
            "div",
            { staticClass: "flex justify-evenly w-full" },
            [
              _c("button-el", {
                staticClass: "self-center",
                attrs: {
                  text: _vm.shift.cancelled
                    ? "scheduleShift.reactivateButton"
                    : "scheduleShift.cancelButton",
                  icon: "ban",
                  shadow: true,
                  "background-color": "warning",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.chooseAction("cancel")
                  },
                },
              }),
              _vm._v(" "),
              _c("button-el", {
                staticClass: "self-center",
                attrs: {
                  text: "scheduleShift.replace",
                  icon: "fa-exchange-alt",
                  shadow: true,
                  "background-color": "success",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.editShift("swap")
                  },
                },
              }),
              _vm._v(" "),
              _c("button-el", {
                staticClass: "self-center",
                attrs: {
                  text: "scheduleShift.delete",
                  icon: "trash",
                  shadow: true,
                  "background-color": "error",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.chooseAction("delete")
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.confirmation
        ? _c(
            "div",
            { staticClass: "flex justify-evenly w-full" },
            [
              _c("button-el", {
                staticClass: "self-center",
                attrs: {
                  text: _vm.confirmButtonText,
                  icon: "ban",
                  shadow: true,
                  "background-color":
                    _vm.action === "cancel" ? "warning" : "error",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.editShift(_vm.action)
                  },
                },
              }),
              _vm._v(" "),
              _c("button-el", {
                staticClass: "self-center",
                attrs: {
                  text: "global.cancel",
                  icon: "undo",
                  shadow: true,
                  "background-color": "white",
                },
                nativeOn: {
                  click: function ($event) {
                    _vm.confirmation = !_vm.confirmation
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }