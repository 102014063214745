export default {
  get(color, height = 50, imageUrl = '') {
    const gradient = `linear-gradient(to bottom, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 20%, ${ color} ${height}%, ${ color } 10%)`;

      const backgroundImage = `${gradient}, url(${imageUrl})`;
      
      return `
          backgroundColor: ${!imageUrl ? color : 'none'};
          opacity: ${!imageUrl ? '0.9' : '1'};
          backgroundImage: ${backgroundImage};
          height: 100%;
          backgroundPosition: center;
          backgroundRepeat: no-repeat;
          backgroundSize: cover;
          border-radius: 3px;
          flex-basis: 100%;
      `;
  }
};
