var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    {
      attrs: {
        "enter-active-class": "animate__animated animate__fadeIn",
        "leave-active-class": "animate__animated animate__fadeOut",
        duration: 800,
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "media-preview w-full bg-charcoal-opacity z-30 fixed flex flex-col justify-center",
        },
        [
          _c(
            "div",
            {
              staticClass: "flex flex-col items-center w-full",
              class: {
                "h-2/4 pb-24": _vm.mediaType !== "documents",
                "h-full pb-6": _vm.mediaType === "documents",
              },
            },
            [
              _vm.mediaType !== "documents"
                ? _c(
                    "carousel",
                    {
                      class:
                        _vm.items.length === 1 ? "pointer-events-none" : "",
                      attrs: {
                        name: "mediaPreview",
                        "ref-name": "mediaPreview",
                        pagination: false,
                        "start-index": _vm.currentSlide,
                        "slide-duration": _vm.slideshowDuration,
                        autoplay: _vm.autoplay,
                      },
                      on: {
                        swiped: function ($event) {
                          _vm.currentSlide = $event
                        },
                        "slide-change": _vm.goToSlide,
                      },
                    },
                    _vm._l(_vm.items, function (item, index) {
                      return _c(
                        "slide",
                        {
                          key: index,
                          style: [
                            _vm.mediaType === "videos"
                              ? { width: "950px" }
                              : "",
                          ],
                          on: {
                            slideclick: function ($event) {
                              return _vm.handleSlideClick(index)
                            },
                          },
                        },
                        [
                          _vm.mediaType === "pictures"
                            ? _c("div", {
                                staticClass: "px-10 bg-cover bg-center",
                                style: {
                                  backgroundImage: `url(${item.source})`,
                                  height: "800px",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.mediaType === "videos"
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-3xl text-white h-full flex justify-center w-full",
                                    on: { click: _vm.videoClicked },
                                  },
                                  [
                                    _c("span", {
                                      staticClass:
                                        "h-full absolute w-full z-10",
                                    }),
                                    _vm._v(" "),
                                    item.provider === "YOUTUBE" &&
                                    _vm.currentSlide === index
                                      ? _c("Youtube", {
                                          ref: "youtube",
                                          refInFor: true,
                                          attrs: {
                                            "video-id": _vm.cleanYoutubeUrl(
                                              item.url
                                            ),
                                            "player-vars": _vm.playerVars,
                                            width: 950,
                                            height: 550,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.provider === "VIMEO" &&
                                    _vm.currentSlide === index
                                      ? _c("LazyVimeo", {
                                          ref: "lazyVideo",
                                          refInFor: true,
                                          attrs: {
                                            src: item.url,
                                            autoplay: true,
                                            "max-width": "950px",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.provider === "DREAMBROKER" &&
                                    _vm.currentSlide === index
                                      ? _c("iframe", {
                                          staticClass: "w-full",
                                          attrs: {
                                            height: "550",
                                            src: `${item.url}?autoplay=true`,
                                            allow: "autoplay",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.mediaType === "media"
                            ? _c("div", { staticClass: "h-full" }, [
                                item.__typename === "GalleryPicture"
                                  ? _c("img", {
                                      staticClass:
                                        "px-10 object-cover w-full h-auto",
                                      attrs: { src: item.source },
                                    })
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-full h-full flex justify-center",
                                      },
                                      [
                                        _vm.mediaType === "media" &&
                                        _vm.currentSlide === index
                                          ? _c("iframe", {
                                              staticClass: "w-full h-full",
                                              attrs: {
                                                src: `${item.url}?autoplay=true`,
                                                allow: "autoplay",
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.mediaType === "documents"
                ? _c("vue-pdf-embed", {
                    staticClass: "w-full overflow-scroll h-5/6",
                    attrs: {
                      source: _vm.items[_vm.currentSlide].url,
                      "disable-text-layer": true,
                    },
                    on: { rendered: _vm.rendered },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.mediaType === "videos"
                ? _c("video-controls", {
                    staticClass: "mb-28 mt-8",
                    on: {
                      playVideo: function ($event) {
                        return _vm.handleVideoEvent("playVideo")
                      },
                      pauseVideo: function ($event) {
                        return _vm.handleVideoEvent("pauseVideo")
                      },
                      resetView: function ($event) {
                        return _vm.handleVideoEvent("stopVideo")
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex mt-8" },
                [
                  _vm.allowArchiveAdd && !_vm.isInMyArchive
                    ? _c("button-el", {
                        staticClass: "shadow-xsm mb-10",
                        attrs: {
                          text: `media.addToMy.${_vm.mediaType}`,
                          icon: "cloud-download",
                          "background-color": "success",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.archiveAdd.apply(null, arguments)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isInMyArchive
                    ? _c("button-el", {
                        staticClass: "shadow-xsm mb-10",
                        attrs: {
                          text: `media.removeFromMy.${_vm.mediaType}`,
                          icon: "trash-alt",
                          "background-color": "error",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.archiveRemove.apply(null, arguments)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("carousel-controls", {
                staticClass: "mt-12",
                attrs: {
                  "total-slides": _vm.items.length,
                  "current-slide": _vm.currentSlide + 1,
                  "media-type": _vm.mediaType,
                  autoplay: _vm.autoplay,
                },
                on: {
                  "autoplay-toggled": _vm.toggleAutoplay,
                  previous: function ($event) {
                    _vm.currentSlide = _vm.currentSlide - 1
                  },
                  next: function ($event) {
                    _vm.currentSlide = _vm.currentSlide + 1
                  },
                  close: function ($event) {
                    return _vm.$emit("close-preview")
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }