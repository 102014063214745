var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      staticClass: "flex items-center justify-center",
      class: { onStartPage: _vm.onStartPage },
    },
    [
      _c("img", {
        staticClass: "logo h-20 w-auto",
        attrs: { src: _vm.logoSource },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }