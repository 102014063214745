var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "carousel",
    {
      attrs: {
        name: "meetings",
        "ref-name": "meetings",
        "slide-duration": _vm.widgetSlideDuration,
      },
      on: { "slide-clicked": _vm.slideClicked },
    },
    _vm._l(_vm.meetings, function (meeting, index) {
      return _c(
        "slide",
        {
          key: index,
          class: [
            _vm.slideStyles,
            _vm.widget.frHeight > 1 ? "justify-center" : "justify-end",
          ],
          style: _vm.getBackgroundStyles(index),
        },
        [
          _vm.isItemNow(meeting)
            ? _c("status", { staticClass: "mb-6" })
            : _vm._e(),
          _vm._v(" "),
          _c("fa-icon", {
            staticClass: "text-2xl mb-4 text-white",
            attrs: { icon: ["fas", `${_vm.widget.icon}`] },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "text-2xl mb-6" }, [
            _vm._v("\n      " + _vm._s(meeting.title) + "\n    "),
          ]),
          _vm._v(" "),
          _vm.widget.frHeight > 1
            ? _c("date-time", {
                staticClass: "mb-6",
                attrs: { "start-date": meeting.startDate },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.widget.frHeight > 1
            ? _c(
                "div",
                { staticClass: "flex items-center justify-center" },
                [
                  _c("fa-icon", {
                    staticClass: "text-xl text-white mr-2",
                    attrs: { icon: ["fas", "user"] },
                  }),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(meeting.participants.length) +
                        " " +
                        _vm._s(_vm.$t("global.participants"))
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }