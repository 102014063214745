import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import fetch from 'unfetch';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { ls } from '@/utils/storage';
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import auth from '@/utils/auth';

const httpLink = createHttpLink({ uri: process.env.API_URL, fetch });

let token = '';

function getToken() {
    return new Promise(resolve => setTimeout(() => {
        token = ls.get('api_token');
        resolve(true);
    }, 200));
}

const authLink = setContext(async (_, { headers }) => {
    await getToken();
    
    return {
        headers: {
            ...headers,
            authorization: token
        }
    };
});

const retryIf = (error) => {
    const doNotRetryCodes = [500, 400];
    return !!error && !doNotRetryCodes.includes(error.statusCode);
};

const retryLink = new RetryLink({
    delay: {
        initial: 100,
        max: 2000,
        jitter: true,
    },
    attempts: {
        max: 5,
        retryIf,
    },
});
  

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message }) =>
        console.log(`GraphQL Error: ${message}`),
      );
    }

    if (networkError) {
        if (networkError.statusCode === 401) {
            auth.authenticate();
        } else {
            console.log(`Network Error: ${networkError.message}`);
        }
    }
});

export const apolloClient = new ApolloClient({
    link: from([
        authLink,
        retryLink,
        errorLink,
        httpLink
    ]),
    cache: new InMemoryCache(),
});

const apolloProvider = new VueApollo({
    defaultClient: apolloClient
});

Vue.use(VueApollo)

export default apolloProvider;