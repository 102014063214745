<template>
  <div class="flex flex-row items-center justify-between w-full px-32">
    <button-el
        class="content bold"
        text="global.play"
        icon="play"
        backgroundColor="success"
        @click.native="$emit('playVideo')"
    />

    <button-el
        class="content bold"
        text="global.pause"
        icon="pause"
        backgroundColor="error"
        @click.native="$emit('pauseVideo')"
    />

    <button-el
        class="content bold"
        text="global.restart"
        icon="undo-alt"
        backgroundColor="warning"
        @click.native="$emit('resetView')"
    />
  </div>
</template>

<script>
import Button from '@/components/shared/Button';

export default {
  components: {
    ButtonEl: Button
  },
  props: {
    
  },
  computed: {
    
  },
  methods: {
    
  }
}
</script>