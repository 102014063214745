var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "footer",
    { staticClass: "flex charcoal px-8 py-6" },
    [
      !_vm.useGlobalDepartmentSwitcher ? _c("footer-date-time") : _vm._e(),
      _vm._v(" "),
      _vm.useGlobalDepartmentSwitcher ? _c("department-switcher") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "actions flex-grow flex justify-center items-center text-3xl gap-3",
        },
        [
          !_vm.onStartPage
            ? _c(
                "div",
                {
                  staticClass:
                    "action flex justify-center items-center rounded-lg border border-charcoal border-opacity-10",
                  on: { click: _vm.goBackInHistory },
                },
                [_c("fa-icon", { attrs: { icon: ["fal", "arrow-left"] } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass:
                "action flex justify-center items-center rounded-lg border bg-opacity-5 border-charcoal border-opacity-10",
              class: { ["bg-charcoal"]: _vm.onStartPage },
              attrs: { to: "/" },
              nativeOn: {
                click: function ($event) {
                  return _vm.trackEvent.apply(null, arguments)
                },
              },
            },
            [
              _c("img", {
                staticClass: "w-10 h-10",
                attrs: { src: require("../../../assets/img/ibg_logo.svg") },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "dimmer action flex justify-center items-center border border-charcoal rounded-lg bg-opacity-5 border-opacity-10",
              class: { ["bg-charcoal"]: _vm.showDimmerSlider },
              on: {
                click: function ($event) {
                  _vm.showDimmerSlider = !_vm.showDimmerSlider
                },
              },
            },
            [
              _vm.showDimmerSlider
                ? _c(
                    "div",
                    {
                      staticClass:
                        "dimmerSlider absolute w-96 -mt-40 bg-charcoal px-6 py-4 rounded-xl z-40 shadow-lg",
                    },
                    [
                      _c(
                        "vue-slider",
                        _vm._b(
                          {
                            directives: [
                              {
                                name: "clickoutside",
                                rawName: "v-clickoutside",
                                value: {
                                  excludeString: ".dimmer, .dimmer>*",
                                  handler: _vm.onClickoutside,
                                },
                                expression:
                                  "{ excludeString: '.dimmer, .dimmer>*', handler: onClickoutside }",
                              },
                            ],
                            attrs: {
                              value: _vm.dimmerOpacity,
                              "rail-style": { backgroundColor: "white" },
                              "process-style": { backgroundColor: "grey" },
                              direction: "rtl",
                            },
                            on: { change: _vm.dimScreen },
                          },
                          "vue-slider",
                          _vm.sliderOptions,
                          false
                        )
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showDimmerSlider
                ? _c(
                    "div",
                    {
                      staticClass:
                        "w-96 h-12 -mt-28 absolute flex justify-center",
                    },
                    [
                      _c("span", {
                        staticClass:
                          "w-12 h-12 bg-charcoal absolute transform rotate-45 shadow-lg",
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("fa-icon", { attrs: { icon: ["far", "lightbulb-slash"] } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("video", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        ref: "video",
      }),
      _vm._v(" "),
      _vm.useGlobalDepartmentSwitcher
        ? _c("footer-date-time", { staticClass: "text-right" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "clock flex-none text-right flex flex-col justify-center",
        },
        [_c("flip-clock")],
        1
      ),
      _vm._v(" "),
      _vm.showFeedbackDialog
        ? _c("feedback-dialog", {
            on: {
              cancel: function ($event) {
                _vm.showFeedbackDialog = false
              },
              startRecording: _vm.startFeedbackCapture,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showStopFeedbackDialog
        ? _c("stop-feedback-dialog", {
            on: {
              cancel: function ($event) {
                _vm.showStopFeedbackDialog = false
              },
              stopRecording: _vm.stopFeedbackCapture,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isRecording ? _c("recording-notification") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }