<template>
  <div
    class="relative content flex items-start w-full"
    :class="margin"
  >
    <fa-icon
      class="mr-8"
      :icon="['fal', icon]"
    />
    <span>{{ description }}</span>
  </div>
</template>

<script>

export default {
  props: {
    description: {
      type: String,
      'default': ''
    },
    icon: {
      type: String,
      'default': 'align-left'
    },
    margin: {
      type: String,
      'default': 'mb-14'
    }
  }
}
</script>