import { ls, ss } from '@/utils/storage';
import store from '@/store';
import axios from 'axios';
import * as Sentry from '@sentry/vue';

export default {
  isValid() {
    const tokenTTL = ls.get('api_token_ttl', 0);
    const created = new Date(ls.get('api_token_created')).getTime();
    const expiry = created + (tokenTTL * 1000);
    const now = new Date().getTime();

    const isValid = expiry > now;

    if (!isValid) {
      ls.remove('api_token');
      ls.remove('api_token_ttl');
      ls.remove('department_data');
      ls.remove('dimmer');
      ls.remove('api_token_ttl');
      ls.remove('api_token_created');
    }

    return isValid;
  },
  async get(loginKey) {
    const data = (await axios.get(`https://ibg.proreact.dk/api/Departments/${loginKey}/login`)).data;

    if (!data.id) {
      throw new Error('No data found for department with loginKey ', loginKey);
    }

    ls.set('api_token', data.id);
    ls.set('api_token_ttl', data.ttl);
    ls.set('api_token_created', data.created);
    ls.set('department_data', data.department);
    Sentry.setUser({ departmentId: data.department.id });

    store.dispatch('department/setDepartmentId', data.department.id);
  },
  async authenticate() {
    const tempKey = ss.get('temp_login_key');
    const mainKey = new URLSearchParams(window.location.search).get('loginKey') || ss.get('main_login_key');
    
    ls.set('login_key', loginKey);
    ss.set('main_login_key', mainKey);

    let loginKey = tempKey || mainKey;

    console.log('loginkey', loginKey);

    // if (process.env.NODE_ENV === 'development') {
    //   loginKey = process.env.LOGIN_KEY;
    // }

    const departmentData = ls.get('department_data');
  
    if (this.isValid() && departmentData) {
      await store.dispatch('department/setDepartmentId', departmentData.id);
      return true;
    }
    
    if (loginKey.length) {
      await this.get(loginKey);
    } else {
      throw new Error('No loginKey provided');
    }
  }
};