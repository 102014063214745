<template>
  <div class="content-views z-50">
    <div
      v-for="(filter, index) in contentViews"
      :key="index"
      class="content-view rounded-full p-4 mr-5 text-4xl shadow-lg"
      :class="{
        [ `bg-${moduleColor}` ]: activeContentView.name === filter.name,
        'bg-white': activeContentView.name !== filter.name,
        'text-white': activeContentView.name === filter.name,
        'separate': filter.position === 'right'
      }"
      @click="selectFilter(index)"
    >
      <fa-icon :icon="['fal', `${filter.icon}`]" />
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  props: {
    contentViews: {
        type: Array,
        'default': () => []
    }
  },
  computed: {
    ...mapGetters({
      activeContentView: 'general/activeContentView'
    }),
    moduleColor() {
      return this.$route.meta.color;
    }
  },
  methods: {
    selectFilter(index) {
      this.$emit('filter-selected', index);
      document.querySelector('.route-wrapper').scrollTop = 1200;
    }
  }
};
</script>

<style lang="scss">
  .content-views {
      height: 90px;
      margin-bottom: -25px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background: transparent;
    }

    .content-view {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90px;
      height: 90px;

      &.separate {
        position: absolute;
        right: 50px;
      }
    }
  
</style>
