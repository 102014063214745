var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50",
        attrs: { "aria-live": "assertive" },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "w-full flex flex-col items-center space-y-4 sm:items-end",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "max-w-sm w-full bg-red-500 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden",
              },
              [
                _c("div", { staticClass: "p-4" }, [
                  _c("div", { staticClass: "flex items-center" }, [
                    _c(
                      "div",
                      { staticClass: "w-0 flex-1 flex justify-between" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "w-0 flex-1 text-sm font-medium text-white",
                          },
                          [
                            _vm._v(
                              "\n              Video/Audio feedback is being recorded.\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }