<template>
  <div class="fixed w-80 right-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
    <img
      :src="`../../../assets/img/emptyViews/${viewType}.svg`"
      alt="No data"
      class="w-60 m-auto"
    >
    <div
      class="text-center mt-32 text-3xl font-semibold"
      :class="`text-${color}`"
    >
      <fa-icon
        class="text-4xl pb-4"
        :icon="[ 'fas', icon(viewType) || 'clock' ]"
      />
      <p>{{ $t(`emptyViews.${viewType}`) }}</p>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    viewType: {
      type: String,
      'default': ''
    },
    color: {
      type: String,
      'default': ''
    }
  },
  computed: {
    imageName() {
      return this.widgetType.toLowerCase();
    }
  },
  methods: {
    icon(view) {
      switch (view) {
        case 'WorkSchedule':
          return 'id-card';
        case 'Booking':
          return 'key';
        case 'MealPlan':
          return 'utensils';
        case 'Activities':
          return 'running';
        case 'Galleries':
          return 'photo-video';
        case 'Meetings':
          return 'comments-alt';
          case 'News':
          return 'comment-alt-lines';
        default:
          return 'grey';
      }
    },
  }
}
</script>