var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "flex justify-between self-center px-20 pb-20 -mt-12 rounded-t-3xl",
      },
      [
        _c("div", { staticClass: "largeListItemHeader" }, [
          _vm._v("\n      " + _vm._s(_vm.$t(_vm.data.title)) + "\n    "),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pb-10 pl-20" },
      [
        _c("p", { staticClass: "content bold mb-4" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("global.startDate")) + "\n    "),
        ]),
        _vm._v(" "),
        _c("date-selector", {
          attrs: {
            color: _vm.moduleColor,
            "unavailable-dates": _vm.unavailableDates,
          },
          on: { "date-selected": _vm.startDateSelected },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "w-full pb-10 px-20" },
      [
        _c("p", { staticClass: "content bold mb-4" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("global.startTime")) + "\n    "),
        ]),
        _vm._v(" "),
        _c("time-selector", {
          attrs: {
            type: "start",
            "selected-date": _vm.selected.startDate,
            "start-time": _vm.startTimeFrom,
            "end-time": "23:45",
            color: _vm.moduleColor,
            "time-slots": _vm.getTimeSlots(),
          },
          on: {
            "time-selected": _vm.startTimeSelected,
            deselect: function ($event) {
              _vm.selected.startTime = ""
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pb-10 pl-20" },
      [
        _c("p", { staticClass: "content bold mb-4" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("global.endDate")) + "\n    "),
        ]),
        _vm._v(" "),
        _c("date-selector", {
          attrs: {
            color: _vm.moduleColor,
            "end-date": _vm.endDateTo,
            "initial-date": _vm.startDateObject,
          },
          on: { "date-selected": _vm.endDateSelected },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "w-full pb-10 px-20" },
      [
        _c("p", { staticClass: "content bold mb-4" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("global.endTime")) + "\n    "),
        ]),
        _vm._v(" "),
        _c("time-selector", {
          attrs: {
            type: "end",
            "selected-date": _vm.selected.endDate,
            "start-time": _vm.startTimeTo,
            "end-time": _vm.endTimeTo,
            color: _vm.moduleColor,
            "time-slots": _vm.getTimeSlots(),
          },
          on: {
            "time-selected": function ($event) {
              _vm.selected.endTime = $event
            },
            deselect: function ($event) {
              _vm.selected.endTime = ""
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.canGoNext
      ? _c(
          "div",
          { staticClass: "flex w-full justify-center" },
          [
            _c("button-el", {
              staticClass: "mr-5 mt-8 shadow-xsm",
              attrs: {
                text: "modals.next",
                icon: "arrow-right",
                "background-color": "success",
                "text-color": "white",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.next.apply(null, arguments)
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }