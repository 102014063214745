<template>
  <div class="px-12 relative">
    <div class="flex-2 h-full overflow-y-scroll pb-80">
      <div
        v-for="(suggestion, index) in data.suggestions"
        :key="index"
        class="participants content inline-flex w-full mb-10 px-20"
      >
        <img
          :src="suggestion.suggester.displayImage"
          :class="{ '-m-1': index !== 0 }"
          class="w-24 h-24 rounded-full border border-gray-200 mr-1 shadow-xsm"
        >

        <div class="suggestion relative p-8 -mt-1 ml-1 bg-white shadow-xsm rounded-xl">
          <p class="label">
            {{ suggestion.suggester.displayName }}
          </p>
          <p>{{ suggestion.title }}</p>
        </div>
      </div>
    </div>

    <div class="flex w-full sticky justify-center bottom-80">
      <button-el
        class="bold"
        text="meeting.writeSuggestion"
        icon="pen-alt"
        background-color="success"
        @click.native="addSuggestion"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/shared/Button';

export default {
  components: {
    ButtonEl: Button
  },
  computed: {
    ...mapGetters({
      activeOverlay: 'general/activeOverlay'
    }),
    data() {
      return this.activeOverlay.data;
    }
  },
  methods: {
    async addSuggestion() {
      await this.$store.dispatch('general/setNextActiveOverlay', { name: 'write-suggestion', data: {
        title: this.data.title,
        sharedDepartments: this.data.sharedDepartments
      } });

      this.$emit('close-overlay');
    }
  }
}
</script>

<style lang="scss">
.suggestions {
  min-height: 1200px;
}
.meetingActions {
  bottom: 150px;
}

.suggestion::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: 2px;
  top: 38px;
  box-sizing: border-box;
  border: 10px solid silver;
  border-color: transparent transparent #fff #fff;
  transform-origin: 0 0;
  transform: rotate(45deg);
  box-shadow: -1px 1px 3px 0 rgba(0, 0, 0, 0.05);
}

</style>
