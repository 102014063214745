var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "text-center text-white" }, [
    _c("p", { staticClass: "font-thin" }, [_vm._v(_vm._s(_vm.startDay))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex items-center justify-center" },
      [
        _c("fa-icon", {
          staticClass: "text-white text 3xl mr-2",
          attrs: { icon: ["fal", "clock"] },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "text-xl" }, [_vm._v(_vm._s(_vm.startTime))]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }