<template>
  <div
    class="flex flex-col"
    :style="{ height: '1175px' }"
  >
    <div class="content flex flex-col h-full">
      <tab-group
        v-if="!employeeOnly"
        :selected-profile-type="profileType"
        @tab-selected="profileType = $event"
      />
      <div
        class="grid grid-cols-3 gap-y-10 pt-12 pb-72 px-16 overflow-scroll mb-32 h-full max-h-full"
        :class="{ 'grid-cols-3 gap-x-4': size === 'large', 'grid-cols-5 gap-x-0': size !== 'large' }"
      >
        <div
          v-for="(profile, index) in shownProfiles"
          :key="index"
          class="profile flex flex-col items-center"
          :class="{ 'h-36': size !== 'large', 'h-60': size === 'large' }"
          @click="selectProfile(profile)"
        >
          <img
            class="profileImage rounded-full shadow-lg object-cover border-8 border-white mb-4"
            :src="profile.displayImage"
            :class="[size, {'border-8 border-success': isSelected(profile)}]"
          >
          <p
            class="text-2xl font-bold text-center"
            :class="{ 'text-success font-bold' : isSelected(profile) }"
          >
            {{ profile.displayName }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="sticky flex flex-col items-center justify-end pt-4 w-96 z-20 self-center"
      :class="[ selectBottom ]"
    >
      <select-el
        icon="house-user"
        :options="filterSharedDepartments"
        :selected-option="selectedDepartment"
        @option-selected="departmentSelected"
      />

      <button-el
        v-if="canSave"
        text="booking.confirmBooking"
        icon="check"
        :is-save="true"
        :shadow="true"
        class="w-full self-center mb-32"
        background-color="success"
        @click.native="save()"
      />

      <button-el
        v-if="canClose"
        text="global.close"
        icon="times"
        :shadow="true"
        class="w-auto inline-block self-center mb-4"
        @click.native="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/shared/Button';
import TabGroup from '@/components/shared/TabGroup';
import SelectEl from '@/components/shared/SelectEl';
import { cloneDeep } from 'lodash';

export default {
  components: {
    SelectEl,
    ButtonEl: Button,
    TabGroup
  },
  props: {
    canClose: {
      type: Boolean,
      'default': false
    },
    canSave: {
      type: Boolean,
      'default': false
    },
    multiselect: {
      type: Boolean,
      'default': false
    },
    employeeOnly: {
      type: Boolean,
      'default': false
    },
    selectedProfileType: {
      type: String,
      'default': 'RESIDENT'
    },
    selectBottom: {
      type: String,
      'default': 'bottom-12'
    },
    sharedDepartments: {
      type: Array,
      'default': (() => [])
    }
  },
  data() {
    return {
      showResidents: true,
      selectedProfiles: [],
      selectedDepartment: {},
      profileType: ''
    }
  },
  computed: {
    ...mapGetters({
      departments: 'institution/departments',
      profiles: 'profiles/all',
      institutionLocale: 'institution/locale',
      departmentId: 'department/id'
    }),
    shownProfiles() {
      const profilesInDepartment = this.profiles.filter(profile => this.getDepartmentIds(profile).includes(parseInt(this.selectedDepartment.id)));
      return profilesInDepartment.filter(profile => profile.type === this.profileType).sort((a,b) => a.firstName.localeCompare(b.firstName, this.institutionLocale));
    },
    size() {
      if (this.shownProfiles.length > 25) return 'small';
      return 'large';
    },
    filterSharedDepartments() {
      const allDepartments = cloneDeep(this.departments);
      const sharedDepartmentsClone = cloneDeep(this.sharedDepartments);

      return allDepartments.filter(department => sharedDepartmentsClone.some(sharedDepartment => department.id === sharedDepartment.id));
    }
  },
  mounted() {
    this.profileType = this.selectedProfileType;
    this.selectedDepartment = this.departments.find(department => department.id == this.departmentId);
  },
  methods: {
    getDepartmentIds(profile) {
      return profile.departments.map(department => parseInt(department.id));
    },
    selectProfile(profile) {
      const profileIndex = this.selectedProfiles.indexOf(profile);

      if (profileIndex > -1) {
        this.selectedProfiles.splice(profileIndex, 1);
      } else {
        this.selectedProfiles.push(profile);
      }

      if (!this.multiselect) {
        this.$emit('profile-selected', profile);
      }
    },
    isSelected(profile) {
      return this.selectedProfiles.indexOf(profile) > -1;
    },
    save() {
      this.$emit('save', this.selectedProfiles);
    },
    departmentSelected(department) {
      this.selectedDepartment = department;
      this.$emit('department-selected', department);
    }
  }
}
</script>

<style lang="scss" scoped>

.profile {

  &.inline {
    display: flex;
    align-items: center;
    margin-left: 40px;

    .content {
      margin-left: 20px;
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
  }

  .profileImage {
    &.small {
      width: 96px;
      height: 96px;
    }

    &.large {
      width: 190px;
      height: 190px;
    }
  }
}
.select-el {
  bottom: 250px;
}
.employeeSelected {
  background-color: #FF765B;
  color: white;
}
</style>