<template>
  <div
    class="bookingsWrapper"
    :class="{ 'verticalCenter flex items-center flex-col justify-center items-stretch': verticalCenterGrid || verticalCenterList }"
  >
    <div
      class="bookings bg-white"
    >
      <list-view
        v-if="activeContentView.name === 'list'"
        :day-items="bookingDays"
        :width-percent="80"
        :sort-by-day="true"
        @item-selected="showSingleBooking($event)"
      />

      <grid-view
        v-if="activeContentView.name === 'grid'"
        :items="bookings"
        item-type="booking"
        @item-selected="showSingleBooking($event)"
      />

      <category-selector
        v-if="activeContentView.name === 'category'"
        :items="allBookingResources"
        :active-item-index="selectedResourceIndex"
        @category-selected="resourceSelected($event)"
      />
    </div>

    <empty-views
      v-if="!bookings.length && activeContentView.name !== 'category'"
      view-type="Booking"
      :color="moduleColor"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CategorySelector from '@/components/shared/CategorySelector';
import { cloneDeep } from 'lodash';
import ListView from '@/components/shared/ListView';
import GridView from '@/components/shared/GridView';
import { isItemToday, formatTime } from '@/utils/helpers.js';
import { getBookings } from '@/utils/bookings';
import EmptyViews from '@/views/shared/EmptyViews';

export default {
  components: {
    CategorySelector,
    ListView,
    GridView,
    EmptyViews
  },
  data() {
    return {
      selectedResourceIndex: -1,
      lastScrollTop: 0,
      topVisibleDay: null,
      singleBookingShown: false,
      selectedDate: new Date()
    };
  },
  computed: {
    ...mapGetters({
      allBookingResources: 'bookingResources/all',
      activeViewType: 'general/activeViewType',
      activeContentView: 'general/activeContentView',
      activeItemFilter: 'general/activeItemFilter',
      externalConnections: 'institution/externalConnections',
      institutionId: 'institution/id'
    }),
    moduleColor() {
      return this.$route?.meta?.color;
    },
    contentViews() {
      return this.$route?.meta?.contentViews;
    },
    bookingDays() {
      const bookings = cloneDeep(this.bookings);
      return bookings.reduce((accumulator, current, index) => {
        const uniqueDate = current.start.split('T')[0];

        if (accumulator[uniqueDate] || (accumulator[uniqueDate]=[])) {
          accumulator[uniqueDate].push(current);

          accumulator[uniqueDate].meta = {
            startDate: current.start,
            showTodayLabel: index === 0 && isItemToday(current.start)
          }
        }

        return accumulator;
      }, {});
    },
    bookings() {
      return getBookings(this.allBookingResources);
    },
    verticalCenterList() {
      return this.activeViewType === 'list' && this.bookings.length < 3;
    },
    verticalCenterGrid() {
      return (this.activeViewType === 'grid' && this.bookings.length < 10) || (this.activeContentView.name === 'filter');
    }
  },
  async mounted() {
    await this.$store.dispatch('bookingResources/getBookingResources');
    this.$store.dispatch('general/setActiveContentView', this.$route.meta?.contentViews[2]);
    await this.$store.dispatch('general/setActiveViewType', this.bookings.length > 3 ? 'grid' : 'list');
  },
  methods: {
    async showSingleBooking(booking) {
      if (booking.cancelled) return;

      const dayLabels = document.getElementsByClassName('dayLabelWrapper');

      for (let item of dayLabels) {
        item.style.zIndex = '0';
      }

      await this.$store.dispatch('bookingResources/setSelectedBookingEntry', booking);
      this.$router.push(`/bookings/booking/${booking.id}`);
    },
    formatTime(booking) {
      if(booking.cancelled) return this.$t('global.cancelled');
      return formatTime(booking);
    },
    async resourceSelected(resource) {
      await this.$store.dispatch('bookingResources/setSelectedBookingResource', resource);
      this.$router.push(`/bookings/resource/${resource.id}`);
    }
  }
};
</script>

<style lang="scss">
  .bookingsWrapper {
    position: relative;
    background: none;
    padding: 130px 0;

    &.verticalCenter .dayLabelWrapper {
      padding: 0;
    }

    &.gridView {
      padding-top: 15px;
    }
  }

</style>
