var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "meetingsWrapper",
      class: {
        "verticalCenter flex items-center flex-col justify-center items-stretch":
          _vm.verticalCenterGrid || _vm.verticalCenterList,
      },
    },
    [
      _vm.meetings.length
        ? _c(
            "div",
            { staticClass: "activities bg-white" },
            [
              _vm.activeContentView.name === "list"
                ? _c("list-view", {
                    attrs: {
                      "day-items": _vm.meetingDays,
                      "width-percent": 80,
                      "sort-by-day": true,
                    },
                    on: {
                      "item-selected": function ($event) {
                        return _vm.showSingleMeeting($event)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.activeContentView.name === "grid"
                ? _c("grid-view", {
                    attrs: { items: _vm.meetings, "item-type": "meeting" },
                    on: {
                      "item-selected": function ($event) {
                        return _vm.showSingleMeeting($event)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _c("empty-views", {
            attrs: { "view-type": "Meetings", color: _vm.moduleColor },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }