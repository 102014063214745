var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "bottom-72 bg-white h-48 flex p-12 flex flex-row w-full overflow-none z-20",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "w-24 h-24 rounded rounded-lg flex items-center justify-center",
          class: _vm.canGoBack ? _vm.activeClasses : _vm.inactiveClasses,
          on: {
            click: function ($event) {
              return _vm.goBack()
            },
          },
        },
        [
          _c("fa-icon", {
            staticClass: "text-charcoal text-5xl",
            attrs: { icon: ["fal", "angle-left"] },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "h-24 flex-1 mx-12 rounded rounded-lg flex items-center justify-center",
          class: _vm.inactiveClasses,
        },
        [
          _c("p", { staticClass: "text-3xl font-bold text-black" }, [
            _vm._v("\n      " + _vm._s(_vm.formattedSelectedDate) + "\n    "),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "w-24 h-24 rounded rounded-lg flex items-center justify-center",
          class: _vm.canGoForward ? _vm.activeClasses : _vm.inactiveClasses,
          on: {
            click: function ($event) {
              return _vm.goForward()
            },
          },
        },
        [
          _c("fa-icon", {
            staticClass: "text-charcoal text-5xl",
            attrs: { icon: ["fal", "angle-right"] },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }