<template>
  <div
    class="widget h-full flex flex-col"
    :class="[`bg-${widget.colorId}`, widgetSize ]"
    @click="slideClicked()"
  >
    <div
      v-if="workSchedule.length"
      class="container flex justify-center items-center p-5"
      :class="{
        'h-3/4': widgetSize === 'small',
        'h-5/6': widgetSize === 'medium',
        'h-4/5': widgetSize === 'large',
        'h-3/5 mt-12': widgetSize === 'medium-flat',
      }"
    >
      <div
        v-for="( shift, index ) in workSchedule"
        :key="index"
        :class="{
          'h-4/6': widgetSize === 'small' && workSchedule.length > 2,
          'h-3/6': widgetSize === 'small' && workSchedule.length <= 2,
          'h-5/6': widgetSize === 'medium' || widgetSize === 'large',
          'h-2/4': widgetSize === 'medium-flat' && workSchedule.length <= 5,
          'h-4/5': widgetSize === 'medium-flat' && workSchedule.length > 5
        }"
      >
        <div 
          :style="{ backgroundImage: `url(${shift.employee.displayImage})`, width: `${shiftSize}px`}"
          class="shift bg-cover bg-center rounded-full mx-1 relative h-full"
          :class="{
            'shiftNow': isSimpleOrNormalWorkSchedule,
            'shiftLater': widget.type === 'LATER_WORK_SCHEDULE'
          }"
        >
          <div
            class="text-center absolute text-white w-full h-full flex flex-col items-center justify-end pb-4 rounded rounded-full"
            :style="{ background: 'linear-gradient(0deg, rgba(50,50,50,1) 0%, rgba(50,50,50,0.40) 30%, rgba(0,0,0,0) 50%)' }"
          >
            <p
              v-if="showName"
              class="font-semibold overflow-ellipsis overflow-hidden"
              :class="nameFontSize"
            >
              {{ shift.employee.displayName }}
            </p>

            <p v-if="showTime">
              <span>{{ getShiftTimes(shift) }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center mb-8 text-white text-2xl font-semibold mt-auto">
      <fa-icon
        class="text-white text-3xl mb-4"
        :icon="['fas', 'id-card']"
      />
      <p>{{ isSimpleOrNormalWorkSchedule ? $t('scheduleShift.workingNow') : $t('scheduleShift.workingLater') }}</p>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { cloneDeep, get } from 'lodash';
import { differenceInMinutes } from 'date-fns';
import { format } from '@/utils/date-fns';
import { getNameSize, getWidgetSize, getMaxAmountOfShifts } from '@/utils/workSchedule';

export default {
  props: {
    widget: {
      type: Object,
      'default': (() => {})
    }
  },
  data() {
    return {
      shiftSize: 0,
      thickness: 5,
      shiftHeight: 'h-5/6'
    }
  },
  watch: {
    workScheduleNow(newValue, oldValue){
      if(newValue !== oldValue){
        this.setShiftSize();
      }
    },
    workScheduleLater(newValue, oldValue){
      if(newValue !== oldValue){
        this.setShiftSize();
      }
    }
  },
  computed: {
    ...mapGetters({
      workScheduleNow: 'workSchedule/workingNow',
      workScheduleLater: 'workSchedule/comingLater',
      settings: 'institution/settings',
      type: 'workSchedule/type'
    }),
    widgetSize() {
      return getWidgetSize(this.widget);
    },
    showName() {
      return this.shiftSize > 60;
    },
    showTime() {
      return this.shiftSize > 110 && this.type !== 'SIMPLE_WORK_SCHEDULE';
    },
    nameFontSize() {
      return getNameSize(this.shiftSize, this.widgetSize);
    },
    showCancelledShifts() {
      return get(this.settings, 'shift[0].showCancelledFp', false);
    },
    workSchedule() {
      let schedule = this.widget.type === 'LATER_WORK_SCHEDULE' ? cloneDeep(this.workScheduleLater) : cloneDeep(this.workScheduleNow);
      if (!this.showCancelledShifts) {
        schedule = schedule.filter(shift => !shift.cancelled);
      }

      const maxAmount = getMaxAmountOfShifts(this.widgetSize);
      return schedule.splice(0, maxAmount);
    },
    size() {
      return this.shiftSize + ( this.thickness * 2 );
    },
    isSimpleOrNormalWorkSchedule() {
      return this.widget.type === 'WORK_SCHEDULE' || this.widget.type === 'SIMPLE_WORK_SCHEDULE';
    }
  },
  mounted() {
    this.setShiftSize();
  },
  methods: {
    getShiftTimes(shift) {
      return `${format(new Date(shift.start), 'HH:mm')} - ${format(new Date(shift.end), 'HH:mm')}`
    },
    getProgress(shift) {
      const startToEndMinutes = differenceInMinutes(new Date(shift.end), new Date(shift.start));
      const nowToEndMinutes = differenceInMinutes(new Date(shift.end), new Date());
      return ( nowToEndMinutes / startToEndMinutes ) * 100;
    },
    setShiftSize() {
      this.$nextTick(() => {
        const className = this.isSimpleOrNormalWorkSchedule ? '.shiftNow' : '.shiftLater';
        const shift = document.querySelector(className);

        this.shiftSize = shift.clientHeight;
      });
    },
    slideClicked() {
      const route = this.type === 'WORK_SCHEDULE' ? '/workSchedule' : '/simpleWorkSchedule';
      this.$emit('slide-clicked', route);
    }
  }
}
</script>

<style scoped>
.textShadow {
  filter: drop-shadow(0 5px 4px rgba(0, 0, 0, 0.35));
}
.small {
  .container {
    > div:first-child:nth-last-child(n+2) ~ :nth-child(2) {
      grid-column: 2;
    }
    > div:first-child:nth-last-child(n+6) ~ :nth-child(3) {
      grid-column: 3;
    }
  }
  
  .shift {
    max-width: 300px;
    max-height: 300px;
  }
  .shift:after {
    padding-top: 100%;
    display: block;
    content: '';
  }
}
.medium {
  .container{
    > div:first-child:nth-last-child(n+7) ~ :nth-child(2) {
      grid-column: 2;
    }
    > div:first-child:nth-last-child(n+15) ~ :nth-child(3) {
      grid-column: 3;
    }
  }
  
  .shift {
    max-width: 300px;
    max-height: 300px;
  }
}
.medium-flat {
  .container{
    
    > div:first-child:nth-last-child(n+2) ~ :nth-child(2) {
      grid-column: 2;
    }
    > div:first-child:nth-last-child(n+3) ~ :nth-child(3) {
      grid-column: 3;
    }
    > div:first-child:nth-last-child(n+4) ~ :nth-child(4) {
      grid-column: 4;
    }
    > div:first-child:nth-last-child(n+5) ~ :nth-child(5) {
      grid-column: 5;
    }
  }
  
  .shift {
    max-width: 200px;
    max-height: 200px;
  }
  .shift:after {
    padding-top: 100%;
    display: block;
    content: '';
}
  
}
.large {
  .container{
    > div:first-child:nth-last-child(n+5) ~ :nth-child(2) {
      grid-column: 2;
    }
    > div:first-child:nth-last-child(n+7) ~ :nth-child(3) {
      grid-column: 3;
    }
    > div:first-child:nth-last-child(n+13) ~ :nth-child(4) {
      grid-column: 4;
    }
    > div:first-child:nth-last-child(n+24) ~ :nth-child(5) {
      grid-column: 5;
    }
    > div:first-child:nth-last-child(n+32) ~ :nth-child(6) {
      grid-column: 6; 
    }
  }
  
  .shift {
    max-width: 300px;
    max-height: 300px;
  }
}
.container {
  display: grid;
  grid-gap: 5px;
}

</style>