<template>
  <div>
    <div class="flex justify-between self-center px-20 pb-20 -mt-12 rounded-t-3xl">
      <div class="largeListItemHeader">
        {{ $t(data.title) }}
      </div>
    </div>

    <div class="w-full px-20 flex justify-center">
      <div
        v-for="(day, index) in 8"
        :key="index"
        class="slot mr-3 p-3 flex items-end justify-center"
      >
        <p v-if="index === 0" />
        <div
          v-else
          class="text-center text-xs font-bold"
          :style="{ color: isPastByIndex(index) ? mediumGray : '#000' }"
        >
          <p>{{ getDay(index) }}</p>
          <p>{{ getDate(index) }}</p>
        </div>
      </div>
    </div>

    <div class="w-full pb-10 px-20">
      <div
        v-for="(row, index) in rows"
        :key="index"
        class="flex justify-center mb-3"
      >
        <div class="labelSmall font-bold slot mr-3 flex flex-col items-center justify-center">
          <p>{{ formatTime(row[index].start) }}</p>
          <p>{{ formatTime(row[index].end) }}</p>
        </div>
        <div
          v-for="(slot, index2) in row"
          :key="index2"
          class="slot mr-3 p-3 rounded-sm"
          :style="{ background: lightGray, opacity: isPast(slot) ? '0.3' : '1' }"
        >
          <div
            class="w-full h-full flex items-center justify-center"
            :class="{ 'border-4 rounded-full': slot.state !== 'LOCKED' && !slot.participants.length }"
            :style="{ 'border-color': moduleColor }"
            @click="slotClicked(slot)"
          >
            <fa-icon
              v-if="slot.state === 'LOCKED'"
              class="text-5xl"
              :icon="['fas', 'lock']"
            />
            <img
              v-if="slot.participants.length && slot.state !== 'LOCKED'"
              :src="slot.participants[0].displayImage"
              alt="Participant"
              class="w-full h-full bg-cover rounded-full"
            >
          </div>
        </div>
      </div>
    </div>

    <div class="w-full px-20 mt-8 flex justify-center items-center">
      <button-el
        class="flex"
        text=""
        icon="arrow-left"
        :background-color="canGoPrevious ? 'success' : 'charcoal-light'"
        @click.native="getPreviousWeek"
      />

      <div class="text-3xl mx-8 flex flex-col justify-center items-center">
        <p class="font-semibold">
          {{ $t('global.week') }} {{ weekNumber }}
        </p>
        <p
          class="text-charcoal-light underline"
          @click="getThisWeek"
        >
          {{ $t('global.thisWeek') }}
        </p>
      </div>

      <button-el
        class="flex"
        text=""
        icon="arrow-right"
        background-color="success"
        @click.native="getNextWeek"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { get, cloneDeep, isEmpty } from 'lodash';
  import { isPast, addDays, endOfDay, isThisWeek, getWeek, addWeeks, subWeeks } from 'date-fns';
  import { format } from '@/utils/date-fns';
  import { hexToRgbA, getWeekday } from '@/utils/helpers';
  import colors from '@/utils/colors';
  import Button from '@/components/shared/Button';
  import PinFlow from '@/utils/pinFlow';

  export default {
    components: {
      ButtonEl: Button
    },
    data() {
      return {
        opt: ''
      }
    },
    computed: {
      ...mapGetters({
        activeOverlay: 'general/activeOverlay',
        resource: 'bookingResources/selectedBookingResource',
        originalSlots: 'bookingResources/selectedBookingSlots',
        selectedSlottedWeek: 'bookingResources/selectedSlottedWeek'
      }),
      data() {
        return this.activeOverlay.data;
      },
      times() {
        return new Set(...[cloneDeep(this.rows)]);
      },
      rows() {
        // return nested array based on slot id
        return cloneDeep(this.originalSlots).reduce((rv, x) => {
          (rv[x.id] = rv[x.id] || []).push(x);
          return rv;
        }, {});
      },
      lightGray() {
        return hexToRgbA(colors.charcoal, 0.05);
      },
      mediumGray() {
        return hexToRgbA(colors.charcoal, 0.25);
      },
      successColor() {
        return colors.success;
      },
      moduleColor() {
        return colors.BOOKING;
      },
      canGoPrevious() {
        return !isThisWeek(this.selectedSlottedWeek.weekStart);
      },
      weekNumber() {
        return getWeek(this.selectedSlottedWeek.weekStart);
      }
    },
    mounted() {
      this.getBookingSlots();
    },
    methods: {
      getDepartmentName(profile) {
        return get(profile, 'departments[0].name', '');
      },
      getDepartmentColor(profile) {
        return get(profile, 'departments[0].color', 'charcoal');
      },
      getBookingSlots() {
        this.$store.dispatch('bookingResources/getBookingSlots', {
          id: this.resource.id,
          dateFrom: format(this.selectedSlottedWeek.weekStart, 'yyyy-MM-dd'),
          dateTo: format(this.selectedSlottedWeek.weekEnd, 'yyyy-MM-dd')
        });
      },
      formatTime(dateTime) {
        return format(new Date(dateTime), 'HH:mm');
      },
      getSlots(index) {
        return this.slots.filter(slot => parseInt(slot.id) === index);
      },
      isPast(slot) {
        return isPast(new Date(slot.end));
      },
      isPastByIndex(index) {
        const day = endOfDay(addDays(this.selectedSlottedWeek.weekStart, index - 1));
        return isPast(day);
      },
      getDay(index) {
        return getWeekday(addDays(this.selectedSlottedWeek.weekStart, index - 1));
      },
      getDate(index) {
        return format(addDays(this.selectedSlottedWeek.weekStart, index - 1), 'dd MMMM');
      },
      saveWeekToStore(weekStart, weekEnd) {
        this.$store.dispatch('bookingResources/setSelectedSlottedWeek', { weekStart, weekEnd });
      },
      async getPreviousWeek() {
        if (!this.canGoPrevious) return;
        const newStart = subWeeks(this.selectedSlottedWeek.weekStart, 1);
        const newEnd = subWeeks(this.selectedSlottedWeek.weekEnd, 1);
        await this.saveWeekToStore(newStart, newEnd);
        this.getBookingSlots();
      },
      async getNextWeek() {
        const newStart = addWeeks(this.selectedSlottedWeek.weekStart, 1);
        const newEnd = addWeeks(this.selectedSlottedWeek.weekEnd, 1);
        await this.saveWeekToStore(newStart, newEnd);
        this.getBookingSlots();
      },
      async getThisWeek() {
        await this.$store.dispatch('bookingResources/setSelectedSlottedWeek');
        this.getBookingSlots();
      },
      canChooseSlot(slot) {
        if (slot.state === 'LOCKED') return;
        if (this.isPast(slot)) return;

        return true;
      },
      async slotClicked(slot) {
        if (!this.canChooseSlot(slot)) return;

        const opt = slot.state === 'AVAILABLE' ? 'in' : 'out';
        this.opt = opt;
        this.$store.dispatch('bookingResources/setOpt', opt);

        await this.$store.dispatch('bookingResources/setNewSlottedBooking', {
          bookingSlot: {
            bookingResourceId: this.resource.id,
            bookingResourceSlotId: slot.id,
            date: format(new Date(slot.start), 'yyyy-MM-dd')
          }
        });

        const flow = new PinFlow({
          entity: 'booking',
          text: 'booking.theBooking',
          opt,
          title: this.resource.title
        });

        flow.startSignupFlow('booking');
      }
    }
  }
</script>
<style scoped lang="scss">
  .slot {
    width: 100px;
    height: 100px;
  }
</style>
