<template>
  <div class="profile-select h-full overflow-y-scroll">
    <div class="content h-full flex flex-col">
      <div class="mb-12 mx-20">
        {{ $t('profileSelect.description') }}
      </div>

      <profiles-list
        class="h-full mb-48"
        :can-close="true"
        :employee-only="employeeOnly"
        :selected-profile-type="selectedProfileType"
        :shared-departments="activeOverlay.data.sharedDepartments"        
        select-bottom="bottom-96"
        @profile-selected="profileSelected"
        @close="close"
      />
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import ProfilesList from '@/components/shared/ProfilesList';

export default {
  components: {
    ProfilesList
  },
  computed: {
    ...mapGetters({
      activeProfile: 'profiles/activeProfile',
      activeOverlay: 'general/activeOverlay'
    }),
    employeeOnly() {
      return this.activeOverlay.data.employeeOnly
    },
    selectedProfileType() {
      return this.activeOverlay.data.selectedProfileType || 'RESIDENT'
    }
  },
  mounted() {
        document.querySelector('.overlay').scrollTop = 300;
  },
  methods: {
    close() {
      this.$matomo.trackEvent('Navigation', 'Clicked', 'CloseOverlayX');
      this.$emit('close-overlay', true);

      setTimeout(() => {
        this.$store.dispatch('general/resetOverlays', {});
      }, this.$constants.animationDuration);
    },
    async profileSelected(profile) {
      await this.$store.dispatch('profiles/setActiveProfile', profile);
      await this.$store.dispatch('general/setNextActiveOverlay', { name: 'enter-pin', data: this.activeOverlay.data });
      this.$emit('close-overlay');
    }
  }
};
</script>
