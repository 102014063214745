var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col" },
    [
      _c(
        "div",
        { staticClass: "overflow-x-auto schedule relative h-full z-10" },
        [
          _c(
            "div",
            {
              staticClass: "flex flex-col overflow-x-scroll h-full absolute",
              style: { width: _vm.viewWidth },
            },
            [
              _c(
                "div",
                { staticClass: "w-full h-full absolute z-10 inline-flex" },
                _vm._l(_vm.times.length, function (index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "h-full",
                      style: { "flex-basis": `${_vm.hourWidth}px` },
                    },
                    [
                      _c("div", {
                        staticClass:
                          "w-6 h-full border-r border-charcoal-xxlight",
                      }),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "overflow-y-scroll overflow-x-none flex flex-col relative h-full",
                },
                [
                  _c("timeline", {
                    staticClass:
                      "w-max border-b-2 border-charcoal-xxlight fit-content z-20 bg-white",
                    attrs: { times: _vm.times },
                  }),
                  _vm._v(" "),
                  _vm.isToday && _vm.shifts.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-col space-between w-2 absolute h-full z-30",
                          style: { marginLeft: `${_vm.offsetLeft}px` },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "bg-charcoal w-20 h-12 mt-6 flex items-center justify-center rounded rounded-lg relative -ml-9 shadow-bottomRight",
                            },
                            [
                              _c("p", { staticClass: "text-white text-2xl" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.currentTime) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "bg-charcoal w-20 h-12 mb-6 flex items-center justify-center rounded rounded-lg relative -ml-9 shadow-bottomRight",
                            },
                            [
                              _c("p", { staticClass: "text-white text-2xl" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.currentTime) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.shifts.length
                    ? _c("empty-views", {
                        attrs: {
                          "view-type": "WorkSchedule",
                          color: _vm.moduleColor,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-row relative w-full overflow-x-hidden h-full",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "overflow-y-scroll flex flex-col items-center justify-center z-30",
                          class: { absolute: _vm.shifts.length > 5 },
                        },
                        _vm._l(_vm.shifts, function (shift, index) {
                          return _c("shift", {
                            key: index,
                            attrs: {
                              index: index,
                              shift: shift,
                              "module-color": _vm.moduleColor,
                              "view-width": _vm.viewWidth,
                              "selected-day": _vm.selectedDay,
                              "type-of-shift": "normal",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.showSingleShift(shift)
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("timeline", {
                    staticClass:
                      "w-max border-t-2 border-charcoal-xxlight fit-content z-20 bg-white",
                    style: { width: _vm.viewWidth },
                    attrs: { times: _vm.times },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isToday
        ? _c(
            "div",
            {
              staticClass:
                "bottom-1/3 w-full fixed -mb-6 flex items-center justify-center z-20",
            },
            [
              _vm.isOutOfBounds && _vm.shifts.length > 0
                ? _c("button-el", {
                    attrs: {
                      text: "global.backToNow",
                      icon: "angle-right",
                      "background-color": "charcoal",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.scrollToNow()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("daySelector", {
        on: { "handle-selected-day": _vm.handleSelectedDay },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-2 h-full" }, [
      _c("div", {
        staticClass: "shadow-bottomRight indicator bg-charcoal w-full h-full",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }