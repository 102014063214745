<template>
  <transition
    enter-active-class="animate__animated animate__slideInUp"
    leave-active-class="animate__animated animate__slideOutDown"
    :duration="{ enter: showBackdrop ? 1000 : 0, leave: 800 }"
    @after-enter="showBackdrop = true"
  >
    <div
      v-if="show"
      :style="overlayStyles"
      class="overlay flex flex-col w-full fixed left-0 fixed top-0 z-30"
    >
      <transition
        enter-active-class="animate__animated animate__slideInUp"
        leave-active-class="animate__animated animate__slideOutDown"
        :duration="1000"
      >
        <div
          v-if="showBackdrop"
          class="backdrop bg-white shadow-top pb-12"
          :class="{ 'overflow-y-scroll': overlay.canScroll }"
          :style="backdropStyles"
        >
          <div
            v-if="overlay.canClose"
            class="flex justify-between my-20 mx-20 items-center"
          >
            <div
              class="largeListItemHeader"
            >
              {{ $t(overlay.title || '') }}
            </div>
            
            <fa-icon
              :icon="['fal', 'times']"
              class="text-5xl font-bold"
              @click="close(true, true)"
            />
          </div>
          <component
            :is="overlay.name"
            class="min-h-full"
            @close-overlay="close"
          />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import EnterPin from '@/views/overlays/EnterPin';
import ProfileSelect from '@/views/shared/ProfileSelect';
import ProfileDetails from '@/views/overlays/ProfileDetails';
import ParticipantsList from '@/views/overlays/ParticipantsList';
import WorkScheduleEntry from '@/views/overlays/WorkScheduleEntry';
import Agenda from '@/views/overlays/Agenda';
import Suggestions from '@/views/overlays/Suggestions';
import WriteSuggestion from '@/views/overlays/WriteSuggestion';
import NewBooking from '@/views/overlays/NewBooking';
import NewSlottedBooking from '@/views/overlays/NewSlottedBooking';
import NewBookingDescription from '@/views/overlays/NewBookingDescription';
import NewBookingParticipants from '@/views/overlays/NewBookingParticipants';
import SwapShiftEmployee from '@/views/overlays/SwapShiftEmployee';
import SwapShiftConfirmation from '@/views/overlays/SwapShiftConfirmation';
import OurHomeGallery from '@/views/overlays/OurHomeGallery';
import Error from '@/views/overlays/Error';
import { hexToRgbA } from '@/utils/helpers.js';
import overlays from '@/utils/overlays';
import { mapGetters } from 'vuex';
import Button from '@/components/shared/Button';

export default {
  components: {
    EnterPin,
    ProfileSelect,
    ProfileDetails,
    ParticipantsList,
    WorkScheduleEntry,
    Agenda,
    Suggestions,
    WriteSuggestion,
    NewBooking,
    NewSlottedBooking,
    NewBookingDescription,
    NewBookingParticipants,
    SwapShiftEmployee,
    SwapShiftConfirmation,
    OurHomeGallery,
    Error,
    ButtonEl: Button
  },
  props: {
    show: {
      type: Boolean,
      'default': false
    }
  },
  data() {
    return {
      showBackdrop: false,
    }
  },
  computed: {
    ...mapGetters({
      activeOverlay: 'general/activeOverlay',
      nextActiveOverlay: 'general/nextActiveOverlay'
    }),
    overlay() {
      return overlays.find(overlay => overlay.name === this.activeOverlay.name);
    },
    overlayHeight() {
      return window.innerHeight - this.$constants.navHeight + 50;
    },
    backdropColor() {
      return 'transparent';
      // return this.overlay.backdropColor || 'transparent';
    },
    overlayStyles() {
      return {
        backgroundColor: hexToRgbA(this.backdropColor, 0.9) || 'transparent',
        'min-height': this.overlayHeight,
        bottom: this.$constants.navHeight,
        'overflow-y': this.overlay.canScroll ? 'scroll': 'none'
      }
    },
    backdropStyles() {
      const height = this.overlay.pxHeight || 100;

      return {
        'min-height': `${height}px`,
        marginTop: `${this.overlay.marginTop}px`
      };
    },
  },
  methods: {
    async close(force, track = false) {
      if (track) this.$matomo.trackEvent('Navigation', 'Clicked', 'CloseOverlayX');
      this.$emit('close-overlay', force);
    }
  }
}
</script>

<style lang="scss" scoped>
.overlay {
  .backdrop {
    border-radius: 25px 25px 0 0;
  }
}
</style>