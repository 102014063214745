<template>
  <div 
    :class="`bg-${widget.colorId}`"
    class="relative h-full overflow-hidden"
    :style="backgroundStyles"
  >
    <div class="absolute w-full h-full overflow-y-scroll pb-18">
      <div class="flex flex-col justify-center items-stretch pt-4 pb-32">
        <TransitionGroup
          v-if="!isLoading"
          name="fade-out-in"
          tag="ul"
        >
          <li
            v-for="departure in filteredDepartments"
            :key="departure.JourneyDetailRef.ref"
            class="flex justify-center"
          >
            <div class="flex flex-row h-14 shadow-lg my-1 mx-6 w-full bg-journeyPlanner-dark-default">
              <div
                class="flex justify-center w-16 rounded-lg"
                :class="`bg-${departureColor(departure.type)}`"
              >
                <div
                  class="self-center font-bold text-center"
                  :class="[departure.type === 'S' ? 'text-white' : '']"
                >
                  {{ departure.name }}
                </div>
              </div>
              <div class="flex justify-center w-full rounded-lg text-white bg-gradient-to-l from-transparent-white">
                <div class="m-3 mr-0 flex flex-col justify-start w-full text-xs">
                  <div class="truncate w-44">
                    {{ departure.stop }}
                  </div>
                  <div class="font-bold">
                    {{ departure.direction }}
                  </div>
                </div>
                <div
                  v-if="departure.rtTime"
                  class="self-center mr-3"
                >
                  <div class="font-bold text-2xl leading-none">
                    {{ departure.rtTime }}
                  </div>
                  <div class="bg-error rounded-sm text-center text-xs w-max px-2 mx-auto">
                    {{ $t('journeyPlanner.newTime') }}
                  </div>  
                </div>
                <div
                  v-else
                  class="self-center mr-3 font-bold text-2xl"
                >
                  {{ departure.time }}
                </div>
              </div>
            </div>
          </li> 
        </TransitionGroup>
      </div>
    </div>

    <transition name="fade-out-in">
      <div
        v-if="isLoading"
        class="isLoading w-full h-full bg-transparent"
      />
    </transition>

    <div class="flex flex-col bg-gradient-to-t from-charcoal via-charcoal absolute bottom-0 w-full pt-12 pb-4 pb-3 pointer-events-none">
      <p class="text-center text-white text-3xl font-semibold mb-8 pt-16">
        {{ $t('travelPlan.moduleName') }}
      </p>
      <div class="tabs flex items-center justify-between rounded-full max-w-xs mx-auto p-1 text-white text-xs pointer-events-auto">
        <p
          v-for="( filter, index ) in filterOptions"
          :key="index"
          :class="{ 'bg-white text-black rounded-full': selectedFilter.value === filter.value }"
          class="day px-4 opacity-100"
          @click="selectFilter(index, filter)"
        >
          {{ $t(`${filter.text}`) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getYear } from 'date-fns';
import { isEmpty } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  props: {
    widget: {
      type: Object,
      'default': (() => {})
    },
    backgroundStyles: {
      type: String,
      'default': ''
    }
  },
  data() {
    return {
      filteredDepartments: [],
      filterOptions: [
        {
          text: 'global.now',
          value: 0
        },
        {
          text: 'journeyPlanner.30Minutes',
          value: 30
        },
        {
          text: 'journeyPlanner.1Hour',
          value: 60
        },
        {
          text: 'journeyPlanner.2Hours',
          value: 120
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      journeyPlan: 'journeyPlanner/all',
      isLoading: 'journeyPlanner/isLoading',
      selectedFilter: 'journeyPlanner/selectedFilter'
    }),
    moduleColor() {
      return this.$route?.meta?.color;
    }
  },
  async mounted() {
    await this.$store.dispatch('journeyPlanner/setSelectedFilter', this.filterOptions[0]);
    await this.$store.dispatch('journeyPlanner/getJourneyPlan');
    this.getFilteredDepartures();
  },
  methods: {
    getFilteredDepartures() {
      if (!this.journeyPlan || isEmpty(this.journeyPlan)) {
        console.log('No departures');
      }

      this.filteredDepartments = this.journeyPlan
        .filter(plan => !!plan.MultiDepartureBoard.Departure)
        .map(data => data.MultiDepartureBoard.Departure)
        .flat(1)
        .sort((a,b) => this.formatDepartureTime(a.date, a.time) - this.formatDepartureTime(b.date, b.time));
    },
    departureColor(modeOfTransport) {
      switch (modeOfTransport) {
        case 'S':
          return 'journeyPlanner-dark-red';
        case 'BUS':
          return 'journeyPlanner-yellow';
        case 'IC':
          return 'white';
        case 'REG':
          return 'white';
        default:
          return 'grey';
      }
    },
    selectFilter(index) {
      this.$store.dispatch('journeyPlanner/setSelectedFilter', this.filterOptions[index]);
      this.$store.dispatch('journeyPlanner/getJourneyPlan');
    },

    formatDepartureTime(date, time) {
      const [ hour, minute ] = time.split(':');
      const [ day, month, year ] = date.split('.');

      const currentYear = getYear(new Date());

      // we're not getting the full year from the API, only "24" instead of "2024"
      const fullYear = `${currentYear.toString().slice(0, 2)}${year}`;
      return new Date(fullYear, parseInt(month) - 1, parseInt(day) - 1, hour, minute);
    }
  }
}
</script>

<style lang="scss" scoped>
  .isLoading {
    background-color: transparent !important;
    &::before {
      width: 120px;
      height: 30px;
    }
  }
  .tabs {
    background: rgba(0,0,0,0.25);
  }
  .fade-out-in-enter-active,
  .fade-out-in-leave-active {
    transition: all .5s;
  }
  .fade-out-in-enter-active {
    transition-delay: .5s;
  }
  .fade-out-in-enter,
  .fade-out-in-leave-to {
    opacity: 0;
    transform: translateY(20px);
  }
</style>