<template>
  <div class="h-full">
    <div class="flex justify-between self-center px-20 pb-48 -mt-12 rounded-t-3xl">
      <div class="largeListItemHeader">
        {{ $t(data.title) || '' }}
      </div>
    </div>

    <div class="flex flex-col items-center justify-center">
      <fa-icon
        :icon="['fal', 'exclamation-circle']"
        class="text-error text-8xl mb-12"
      />

      <p class="content">
        {{ $t(data.descriptionOne) || '' }}
      </p>

      <p class="content mb-80">
        {{ $t(data.descriptionTwo) || '' }}
      </p>

      <button-el
        :text="data.buttonText || 'global.tryAgain'"
        background-color="error"
        icon="key"
        @click.native="reloadBookings"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/shared/Button';
import { mapGetters } from 'vuex';

export default {
  components: {
    ButtonEl: Button,
  },
  computed: {
    ...mapGetters({
      activeOverlay: 'general/activeOverlay'
    }),
    data() {
      return this.activeOverlay.data;
    }
  },
  methods: {
    async reloadBookings() {
      await this.$store.dispatch('bookingResources/getBookingResources');
      this.$emit('close-overlay');
    }
  },
};
</script>