import Vue from 'vue';
import VueI18n from 'vue-i18n';
import da from '@/i18n/da.json';
import de from '@/i18n/de.json';
import no from '@/i18n/no.json';

const messages = {
  da,
  de,
  no
};

const numberFormats = {
  'da': {
    currency: {
      style: 'currency',
      currency: 'DKK',
    }
  },
  'de': {
    currency: {
      style: 'currency',
      currency: 'EUR',
    }
  },
  'no': {
    currency: {
      style: 'currency',
      currency: 'NOK',
    }
  }
}

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'da',
  messages,
  numberFormats,
  silentTranslationWarn: true
});
