var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "activityResidents relative content pb-10 flex items-center w-full",
      on: {
        click: function ($event) {
          _vm.hideParticipants ? "" : _vm.$emit("show-people")
        },
      },
    },
    [
      _c("fa-icon", {
        staticClass: "mr-6",
        attrs: { icon: ["fal", "user-friends"] },
      }),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass: "flex-1",
          class: _vm.hideParticipants ? "font-light" : "font-semibold",
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.profiles.length) +
              " \n    " +
              _vm._s(_vm.$tc(_vm.text, _vm.profiles.length)) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.hideParticipants
        ? _c("fa-icon", {
            staticClass: "text-5xl",
            attrs: { icon: ["fal", "angle-right"] },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }