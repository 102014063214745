<template>
  <div class="h-full z-50">
    <div class="content h-full px-16 pt-5 border-t">
      <div class="-mt-96 absolute m-auto w-max right-0 left-0 flex flex-col items-center mb-16">
        <img
          :src="shift.employee.displayImage"
          alt="Employee"
          class="rounded-full w-60 h-60 border-8 border-white shadow-m object-cover"
          :class="[{ 'filter grayscale': shiftOver || shift.cancelled } ]"
        >
        <div
          v-if="shift.nightShift"
          class="bg-charcoal z-10 absolute w-16 h-16 rounded-full flex items-center justify-center mt-44 ml-48 border-4 border-white shadow-bottom"
        >
          <fa-icon
            class="text-white text-xl"
            :icon="['fas', 'moon-stars']"
          />
        </div>
        <div
          v-else-if="shift.cancelled"
          class="bg-warning z-10 absolute w-16 h-16 rounded-full flex items-center justify-center mt-44 ml-48 border-4 border-white shadow-bottom"
        >
          <fa-icon
            class="text-white text-xl"
            :icon="['fas', 'times']"
          />
        </div>
        <div
          v-else-if="unavailableNow"
          class="bg-warning z-10 absolute w-16 h-16 rounded-full flex items-center justify-center mt-44 ml-48 border-4 border-white shadow-bottom"
        />
        
        <div
          v-else-if="shiftStarted"
          class="z-10 absolute w-16 h-16 rounded-full flex items-center justify-center mt-44 ml-48 border-4 border-white shadow-bottom"
          :class="`bg-${moduleColor}`"
        />

        <div class="largeListItemHeader py-4">
          {{ shift.employee.displayName }}
        </div>
      </div>

      <div class="px-12">
        <div
          v-if="shift.nightShift"
          class="flex items-center mb-12 h-16"
        >
          <fa-icon
            class="text-3xl mr-12"
            :icon="['fal', 'moon-stars']"
          />
          <p class="font-bold">
            {{ $t("global.nightShift") }}
          </p>
        </div>

        <div class="flex items-center mb-12 h-16">
          <fa-icon
            class="text-3xl mr-12 w-8"
            :icon="['fal', 'calendar']"
          />
          <div>
            <p class="font-bold">
              {{ shift.cancelled ? $t("scheduleShift.shiftCancelled") : $t("employee.working") }}
            </p>
            <p>{{ getStartDay() }}</p>
          </div>
        </div>

        <div class="flex items-center justify-between w-full mb-12 h-16">
          <div
            v-if="shift.timeOfDayIcon || shift.timeOfDayName"
            class="flex items-center"
          >
            <fa-icon
              class="text-3xl mr-12 w-8"
              :icon="['far', `${shift.timeOfDayIcon}`]"
            />
            
            <p>{{ shift.timeOfDayName }}</p>
          </div>
          <div
            v-else
            class="flex items-center"
          >
            <fa-icon
              class="text-3xl mr-12 w-8"
              :icon="['fal', 'clock']"
            />
            <div>
              <p class="font-bold">
                {{ $t("global.oclock") }}
              </p>
              <p>{{ shiftDuration }}</p>
            </div>
          </div>
          <status
            v-if="shiftStarted"
            :color="moduleColor"
            icon="clock"
            :shadow="false"
          />
        </div>
        
        <div
          v-if="shift.unavailable"
          class="flex items-center justify-between w-full mb-12 h-16"
        >
          <div class="flex items-center">
            <fa-icon
              class="text-3xl mr-12 w-8"
              :icon="['fal', 'ban']"
            />
            <p class="flex flex-col">
              <span class="font-bold">{{ unavailabilityDescription }}</span>
              <span>{{ unavailableDuration }}</span>
            </p>
          </div>
          <status
            v-if="unavailableNow"
            color="error"
            icon="clock"
            :shadow="false"
          />
        </div>
        
        <edit-work-schedule-controls
          :shift="shift"
          @close-overlay="$emit('close-overlay')"
        />
        <!-- TODO: Add back in when we have the profile view -->
        <!-- <div class="flex items-center justify-between w-full mb-12 h-16">
          <div class="flex">
            <fa-icon class="text-3xl mr-12 w-8" :icon="['fal', 'id-badge']"/>
            <p class="font-semibold" :class="`text-${moduleColor}`">{{ $t('global.showProfile') }}</p>
          </div>
          <fa-icon class="text-6xl" :icon="['fal', 'angle-right']"/>
        </div>  -->
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { getStartDay, formatTime } from '@/utils/helpers.js';
import { capitalize } from 'lodash';
import Status from '@/components/shared/infoLabels/Status';
import EditWorkScheduleControls from '@/components/shared/EditWorkScheduleControls.vue';
import { isAfter, addDays } from 'date-fns';
import { toDate } from 'date-fns-tz';
import constants from '@/utils/constants';

export default {
  components: {
    Status,
    EditWorkScheduleControls,
  },
  data() {
    return {
      today: new Date()
    }
  },
  computed: {
    ...mapGetters({
      workSchedule: 'workSchedule/all',
      activeOverlay: 'general/activeOverlay'
    }),
    shiftStart() {
      return toDate(this.shift.start, { timeZone: constants.timezone });
    },
    shiftEnd() {
      return toDate(this.shift.end, { timeZone: constants.timezone });
    },
    unavailabilityStart() {
      return toDate(this.shift.unavailable[0].start, { timeZone: constants.timezone });
    },
    unavailabilityEnd() {
      return toDate(this.shift.unavailable[0].end, { timeZone: constants.timezone });
    },
    moduleColor() {
      return this.$route.meta?.color;
    },
    shift() {
      return this.activeOverlay.data;
    },
    shiftDuration() {
      return `${formatTime(this.shift.start)} - ${formatTime(this.shift.end)}`;
    },
    unavailableDuration() {
      return `${formatTime(this.shift.unavailable[0].start)} - ${formatTime(this.shift.unavailable[0].end)}`;
    },
    unavailableNow() {
      if (!this.shift.unavailable) return false;

      if (isAfter(new Date(), this.unavailabilityStart) && isAfter(this.unavailabilityEnd, new Date())) return true;
      return false;
    },
    unavailabilityDescription() {
      return this.shift.unavailable[0].description;
    },
    shiftStarted() {
      return isAfter(this.today, this.shiftStart) && isAfter(this.shiftEnd, this.today);
    },
    shiftOver() {
      return isAfter(new Date(), this.shiftEnd);
    },
  },
  methods: {
    getStartDay() {
      return capitalize(this.$t(getStartDay(this.shift.start, true)));
    },
    async editShift(action) {
      this.action = action;

      this.$emit('close-overlay');

      setTimeout(() => {
        const flow = new PinFlow({
          entity: 'shift',
          action,
          employeeName: this.shift.employee.displayName
        }); 

        flow.startCancelFlow('shift');
      }, 700);
    },
  }
}
</script>