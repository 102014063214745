<template>
  <div class="grid grid-cols-3 p-10 gap-14">
    <card-basic
      v-for="(item, index) in items"
      :key="index"
      :title="getItemTitle(item)"
      :image-url="item.coverImage.source"
      :active="activeItemIndex === index"
      class="shadow-xsm"
      @click.native="$emit('category-selected', item)"
    />
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import CardBasic from '@/components/cards/Basic';

export default {
  components: {
    CardBasic
  },
  props: {
    items: {
      type: Array,
      'default'() {
        return [];
      }
    },
    activeItemIndex: {
      type: Number,
      'default': -1
    }
  },
  data() {
    return {
      showPrompt: false,
    }
  },
  computed: {
    ...mapGetters({
      activeContentView: 'general/activeContentView'
    }),
  },
  methods: {
    getItemTitle(item) {
      return item.name || item.title;
    }
  }
};
</script>
<style lang="scss">

</style>
