const colors = require('@/utils/colors.js');

const overlays = [
    {
        name: 'profile-select',
        pxHeight: 1400,
        marginTop: 400,
        backdropColor: colors.charcoal,
        canScroll: false,
        canClose: true,
        title: 'profileSelect.header'
    },
    {
        name: 'profile-details',
        pxHeight: 900,
        marginTop: 720,
        backdropColor: colors.charcoal,
        canScroll: false
    },
    {
        name: 'participants-list',
        pxHeight: 1500,
        marginTop: 700,
        canScroll: true
    },
    {
        name: 'enter-pin',
        pxHeight: 1300,
        marginTop: 500,
        backdropColor: colors.charcoal,
        canScroll: false,
        canClose: true
    },
    {
        name: 'profiles-list',
        pxHeight: 1500,
        marginTop: 700,
        canClose: true,
        canScroll: true
    },
    {
        name: 'agenda',
        pxHeight: 1500,
        marginTop: 700,
        canClose: true,
        canScroll: true
    },
    {
        name: 'suggestions',
        pxHeight: 1400,
        marginTop: 500,
        canClose: true,
        canScroll: false,
        title: 'meeting.suggestions'
    },
    {
        name: 'write-suggestion',
        pxHeight: 1400,
        marginTop: 500,
        canClose: true,
        canScroll: false,
        title: 'meeting.writeSuggestion'
    },
    {
        name: 'media-list',
        pxHeight: 1500,
        marginTop: 600,
        canClose: true,
        canScroll: true
    },
    {
        name: 'media-preview',
        pxHeight: 1500,
        marginTop: 600,
        canClose: true,
        canScroll: true
    },
    {
        name: 'work-schedule-entry',
        pxHeight: 1500,
        marginTop: 600,
        canClose: true,
        backdropColor: colors.charcoal,
        canScroll: true
    },
    {
        name: 'new-booking',
        pxHeight: 1600,
        marginTop: 300,
        canClose: true,
        canScroll: true
    },
    {
        name: 'new-slotted-booking',
        pxHeight: 1600,
        marginTop: 300,
        canClose: true,
        canScroll: true
    },
    {
        name: 'new-booking-description',
        pxHeight: 1600,
        marginTop: 300,
        canClose: true,
        canScroll: true
    },
    {
        name: 'new-booking-participants',
        pxHeight: 1400,
        marginTop: 400,
        canClose: true,
        canScroll: true,
        title: 'booking.selectParticipantsTitle'
    },
    {
        name: 'error',
        pxHeight: 1300,
        marginTop: 500,
        canClose: true,
        canScroll: true
    },
    {
        name:'our-home-gallery',
        pxHeight: 1400,
        marginTop: 450,
        canClose: true,
        canScroll: true,
    },
    {
        name: 'swap-shift-employee',
        pxHeight: 1400,
        marginTop: 400,
        canClose: true,
        canScroll: true,
        title: 'scheduleShift.replaceShift'
    },
    {
        name: 'swap-shift-confirmation',
        pxHeight: 1400,
        marginTop: 400,
        canClose: true,
        canScroll: true,
    },
]

module.exports = overlays;