<template>
  <div class="flex flex-col pb-48 h-initial min-h-full">
    <div
      v-for="timeOfDay in timeSlots"
      :id="isNowWithinInterval(timeOfDay.starttime, timeOfDay.endtime) ? 'scrollHere': ''" 
      :key="timeOfDay.name"
      class="flex flex-col justify-center items-center bg-charcoal mt-16 mx-16 p-8 rounded-xl bg-opacity-5"
    >
      <div
        class="w-max rounded-lg shadow-lg px-12 py-5 text-3xl bg-white font-semibold"
        :class="isNowWithinInterval(timeOfDay.starttime, timeOfDay.endtime) ? `bg-${moduleColor} text-white` : ''"
      >
        <fa-icon
          class="pr-1"
          :icon="['far', `${icon[timeOfDay.icon]}`]"
        />
        {{ timeOfDay.name }}
      </div>
      <div class="flex flex-wrap pt-12 pb-6 space-between justify-center grid-cols-3 flex-grow">
        <div
          v-for="shift, index in filteredWorkSchedule(timeOfDay.starttime, timeOfDay.endtime)"
          :key="index"
          class="relative m-2"
        >
          <img
            :src="shift.employee.displayImage"
            alt="Employee"
            class="w-36 h-36 rounded-full border-8 shadow-m border-white object-cover"
            :class="isShiftActive(timeOfDay.starttime, timeOfDay.endtime, shift.start, shift.end) && !shift.cancelled ? '' : 'filter grayscale'"
            @click="showSingleShift(index, timeOfDay.starttime, timeOfDay.endtime, icon[timeOfDay.icon], timeOfDay.name)"
          >
          <div
            v-if="isNowWithinInterval(timeOfDay.starttime, timeOfDay.endtime) && isShiftToday(shift.end) && !shift.cancelled || isNowWithinInterval(shift.start, shift.end)"
            :class="`bg-${moduleColor}`"
            class="absolute right-0 bottom-1 inline-flex rounded-full h-12 w-12 border-4 border-white"
          />
          <div
            v-if="isNowWithinInterval(shift.start, shift.end) && isNowWithinInterval(shift.start, shift.end) && shift.cancelled"
            class="bg-warning absolute w-12 h-12 rounded-full flex items-center justify-center right-0 bottom-1 border-4 border-white"
          >
            <fa-icon
              class="text-white text-xl"
              :icon="['fas', 'times']"
            />
          </div>
        </div>
      </div>
    </div>
    <daySelector
      class="fixed bottom-72"
      @handle-selected-day="handleSelectedDay"
    />
  </div>
</template>

<script>
import DaySelector from '@/components/workSchedule/DaySelector';
import { mapGetters } from 'vuex';
import { isToday, isWithinInterval, parseISO, areIntervalsOverlapping, isSameDay, getHours, set, addDays, subDays } from 'date-fns';
import { cloneDeep, get, isEmpty } from 'lodash';

export default {
  components: {
    DaySelector
  },
  data() {
    return {
      icon: {
        morgen: 'sunrise',
        eftermiddag: 'sun',
        aften: 'moon-stars',
        nat: 'moon'
      },
      selectedDay: new Date(),
      today: new Date(),
      selectedShift: {},
    }
  },
  computed: {
    ...mapGetters({
      workSchedule: 'workSchedule/all',
      settings: 'department/settings',
      pinValidation: 'profiles/pinValidation',
      activeOverlay: 'general/activeOverlay'
    }),
    moduleColor() {
      return this.$route.meta.color;
    },
    clonedWorkSchedule() {
      return cloneDeep(this.workSchedule);
    },
    timeSlots() {
      const timeSlotsClone = cloneDeep(this.settings.simpleSchedulePlan);

      return timeSlotsClone.sort((a,b) => new Date(a.starttime) - new Date(b.starttime));
    },
    shownDays() {
      return get(this.settings, 'shift[0].daysForward', 7);
    },
    dateInterval() {
      return {
        from: subDays(this.today, this.shownDays),
        to: addDays(this.today, this.shownDays)}
    },
    editAction() {
      return this.activeOverlay.data.action;
    }
  },
  watch: {
    pinValidation(pinData) {
      if (!pinData.validPin || !pinData.pin) return;

      if (this.editAction === 'cancel') {
        this.$store.dispatch('workSchedule/shiftUpdate', {shiftId: this.selectedShift.id , dates: this.dateInterval, cancel: true});
      } else if (this.editAction === 'reactivate') {
        this.$store.dispatch('workSchedule/shiftUpdate', {shiftId: this.selectedShift.id , dates: this.dateInterval, cancel: false});
      }
      
      if (this.editAction === 'swap') {
        this.$store.dispatch('workSchedule/shiftUpdate', {shiftId: this.selectedShift.id , dates: this.dateInterval, cancel: false, employeeId: this.activeOverlay.data.employee.id});
      }

      if (this.editAction === 'delete') {
        this.$store.dispatch('workSchedule/shiftDelete', {shiftId: this.selectedShift.id , dates: this.dateInterval});
      }
    },
    workSchedule() {
      if (this.selectedShift.id === undefined) return;

      this.clonedWorkSchedule.find(shift => {
        if (shift.id === this.selectedShift.id) {
          this.$store.dispatch('general/setActiveOverlay', { name: 'work-schedule-entry', data: { ...shift } });
        }
      })
    }
  },
  mounted() {
    if (!isEmpty(this.workSchedule)) {
      this.scrollToTimeOfDay();
    }
  },
  methods: {
    filteredWorkSchedule(startSetting, endSetting, icon, name) {
      const selectedDayStartSetting = set(this.selectedDay, {hours: getHours(parseISO(startSetting))});
      const selectedDayEndSetting = set(this.selectedDay, {hours: getHours(parseISO(endSetting))});
      
      const shiftsForSelectedDay = this.clonedWorkSchedule.filter(schedule => {
        return isSameDay(new Date(schedule.start), this.selectedDay) || isSameDay(new Date(schedule.end), this.selectedDay);
      });

      const shiftsInTimeOfDay = shiftsForSelectedDay.filter(shift => areIntervalsOverlapping(
        {start: new Date(shift.start), end: new Date(shift.end)},
        {start: selectedDayStartSetting, end: selectedDayEndSetting}
      ));

      return shiftsInTimeOfDay.map(shift => ({...shift, timeOfDayIcon: icon, timeOfDayName: name}));
    },
    isNowWithinInterval(startSetting, endSetting) {
      return isWithinInterval(this.today, {start: parseISO(startSetting), end: parseISO(endSetting)});
    },
    showSingleShift(index, startSetting, endSetting, icon, name) {
      this.selectedShift = this.filteredWorkSchedule(startSetting, endSetting, icon, name)[index];
      this.$store.dispatch('general/setActiveOverlay', { name: 'work-schedule-entry', data: { index: index, ...this.filteredWorkSchedule(startSetting, endSetting, icon, name)[index]}});
    },
    isShiftToday(shiftEnd) {
      return isToday(parseISO(shiftEnd));
    },
    isShiftActive(timeOfDayStart, timeOfDayEnd, shiftStart, shiftEnd) {
      return this.isNowWithinInterval(timeOfDayStart, timeOfDayEnd) && this.isShiftToday(shiftEnd) || this.isNowWithinInterval(shiftStart, shiftEnd)
    },
    scrollToTimeOfDay() {
      this.$el.querySelector('#scrollHere').scrollIntoView({ behavior: 'smooth' });
    },
    handleSelectedDay(selectedDay) {
      this.selectedDay = selectedDay;
    }
  }
}
</script>

<style>
  .h-initial {
    height: initial !important;
  }
</style>