import { apolloClient } from '../../utils/apollo';
import store from '@/store';
import constants from '@/utils/constants.js';

const state = {
	all: [],
	activeProfile: {},
	myArchive: {},
	pinValidation: {},
};

const mutations = {
	SET_PROFILES(state, profiles) {
		state.all = profiles;
	},
	SET_ACTIVE_PROFILE(state, activeProfile) {
		state.activeProfile = activeProfile;
	},
	SET_MY_ARCHIVE(state, myArchive) {
		state.myArchive = myArchive;
	},
	SET_PIN_VALIDATION(state, pinValidation) {
		state.pinValidation = pinValidation;
	},
};

const actions = {
	async getProfiles({ commit }) {
		const departmentIds = store.getters['institution/departments'].map(department => department.id);

		await apolloClient
			.query({
				query: require('@/graphql/queries/profiles').default,
				variables: {
					filter: {
						departments: departmentIds
					}
				},
				fetchPolicy: 'no-cache'
			})
			.then(response => {
				commit('SET_PROFILES', response.data.profiles.map(profile => {
					if (!profile.showImage) {
						profile.displayImage = constants.avatarPlaceholder;
					}
					return profile;
				}));
			})
			.catch(error => {
				console.log('Error getting profiles', error);
			});
	},
	setActiveProfile({ commit }, activeProfile) {
		commit('SET_ACTIVE_PROFILE', activeProfile);
	},
	async getMyArchive({ commit }, { id, type }) {
		await apolloClient
			.query({
				query: require('@/graphql/queries/myArchiveScreen').default,
				variables: {
					profile: {
						profileType: type,
						profileId: id
					}
				},
				fetchPolicy: 'no-cache'
			}).then(response => {
				commit('SET_MY_ARCHIVE', response.data.myArchiveScreen);
			}).catch(error => {
				console.log('Error getting my archive', error);
			});
	},
	setPinValidation({ commit }, pinValidation) {
		commit('SET_PIN_VALIDATION', pinValidation);
	},
	async validatePin({ commit }, { profile, pin }) {
		commit('SET_PIN_VALIDATION', {
			validating: true,
			validPin: false,
			pin
		});

		await apolloClient
			.query({
				query: require('@/graphql/queries/user').default,
				variables: {
					profile: {
						pin: pin.toString(),
						profileId: profile.id,
						profileType: profile.type
					}
				},
				fetchPolicy: 'no-cache'
			})
			.then(response => {
				commit('SET_PIN_VALIDATION', {
					validating: false,
					validPin: true,
					pin
				});
			})
			.catch(error => {
				commit('SET_PIN_VALIDATION', {
					validating: false,
					validPin: false
				});
			})
	},
};

const getters = {
	all: state => state.all,
	activeProfile: state => state.activeProfile,
	myArchive: state => state.myArchive,
	pinValidation: state => state.pinValidation,
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};