var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "content w-full mb-12 mx-20" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("scheduleShift.replaceShiftChooseText")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("profiles-list", {
        attrs: {
          "can-save": false,
          multiselect: false,
          "employee-only": true,
          "shared-departments": _vm.departments,
          "selected-profile-type": "EMPLOYEE",
          "select-bottom": "bottom-48",
        },
        on: { "profile-selected": _vm.profileSelected },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }