import { render, staticRenderFns } from "./ProfileDetails.vue?vue&type=template&id=7e1413c7&scoped=true"
import script from "./ProfileDetails.vue?vue&type=script&lang=js"
export * from "./ProfileDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e1413c7",
  null
  
)

export default component.exports