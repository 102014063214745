var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "carouselControls flex flex-row items-center justify-between w-full px-32 h-32",
    },
    [
      _c("div", { staticClass: "text-white w-2/5" }, [
        _vm.mediaType === "pictures"
          ? _c("div", [
              _vm.autoplay
                ? _c(
                    "div",
                    { staticClass: "w-48" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "rounded-md h-5 p-1 w-full mb-2",
                          style: { backgroundColor: _vm.progressBackColor },
                        },
                        [
                          _c("div", {
                            staticClass:
                              "rounded-tl-md rounded-bl-md h-full transition-all",
                            class:
                              _vm.progressPercent === "100%"
                                ? "rounded-tr-md rounded-br-md"
                                : "rounded-tr-none rounded-br-none",
                            style: {
                              backgroundColor: _vm.progressFrontColor,
                              width: _vm.progressPercent,
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("button-el", {
                        staticClass: "content bold",
                        attrs: {
                          text: "global.stop",
                          icon: "square",
                          "background-color": "error",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.$emit("autoplay-toggled")
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass: "flex items-center",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("autoplay-toggled")
                        },
                      },
                    },
                    [
                      _c("fa-icon", {
                        staticClass: "text-white text-3xl mr-2",
                        attrs: { icon: ["far", "play-circle"] },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "content bold" }, [
                        _vm._v(_vm._s(_vm.$t("global.autoplay"))),
                      ]),
                    ],
                    1
                  ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      !_vm.hideNavigation
        ? _c(
            "div",
            {
              staticClass:
                "text-white text-center w-1/5 flex justify-between items-center",
            },
            [
              _c("fa-icon", {
                staticClass: "text-white content light text-2xl",
                style: { opacity: !_vm.canGoPrevious ? 0.5 : 1 },
                attrs: { icon: ["fa", "chevron-left"] },
                on: { click: _vm.goToPrevious },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "content" }, [
                _vm._v(
                  _vm._s(_vm.currentSlide) + " / " + _vm._s(_vm.totalSlides)
                ),
              ]),
              _vm._v(" "),
              _c("fa-icon", {
                staticClass: "text-white text-2xl content light",
                style: { opacity: !_vm.canGoNext ? 0.5 : 1 },
                attrs: {
                  icon: ["fa", "chevron-right"],
                  disabled: _vm.currentSlide === _vm.totalSlides,
                },
                on: { click: _vm.goToNext },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "w-2/5 flex justify-end" },
        [
          _c("button-el", {
            staticClass: "content bold",
            attrs: {
              text: "global.close",
              icon: "times",
              padding: false,
              "text-color": "white",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }