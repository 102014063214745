<template>
  <div
    class="card-advanced flex flex-col justify-between relative"
    :class="cardType"
    :style="cardStyle"
  >
    <div
      class="state w-max rounded text-white text-3xl"
      :class="{['bg-success']: isNow && !isCancelled, ['bg-error']: isCancelled}"
    >
      <span v-if="isNow && !isCancelled"><fa-icon :icon="['fal', 'bell']" />{{ $t('global.now') }}</span>
      <span v-if="isCancelled"><fa-icon :icon="['fal', 'ban']" />{{ $t('global.cancelled') }}</span>
    </div>
        
    <div class="info">
      <p
        v-if="title.length"
        class="title text-5xl text-white font-bold mb-3 ellipsis"
      >
        {{ title }}
      </p>
      <p
        v-if="startDate && weekday.length && cardType === 'vertical'"
        class="weekday text-3xl text-white"
      >
        {{ entryDate }}
      </p>

      <p
        v-if="meta"
        class="text-3xl text-white"
      >
        <fa-icon
          v-if="meta.icon"
          :icon="['fal', `${meta.icon}`]"
          class="text-white"
        />
        {{ meta.text }}
      </p>
    </div>
  </div>
</template>

<script>

import { hexToRgbA, getWeekday, formatDate, isItemThisWeek } from '@/utils/helpers.js';
import colors from '@/utils/colors.js';

export default {
  props: {
    cardType: {
        type: String,
        'default': ''
    },
    title: {
        type: String,
        'default': ''
    },
    meta: {
        type: Object,
        'default': () => {}
    },
    isNow: {
        type: Boolean,
        'default': false
    },
    isCancelled: {
        type: Boolean,
        'default': false
    },
    image: {
        type: String,
        'default': ''
    },
    gradient: {
        type: Boolean,
        'default': false
    },
    startDate: {
        type: String,
        'default': ''
    }
  },
  computed: {
    cardStyle() {
        const colorHex = colors[this.$route.meta.color];
        const moduleColorAlpha = hexToRgbA(colorHex, 0);

        return { background: `linear-gradient(0deg, ${colorHex} 30.02%, ${moduleColorAlpha} 70.33%), url(${this.image}) center center no-repeat`, backgroundSize: 'cover' };
    },
    weekday() {
        return getWeekday(this.startDate);
    },
    date() {
        return formatDate(this.startDate);
    },
    isThisWeek() {
        return isItemThisWeek(this.startDate);
    },
    entryDate() {
        return this.isThisWeek ? this.weekday : `${this.weekday} ${formatDate(this.startDate)}`
    }
  },
};
</script>

<style lang="scss">
    .card-advanced {
        align-self: center;
        width: 300px;

        .state {
            padding: 25px;
            border-radius: 12px;
            
            span {
                font-weight: 600;

                svg {
                    margin-right: 15px;
                }
            }
        }

        &.horizontal {
            margin-bottom: 80px;
            border-radius: 24px;
            padding: 50px 60px;
            height: 500px;
            width: 700px;

            .info {
                justify-self: flex-end;
            }

            .state.bg-error {
                align-self: center;
                margin-top: 140px;
            }
        }

        &.vertical {
            height: 390px;
            padding: 30px 20px;
            text-align: center;
            border-radius: 12px;

            .info {
                .title {
                    font-size: 32px;
                }

                .weekday {
                    font-weight: 300;
                    font-size: 24px;
                }
            }

            .state {
                align-self: center;
                margin-top: 100px;
                font-size: 24px;
                padding: 18px 20px;
            }
        }
    }  
</style>
