
<template>
  <div class="list grid-cols-1 items">
    <div
      v-if="!sortByDay"
      class="flex flex-col justify-center"
    >
      <card-advanced
        v-for="(item, index) in items"
        :key="index"
        :card-type="activeContentView.cardType"
        :title="item.title"
        :image="getCoverImage(item)"
        :gradient="true"
        :meta="{ icon: item.meta.icon, text: item.meta.text }"
        class="shadow-m"
        @click.native="$emit('item-selected', item)"
      />
    </div>

    <div v-else>
      <div
        v-for="(itemDay, index) in Object.values(dayItems)"
        :key="index"
        class="itemDay"
      >
        <div class="dayLabelWrapper sticky z-20">
          <span
            :class="`bg-${moduleColor}`"
            class="dayLabel p-4 text-3xl text-white left-0 rounded-r-lg"
          >
            {{ getStartDay(itemDay.meta.startDate) }}
          </span>
        </div>

        <card-advanced
          v-for="(item, index) in itemDay"
          :key="index"
          :card-type="activeContentView.cardType"
          :title="item.title"
          :start-date="item.startDate"
          :meta="!useExternalMeals ? { icon: item.meta.icon, text: item.meta.text } : null"
          :is-now="isItemNow(item)"
          :is-cancelled="item.cancelled"
          :image="getCoverImage(item)"
          :gradient="true"
          class="shadow-m"
          @click.native="$emit('item-selected', item)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CardAdvanced from '@/components/cards/Advanced';
import { get, capitalize, cloneDeep } from 'lodash';
import { isItemNow, getWeekday, getStartDay } from '@/utils/helpers.js';

export default {
  components: {
    CardAdvanced
  },
  props: {
    items: {
      type: Array,
      'default': (() => [])
    },
    dayItems: {
      type: Object,
      'default': (() => {})
    },
    widthPercent: {
      type: Number,
      'default': 100
    },
    sortByDay: {
      type: Boolean,
      'default': false
    }
  },
  computed: {
    ...mapGetters({
      activeContentView: 'general/activeContentView',
      institutionSettings: 'institution/settings'
    }),
    useExternalMeals() {
      return get(this.institutionSettings, 'useExternalMeals', false);
    },
    moduleColor() {
      return this.$route?.meta?.color;
    },
    itemDays() {
      return cloneDeep(this.items).reduce((accumulator, current, index) => {
        const uniqueDate = current.startDate.split('T')[0];

        if (accumulator[uniqueDate] || (accumulator[uniqueDate]=[])) {
          accumulator[uniqueDate].push(current);

          accumulator[uniqueDate].meta = {
            startDate: current.startDate,
            showTodayLabel: index === 0 && isItemToday(current.startDate)
          }
        }

        return accumulator;
      }, {});
    }
  },
  methods: {
    getStartDay(dateString) {
      return capitalize(this.$t(getStartDay(dateString)));
    },
    getWeekday(item) {
      return getWeekday(item.startDate);
    },
    isItemNow(item) {
      return isItemNow(item.startDate, item.endDate);
    },
    getCoverImage(item) {
      return get(item, 'coverImage.source', require('@assets/img/placeholder.png'))
    }
  }
};
</script>

<style lang="scss">
  .items {
    .itemDay {
      right: 0;
      position: relative;
      display: flex;
      flex-direction: column;

      &:first-child .dayLabelWrapper .dayLabel {
        margin-top: 25px;
      }

      .dayLabelWrapper {
        top: 180px;
        padding: 25px 0;
        height: fit-content;

        .dayLabel {
          position: absolute;
          padding: 25px 60px;
          border-radius: 0px 12px 12px 0px;
          margin-left: -2px;
          width: fit-content;
          margin-top: 75px;
        }
      }
    }
  }

</style>
