import { isToday, isBefore, isAfter, isTomorrow, isThisWeek } from 'date-fns';
import { format } from '@/utils/date-fns';
import { capitalize } from 'lodash';

export function isItemNow(startDate, endDate) {
  return isAfter(new Date(), new Date(startDate)) && isBefore(new Date(), new Date(endDate));
}

export function isItemToday(dateString) {
  return isToday(new Date(dateString));
}

export function isItemThisWeek(dateString) {
  return isThisWeek(new Date(dateString));
}

export function getWeekday(dateString) {
  if (!dateString) return '';
  const date = new Date(dateString);
  return capitalize(format(date, 'EEEE'))
}

export function formatTime(dateString) {
  return format(new Date(dateString), 'HH:mm');
}

export function formatDate(dateString) {
  return format(new Date(dateString), 'dd MMMM')
}

export function formatRepeat(repeat) {
  const matches = repeat.rrule.match(/(?<=FREQ=).*?(?=;)/);
  return matches.length ? matches[0] : false;
}

export function stringToMinutes(str) {
  return str.split(':').reduce((h, m) => h * 60 + +m);
}

export function minutesToString(min) {
  return (Math.floor(min / 60) + ':' + (min % 60 === 0 ? '00' : min % 60)).replace(/\b\d\b/, '0$&');
}

export function hexToRgbA(hex, alpha) {
  let color;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      color = hex.substring(1).split('');

      if (color.length === 3) {
        color = [color[0], color[0], color[1], color[1], color[2], color[2]];
      }

      color = '0x'+color.join('');
      const colorParts = [(color>>16)&255, (color>>8)&255, color&255];
      
      return `rgba(${colorParts.join(',')}, ${alpha})`;
  }
}

export function getStartDay(dateString, onlyWeekday = false) {
  const date = new Date(dateString);
  if(isToday(date)) return 'global.today';
  if(isTomorrow(date)) return 'global.tomorrow';
  if(isThisWeek(date)) return format(date, 'EEEE');

  if (onlyWeekday) return format(date, 'EEEE');

  return format(date, 'EEEE dd MMMM');
}

export function getAATextColor(backgroundColor) {
  const color = (backgroundColor.charAt(0) === '#') ? backgroundColor.substring(1, 7) : backgroundColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ? '#000' : '#fff';
}