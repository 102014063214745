import store from '@/store';
import colors from './colors';
import { isEmpty, cloneDeep } from 'lodash';
import { isToday, parseISO } from 'date-fns';

export function getModuleData() {
  const resources = cloneDeep(store.getters['bookingResources/all']);
  const bookings = resources.length ? resources.filter(resource => !!resource.bookings.length) : [];

  const meals = cloneDeep(store.getters['meals/all']).filter(mealsToday => isToday(parseISO(mealsToday.startDate)));
  
  return {
    'ACTIVITIES': {
      component: 'Activities',
      name: 'activities',
      icon: 'running',
      colorId: 'activities-light-default',
      colorHex: colors.ACTIVITIES,
      hasContent: !isEmpty(store.getters['activities/internal'])
    },
    'BOOKING': {
      component: 'Booking',
      icon: 'key',
      colorId: 'booking-light-default',
      colorHex: colors.BOOKING,
      hasContent: !!bookings.length
    },
    'EXTERNAL_ACTIVITIES': {
      component: 'Activities',
      name: 'externalActivities',
      icon: 'running',
      colorId: 'externalActivities-light-default',
      colorHex: colors.EXTERNAL_ACTIVITIES,
      hasContent: !isEmpty(store.getters['activities/external'])
    },
    'GALLERY': {
      component: 'Gallery',
      icon: 'photo-video',
      colorId: 'galleries-light-default',
      colorHex: colors.GALLERY,
      hasContent: !isEmpty(store.getters['galleries/all'])
    },
    'SLIDESHOW': {
      component: 'Slideshow',
      icon: 'photo-video',
      colorId: 'galleries-light-default',
      colorHex: colors.GALLERY,
      hasContent: !isEmpty(store.getters['galleries/all'])
    },
    'JOURNEY_PLANNER': {
      component: 'JourneyPlanner',
      icon: 'bus',
      colorId: 'journeyPlanner-dark-default',
      colorHex: colors.JOURNEY_PLANNER,
      hasContent: true
    },
    'MEAL_PLAN': {
      component: 'MealPlan',
      icon: 'utensils',
      colorId: 'meals-light-default',
      colorHex: colors.MEAL_PLAN,
      hasContent: !isEmpty(meals)
    },
    'MEETINGS': {
      component: 'Meetings',
      icon: 'comments-alt',
      colorId: 'meetings-light-default',
      colorHex: colors.MEETINGS,
      hasContent: !isEmpty(store.getters['meetings/all'])
    },
    'NEWS': {
      component: 'News',
      icon: 'comment-alt-lines',
      colorId: 'news-light-default',
      colorHex: colors.NEWS,
      hasContent: !isEmpty(store.getters['news/all'])
    },
    'OUR_HOME': {
      component: 'OurHome',
      icon: 'house-user',
      colorId: 'about-light-default',
      colorHex: colors.OUR_HOME,
      hasContent: false
    },
    'SIMPLE_WORK_SCHEDULE': {
      component: 'WorkSchedule',
      icon: 'id-card',
      colorId: 'schedule-light-default',
      colorHex: colors.SIMPLE_WORK_SCHEDULE,
      hasContent: !isEmpty(store.getters['workSchedule/workingNow'])
    },
    'WAYFINDER': {
      component: 'Wayfinder',
      icon: 'id-card',
      colorId: 'schedule-light-default',
      colorHex: colors.WAYFINDER,
      hasContent: false
    },
    'WEATHER': {
      component: 'Weather',
      icon: 'cloud',
      colorId: 'weather-light-default',
      colorHex: colors.WEATHER,
      hasContent: !isEmpty(store.getters['department/weather'])
    },
    'WORK_SCHEDULE': {
      component: 'WorkSchedule',
      icon: 'id-card',
      colorId: 'schedule-light-default',
      colorHex: colors.WORK_SCHEDULE,
      hasContent: !isEmpty(store.getters['workSchedule/workingNow'])
    },
    'LATER_WORK_SCHEDULE': {
      component: 'WorkSchedule',
      icon: 'id-card',
      colorId: 'laterSchedule-light-default',
      colorHex: colors.LATER_WORK_SCHEDULE,
      hasContent: !isEmpty(store.getters['workSchedule/comingLater'])
    }
  }
}