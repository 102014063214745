<template>
  <div class="mediaIcons absolute -top-28 left-10 h-20 flex justify-end">
    <div
      class="rounded-full bg-white w-20 h-20 flex justify-center items-center relative duration-300 transition"
      :class="{'bg-orange' : audioPlaying }"
      @click="playAudio"
    >
      <fa-icon
        v-if="!audioPlaying "
        :icon="['fal', 'volume']" 
        class="text-4xl"
      />
      <div
        v-else
        class="sound-wave flex justify-around items-center"
      >
        <i class="bar" />
        <i class="bar" />
        <i class="bar" />
        <i class="bar" />
        <i class="bar" />
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  props: {
    entityId: {
      type: String,
      'default': ''
    },
    entityType: {
      type: String,
      'default': ''
    },
  },
  data() {
    return {
      audioEnabled: false,
      audioPlaying: false,
    }
  },
  computed: {
      ...mapGetters({
          speech: 'tts/all'
      }),
      audioSource() {
        return new Audio(this.speech.source);
      }
  },
  mounted() {
    this.$store.dispatch('tts/getSpeech', { id: this.entityId, type: this.entityType });
  },
  destroyed() {
    this.audioSource.pause();
    this.audioSource.currentTime = 0;
  },
  methods: {
    playAudio() {
      this.audioEnabled = !this.audioEnabled;

      if (this.audioEnabled) {
        this.audioSource.play();
        this.audioPlaying = true;
      } else {
        this.audioSource.pause();
        this.audioSource.currentTime = 0;
        this.audioPlaying = false;
      }

      this.audioSource.addEventListener('ended', () => this.audioPlaying = false);
    }
  }
};

</script>

<style lang="scss" scoped="true">
.sound-wave {
  width: 50px;
  height: 65px;
  
  .bar {
    width: 6px;
    margin-right: 1px;
    height: 65px;
    background: #ffffff;
    animation: sound 0ms -800ms linear infinite alternate;
    transition: height 0.4s;
    border-radius: 10px;
  }
}

@keyframes sound {
    0% {
      opacity: .35;
      height: 3px; 
    }
    100% {
      opacity: 1;       
      height: 40px;        
    }
}

.bar:nth-child(1)  { animation-duration: 374ms; }
.bar:nth-child(2)  { animation-duration: 333ms; }
.bar:nth-child(3)  { animation-duration: 307ms; }
.bar:nth-child(4)  { animation-duration: 358ms; }
.bar:nth-child(5)  { animation-duration: 300ms; }
</style>