var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "profile" },
    [
      _vm.showMediaPreview
        ? _c("media-preview", {
            attrs: {
              "selected-item-index": _vm.selectedMediaIndex,
              "media-type": _vm.mediaType,
              items: _vm.mediaItems,
              "is-in-my-archive": true,
            },
            on: {
              "close-preview": function ($event) {
                _vm.selectedMediaIndex = -1
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "cover-view",
            {
              attrs: {
                "image-url": _vm.profile.displayImage,
                "module-color": _vm.moduleColor,
                "margin-top": 60,
              },
            },
            [
              _c(
                "div",
                { staticClass: "profileContent pt-20" },
                [
                  _vm.showResInfoDefault
                    ? _c("media-icons", {
                        attrs: {
                          icons: _vm.mediaIcons,
                          "media-type": _vm.mediaType,
                          amount: _vm.documents.length,
                          color: _vm.moduleColor,
                        },
                        on: { "media-selected": _vm.mediaIconSelected },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.showMediaList
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            { staticClass: "largeListItemHeader pt-12 mb-20" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.profile.displayName) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.isEmployee
                            ? _c("basic-block", {
                                attrs: { text: _vm.profile.title, icon: "tag" },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.profile.description
                            ? _c("description", {
                                attrs: { description: _vm.profile.description },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.profile.showBirthday && _vm.profile.birthday
                            ? _c("basic-block", {
                                attrs: {
                                  text: _vm.getBirthday(_vm.profile.birthday),
                                  icon: "birthday-cake",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.profile.departments.length
                            ? _c("departments", {
                                attrs: { departments: _vm.profile.departments },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.profile.showMobile && _vm.profile.mobile
                            ? _c("basic-block", {
                                attrs: {
                                  text: _vm.profile.mobile,
                                  icon: "mobile",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.profile.showEmail && _vm.profile.email
                            ? _c("basic-block", {
                                attrs: {
                                  text: _vm.profile.email,
                                  icon: "envelope",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.profile.type === "RESIDENT" &&
                          _vm.profile.homeDays.length
                            ? _c("basic-block", {
                                staticClass: "capitalize",
                                attrs: {
                                  text: _vm.homeDays,
                                  icon: "street-view",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c("media-list", {
                        staticClass: "pt-20",
                        attrs: {
                          items: _vm.mediaItems,
                          "media-type": _vm.mediaType,
                          "can-close": true,
                        },
                        on: {
                          close: _vm.closeMediaList,
                          "item-selected": function ($event) {
                            _vm.selectedMediaIndex = $event
                          },
                        },
                      }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }