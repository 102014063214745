var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    {
      attrs: {
        "enter-active-class": "animate__animated animate__slideInUp",
        "leave-active-class": "animate__animated animate__slideOutDown",
        duration: { enter: _vm.showBackdrop ? 1000 : 0, leave: 800 },
      },
      on: {
        "after-enter": function ($event) {
          _vm.showBackdrop = true
        },
      },
    },
    [
      _vm.show
        ? _c(
            "div",
            {
              staticClass:
                "overlay flex flex-col w-full fixed left-0 fixed top-0 z-30",
              style: _vm.overlayStyles,
            },
            [
              _c(
                "transition",
                {
                  attrs: {
                    "enter-active-class":
                      "animate__animated animate__slideInUp",
                    "leave-active-class":
                      "animate__animated animate__slideOutDown",
                    duration: 1000,
                  },
                },
                [
                  _vm.showBackdrop
                    ? _c(
                        "div",
                        {
                          staticClass: "backdrop bg-white shadow-top pb-12",
                          class: { "overflow-y-scroll": _vm.overlay.canScroll },
                          style: _vm.backdropStyles,
                        },
                        [
                          _vm.overlay.canClose
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex justify-between my-20 mx-20 items-center",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "largeListItemHeader" },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$t(_vm.overlay.title || "")
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("fa-icon", {
                                    staticClass: "text-5xl font-bold",
                                    attrs: { icon: ["fal", "times"] },
                                    on: {
                                      click: function ($event) {
                                        return _vm.close(true, true)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(_vm.overlay.name, {
                            tag: "component",
                            staticClass: "min-h-full",
                            on: { "close-overlay": _vm.close },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }