<template>
  <div
    class="h-full"
    :class="{[`bg-${color}`]: useBackgroundColor}"
  >
    <div
      class="h-full flex flex-col items-center justify-between py-16"
      :style="gradient"
    >
      <div class="w-36 h-36 flex flex-col items-center justify-between">
        <img
          :src="`../../../assets/img/emptyWidgets/${imageName}.svg`"
          alt="No data"
          class="absolute"
        >

        <div class="w-48 h-36 z-20 flex flex-col justify-between py-3">
          <div
            v-for="(item, index) in 3"
            :key="index"
            class="z-10 bg-white rounded rounded-4 w-36 h-8 py-1 flex items-center"
            :class="{ 'self-start': index !== 1, 'self-end': index === 1, 'px-2': index === 2, 'px-5': index === 0 || index === 1 }"
          >
            <div
              class="w-4 h-4 rounded rounded-full p-1 mr-2 flex items-center justify-center"
              :class="`bg-${color}`"
            >
              <fa-icon
                class="z-20 text-white w-2"
                :icon="[ 'fas', icon || 'clock' ]"
              />
            </div>

            <div class="flex flex-col">
              <span class="rounded rounded-full h-1.5 w-8 bg-gray-300 mb-1" />
              <span class="rounded rounded-full h-1.5 w-12 bg-gray-200" />
            </div>
          </div>
        </div>
      </div>

      <div class="text-center text-white text-3xl font-semibold">
        <fa-icon
          class="text-white text-4xl pb-4"
          :icon="[ 'fas', icon || 'clock' ]"
        />
        <p>{{ $t(`emptyStates.${widgetType}`) }}</p>
      </div>
    </div>
  </div>
</template>

<script>

import constants from '@/utils/constants';

export default {
  props: {
    widgetType: {
      type: String,
      'default': '',
      validator(value) {
        return constants.widgetTypes.includes(value)
      }
    },
    color: {
      type: String,
      'default': ''
    },
    icon: {
      type: String,
      'default': ''
    },
    useBackgroundColor: {
      type: Boolean,
      'default': true
    },
    useGradient: {
      type: Boolean,
      'default': true
    }
  },
  computed: {
    gradient() {
      if (!this.useGradient) return '';
      return { 'background': 'linear-gradient(0deg, rgba(50, 50, 50, 0) 0% 30%, rgba(50, 50, 50, 0.4) 100%)' };
    },
    imageName() {
      return this.widgetType.toLowerCase();
    }
  }
}
</script>