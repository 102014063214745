<template>
  <div class="relative">
    <carousel
      ref="carouselWeather"
      ref-name="carouselWeather"
      :per-page="1"
      :autoplay="false"
      :pagination-enabled="false"
      class="h-full"
    >
      <slide
        v-for="( day, index ) in prognosis"
        :key="index"
        :style="{'background-image': `url('${day.coverImage}')`}"
        class="h-full text-white background-center no-repeat bg-cover bg-center py-12 px-8 flex flex-col justify-between"
      >
        <div class="flex justify-between">
          <p class="text-7xl textShadow">
            {{ day.temp }}&deg;
          </p>
          <p class="text-2xl mt-4">
            <fa-icon
              class="text-white text-xl mr-2 textShadow"
              :icon="['fas', 'location-arrow']"
            />
            <span class="textShadow">{{ day.wind }} m/s</span>
          </p>
        </div>

        <div class="mb-12 flex flex-col items-center">
          <fa-icon
            class="text-4xl mb-4 textShadow"
            :icon="['fas', `${day.icon}`]"
          />
          <p class="text-4xl textShadow">
            {{ $t('weather.moduleName') }}
          </p>
        </div>
      </slide>
    </carousel>

    <div class="absolute bottom-4 w-full px-4 flex justify-center">
      <div class="flex items-center justify-between rounded rounded-full bg-black-025 p-1 max-w-2xl">
        <p
          v-for="( day, index ) in prognosis"
          :key="index"
          :class="{ active: activeDay === index + 1 }"
          class="day px-4 text-white"
          @click="selectDay(index)"
        >
          {{ day.weekday }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from '@/utils/date-fns';
import { getStartDay } from '@/utils/helpers.js';
import { capitalize } from 'lodash';

export default {
  props: {
    widget: {
      type: Object,
      'default': (() => {})
    }
  },
  data() {
    return {
      activeDay: 1
    }
  },
  computed: {
    ...mapGetters({
      weather: 'department/weather'
    }),
    prognosis() {
      const today = format(new Date(), 'yyyy-MM-dd');
      const currentSeries = this.weather.properties.timeseries[0];

      let dates = this.weather.properties.timeseries.filter(series => {
        return !series.time.includes(today) && series.time.includes('T12:00');
      });

      dates.unshift(currentSeries);
      dates = dates.splice(0, 5);

      return dates.map((day, index) => {
        const isThisDay = index === 0;
        const instant = day.data.instant.details;
        const summary = isThisDay
              ? day.data.next_1_hours.summary.symbol_code.split('_')[0]
              : day.data.next_12_hours.summary.symbol_code.split('_')[0];
        const wind = Math.round(instant.wind_speed);
        const weatherData = this.getWeatherData(summary, wind);

        return {
          temp: Math.round(instant.air_temperature),
          wind,
          coverImage: `../../../assets/img/weather/${weatherData.image}.jpg`,
          weekday: this.getStartDay(day.time),
          icon: weatherData.icon,
          summary
        }
      });
    }
  },
  methods: {
    getStartDay(dateString) {
      return capitalize(this.$t(getStartDay(dateString, true)));
    },
    selectDay(index) {
      this.activeDay = index + 1;
      this.$refs.carouselWeather.goToPage(index);
    },
    getWeatherData(summary, wind) {
      switch(summary) {
        case 'fair': case 'clearsky':
          return {
            image: 'clear_day',
            icon: 'sun'
          };
        case 'cloudy':
          return wind > 10 ? {
            image: 'wind',
            icon: 'wind'
          } : {
            image: 'wind',
            icon: 'clouds'
          };
        case 'partlycloudy':
          return wind > 10 ? {
            image: 'wind',
            icon: 'wind'
          } : {
            image: 'partly-cloudy-day',
            icon: 'cloud-sun'
          };
        case 'heavyrain': case 'heavyrainshowers': case 'lightrain': case 'lightrainshowers': case 'rain': case 'rainshowers':
          return {
            image: 'rain',
            icon: 'cloud-rain'
          };
        case 'heavyrainandthunder': case 'heavyrainshowersandthunder': case 'lightrainandthunder': case 'lightrainshowersandthunder': case 'rainandthunder': case 'rainshowersandthunder': case 'heavysleetandthunder': case 'heavysleetshowersandthunder': case 'lightsleetandthunder': case 'lightssleetshowersandthunder': case 'sleetandthunder': case 'sleetshowersandthunder':
          return {
            image: 'thunderstorm',
            icon: 'cloud-bolt'
          }
        case 'heavysleet': case 'heavysleetshowers': case 'lightsleet': case 'lightsleetshowers': case 'sleet': case 'sleetshowers':
          return {
            image: 'hail',
            icon: 'cloud-hail-mixed'
          }
        case 'heavysnow': case 'heavysnowandthunder': case 'heavysnowshowers': case 'heavysnowshowersandthunder': case 'lightsnow': case 'lightsnowandthunder': case 'lightsnowshowers': case 'lightssnowshowersandthunder': case 'snow': case 'snowandthunder': case 'snowshowers': case 'snowshowersandthunder':
          return {
            image: 'snow',
            icon: 'snowflake'
          }
        case 'fog':
          return {
            image: 'fog',
            icon: 'cloud-fog'
          }
        default:
          return undefined;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .active {
    border-radius: 15px;
    background: white;
    color: black;
  }

  .day {
    font-size: 0.6rem;
  }

  .textShadow {
    filter: drop-shadow(0 5px 4px rgba(0, 0, 0, 0.35));
  }
</style>