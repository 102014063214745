<template>
  <div class="activity">
    <media-preview
      v-if="showMediaPreview"
      :selected-item-index="selectedMediaIndex"
      :media-type="mediaType"
      :items="mediaItems"
      @close-preview="closeMediaList"
    />

    <cover-view
      v-if="activity.id"
      :image-url="imageUrl"
      :gradient="true"
      :module-color="moduleColor"
      :margin-top="65"
    >
      <div class="activityContent pt-20">
        <media-icons
          :icons="mediaIcons"
          :media-type="mediaType"
          :amount="mediaItems.length"
          @media-selected="mediaIconSelected"
        />
        <tts
          :entity-id="activity.id"
          entity-type="ACTIVITY"
        />

        <div v-if="!showMediaList">
          <div class="largeListItemHeader pt-12 mb-10">
            {{ activity.title }}
          </div>

          <div class="pictograms inline-flex w-full mb-20">
            <img
              v-for="(pictogram, index) in activity.pictograms"
              :key="index"
              class="w-20 mr-5"
              :src="activity.pictograms[index].source"
            >
          </div>

          <div class="activityDetails">
            <div class="activityDescription relative content pb-10 flex items-center w-full">
              <fa-icon
                class="mr-6 self-start"
                :icon="['fal', 'align-left']"
              />
              <p class="whitespace-pre-line -mt-2">
                <span>{{ activity.description }}</span>
              </p>
            </div>

            <date-time
              :start="activity.startDate"
              :end="activity.endDate"
            />

            <div
              v-if="signupExpired"
              class="relative content pb-10 flex items-center w-full"
            >
              <fa-icon
                class="mr-6 text-error"
                :icon="['fal', 'alarm-clock']"
              />
              <p class="text-error">
                {{ $t('global.signUpExpired') }}
              </p>
            </div>

            <div
              v-else
              class="relative content pb-10 flex items-center w-full"
            >
              <fa-icon
                class="mr-6"
                :icon="['fal', 'alarm-clock']"
              />
              <p>{{ formatDeadline(activity) }}</p>
            </div>

            <div
              v-if="!!activity.price"
              class="activityPrice relative content pb-10 flex items-center w-full"
            >
              <fa-icon
                class="mr-6"
                :icon="['fal', 'coins']"
              />
              {{ $n(activity.price[0].price / 100, 'currency') }}
            </div>

            <people
              v-if="activity.participants.length"
              text="global.participantsPlural"
              :hide-participants="activityHideSignups"
              :module-color="moduleColor"
              :profiles="activity.participants"
              @show-people="showProfiles('participants')"
            />

            <people
              v-if="activity.responsible.length"
              text="global.responsiblePlural"
              :module-color="moduleColor"
              :profiles="activity.responsible"
              @show-people="showProfiles('responsible')"
            />

            <departments
              v-if="activity.departments.length"
              :departments="activity.departments"
            />

            <div
              v-if="maxSignupsReached"
              class="relative content pb-10 flex items-center w-full"
            >
              <fa-icon
                class="mr-6 text-error"
                :icon="['fal', 'user-slash']"
              />
              <p class="text-error">
                {{ $t('global.maxSignupsReached') }}
              </p>
            </div>

            <div
              v-if="activity.club"
              class="activityClub relative content pb-10 flex items-center w-full"
            >
              <fa-icon
                class="mr-6"
                :icon="['fal', 'icons']"
              />
              <img
                :src="activity.club.logo.source"
                class="w-10 h-10 mr-2 rounded-full inline-block align-middle border border-gray-200"
              >
              {{ activity.club.name }}
            </div>

            <div class="relative label flex items-center w-full">
              <fa-icon
                class="mr-6"
                :icon="['fal', 'hotel']"
              />
              <span class="font-light">{{ activity.institution.name }}</span>
            </div>
          </div>
        </div>

        <div v-else>
          <media-list
            :items="mediaItems"
            :media-type="mediaType"
            :can-close="true"
            class="pt-20"
            @close="closeMediaList"
            @item-selected="selectedMediaIndex = $event"
          />
        </div>
      </div>

      <div
        v-if="!showMediaList"
        class="activityActions flex w-max left-full sticky"
      >
        <button-el
          v-if="signupPossible"
          text="global.signUp"
          icon="check"
          background-color="success"
          text-color="white"
          class="mr-5 shadow-xsm"
          @click.native="optInOrOut('in')"
        />

        <button-el
          v-if="optoutPossible"
          text="global.optOut"
          icon="minus-circle"
          background-color="error"
          text-color="white"
          class="shadow-xsm"
          @click.native="optInOrOut('out')"
        />
      </div>
    </cover-view>
  </div>
</template>
<script>
import { isFuture, isBefore } from 'date-fns';
import { formatDistanceToNow } from '@/utils/date-fns';
import { mapGetters } from 'vuex';
import Button from '@/components/shared/Button';
import { get, cloneDeep } from 'lodash';
import CoverView from '@/views/shared/CoverView';
import MediaIcons from '@/components/shared/MediaIcons';
import Tts from '@/components/shared/Tts';
import MediaPreview from '@/views/overlays/MediaPreview';
import MediaList from '@/views/shared/MediaList';
import PinFlow from '@/utils/pinFlow';
import DateTime from '@/components/shared/blocks/DateTime';
import People from '@/components/shared/blocks/People';
import Departments from '@/components/shared/blocks/Departments';

export default {
  components: {
    ButtonEl: Button,
    CoverView,
    MediaIcons,
    Tts,
    MediaPreview,
    MediaList,
    DateTime,
    People,
    Departments
  },
  data() {
    return {
      opt: '',
      mediaType: '',
      selectedMediaIndex: -1,
      hideMediaPreview: false,
      showMediaList: false
    }
  },
  computed: {
    ...mapGetters({
      allActivities: 'activities/all',
      activity: 'activities/selected',
      activeProfile: 'profiles/activeProfile',
      pinValidation: 'profiles/pinValidation',
      institutionId: 'institution/id',
      institutionSettings: 'institution/settings',
    }),
    moduleColor() {
      return this.$route?.meta?.color;
    },
    activityHideSignups() {
      return get(this.institutionSettings, 'screen.activityHideSignups', false);
    },
    imageUrl() {
      return get(this.activity, 'coverImage.source', require('@assets/img/placeholder.png'));
    },
    signupPossible() {
      // TODO: Remove once API supports signup on external activities
      // https://proreact.atlassian.net/browse/IBG-2611
      const isExternal = this.activity.institution.id !== this.institutionId;
      return this.activity.signupPossible && !isExternal && !this.activity.cancelled;
    },
    signupExpired() {
      return !this.activity.signupPossible && !isBefore(new Date(), new Date(this.activity.signupEnd));
    },
    maxSignupsReached() {
      if (!this.activity.maxSignups) return false;
      return this.activity.participants.length + this.activity.responsible.length >= this.activity.maxSignups;
    },
    optoutPossible() {
      return isFuture(new Date(this.activity.startDate)) && !this.activity.cancelled && this.activity.signupPossible;
    },
    mediaIcons() {
      return [
        {
          name: 'pictures',
          fa: 'image',
          amount: this.activity.pictures.length
        },
        {
          name: 'videos',
          fa: 'film',
          amount: this.activity.videos.length
        },
        {
          name: 'documents',
          fa: 'file-alt',
          amount: this.activity.documents.length
        }
      ];
    },
    mediaItems() {
      let items;

      switch(this.mediaType) {
        case 'pictures':
          items = this.activity.pictures;
          break;
        case 'videos':
          items = this.activity.videos;
          break;
        case 'documents':
          items = this.activity.documents;
          break;
        default:
          items = [];
      }
      return items;
    },
    showMediaPreview() {
      return this.selectedMediaIndex > -1 && !this.hideMediaPreview;
    },
    isAlreadyOptedInOut() {
      const participantIds = this.activity.participants.map(participant => participant.id);

      return participantIds.includes(this.activeProfile.id);
    }
  },
  watch: {
    pinValidation(pinData) {
      if (!pinData.validating) return;

      const signupData = {
        subscription: {
          id: this.activity.id
        },
        profile: {
          profileId: this.activeProfile.id,
          profileType: this.activeProfile.type,
          pin: pinData.pin.toString()
        },
        opt: this.opt
      };

      if ((this.opt === 'in' && !this.isAlreadyOptedInOut) || (this.opt === 'out' && this.isAlreadyOptedInOut)) {
        this.$store.dispatch('activities/activitySignupScreen', signupData);
      }
    }
  },
  mounted() {
    this.$store.dispatch('activities/getSelectedActivity', this.$route.params.id);
  },
  methods: {
    formatDeadline(activity) {
      return `${this.$t('global.signUpDeadline')} ${formatDistanceToNow(new Date(activity.signupEnd), { addSufix: true })}`;
    },
    showProfiles(profileType) {
      this.$matomo.trackEvent('ProfilesListActivity', 'Clicked', profileType);
      this.$store.dispatch('general/setActiveOverlay', { name: 'participants-list', data: { title: `global.${profileType}`, profiles: this.activity[profileType] } })
    },
    async optInOrOut(opt) {
      this.opt = opt;

      const flow = new PinFlow({
        entity: 'activity',
        text: 'activity.theActivity',
        opt,
        title: this.activity.title,
        sharedDepartments: this.activity.departments
      });

      flow.startSignupFlow('activity');
    },
    closeMediaList() {
      this.mediaType = '';
      this.selectedMediaIndex = -1;
      this.showMediaList = false;
    },
    mediaIconSelected(mediaType) {
      this.mediaType = mediaType;

      if (this.mediaItems.length === 1) {
        // if only one item, go straight to media-preview
        this.selectedMediaIndex = 0;
        this.hideMediaPreview = false;
        return;
      }

      this.showMediaList = true;
    },
  }
};
</script>

<style lang="scss">
  .activity {

    .activityContent {

      &.participants {
        margin-top: 700px;
      }

      > *:not(.mediaIcons) {
        padding: 0 100px;
      }
    }

    .activityActions {
      bottom: 70px;
      padding-right: 80px;
    }
  }

</style>
