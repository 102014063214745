<template>
  <div class="flex justify-evenly w-full mt-96">
    <button-el
      v-if="!isEditing"
      text="scheduleShift.editShiftButton"
      icon="edit"
      :shadow="true"
      class="self-center"
      background-color="white"
      @click.native="isEditing = !isEditing"
    />

    <div
      v-if="isEditing && !confirmation"
      class="flex justify-evenly w-full"
    >
      <button-el
        :text="shift.cancelled ? 'scheduleShift.reactivateButton' : 'scheduleShift.cancelButton'"
        icon="ban"
        :shadow="true"
        class="self-center"
        background-color="warning"
        @click.native="chooseAction('cancel')"
      />
      <button-el
        text="scheduleShift.replace"
        icon="fa-exchange-alt"
        :shadow="true"
        class="self-center"
        background-color="success"
        @click.native="editShift('swap')"
      />
      <button-el
        text="scheduleShift.delete"
        icon="trash"
        :shadow="true"
        class="self-center"
        background-color="error"
        @click.native="chooseAction('delete')" 
      />
    </div>
    <div
      v-if="confirmation"
      class="flex justify-evenly w-full"
    >
      <button-el
        :text="confirmButtonText"
        icon="ban"
        :shadow="true"
        class="self-center"
        :background-color="action === 'cancel' ? 'warning' : 'error'"
        @click.native="editShift(action)"
      />
      <button-el
        text="global.cancel"
        icon="undo"
        :shadow="true"
        class="self-center"
        background-color="white"
        @click.native="confirmation = !confirmation"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty } from 'lodash'
import Button from '@/components/shared/Button';
import PinFlow from '@/utils/pinFlow';

export default {
  components: {
    ButtonEl: Button
  },
  props: {
    shift: {
      type: Object,
      'default': {}
    },
  },
  data() {
    return {
      isEditing: false,
      confirmation: false,
      confirmButtonText: '',
      action: ''
    }
  },
  computed: {
    ...mapGetters({
      activeProfile: 'profiles/activeProfile'
    })
  },
  methods: {
    chooseAction(actionType) {
      this.confirmation = !this.confirmation;
      this.action = actionType;

      if (actionType === 'cancel' && this.shift.cancelled === true) {
        this.confirmButtonText = 'scheduleShift.confirmReactivationButton';
      } else {
        this.confirmButtonText = 'scheduleShift.confirmCancelationButton';
      }

      if (actionType === 'delete') {
        this.confirmButtonText = 'scheduleShift.confirmDeleteButton';
      }

    },
    editShift(actionType) {
      if (actionType === 'swap') {
        this.$store.dispatch('general/setNextActiveOverlay', { name: 'swap-shift-employee', data: { selectedShift: this.shift, action: actionType } });

        this.$emit('close-overlay');
      } else {
        if (actionType === 'cancel') {
          actionType = this.shift.cancelled ? 'reactivate' : 'cancel';
        }

        this.$emit('close-overlay');

        setTimeout(() => {
          const flow = new PinFlow({
            entity: 'shift',
            action: actionType,
            employeeName: this.shift.employee.displayName
          });

          flow.startUpdateShiftFlow('shift');
        }, 700);  
      }
      
    }
  }
}
</script>