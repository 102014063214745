<template>
  <div class="h-auto">
    <div class="flex justify-between self-center px-20 pb-48 -mt-32 rounded-t-3xl">
      <div class="largeListItemHeader">
        {{ $t('scheduleShift.replaceWith') + data.selectedProfile.displayName }}?
      </div>
    </div>
    <div class="flex justify-center items-center py-24">
      <div class="profile flex flex-col items-center opacity-60">
        <img
          class="profileImage rounded-full shadow-lg object-cover border-8 border-white mb-4 h-60 w-60"
          :src="data.selectedShift.employee.displayImage"
        >
        <p
          class="text-2xl font-bold text-center"
        >
          {{ data.selectedShift.employee.displayName }}
        </p>
      </div>
      <div class="profile flex flex-col items-center -ml-12 z-10">
        <img
          class="profileImage rounded-full shadow-lg object-cover border-8 border-white mb-4 h-60 w-60"
          :src="data.selectedProfile.displayImage"
        >
        <p
          class="text-2xl font-bold text-center"
        >
          {{ data.selectedProfile.displayName }}
        </p>
      </div>
    </div>
    <div class="flex justify-center pt-72 gap-3">
      <button-el
        text="scheduleShift.replace"
        icon="fa-exchange"
        :shadow="true"
        class="self-center"
        background-color="success"
        @click.native="swap()"
      />
      <button-el
        text="global.cancel"
        icon="undo"
        :shadow="true"
        class="self-center"
        background-color="white"
        @click.native="back()"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Button from '@/components/shared/Button';
  import PinFlow from '@/utils/pinFlow';

  export default {
    components: {
      ButtonEl: Button
    },
    computed: {
      ...mapGetters({
        activeOverlay: 'general/activeOverlay'
      }),
      data() {
        return this.activeOverlay.data;
      },
    },
    methods: {
      back() {
        this.$store.dispatch('general/setNextActiveOverlay', { name: 'swap-shift-employee', data: { selectedShift: this.data.selectedShift, action: 'swap' } });

        this.$emit('close-overlay');
      },
      swap() {
        this.$emit('close-overlay');
        
        setTimeout(() => {
          const flow = new PinFlow({
            entity: 'shift',
            action: this.data.action,
            employee: this.data.selectedProfile,
            employeeName: this.data.selectedShift.employee.displayName
          });

          flow.startUpdateShiftFlow('shift');
        }, 700);
      }
    }
  }
</script>