var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "flex text-center text-3xl border-b border-gray-200 mt-10 sticky top-0 bg-white font-light",
    },
    [
      _c(
        "div",
        {
          staticClass: "flex-auto py-10",
          class: {
            [_vm.activeTabClasses]: _vm.selectedProfileType === "RESIDENT",
          },
          on: {
            click: function ($event) {
              return _vm.$emit("tab-selected", "RESIDENT")
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("ourHome.residents")) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "flex-auto py-10",
          class: {
            [_vm.activeTabClasses]: _vm.selectedProfileType === "EMPLOYEE",
          },
          on: {
            click: function ($event) {
              return _vm.$emit("tab-selected", "EMPLOYEE")
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("ourHome.employees")) + "\n  ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }