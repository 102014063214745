<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <!--
        Background overlay, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"></span>

      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
        <div class="sm:flex sm:items-start">
          <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <!-- Heroicon name: outline/exclamation -->
            <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
              {{ $t('feedback.stopRecording') }}
            </h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">
                {{ $t('feedback.addMessage') }}
              </p>
            </div>
            <div class="mt-4">
              <div class="flex justify-between">
                <label for="message" class="block text-sm font-medium text-warm-gray-900">{{ $t('feedback.message') }}</label>
                <span id="message-max" class="text-sm text-warm-gray-500">{{ $t('feedback.maxCharacters') }}</span>
              </div>
              <div class="mt-1">
                <textarea ref="textarea" @focus="onFocus" name="message" rows="4" class="py-3 px-4 block w-full shadow text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md" aria-describedby="message-max">{{ keyboardInput }}</textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button @click="stopRecording" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
            {{ $t('feedback.stopRecording') }}
          </button>
          <button @click="cancel" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
            {{ $t('global.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      keyboardInput: 'general/keyboardInput',
      keyboardCaretPosition: 'general/keyboardCaretPosition'
    }),
  },
  watch: {
    keyboardInput() {
      this.$nextTick(() => {
        if(this.$refs.textarea.createTextRange) {
          const range = this.$refs.textarea.createTextRange();
          range.move('character', this.keyboardCaretPosition);
          range.select();
        } else {
          if (this.$refs.textarea.selectionStart) {
            this.$refs.textarea.focus();
            this.$refs.textarea.setSelectionRange(this.keyboardCaretPosition, this.keyboardCaretPosition);
          } else {
            this.$refs.textarea.focus();
          }
        }
      });
    }
  },
  methods: {
    cancel(){
      this.$emit('cancel');
      this.$store.dispatch('general/closeKeyboard');
    },
    stopRecording() {
      this.$emit('stopRecording', this.keyboardInput);
      this.$store.dispatch('general/closeKeyboard');
    },
    onFocus() {
      if(this.keyboardInput === null) {
        this.$store.dispatch('general/setKeyboardInput', '');
      }
    }
  }
};
</script>
