import { apolloClient } from '../../utils/apollo';
import store from '@/store';
import { get, cloneDeep } from 'lodash';
import { getModuleData } from '@/utils/modules';
import { ss } from '@/utils/storage';

const state = {
  loginKey: '',
  color: '',
  icon: '',
  id: null,
  institutionId: null,
  presentation: {
    description: '',
    image: ''
  },
  name: '',
  settings: {
    screenConfig: {
      gridSize: null,
      modules: [],
      footerConfig: [],
    },
    appConfig: {
      modules: []
    }
  },
  weather: {},
  archive: {}
};

const mutations = {
  SET_DEPARTMENT(state, department) {
    Object.assign(state, department);
  },
  SET_WEATHER(state, weather) {
    state.weather = weather;
  },
  SET_MY_ARCHIVE(state, archive) {
    state.archive = archive;
  },
  SET_DEPARTMENT_ID(state, id) {
    state.id = id;
  }
};

const actions = {
  async getDepartment({ commit }) {
    if (!state.id) {
      throw new Error('No department id provided');
    }

    await apolloClient
      .query({
        query: require('@/graphql/queries/department').default,
        variables: {
          id: state.id
        },
      })
      .then(response => {
        const department = cloneDeep(get(response, 'data.department', []));
        const moduleData = getModuleData();

        const modules = department.settings.screenConfig.modules.map(module => {
          const rowValues = module.rowSpan.split('/');
          const colValues = module.colSpan.split('/');
          const name = moduleData[module.type].name || moduleData[module.type].component;

          return {
            type: module.type,
            name,
            colorId: moduleData[module.type].colorId,
            colorHex: moduleData[module.type].colorHex,
            icon: moduleData[module.type].icon,
            row: {
              start: rowValues[0],
              end: rowValues[1]
            },
            col: {
              start: colValues[0],
              end: colValues[1]
            },
            frHeight: rowValues[1] - rowValues[0],
            frWidth: colValues[1] - colValues[0]
          }
        });

        department.settings.screenConfig.modules = modules;
        commit('SET_DEPARTMENT', department);
      })
      .catch(error => {
        console.log('Error getting department', error);
      });
  },
  async setDepartmentId({ commit }, id) {
    commit('SET_DEPARTMENT_ID', id);
  },
  async switchDepartment({commit}, key) {
    if (!key) {
      ss.remove('temp_login_key');
    } else {
      ss.set('temp_login_key', key);
    }

    window.localStorage.clear();
    window.location.reload();

    if (location.pathname !== '/') {
      document.location.href = '/';
    }
  },
  async getWeather({ commit }) {
    const departmentId = store.getters['department/id'];

    await apolloClient
      .query({
        query: require('@/graphql/queries/departmentWeather').default,
        variables: {
          id: departmentId
        },
      })
      .then(response => {
        commit('SET_WEATHER', JSON.parse(response.data.departmentWeather));
      })
      .catch(error => {
        console.log('Error getting weather', error);
      });
  },
  async getArchive({ commit }, id) {
    await apolloClient
      .query({
        query: require('@/graphql/queries/myArchiveScreen').default,
        variables: {
          profile: {
            profileType: 'DEPARTMENT',
            profileId: id
          }
        }
      })
      .then(response => {
        commit('SET_MY_ARCHIVE', response.data.myArchiveScreen)
      })
      .catch(error => {
        console.log('Error getting my archive', error);
      })
  }
};

const getters = {
  id: state => state.id,
  name: state => state.name,
  color: state => state.color,
  settings: state => state.settings,
  weather: state => state.weather,
  archive: state => state.archive,
  presentation: state => state.presentation,
  loginKey: state => state.loginKey,
  current: state => state
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};