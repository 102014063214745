var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "meal" },
    [
      _vm.showMediaPreview
        ? _c("media-preview", {
            attrs: {
              "selected-item-index": _vm.selectedMediaIndex,
              "media-type": _vm.mediaType,
              items: _vm.mediaItems,
            },
            on: {
              "close-preview": function ($event) {
                _vm.selectedMediaIndex = -1
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.meal && _vm.meal.id
        ? _c(
            "cover-view",
            {
              attrs: {
                "image-url": _vm.imageUrl,
                gradient: true,
                "module-color": _vm.moduleColor,
                "margin-top": 60,
              },
            },
            [
              _c(
                "div",
                { staticClass: "mealContent pt-20" },
                [
                  !_vm.useExternalMeals
                    ? _c("media-icons", {
                        attrs: {
                          icons: _vm.mediaIcons,
                          "media-type": _vm.mediaType,
                          amount: _vm.mediaItems.length,
                        },
                        on: { "media-selected": _vm.mediaIconSelected },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.useExternalMeals
                    ? _c("tts", {
                        attrs: {
                          "entity-id": _vm.meal.id,
                          "entity-type": "MEAL",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.showMediaList
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "largeListItemHeader pt-20 mb-10" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.meal.title) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mealDetails" },
                          [
                            !_vm.useExternalMeals
                              ? _c("date-time", {
                                  attrs: {
                                    start: _vm.meal.startDate,
                                    end: _vm.meal.endDate,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.meal.parts, function (part, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "content pb-10 flex items-center w-full",
                                },
                                [
                                  _c("fa-icon", {
                                    staticClass: "mr-6",
                                    attrs: { icon: ["fal", "soup"] },
                                  }),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("p", { staticClass: "mb-4" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(part.title) +
                                          "\n              "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("img", {
                                      staticClass: "mealPartImage rounded-2xl",
                                      attrs: { src: part.picture.source },
                                    }),
                                  ]),
                                ],
                                1
                              )
                            }),
                            _vm._v(" "),
                            !_vm.useExternalMeals
                              ? _c(
                                  "div",
                                  [
                                    _vm.meal.description.length
                                      ? _c("description", {
                                          attrs: {
                                            description: _vm.meal.description,
                                            icon: "align-left",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _c("description", {
                                  attrs: {
                                    description: _vm.meal.category,
                                    icon: "align-left",
                                  },
                                }),
                            _vm._v(" "),
                            _vm.meal.signupPossible
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "content pb-10 flex items-center w-full",
                                  },
                                  [
                                    _c("fa-icon", {
                                      staticClass: "mr-6",
                                      attrs: { icon: ["fal", "alarm-clock"] },
                                    }),
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.formatDeadline(_vm.meal)) +
                                        "\n          "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.useExternalMeals
                              ? _c(
                                  "div",
                                  [
                                    _vm.meal.participants.length
                                      ? _c("people", {
                                          attrs: {
                                            text: "global.participantsPlural",
                                            "module-color": _vm.moduleColor,
                                            profiles: _vm.meal.participants,
                                          },
                                          on: {
                                            "show-people": function ($event) {
                                              return _vm.showProfiles(
                                                "participants"
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.useExternalMeals
                              ? _c(
                                  "div",
                                  [
                                    _vm.meal.departments.length
                                      ? _c("departments", {
                                          attrs: {
                                            departments: _vm.meal.departments,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("institution"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ])
                    : _c(
                        "div",
                        [
                          _c("media-list", {
                            attrs: {
                              items: _vm.mediaItems,
                              "media-type": _vm.mediaType,
                              "can-close": true,
                            },
                            on: {
                              close: _vm.closeMediaList,
                              "media-selected": function ($event) {
                                _vm.selectedMediaIndex = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.showMediaList
                ? _c(
                    "div",
                    { staticClass: "mealActions flex w-max left-full sticky" },
                    [
                      _vm.signupPossible
                        ? _c("button-el", {
                            staticClass: "mr-5 shadow-xsm",
                            attrs: {
                              text: "global.signUp",
                              icon: "check",
                              "background-color": "success",
                              "text-color": "white",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.optInOrOut("in")
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.optoutPossible && _vm.signupPossible
                        ? _c("button-el", {
                            staticClass: "shadow-xsm",
                            attrs: {
                              text: "global.optOut",
                              icon: "minus-circle",
                              "background-color": "error",
                              "text-color": "white",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.optInOrOut("out")
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }