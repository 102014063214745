var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card-advanced flex flex-col justify-between relative",
      class: _vm.cardType,
      style: _vm.cardStyle,
    },
    [
      _c(
        "div",
        {
          staticClass: "state w-max rounded text-white text-3xl",
          class: {
            ["bg-success"]: _vm.isNow && !_vm.isCancelled,
            ["bg-error"]: _vm.isCancelled,
          },
        },
        [
          _vm.isNow && !_vm.isCancelled
            ? _c(
                "span",
                [
                  _c("fa-icon", { attrs: { icon: ["fal", "bell"] } }),
                  _vm._v(_vm._s(_vm.$t("global.now"))),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isCancelled
            ? _c(
                "span",
                [
                  _c("fa-icon", { attrs: { icon: ["fal", "ban"] } }),
                  _vm._v(_vm._s(_vm.$t("global.cancelled"))),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "info" }, [
        _vm.title.length
          ? _c(
              "p",
              {
                staticClass:
                  "title text-5xl text-white font-bold mb-3 ellipsis",
              },
              [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.startDate && _vm.weekday.length && _vm.cardType === "vertical"
          ? _c("p", { staticClass: "weekday text-3xl text-white" }, [
              _vm._v("\n      " + _vm._s(_vm.entryDate) + "\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.meta
          ? _c(
              "p",
              { staticClass: "text-3xl text-white" },
              [
                _vm.meta.icon
                  ? _c("fa-icon", {
                      staticClass: "text-white",
                      attrs: { icon: ["fal", `${_vm.meta.icon}`] },
                    })
                  : _vm._e(),
                _vm._v("\n      " + _vm._s(_vm.meta.text) + "\n    "),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }