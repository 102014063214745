var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bg-white startPage" }, [
    _vm.widgets.length
      ? _c(
          "div",
          { staticClass: "startPageGrid grid px-1 gap-2 h-full" },
          _vm._l(_vm.widgets, function (widget, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "h-full relative",
                class: [
                  `grid-${_vm.settings.gridSize}`,
                  { hasSelector: _vm.hasDepartmentSelector(widget.name) },
                  `frWidth${widget.frWidth}`,
                ],
                style: _vm.getGridStyles(widget),
              },
              [
                _vm.widgetData[widget.type].hasContent
                  ? _c(_vm.widgetData[widget.type].component, {
                      tag: "component",
                      attrs: { widget: widget },
                      on: {
                        "slide-clicked": function ($event) {
                          return _vm.$router.push($event)
                        },
                      },
                    })
                  : _c("empty-widget", {
                      attrs: {
                        "widget-type": widget.type,
                        color: widget.colorId,
                        icon: widget.icon,
                      },
                    }),
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }