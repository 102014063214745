import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas as ffas } from '@fortawesome/free-solid-svg-icons';
import { fas as pfas } from '@fortawesome/pro-solid-svg-icons';
import { fal as pfal } from '@fortawesome/pro-light-svg-icons';
import { far as pfar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import apolloClient from './utils/apollo';
import VueCarousel from 'vue-carousel';
import VueDatePicker from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import LazyTube from 'vue-lazytube';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import VueMatomo from 'vue-matomo';
import VueYoutube from 'vue-youtube';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/utils/lang';
import auth from '@/utils/auth';
import StoragePlugin from '@/plugins/storage';
import clickoutside from '@/directives/clickoutside';
import * as constants from '@/utils/constants.js';
import VueEllipseProgress from 'vue-ellipse-progress';

import Vue2TouchEvents from 'vue2-touch-events';

import 'animate.css';
import '@scss/main.scss';
import VueRouter from 'vue-router';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: process.env.SENTRY_DSN,
    maxBreadcrumbs: 50,
    debug: false,
    release: process.env.SENTRY_RELEASE || 'ibg-screen-vue@unknown',
    environment: process.env.NODE_ENV || 'development',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['prod-ibg-screen.proreact.dk', 'stage-ibg-screen.proreact.dk', /^\//],
      }),
    ],
    tracesSampleRate: 0.2,
    tracingOrigins: {
      trackComponents: true
    },
    attachProps: true,
    attachStacktrace: true
  });
}

Vue.directive('clickoutside', clickoutside);

Vue.prototype.$constants = constants.default;

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV === 'development';

// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('fa-icon', FontAwesomeIcon);

library.add(ffas);
library.add(pfas);
library.add(pfal);
library.add(pfar);

Vue.use(StoragePlugin);
Vue.use(VueCarousel);
Vue.use(VueDatePicker);
Vue.use(LazyTube);
Vue.use(VueYoutube);
Vue.use(VueEllipseProgress);
Vue.use(Vue2TouchEvents);
Vue.use(VueRouter);

const app = new Vue({
  i18n,
  router,
  store,
  apolloClient,
  render: h => h(App)
});

Vue.use(VueMatomo, {
  host: 'https://aws.proreact.dk/tracking/',
  siteId: 10,
  router
});

auth.authenticate().then(() => {
  app.$mount('#app');
}).catch(() => {
  throw new Error('Could not authenticate');
});
