var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("input", {
      staticClass:
        "w-full input border-b-2 border-charcoal mb-16 text-6xl pb-2 font-semibold",
      attrs: { placeholder: _vm.$t(_vm.placeholder) },
      domProps: { value: _vm.input },
    }),
    _vm._v(" "),
    _c("div", { class: _vm.keyboardClass }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }