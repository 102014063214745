var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "flex justify-between self-center px-20 pb-48 -mt-12 rounded-t-3xl",
      },
      [
        _c("div", { staticClass: "largeListItemHeader" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("booking.addDescription")) + "\n    "
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "px-20 pb-12" },
      [
        _c("keyboard-simple", {
          on: {
            onChange: function ($event) {
              _vm.description = $event
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "w-full flex justify-center" },
      [
        _c("button-el", {
          staticClass: "mr-5 mt-8 shadow-xsm",
          attrs: {
            text: _vm.description.length ? "modals.next" : "modals.skip",
            icon: "arrow-right",
            "background-color": "success",
            "text-color": "white",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.next.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }