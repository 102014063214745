var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "flex justify-between self-center px-20 pb-20 -mt-12 rounded-t-3xl",
        },
        [
          _c("div", { staticClass: "largeListItemHeader" }, [
            _vm._v("\n      " + _vm._s(_vm.$t(_vm.data.title)) + "\n    "),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.data.items, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass:
              "participants content inline-flex w-full pb-10 px-20 items-center",
          },
          [
            item.picture !== null
              ? _c("img", {
                  staticClass:
                    "w-24 h-24 rounded-full border border-gray-200 mr-10",
                  class: { "-m-1": index !== 0 },
                  attrs: { src: item.picture.source },
                })
              : _c(
                  "div",
                  {
                    staticClass:
                      "w-24 h-24 mr-10 flex items-center justify-center",
                    class: { "-m-1": index !== 0 },
                  },
                  [_c("div", { staticClass: "w-5 h-5 rounded-full bg-black" })]
                ),
            _vm._v(" "),
            _c("p", { staticClass: "label" }, [
              _vm._v("\n      " + _vm._s(item.title) + "\n    "),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }