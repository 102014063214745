import gql from 'graphql-tag';

export default gql`
query mealEntry ($id: ID!) {
  mealEntry (id: $id) {
      cancelled
      coverImage {
        blurhash
        id
        source
      }
      departments {
        color
        icon
        id
        name
      }
      description
      documents {
        description
        id
        title
        url
      }
      endDate
      id
      institution {
        color
        id
        name
      }
      location
      maxSignups
      participants {
        ... on Employee {
          alias
          displayImage
          displayName
          showImage
          id
          departments {
            name
            color
          }
        }
        ... on Resident {
          alias
          displayImage
          showImage
          displayName
          id
          departments {
            name
            color
          }
        }
      }
      participating
      parts {
          description
          picture {
              blurhash
              description
              id
              source
          }
          title
      }
      pictograms {
          blurhash
          id
          source
          type
      }
      pictures {
          blurhash
          description
          id
          inMyArchive
          source
      }
      signupEnd
      signupPossible
      signupStart
      startDate
      title
      videos {
          blurhash
          id
          provider
          thumbnail
          url
      }
  }
}`