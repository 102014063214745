var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "animate transition-all" },
    [
      _vm.timeSlots.length
        ? _c(
            "TransitionGroup",
            { attrs: { name: "list", tag: "ul" } },
            _vm._l(_vm.slots, function (slot, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass:
                    "relative border border-sm rounded rounded-md h-20 mb-4 flex items-center justify-center",
                  style: _vm.getStyles(slot),
                  on: {
                    click: function ($event) {
                      return _vm.selectTime(slot, index)
                    },
                  },
                },
                [
                  !slot.available && _vm.type !== "end"
                    ? _c("fa-icon", {
                        staticClass:
                          "text-error transform rotate-90 absolute text-4xl",
                        attrs: { icon: ["fa", "slash"] },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "smallListItemHeader" }, [
                    _vm._v("\n        " + _vm._s(slot.time) + "\n      "),
                  ]),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }