import { apolloClient } from '../../utils/apollo';
import store from '@/store';

const state = {
  all: []
};

const mutations = {
  SET_GALLERIES(state, galleries) {
    state.all = galleries;
  }
};

const actions = {
  async getGalleries({commit}) {
    const departmentId = store.getters['department/id'];
    
    if (!departmentId) {
      throw new Error('No department id provided');
    }

    await apolloClient
      .query({
        query: require('@/graphql/queries/galleries').default,
        variables: {
          filter: {
            departments: departmentId
          }
        },
        fetchPolicy: 'no-cache'
      })
      .then(response => {
        commit('SET_GALLERIES', response.data.galleries);
      })
      .catch(error => {
        console.log('Error getting galleries', error);
      })
  },
  async archiveAddScreen({commit}, { resourceId, profile, opt }) {
    const mutation = opt === 'in' ? 'archiveAddScreen' : 'archiveRemoveScreen';

    await apolloClient
      .mutate({
        mutation: require(`@/graphql/mutations/${mutation}`).default,
        variables: {
          resourceId,
          profile
        },
      })
      .then(async () => {
        await store.dispatch('profiles/setPinValidation', { validating: false, validPin: true });
        store.dispatch('profiles/getMyArchive');
      })
      .catch(error => {
        store.dispatch('profiles/setPinValidation', { validating: false, validPin: false });
        throw new Error('Could not add to archive', error);
      });
  }
};

const getters = {
  all: state => state.all
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
