var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "date flex-none text-lg leading-none flex flex-col justify-center",
    },
    [
      _c("p", { staticClass: "dayOfWeek h500" }, [
        _vm._v("\n        " + _vm._s(_vm.dayOfWeek) + "\n    "),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "date h500" }, [
        _vm._v("\n        " + _vm._s(_vm.date) + "\n    "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }