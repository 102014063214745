export function getWidgetSize(widget) {
  if (widget.frHeight === 1 && widget.frWidth === 1) {
    return 'small'
  } else if (widget.frHeight >= 2 && widget.frWidth < 2) {
    return 'medium';
  } else if (widget.frHeight === 1 && widget.frWidth > 1) {
    return 'medium-flat';
  } else if (widget.frHeight >= 2 && widget.frWidth >= 2) {
    return 'large';
  }
}

export function getNameSize(shiftHeight) {
  if (shiftHeight < 110) return 'text-xs';
  return 'text-xl';
}

export function getMaxAmountOfShifts(widgetSize) {
  if (widgetSize === 'small') return 9;
  if (widgetSize === 'medium-flat') return 10;
  if (widgetSize === 'medium') return 24;
  return 35;
}