<template>
  <div class="newsEntry">
    <media-preview
      v-if="showMediaPreview"
      :selected-item-index="selectedMediaIndex"
      :media-type="mediaType"
      :items="mediaItems"
      @close-preview="selectedMediaIndex = -1"
    />

    <cover-view
      v-if="newsEntry"
      :image-url="newsEntry.coverImage.source"
      :gradient="true"
      :module-color="moduleColor"
      :margin-top="60"
    >
      <div class="newsEntryContent pt-20">
        <media-icons
          :icons="mediaIcons"
          :media-type="mediaType"
          :amount="mediaItems.length"
          @media-selected="mediaIconSelected"
        />
        <tts
          :entity-id="newsEntry.id"
          entity-type="NEWS"
        />

        <div v-if="!showMediaList">
          <div class="largeListItemHeader pt-20 pb-6">
            {{ newsEntry.title }}
          </div>
          <div class="text-3xl font-light">
            {{ $t('news.published') }} {{ getStartDay(newsEntry.startDate) }}
          </div>

          <div class="pictograms inline-flex w-full mb-20">
            <img
              v-for="(pictogram, index) in newsEntry.pictograms" 
              :key="index"
              class="w-20 mr-5"
              :src="newsEntry.pictograms[index].source"
            >
          </div>

          <div class="newsDetails">
            <div class="activityDescription col-span-2 pl-16 relative content pb-10">
              <fa-icon
                class="absolute top-1 left-0"
                :icon="['fal', 'align-left']"
              />
              {{ newsEntry.body }}
            </div>
          </div>
        </div>

        <div v-else>
          <media-list
            :items="mediaItems"
            :media-type="mediaType"
            :can-close="true"
            class="pt-20"
            @close="closeMediaList"
            @item-selected="selectedMediaIndex = $event"
          />
        </div>
      </div>
    </cover-view>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import { isEmpty, capitalize } from 'lodash';
import CoverView from '@/views/shared/CoverView';
import MediaIcons from '@/components/shared/MediaIcons';
import MediaPreview from '@/views/overlays/MediaPreview';
import MediaList from '@/views/shared/MediaList';
import Tts from '@/components/shared/Tts';
import { getStartDay } from '@/utils/helpers.js';

export default {
  components: {
    CoverView,
    MediaIcons,
    MediaPreview,
    MediaList,
    Tts
  },
  data() {
    return {
      mediaType: '',
      selectedMediaIndex: -1,
      hideMediaPreview: false,
      showMediaList: false
    }
  },
  computed: {
    ...mapGetters({
      news: 'news/all',
      activeOverlay: 'general/activeOverlay'
    }),
    moduleColor() {
      return this.$route?.meta?.color;
    },
    newsEntry() {
      return this.news.find(newsEntry => newsEntry.id === this.$route.params.id);
    },
    mediaIcons() {
      return [
        {
          name: 'pictures',
          fa: 'image',
          amount: this.newsEntry.pictures.length
        },
        {
          name: 'videos',
          fa: 'film',
          amount: this.newsEntry.videos.length
        },
        {
          name: 'documents',
          fa: 'file-alt',
          amount: this.newsEntry.documents.length
        }
      ];
    },
    mediaItems() {
      let items;

      switch(this.mediaType) {
        case 'pictures':
          items = this.newsEntry.pictures;
          break;
        case 'videos':
          items = this.newsEntry.videos;
          break;
        case 'documents':
          items = this.newsEntry.documents;
          break;
        default:
          items = [];
      }
      return items;
    },
    selectedMediaItem() {
      return this.mediaItems[this.selectedMediaIndex];
    },
    showMediaPreview() {
      return !isEmpty(this.selectedMediaItem) && !this.hideMediaPreview;
    }
  },
  mounted() {
    this.$matomo.trackEvent('NewsEntry', 'Viewed', 'NewsEntryViewed');
  },
  methods: {
    getStartDay(dateString) {
      return capitalize(this.$t(getStartDay(dateString)));
    },
    closeMediaList() {
      this.mediaType = '';
      this.selectedMediaIndex = -1;
      this.showMediaList = false;
    },
    mediaIconSelected(mediaType) {
      this.mediaType = mediaType;

      if (this.mediaItems.length === 1) {
        // if only one item, go straight to media-preview
        this.selectedMediaIndex = 0;
        this.hideMediaPreview = false;
        return;
      }

      this.showMediaList = true;
    }
  }
};
</script>

<style lang="scss">
  .newsEntry {

    .newsEntryContent {
  
      > *:not(.mediaIcons) {
        padding: 0 100px;
      }
    }
  }
</style>