import gql from 'graphql-tag';

export default gql`
mutation meeting ($subscription: MeetingSubscription!, $profile: ScreenProfile!) {
  meeting {
      cancelSignupScreen (profile: $profile, subscription: $subscription) {
          id
          message
          success
      }
  }
}`