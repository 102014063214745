const constants = {
  widgetTypes: [
    'ACTIVITIES',
    'BOOKING',
    'EXTERNAL_ACTIVITIES',
    'GALLERY',
    'SLIDESHOW',
    'JOURNEY_PLANNER',
    'MEAL_PLAN',
    'MEETINGS',
    'NEWS',
    'OUR_HOME',
    'SIMPLE_WORK_SCHEDULE',
    'WAYFINDER',
    'WEATHER',
    'WORK_SCHEDULE',
    'LATER_WORK_SCHEDULE'
  ],
  animationDuration: 800,
  navHeight: 300,
  slider: {
    sliderOptions: {
      perPage: 1,
      paginationActiveColor: 'fff',
      paginationColor: 'rgba(255, 255, 255, 0.5)',
      isTouch: false,
      minSwipeDistance: 5000
    },
    slideStyles: 'w-full text-white flex items-center h-full flex-col p-16 text-center'
  },
  workSchedule: {
    oneMinuteWidth: window.innerWidth / 36 / 60
  },
  timezone: 'Europe/Copenhagen',
  avatarPlaceholder: '../../assets/img/user-placeholder.png'
}

export default constants;