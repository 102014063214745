var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "radioListItem option border-2 h-20 mb-4 rounded rounded-xl last:mb-0 flex px-6",
      class: {
        "border-darkOcean600": _vm.isSelected,
        "border-neutral300": !_vm.isSelected,
        "bg-darkOcean100": _vm.isSelected,
      },
      on: {
        click: function ($event) {
          return _vm.$emit("option-selected")
        },
      },
    },
    [
      _c("div", { staticClass: "flex items-center justify-between w-full" }, [
        _c("div", { staticClass: "flex items-center" }, [
          _c(
            "div",
            {
              staticClass:
                "mr-4 rounded rounded-full h-12 w-12 flex items-center justify-center",
              style: { backgroundColor: _vm.option.color },
            },
            [
              _c("fa-icon", {
                staticClass: "text-2xl text-white",
                attrs: { icon: ["fas", "home"] },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", { staticClass: "h600" }, [
            _vm._v("\n        " + _vm._s(_vm.option.name) + "\n      "),
          ]),
        ]),
        _vm._v(" "),
        _vm.isSelected
          ? _c(
              "div",
              {
                staticClass:
                  "selected rounded-full w-8 h-8 flex items-center justify-center",
              },
              [_c("div", { staticClass: "rounded-full w-3 h-3 bg-white" })]
            )
          : _c("div", {
              staticClass:
                "rounded-full w-8 h-8 bg-white border border-darkOcean600",
            }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }