var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-full" }, [
    _c(
      "div",
      {
        staticClass:
          "flex justify-between self-center px-20 pb-48 -mt-12 rounded-t-3xl",
      },
      [
        _c("div", { staticClass: "largeListItemHeader" }, [
          _vm._v("\n      " + _vm._s(_vm.$t(_vm.data.title) || "") + "\n    "),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex flex-col items-center justify-center" },
      [
        _c("fa-icon", {
          staticClass: "text-error text-8xl mb-12",
          attrs: { icon: ["fal", "exclamation-circle"] },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "content" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t(_vm.data.descriptionOne) || "") +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "content mb-80" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t(_vm.data.descriptionTwo) || "") +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("button-el", {
          attrs: {
            text: _vm.data.buttonText || "global.tryAgain",
            "background-color": "error",
            icon: "key",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.reloadBookings.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }