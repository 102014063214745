import { apolloClient } from '../../utils/apollo';
import store from '@/store';
import { ls } from '@/utils/storage';

const state = {
  all: []
};

const mutations = {
  SET_DEFAULT_NEWS(state, news) {
    state.all = news;
  }
};

const actions = {
  async getNews({commit}) {
    const departmentId = store.getters['department/id'];
    
    if (!departmentId) {
      throw new Error('No department id provided');
    }

    await apolloClient
      .query({
        query: require('@/graphql/queries/news').default,
        variables: {
          filter: {
            departments: departmentId
          }
        },
        fetchPolicy: 'no-cache'
      })
      .then(response => {
        commit('SET_DEFAULT_NEWS', response.data.news);
      })
      .catch(error => {
        console.log('Error getting news', error);
      })
  }
};

const getters = {
  all: state => state.all
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
