var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "newsWrapper",
      class: {
        "verticalCenter flex flex-col justify-center items-stretch":
          _vm.verticalCenterGrid || _vm.verticalCenterList,
      },
    },
    [
      _vm.news.length
        ? _c(
            "div",
            { staticClass: "news bg-white" },
            [
              _vm.activeContentView.name === "list"
                ? _c("list-view", {
                    attrs: {
                      items: _vm.news,
                      "width-percent": 80,
                      "sort-by-day": false,
                    },
                    on: {
                      "item-selected": function ($event) {
                        return _vm.showSingleNewsEntry($event)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.activeContentView.name === "grid"
                ? _c("grid-view", {
                    attrs: { items: _vm.news, "item-type": "news" },
                    on: {
                      "item-selected": function ($event) {
                        return _vm.showSingleNewsEntry($event)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _c("empty-views", {
            attrs: { "view-type": "News", color: _vm.moduleColor },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }