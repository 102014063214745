<template>
  <div class="enter-pin h-full overflow-hidden">
    <div class="content flex flex-col h-full items-center">
      <div class="absolute -mt-96 w-96 flex flex-col items-center mb-20">
        <img
          :src="activeProfile.displayImage"
          class="rounded rounded-full w-60 h-60 border-8 border-white shadow-m object-cover bg-white mb-8"
        >

        <div class="largeListItemHeader text-center">
          {{ activeProfile.displayName }}
        </div>
      </div>

      <div class="mt-12 w-full flex justify-center">
        <div class="w-full h-1 bg-charcoal-xxlight" />

        <div class="w-60 rounded rounded-md bg-white -mt-10 p-6 flex items-center justify-center shadow-lg absolute">
          <fa-icon
            class="text-3xl mr-3"
            :icon="['far', 'sign-out']"
          />

          <p
            class="font-bold text-2xl"
            @click="switchUser()"
          >
            {{ $t('global.switchUser') }}
          </p>
        </div>
      </div>
      <div
        v-if="!loginSuccessful"
        class="flex flex-col flex-1 items-center p-10"
        :class="{ validating: pinValidation.validating }"
      >
        <div
          v-if="!lockPinCode"
          class="largeListItemHeader mb-10 text-center error mt-8"
          :style="{ opacity: wrongPin ? 1 : 0 }"
        >
          {{ $t("modals.wrongPassword") }}
        </div>

        <div
          v-if="!lockPinCode"
          class="content text-center mb-20"
        >
          {{ activeOverlay.data.title }}
          <span
            v-if="activeOverlay.data.entityName"
            class="font-bold"
          >
            {{ activeOverlay.data.entityName }}
          </span>
        </div>
        <div v-if="lockPinCode">
          <div class="largeListItemHeader mb-10 text-center error mt-8">
            {{ $t("modals.tooManyAttempts") }}
          </div>
          <div class="text-center">
            {{ $t("modals.passwordLocked", { amount: displayedCountDown}) }} 
          </div>
        </div>
        <div
          v-if="!lockPinCode"
          class="inline-flex mb-10 ml-12"
        >
          <div
            v-for="(item, index) in 4"
            :key="index"
            class="w-14 h-14 border border-charcoal border-b-2 rounded-full"
            :class="{
              'mr-3': index !== 4,
              'bg-charcoal': pin.length >= item,
              'bg-error': wrongPin,
            }"
            :style="{ opacity: 0.25 }"
          />
          <fa-icon
            class="text-3xl self-center"
            :icon="['fal', 'backspace']"
            @click="pin.pop()"
          />
        </div>
        
        <div
          v-if="!lockPinCode"
          class="flex flex-wrap mb-10 justify-center w-1/3"
        >
          <div
            v-for="(number, index) in numbers"
            :key="index"
            class="
              w-20
              h-20
              shadow-lg
              rounded-full
              mb-3
              text-5xl
              flex
              items-center
              justify-center
              text-white
            "
            :class="{ 'mr-3': ![3, 6, 9, 0].includes(number) }"
            :style="{ backgroundColor: colors[index] }"
            @click="numberPressed(number)"
          >
            {{ number }}
          </div>
        </div>
      </div>

      <div
        v-else
        class="flex flex-col flex-1 items-center"
      >
        <div class="largeListItemHeader mb-10 pt-20 text-center success">
          <fa-icon :icon="['fa', 'check']" />
          {{ $t("global.completed") }}
        </div>

        <div class="content text-center mb-20">
          {{ activeOverlay.data.titleSuccess }}
          <span class="font-bold">{{ activeOverlay.data.entityName }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ls } from '@/utils/storage';
import { intervalToDuration, subMinutes, addMinutes, formatISO, parseISO, differenceInMinutes } from 'date-fns'
import { formatDistanceToNow } from '@/utils/date-fns';
import { get } from 'lodash';

export default {
  data() {
    return {
      numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
      colors: [
        '#DF2121',
        '#2BB914',
        '#2134DF',
        '#DFB521',
        '#DC0BC7',
        '#E87B17',
        '#5E21DF',
        '#953600',
        '#1FA3B5',
        '#929292',
      ],
      pin: [],
      pinAttempts: 0,
      lockPinCode: false,
      displayedCountDown: {},
      countDownInterval: {},
      lockedUsers: [],
      initialCountDown: new Date()
    };
  },
  computed: {
    ...mapGetters({
      activeProfile: 'profiles/activeProfile',
      activeOverlay: 'general/activeOverlay',
      pinValidation: 'profiles/pinValidation',
      institutionSettings: 'institution/settings',
    }),
    data() {
      return this.activeOverlay.data;
    },
    passwordProtection(){
      return get(this.institutionSettings, 'screen.passwordProtection', false);
    },
    lockedUsersFromLocalStorage(){
      return ls.get('lockedUsers') || [];
    },
    wrongPin() {
      const wrongPin =
        this.pin.length === 4 &&
        !this.pinValidation.validating &&
        !this.pinValidation.validPin;
      if (wrongPin) {
        this.$matomo.trackEvent('EnterPin', 'WrongPin', this.data.title);
      }
      return wrongPin;
    },
    loginSuccessful() {
      const validPin = this.pinValidation.validPin;

      if (validPin)
        this.$matomo.trackEvent('EnterPin', 'PinSuccessful', this.data.title);

      return validPin;
    },
    isSignInOutAction() {
      return this.activeOverlay.data.entity === 'activity' || this.activeOverlay.data.entity === 'meals' || this.activeOverlay.data.entity === 'meeting';
    } 
  },
  watch: {
    pinValidation(pinData) {
      if (pinData.validPin === true && this.data.closeOnSuccess === true) this.$emit('close-overlay');
      if (pinData.validPin === true) this.lockedUsers = this.lockedUsers.filter(user => user.id !== this.activeProfile.id);
      if (pinData.validPin === true && this.isSignInOutAction) {
        const participantIds = this.data.participants.map(participant => participant.id);
        const participantExists = participantIds.includes(this.activeProfile.id);

        if ((this.data.opt === 'in' && !participantExists) || (this.data.opt === 'out' && participantExists)) {
          return;
        } else {
          this.$store.dispatch('general/setNextActiveOverlay', { name: 'error', data: {
            title: 'global.error',
            descriptionOne: `${this.data.entity}.${this.data.opt === 'in' ? 'alreadySignedUp' : 'alreadySignedOff'}`,
            buttonText: 'global.close'
          } });
          this.$emit('close-overlay');
        }
      }
    },
    lockedUsers(lockedUsersData){
      // Update local storage every time we update local variable.
      ls.set('lockedUsers', lockedUsersData)
    },
    initialCountDown(count) {
      // Watch if the counter reaches zero and unlock the user. 
      if (count.minutes === 0 && count.seconds === 0) {
        this.lockedUsers = this.lockedUsers.reduce((acc, user) => {
          if (user.id === this.activeProfile.id) {
            user.pinLocked = false;
            this.resetPin();
            acc.push(user);
            this.pinAttempts = 0;
          }
          return acc;
        }, []);
      }
    },
    deep: true
  },
  created() {
    this.lockedUsers = this.lockedUsersFromLocalStorage;

    // Remember user attempts x minutes after the first try, otherwise reset them.
    this.lockedUsers = this.lockedUsers.filter(user => {
      const resetTimestamp = formatISO(subMinutes(new Date(user.timeStamp), this.passwordProtection.userLockedOutInMinutes));
      const forgetAttemptsTimer = Math.round(differenceInMinutes(parseISO(resetTimestamp), new Date()));

      if (user.pinLocked) {
        return user;
      } else {
        return forgetAttemptsTimer > -2;  
      }
    })
    
    this.lockedUsers.forEach(user => {
      // Update active profile entry with local storage data when component is created.
      if (user.id === this.activeProfile.id) {
        this.lockPinCode = user.pinLocked;
        this.pinAttempts = user.pinAttempts;
      }
    });
  },
  mounted() {
    this.$matomo.trackEvent('EnterPin', 'Viewed', 'EnterPinViewed');

    // Calculate the difference every second to show a real time count down. 
    this.countDownInterval = setInterval(() => {
      this.setCountdown()
    }, 1000);
    
    this.setCountdown();
  },
  destroyed() {
    clearInterval(this.countDownInterval);
  },
  methods: {
    // Calculate difference between now and the last failed password attempt. 
    setCountdown(){
      this.lockedUsers.forEach(user => {
        if (user.id === this.activeProfile.id) {
            this.lockPinCode = user.pinLocked;
            this.initialCountDown = intervalToDuration({
              start: new Date(user.timeStamp), 
              end: new Date(),
          })

          this.displayedCountDown = formatDistanceToNow(new Date(user.timeStamp), { includeSeconds: true });    
        }
      })
    },
    async numberPressed(number) {
      if (this.pinValidation.validating) return;

      if (this.pin.length === 4 && this.wrongPin) {
        this.resetPin();
      }

      this.pin.push(number);

      if (this.pin.length === 4) {
        this.pinAttempts += 1;

        // Keep track of user attempts and lock-state by filling in the log with each attempt.
        if (this.passwordProtection.isProtected) {
          const checkUser = user => user.id === this.activeProfile.id;
          
          if (!this.lockedUsers.some(checkUser)) {
            const offset = addMinutes(new Date(), this.passwordProtection.userLockedOutInMinutes)
            
            // Timestamp is the time from now plus x minutes (x is the amount we want the user to be logged out).
            this.lockedUsers.push({id: this.activeProfile.id, pinLocked: false, timeStamp: formatISO(offset), pinAttempts: this.pinAttempts})
          }

          if (this.pinAttempts === this.passwordProtection.maxFailed) {
            this.lockedUsers = this.lockedUsers.map((user) => {
              if (user.id === this.activeProfile.id) {
                return {
                  ...user,
                  pinLocked: true,
                  pinAttempts: this.pinAttempts
                }
              } else {
                return user;
              }
            })
          } else {
            this.lockedUsers = this.lockedUsers.map((user) => {
              if (user.id === this.activeProfile.id) {
                return {
                  ...user,
                  pinLocked: false,
                  pinAttempts: this.pinAttempts
                }
              } else {
                return user;
              }
            })
          }
        }
        
        await this.$store.dispatch('profiles/validatePin', {
          profile: this.activeProfile,
          pin: this.pin.join('')
        });
      }
    },
    resetPin() {
      this.$store.dispatch('profiles/setPinValidation', {});
      this.pin = [];
    },
    switchUser() {
      this.$emit('close-overlay');

      setTimeout(() => {
        this.$store.dispatch('general/setActiveOverlay', { name: 'profile-select' })
        this.$store.dispatch('general/setNextActiveOverlay', { name: 'enter-pin' })
      }, 700);
    }
  }
};
</script>


<style lang="scss">
.enter-pin {
  .validating {
    opacity: 0.5;
  }
}
</style>
