var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "fixed z-10 inset-0 overflow-y-auto",
      attrs: {
        "aria-labelledby": "modal-title",
        role: "dialog",
        "aria-modal": "true",
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0",
        },
        [
          _c("div", {
            staticClass:
              "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "hidden sm:inline-block sm:align-middle sm:h-screen",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6",
            },
            [
              _c("div", { staticClass: "sm:flex sm:items-start" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10",
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "h-6 w-6 text-red-600",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          stroke: "currentColor",
                          "aria-hidden": "true",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            d: "M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm._m(0),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-5 sm:mt-4 sm:flex sm:flex-row-reverse" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("startRecording")
                        },
                      },
                    },
                    [_vm._v("\n          Start recording\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("cancel")
                        },
                      },
                    },
                    [_vm._v("\n          Cancel\n        ")]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left" },
      [
        _c(
          "h3",
          {
            staticClass: "text-lg leading-6 font-medium text-gray-900",
            attrs: { id: "modal-title" },
          },
          [_vm._v("\n            Provide feedback\n          ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mt-2" }, [
          _c("p", { staticClass: "text-sm text-gray-500" }, [
            _vm._v(
              "\n              To provide feedback for a feature we ask you to start a video and audio recording of the screen, for us to use while assesing the issue.\n            "
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }