<template>
  <div
    class="h-36 w-full mb-8 flex flex-row relative"
    :style="{ width: viewWidth }"
    :class="{ 'mt-12': index === 0 }"
  >
    <div
      class="image absolute z-20"
      :style="{ marginLeft: `${imageLeft}px` }"
    >
      <img
        :src="shift.employee.displayImage"
        alt="Employee"
        class="w-36 h-36 rounded rounded-full border-8 border-white shadow-bottom z-20 object-cover"
        :class="[{ 'filter grayscale': shiftOver || shiftCancelled } ]"
      >
    
      <div
        v-if="shift.nightShift"
        class="bg-charcoal z-10 absolute w-12 h-12 rounded rounded-full flex items-center justify-center -mt-12 ml-28 border-4 border-white shadow-bottom"
      >
        <fa-icon
          class="text-white text-xl"
          :icon="['fas', 'moon-stars']"
        />
      </div>
    
      <div
        v-else-if="shift.cancelled"
        class="bg-warning z-10 absolute w-12 h-12 rounded rounded-full flex items-center justify-center -mt-12 ml-28 border-4 border-white shadow-bottom"
      >
        <fa-icon
          class="text-white text-xl"
          :icon="['fas', 'times']"
        />
      </div>

      <div
        v-else-if="unavailableNow"
        class="z-10 absolute w-12 h-12 rounded rounded-full flex items-center justify-center -mt-12 ml-28 border-4 border-white shadow-bottom bg-orange"
      />
        
      <div
        v-else-if="shiftStarted"
        class="z-10 absolute w-12 h-12 rounded rounded-full flex items-center justify-center -mt-12 ml-28 border-4 border-white shadow-bottom"
        :class="`bg-${moduleColor}`"
      />
    </div>

    <div
      class="shift h-full z-10 rounded rounded-lg shadow-bottom flex items-center"
      :class="backgroundColor"
      :style="{ 'width': `${shiftWidth}px`, 'margin-left': shiftMarginLeft }"
    >
      <div
        class="shiftData absolute flex flex-col justify-center z-10"
        :style="{ marginLeft: `${shiftDetailsLeft}px` }"
      >
        <p
          class="w-max text-3xl font-semibold name"
          :class="[textColor, {'opacity-0': !showName, 'opacity-1': showName}]"
        >
          {{ shift.employee.displayName }}
        </p>
        <p
          class="w-max text-3xl font-light time"
          :class="[textColor, {'opacity-0': !showTime, 'opacity-1': showTime}]"
        >
          {{ startTime }} - {{ endTime }}
        </p>
      </div>
      <div
        v-if="hasUnavailability && !shiftOver"
        class="h-full bg-orange absolute"
        :class="{ 'rounded-l-lg': unavailabilityMarginLeft === 0, 'rounded-r-lg': unavailabilityWidth + unavailabilityMarginLeft === shiftWidth }"
        :style="{ 'margin-left': `${unavailabilityMarginLeft}px`, 'width': `${unavailabilityWidth}px` }"
      />
    </div>
  </div>
</template>

<script>
import { isAfter, differenceInMinutes, isSameDay, setHours, getDate, getMonth, addDays } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { clone } from 'lodash';
import constants from '@/utils/constants';
import { format } from '@/utils/date-fns';

export default {
  props: {
    shift: {
      type: Object,
      'default': () => {}
    },
    moduleColor: {
      type: String,
      'default': ''
    },
    index: {
      type: Number,
      'default': -1
    },
    viewWidth: {
      type: String,
      'default': ''
    },
    selectedDay: {
      type: Date,
      'default': (() => {})
    }
  },
  data() {
    return {
      lastScrollLeft: 60,
      shiftDetailsLeft: 40,
      imageLeft: 60,
      showName: true,
      showTime: true,
      today: new Date()
    }
  },
  computed: {
    now() {
      const shiftStart = new Date(this.shift.start);
      return isSameDay(this.today, shiftStart) ? shiftStart : setHours(new Date(shiftStart), 0);
    },
    shiftStart() {
      return toDate(this.shift.start, { timeZone: constants.timezone });
    },
    shiftEnd() {
      return toDate(this.shift.end, { timeZone: constants.timezone });
    },
    startTime() {
      return format(new Date(this.shift.start), 'HH:mm');
    },
    endTime() {
      return format(new Date(this.shift.end), 'HH:mm');
    },
    unavailabilityStart() {
      return toDate(this.shift.unavailable[0].start, { timeZone: constants.timezone });
    },
    unavailabilityEnd() {
      return toDate(this.shift.unavailable[0].end, { timeZone: constants.timezone });
    },
    unavailableNow() {
      if (!this.shift.unavailable) return false;

      if (isAfter(this.today, this.unavailabilityStart) && isAfter(this.unavailabilityEnd, this.today)) return true;
      return false;
    },
    backgroundColor() {
      if (this.shiftOver || !this.shiftStarted) return 'bg-charcoal-xlight';
      if (this.shiftCancelled) return 'bg-warning';
      
      return `bg-${this.moduleColor}`;
    },
    textColor() {
      if (this.shiftOver || this.shiftCancelled || !this.shiftStarted) return 'text-black';
      return 'text-white';
    },
    shiftStarted() {
      return isAfter(this.today, this.shiftStart) && isAfter(this.shiftEnd, this.today);
    },
    shiftOver() {
      return isAfter(this.today, this.shiftEnd);
    },
    shiftCancelled() {
      return this.shift.cancelled;
    },
    hasUnavailability() {
      return !!this.shift.unavailable;
    },
    oneMinuteWidth() {
      return window.innerWidth / 12 / 60;
    },
    shiftInMinutes() {
      return differenceInMinutes(this.shiftEnd, this.shiftStart);
    },
    isStartDateBeforeSelectedDate() {
      const selectedDay = getDate(this.selectedDay);
      const selectedMonth = getMonth(this.selectedDay) + 1;
      const shiftDay = getDate(new Date(this.shift.start));
      const shiftMonth = getMonth(new Date(this.shift.start)) + 1;

      return (shiftDay < selectedDay) || (shiftMonth < selectedMonth);
    },
    toMidnight(){
      return this.oneMinuteWidth * 6 * 60;
    },
    shiftWidth() {
      const today = clone(this.now);
      const midnightToday = today.setHours(0, 0, 0, 0);
      const minutesSinceNextMidnightWidth = differenceInMinutes(this.shiftEnd, addDays(midnightToday, 1));

      if (!this.isStartDateBeforeSelectedDate) {
        return this.shiftInMinutes * this.oneMinuteWidth;
      }
      
      return this.toMidnight + (minutesSinceNextMidnightWidth * this.oneMinuteWidth) + 25;
    },
    shiftMarginLeft() {
      const today = clone(this.now);
      const midnightToday = today.setHours(0, 0, 0, 0);

      if (!this.isStartDateBeforeSelectedDate) {
        const minutesSinceMidnightWidth = (differenceInMinutes(this.shiftStart, midnightToday) * this.oneMinuteWidth) + 25;
        return `${this.toMidnight + minutesSinceMidnightWidth}px`;
      }

      return '0px';
    },
    unavailabilityWidth() {
      const minutes = differenceInMinutes(this.unavailabilityEnd, this.unavailabilityStart);
      return minutes * this.oneMinuteWidth;
    },
    unavailabilityMarginLeft() {
      const minutesFromStart = differenceInMinutes(this.unavailabilityStart, this.shiftStart);
      return minutesFromStart * this.oneMinuteWidth;
    },
  },
  mounted() {
    setTimeout(() => {
      this.lastScrollLeft = document.querySelector('.schedule').scrollLeft;
      const shift = document.querySelectorAll('.shift')[this.index];
      const name = document.querySelectorAll('.name')[this.index];
      const time = document.querySelectorAll('.time')[this.index];
      const image = document.querySelectorAll('.image')[this.index];
      this.imageLeft = this.lastScrollLeft + 40;
      this.shiftDetailsLeft = 240;
      
      document.querySelector('.schedule').addEventListener('scroll', event => {
        this.lastScrollLeft = event.target.scrollLeft;
        const imageBCR = image.getBoundingClientRect();
        const shiftBCR = shift.getBoundingClientRect();
        const nameBCR = name.getBoundingClientRect();
        const timeBCR = time.getBoundingClientRect();

        this.imageLeft = this.lastScrollLeft + 40;
        
        if (imageBCR.right >= shiftBCR.left && imageBCR.right <= shiftBCR.right) {
          this.shiftDetailsLeft = imageBCR.right - shiftBCR.x + 40;
          this.showName = nameBCR.right + 20 < shiftBCR.right;
          this.showTime = timeBCR.right + 20 < shiftBCR.right;
        } else if (imageBCR.right <= shiftBCR.left) {
          this.shiftDetailsLeft = 40;
        }

        this.showTime = shiftBCR.width > 200 ? this.showTime : false;
      });
    }, 100)
  }
}
</script>