var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-full z-50" }, [
    _c("div", { staticClass: "content h-full px-16 pt-5 border-t" }, [
      _c(
        "div",
        {
          staticClass:
            "-mt-96 absolute m-auto w-max right-0 left-0 flex flex-col items-center mb-16",
        },
        [
          _c("img", {
            staticClass:
              "rounded-full w-60 h-60 border-8 border-white shadow-m object-cover",
            class: [
              { "filter grayscale": _vm.shiftOver || _vm.shift.cancelled },
            ],
            attrs: { src: _vm.shift.employee.displayImage, alt: "Employee" },
          }),
          _vm._v(" "),
          _vm.shift.nightShift
            ? _c(
                "div",
                {
                  staticClass:
                    "bg-charcoal z-10 absolute w-16 h-16 rounded-full flex items-center justify-center mt-44 ml-48 border-4 border-white shadow-bottom",
                },
                [
                  _c("fa-icon", {
                    staticClass: "text-white text-xl",
                    attrs: { icon: ["fas", "moon-stars"] },
                  }),
                ],
                1
              )
            : _vm.shift.cancelled
            ? _c(
                "div",
                {
                  staticClass:
                    "bg-warning z-10 absolute w-16 h-16 rounded-full flex items-center justify-center mt-44 ml-48 border-4 border-white shadow-bottom",
                },
                [
                  _c("fa-icon", {
                    staticClass: "text-white text-xl",
                    attrs: { icon: ["fas", "times"] },
                  }),
                ],
                1
              )
            : _vm.unavailableNow
            ? _c("div", {
                staticClass:
                  "bg-warning z-10 absolute w-16 h-16 rounded-full flex items-center justify-center mt-44 ml-48 border-4 border-white shadow-bottom",
              })
            : _vm.shiftStarted
            ? _c("div", {
                staticClass:
                  "z-10 absolute w-16 h-16 rounded-full flex items-center justify-center mt-44 ml-48 border-4 border-white shadow-bottom",
                class: `bg-${_vm.moduleColor}`,
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "largeListItemHeader py-4" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.shift.employee.displayName) + "\n      "
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "px-12" },
        [
          _vm.shift.nightShift
            ? _c(
                "div",
                { staticClass: "flex items-center mb-12 h-16" },
                [
                  _c("fa-icon", {
                    staticClass: "text-3xl mr-12",
                    attrs: { icon: ["fal", "moon-stars"] },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "font-bold" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("global.nightShift")) +
                        "\n        "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex items-center mb-12 h-16" },
            [
              _c("fa-icon", {
                staticClass: "text-3xl mr-12 w-8",
                attrs: { icon: ["fal", "calendar"] },
              }),
              _vm._v(" "),
              _c("div", [
                _c("p", { staticClass: "font-bold" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.shift.cancelled
                          ? _vm.$t("scheduleShift.shiftCancelled")
                          : _vm.$t("employee.working")
                      ) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.getStartDay()))]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "flex items-center justify-between w-full mb-12 h-16",
            },
            [
              _vm.shift.timeOfDayIcon || _vm.shift.timeOfDayName
                ? _c(
                    "div",
                    { staticClass: "flex items-center" },
                    [
                      _c("fa-icon", {
                        staticClass: "text-3xl mr-12 w-8",
                        attrs: { icon: ["far", `${_vm.shift.timeOfDayIcon}`] },
                      }),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.shift.timeOfDayName))]),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "flex items-center" },
                    [
                      _c("fa-icon", {
                        staticClass: "text-3xl mr-12 w-8",
                        attrs: { icon: ["fal", "clock"] },
                      }),
                      _vm._v(" "),
                      _c("div", [
                        _c("p", { staticClass: "font-bold" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("global.oclock")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.shiftDuration))]),
                      ]),
                    ],
                    1
                  ),
              _vm._v(" "),
              _vm.shiftStarted
                ? _c("status", {
                    attrs: {
                      color: _vm.moduleColor,
                      icon: "clock",
                      shadow: false,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.shift.unavailable
            ? _c(
                "div",
                {
                  staticClass:
                    "flex items-center justify-between w-full mb-12 h-16",
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex items-center" },
                    [
                      _c("fa-icon", {
                        staticClass: "text-3xl mr-12 w-8",
                        attrs: { icon: ["fal", "ban"] },
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "flex flex-col" }, [
                        _c("span", { staticClass: "font-bold" }, [
                          _vm._v(_vm._s(_vm.unavailabilityDescription)),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.unavailableDuration))]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.unavailableNow
                    ? _c("status", {
                        attrs: { color: "error", icon: "clock", shadow: false },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("edit-work-schedule-controls", {
            attrs: { shift: _vm.shift },
            on: {
              "close-overlay": function ($event) {
                return _vm.$emit("close-overlay")
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }