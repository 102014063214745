var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "h-full",
      class: { [`bg-${_vm.color}`]: _vm.useBackgroundColor },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "h-full flex flex-col items-center justify-between py-16",
          style: _vm.gradient,
        },
        [
          _c(
            "div",
            {
              staticClass:
                "w-36 h-36 flex flex-col items-center justify-between",
            },
            [
              _c("img", {
                staticClass: "absolute",
                attrs: {
                  src: `../../../assets/img/emptyWidgets/${_vm.imageName}.svg`,
                  alt: "No data",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "w-48 h-36 z-20 flex flex-col justify-between py-3",
                },
                _vm._l(3, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass:
                        "z-10 bg-white rounded rounded-4 w-36 h-8 py-1 flex items-center",
                      class: {
                        "self-start": index !== 1,
                        "self-end": index === 1,
                        "px-2": index === 2,
                        "px-5": index === 0 || index === 1,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-4 h-4 rounded rounded-full p-1 mr-2 flex items-center justify-center",
                          class: `bg-${_vm.color}`,
                        },
                        [
                          _c("fa-icon", {
                            staticClass: "z-20 text-white w-2",
                            attrs: { icon: ["fas", _vm.icon || "clock"] },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._m(0, true),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center text-white text-3xl font-semibold" },
            [
              _c("fa-icon", {
                staticClass: "text-white text-4xl pb-4",
                attrs: { icon: ["fas", _vm.icon || "clock"] },
              }),
              _vm._v(" "),
              _c("p", [
                _vm._v(_vm._s(_vm.$t(`emptyStates.${_vm.widgetType}`))),
              ]),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex flex-col" }, [
      _c("span", {
        staticClass: "rounded rounded-full h-1.5 w-8 bg-gray-300 mb-1",
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "rounded rounded-full h-1.5 w-12 bg-gray-200",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }