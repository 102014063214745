<template>
  <div class="departmentSwitcherContainer">
    <div
      v-if="showOptions"
      class="w-full h-full absolute backdrop bottom-0 left-0 z-10"
    />

    <div v-clickoutside="{ excludeString: '.options, .options>*, .selection, .selection>*', handler: onClickoutside }">
      <div
        v-if="showOptions"
        class="z-20 absolute bottom-32 bg-white rounded rounded-lg border border-neutral300 pl-6 pr-4 pt-6 pb-2"
      >
        <p class="h600 text-center mb-4">
          {{ $t('global.selectDepartment') }}
        </p>

        <div class="options overflow-y-scroll pr-4">
          <radio-list-item
            :option="selectedDepartment"
            :is-selected="true"
          />
          <radio-list-item
            v-for="(department, i) in availableDepartments"
            :key="i"
            :option="department"
            @option-selected="switchDepartment(department)"
          />
        </div>
      </div>

      <div
        class="selection flex items-center absolute z-50"
        @click="showOptions = !showOptions"
      >
        <div
          class="border border-charcoal border-opacity-10 bg-white rounded-xl h-full flex items-center justify-between p-6"
        >
          <div class="flex items-center">
            <div
              class="mr-4 rounded rounded-full h-10 w-10 flex items-center justify-center"
              :style="{ backgroundColor: selectedDepartment.color }"
            >
              <fa-icon
                class="text-xl text-white"
                :icon="['fas', 'home']"
              />
            </div>
            <p>{{ selectedDepartment.name }}</p>
          </div>

          <div class="flex items-center">
            <fa-icon
              class="text-lg"
              :icon="['far', showOptions ? 'chevron-up' : 'chevron-down']"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import RadioListItem from '@/components/shared/RadioListItem.vue';

export default {
    components: {
        RadioListItem
    },
    data() {
        return {
            showOptions: false
        };
    },
    computed: {
        ...mapGetters({
            departments: 'institution/departments',
            selectedDepartment: 'department/current'
        }),
        availableDepartments() {
          return this.departments.filter(department => (department.id !== this.selectedDepartment.id) && department.loginKey);
        }
    },
    methods: {
        async switchDepartment(department) {
          await this.$store.dispatch('department/switchDepartment', department.loginKey);
        },
        onClickoutside() {
            if (this.showOptions) {
                this.showOptions = false;
            }
        },
    }
}
</script>

<style>
.departmentSwitcherContainer {
    width: 300px;

    .backdrop {
        background: linear-gradient(0deg, rgba(2, 0, 36, 0.5032606792717087) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
    }

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 16px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 12px;
        background-color: #D1D1D1;
    }

    .selection {
        width: 300px;

        >div {
            width: 258px;
        }
    }

    .options {
        width: 670px;
        max-height: 516px;

        .option {
            height: 88px;
        }
    }
}
</style>