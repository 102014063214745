<template>
  <div
    class="relative content flex items-center w-full"
    :class="margin"
  >
    <fa-icon
      :class="{ 'mt-2': formatDate(start) !== formatDate(end) }"
      class="mr-6 self-start"
      :icon="['fal', 'clock']"
    />
    <div
      v-if="formatDate(start) !== formatDate(end)"
      class="flex flex-col"
    >
      <span>
        {{ formatDate(start) }} - <span class="font-bold">{{ formatTime(start) }}</span>
      </span>
      <span>
        {{ formatDate(end) }} - <span class="font-bold">{{ formatTime(end) }}</span>
      </span>
    </div>
    <div
      v-else
      class="flex flex-col"
    >
      <span>{{ formatDate(start) }}</span>
      <span class="font-bold">{{ formatTime(start) }} - {{ formatTime(end) }}</span>
    </div>
  </div>
</template>

<script>
import { format } from '@/utils/date-fns';
import { capitalize } from 'lodash';

export default {
  props: {
    start: {
      type: String,
      'default': ''
    },
    end: {
      type: String,
      'default': ''
    },
    margin: {
      type: String,
      'default': 'mb-14'
    }
  },

  methods: {
    formatDate(date) {
      const formatedDate = capitalize(format(new Date(date), 'EEEE dd MMMM'));
      return formatedDate;
    },
    formatTime(time) {
      const formatedTime = format(new Date(time), 'HH:mm');
      return formatedTime;
    }
  }
}
</script>