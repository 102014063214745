import gql from "graphql-tag";

export default gql`
query news ($filter: Filter!) {
  news (filter: $filter) {
      body
      coverImage {
          blurhash
          id
          source
      }
      documents {
          description
          id
          title
          url
      }
      endDate
      id
      pictograms {
          blurhash
          id
          source
          type
      }
      pictures {
          blurhash
          description
          id
          inMyArchive
          source
      }
      published
      startDate
      title
      videos {
          blurhash
          description
          id
          provider
          thumbnail
          url
      }
  }
}`;