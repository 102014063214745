<template>
  <transition
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
    :duration="800"
  >
    <div
      class="media-preview w-full bg-charcoal-opacity z-30 fixed flex flex-col justify-center"
    >
      <div
        class="flex flex-col items-center w-full"
        :class="{ 'h-2/4 pb-24': mediaType !== 'documents', 'h-full pb-6': mediaType === 'documents' }"
      >
        <carousel
          v-if="mediaType !== 'documents'"
          :class="items.length === 1 ? 'pointer-events-none' : ''"
          name="mediaPreview"
          ref-name="mediaPreview"
          :pagination="false"
          :start-index="currentSlide"
          :slide-duration="slideshowDuration"
          :autoplay="autoplay"
          @swiped="currentSlide = $event"
          @slide-change="goToSlide"
        >
          <slide
            v-for="(item, index) in items"
            :key="index"
            :style="[mediaType === 'videos' ? {'width': '950px'} : '']"
            @slideclick="handleSlideClick(index)"
          >
            <div
              v-if="mediaType === 'pictures'"
              class="px-10 bg-cover bg-center"
              :style="{ backgroundImage: `url(${item.source})`, height: '800px' }">
            </div>

            <div v-if="mediaType === 'videos'">
              <div
                class="text-3xl text-white h-full flex justify-center w-full"
                @click="videoClicked"
              >
                <span class="h-full absolute w-full z-10" />

                <Youtube
                  v-if="item.provider === 'YOUTUBE' && currentSlide === index"
                  ref="youtube"
                  :video-id="cleanYoutubeUrl(item.url)"
                  :player-vars="playerVars"
                  :width="950"
                  :height="550"
                />
                
                <LazyVimeo
                  v-if="item.provider === 'VIMEO' && currentSlide === index"
                  ref="lazyVideo"
                  :src="item.url"
                  :autoplay="true"
                  max-width="950px"
                />

                <iframe
                  v-if="item.provider === 'DREAMBROKER' && currentSlide === index"
                  class="w-full"
                  height="550"
                  :src="`${item.url}?autoplay=true`"
                  allow="autoplay"
                />
              </div>
            </div>

            <div
              v-if="mediaType === 'media'"
              class="h-full"
            >
              <img
                v-if="item.__typename === 'GalleryPicture'"
                :src="item.source"
                class="px-10 object-cover w-full h-auto"
              >
              <div
                v-else
                class="w-full h-full flex justify-center"
              >
                <iframe
                  v-if="mediaType === 'media' && currentSlide === index"
                  class="w-full h-full"
                  :src="`${item.url}?autoplay=true`"
                  allow="autoplay"
                />
              </div>
            </div>
          </slide>
        </carousel>

        <vue-pdf-embed
          v-if="mediaType === 'documents'"
          class="w-full overflow-scroll h-5/6 "
          :source="items[currentSlide].url"
          :disable-text-layer="true"
          @rendered="rendered"
        />
        
        <video-controls
          v-if="mediaType === 'videos'"
          class="mb-28 mt-8"
          @playVideo="handleVideoEvent('playVideo')"
          @pauseVideo="handleVideoEvent('pauseVideo')"
          @resetView="handleVideoEvent('stopVideo')"
        />
        
        <div class="flex mt-8">
          <button-el
            v-if="allowArchiveAdd && !isInMyArchive"
            :text="`media.addToMy.${mediaType}`"
            icon="cloud-download"
            background-color="success"
            class="shadow-xsm mb-10"
            @click.native="archiveAdd"
          />

          <button-el
            v-if="isInMyArchive"
            :text="`media.removeFromMy.${mediaType}`"
            icon="trash-alt"
            background-color="error"
            class="shadow-xsm mb-10"
            @click.native="archiveRemove"
          />
        </div>

        <carousel-controls
          class="mt-12"
          :total-slides="items.length"
          :current-slide="currentSlide + 1"
          :media-type="mediaType"
          :autoplay="autoplay"
          @autoplay-toggled="toggleAutoplay"
          @previous="currentSlide = currentSlide - 1"
          @next="currentSlide = currentSlide + 1"
          @close="$emit('close-preview')"
        />
      </div>
    </div>
  </transition>
</template>

<script>

import { Slide } from 'vue-carousel';
import Carousel from '@/components/shared/Carousel';
import CarouselControls from '@/components/shared/CarouselControls';
import VideoControls from '@/components/shared/VideoControls';
import Button from '@/components/shared/Button';
import { LazyVimeo } from 'vue-lazytube';
import { mapGetters } from 'vuex';
import { get, isEmpty, clone } from 'lodash';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import { getIdFromUrl } from 'vue-youtube'
import { Youtube } from 'vue-youtube';

export default {
  components: {
    Carousel,
    Slide,
    CarouselControls,
    VideoControls,
    ButtonEl: Button,
    LazyVimeo,
    VuePdfEmbed,
    Youtube
  },
  props: {
    items: {
      type: Array,
      'default': (() => [])
    },
    selectedItemIndex: {
      type: Number,
      'default': 0
    },
    mediaType: {
      type: String,
      'default': ''
    },
    isInMyArchive: {
      type: Boolean,
      'default': false
    }
  },
  data() {
    return {
      slideDirection: '',
      currentSlide: null,
      autoplay: false,
      opt: '',
      playerVars: {
        cc_load_policy: 1,
        autoplay: 1
      }
    }
  },
  computed: {
    ...mapGetters({
      activeProfile: 'profiles/activeProfile',
      pinValidation: 'profiles/pinValidation',
      myArchive: 'profiles/myArchive',
      institutionSettings: 'institution/settings',
      departmentSettings: 'department/settings',
      selectedProfile: 'profiles/activeProfile'
    }),
    isInArchive() {
      const items = get(this, `myArchive[${this.mediaType}]`, []);
      return items.findIndex(resource => resource.id === this.items[this.currentSlide].id) > -1;
    },
    allowArchiveAdd() {
      return this.mediaType !== 'documents' && !this.useExternalMedia;
    },
    slideshowDuration() {
      return this.departmentSettings.slideshow.duration * 1000;
    },
    useExternalMedia() {
      return get(this.institutionSettings, 'useExternalMedia', false);
    }
  },
  watch: {
    pinValidation(pinData) {
      if (!pinData.validating) return;

      const archiveData = {
        resourceId: this.items[this.currentSlide].id.toString(),
        profile: {
          profileId: this.activeProfile.id,
          profileType: this.activeProfile.type,
          pin: pinData.pin.toString()
        },
        opt: this.opt
      };

      this.$store.dispatch('galleries/archiveAddScreen', archiveData);

      if (this.opt === 'out') {
        setTimeout(() => {
          this.$emit('close-preview');
        }, 500);
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.currentSlide = clone(this.selectedItemIndex);
    }, 500);
  },
  methods: {
    archiveAdd() {
      this.opt = 'in';

      const pinOverlayData = {
        title: this.$t(`media.addToMy.${this.mediaType}`),
        titleSuccess: this.$t(`media.addedToMy.${this.mediaType}`),
        opt: this.opt
      };

      this.showOverlay(pinOverlayData);
    },
    async archiveRemove() {
      this.opt = 'out';
      const pinOverlayData = {
        title: this.$t(`media.removeFromMy.${this.mediaType}`),
        titleSuccess: this.$t(`media.removedFromMy.${this.mediaType}`),
        opt: this.opt
      };
      await this.$store.dispatch('profiles/setActiveProfile', this.selectedProfile);
      await this.$store.dispatch('general/setActiveOverlay', { name: 'enter-pin', data: pinOverlayData });
    },
    showOverlay(pinOverlayData) {
      this.$store.dispatch('general/setActiveOverlay', { name: 'profile-select', data: pinOverlayData });
    },
    goToSlide(index) {
      this.currentSlide = index;
    },
    handleVideoEvent(event) {
      this.$refs.youtube[0].player[event]();
    },
    handleSlideClick(index) { 
      this.currentSlide = index; 
    },
    toggleAutoplay() {
      this.autoplay = !this.autoplay;
      if (this.autoplay) this.$matomo.trackEvent('Autoplay', 'Toggled', 'AutoplayStarted');
    },
    videoClicked() {
      this.$matomo.trackEvent('Video', 'Clicked', 'VideoClicked');
    },
    rendered() {
      const canvas = document.getElementsByClassName('vue-pdf-embed')[0];
      const hyperlinks = canvas.getElementsByTagName('a');

      for (let i = 0; i < hyperlinks.length; i++) {
        hyperlinks[i].onclick = function(e) {
          if (!e.target.classList.contains('internalLink')) {
            e.preventDefault();
          }
        }

        hyperlinks[i].ontouchstart = function(e) {
          if (!e.target.classList.contains('internalLink')) {
            e.preventDefault();
          }
        }

        hyperlinks[i].ondragstart = function(e) {
          if (!e.target.classList.contains('internalLink')) {
            e.preventDefault();
          }
        }
      }
    },
    cleanYoutubeUrl(url) {
      return getIdFromUrl(url);
    }
  }
}

</script>

<style lang="scss" scoped>
  .media-preview {
    transition: none;
    height: calc(100% - 300px);
    top: 0;
  }
  .media-height {
    max-height: 75%;
  }
</style>