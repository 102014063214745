var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _c(
        "carousel",
        {
          ref: "carouselWeather",
          staticClass: "h-full",
          attrs: {
            "ref-name": "carouselWeather",
            "per-page": 1,
            autoplay: false,
            "pagination-enabled": false,
          },
        },
        _vm._l(_vm.prognosis, function (day, index) {
          return _c(
            "slide",
            {
              key: index,
              staticClass:
                "h-full text-white background-center no-repeat bg-cover bg-center py-12 px-8 flex flex-col justify-between",
              style: { "background-image": `url('${day.coverImage}')` },
            },
            [
              _c("div", { staticClass: "flex justify-between" }, [
                _c("p", { staticClass: "text-7xl textShadow" }, [
                  _vm._v("\n          " + _vm._s(day.temp) + "°\n        "),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "text-2xl mt-4" },
                  [
                    _c("fa-icon", {
                      staticClass: "text-white text-xl mr-2 textShadow",
                      attrs: { icon: ["fas", "location-arrow"] },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "textShadow" }, [
                      _vm._v(_vm._s(day.wind) + " m/s"),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-12 flex flex-col items-center" },
                [
                  _c("fa-icon", {
                    staticClass: "text-4xl mb-4 textShadow",
                    attrs: { icon: ["fas", `${day.icon}`] },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-4xl textShadow" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("weather.moduleName")) +
                        "\n        "
                    ),
                  ]),
                ],
                1
              ),
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "absolute bottom-4 w-full px-4 flex justify-center" },
        [
          _c(
            "div",
            {
              staticClass:
                "flex items-center justify-between rounded rounded-full bg-black-025 p-1 max-w-2xl",
            },
            _vm._l(_vm.prognosis, function (day, index) {
              return _c(
                "p",
                {
                  key: index,
                  staticClass: "day px-4 text-white",
                  class: { active: _vm.activeDay === index + 1 },
                  on: {
                    click: function ($event) {
                      return _vm.selectDay(index)
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(day.weekday) + "\n      ")]
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }