<template>
  <carousel
    name="meetings"
    ref-name="meetings"
    :slide-duration="widgetSlideDuration"
    @slide-clicked="slideClicked"
  >
    <slide
      v-for="( meeting, index ) in meetings"
      :key="index"
      :class="[slideStyles, widget.frHeight > 1 ? 'justify-center' : 'justify-end']"
      :style="getBackgroundStyles(index)"
    >
      <status 
        v-if="isItemNow(meeting)"
        class="mb-6"
      />
      <fa-icon
        class="text-2xl mb-4 text-white"
        :icon="['fas', `${widget.icon}`]"
      />
      <p class="text-2xl mb-6">
        {{ meeting.title }}
      </p>
      <date-time
        v-if="widget.frHeight > 1"
        class="mb-6"
        :start-date="meeting.startDate"
      />

      <div
        v-if="widget.frHeight > 1"
        class="flex items-center justify-center"
      >
        <fa-icon
          class="text-xl text-white mr-2"
          :icon="['fas', 'user' ]"
        />
        <p>{{ meeting.participants.length }} {{ $t('global.participants') }}</p>
      </div>
    </slide>
  </carousel>
</template>
<script>

import Status from '@/components/shared/infoLabels/Status';
import DateTime from '@/components/shared/infoLabels/DateTime';
import coverImageGradient from '@/utils/coverImageGradient.js';
import constants from '@/utils/constants.js';
import { mapGetters } from 'vuex';
import Carousel from '@/components/shared/Carousel';
import { Slide } from 'vue-carousel';
import { get } from 'lodash';
import { isItemNow } from '@/utils/helpers.js';

export default {
  components: {
    Status,
    DateTime,
    Carousel,
    Slide
  },
  props: {
    widget: {
      type: Object,
      'default': (() => {})
    }
  },
  computed: {
    ...mapGetters({
      allMeetings: 'meetings/all',
      departmentSettings: 'department/settings'
    }),
    widgetSlideDuration() {
      return this.departmentSettings.screenConfig.carouselDuration * 1000;
    },
    meetings() {
      return this.allMeetings.slice(0, this.widget.frWidth * 5);
    },
    slideStyles() {
      return constants.slider.slideStyles;
    }
  },
  methods: {
    isItemNow(item) {
      return isItemNow(item.startDate, item.endDate);
    },
    getBackgroundStyles(index) {
      const height = this.widget.frHeight > 1 ? 50 : 70;
      const coverImage = get(this.meetings[index], 'coverImage.source', require('@assets/img/placeholder.png'));
      return coverImageGradient.get(this.widget.colorHex, height, coverImage);
    },
    slideClicked(slideIndex) {
      const itemId = this.meetings[slideIndex].id;
      this.$store.dispatch('meetings/getSelectedMeeting', itemId);
      this.$emit('slide-clicked', `/meetings/${itemId}`);
    }
  }
}
</script>