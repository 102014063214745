var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "meeting" },
    [
      _vm.meeting.id
        ? _c(
            "cover-view",
            {
              attrs: {
                "image-url": _vm.meeting.coverImage.source,
                gradient: true,
                "module-color": _vm.moduleColor,
                "margin-top": 60,
              },
            },
            [
              _c(
                "div",
                { staticClass: "meetingContent pt-20" },
                [
                  _c("tts", {
                    attrs: {
                      "entity-id": _vm.meeting.id,
                      "entity-type": "MEETING",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "largeListItemHeader pt-24 mb-16" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.meeting.title) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "relative content pb-10 flex items-center w-full",
                          },
                          [
                            _c("fa-icon", {
                              staticClass: "mr-6",
                              attrs: { icon: ["fal", "tag"] },
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.category) +
                                "\n          "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "relative content pb-10 flex items-center w-full",
                          },
                          [
                            _c("fa-icon", {
                              staticClass: "mr-6",
                              attrs: { icon: ["fal", "clock"] },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex flex-col" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.formatDate(_vm.meeting))),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.formatTime(_vm.meeting))),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.meeting.repeat && _vm.repeatText.length
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "relative content pb-10 flex items-center w-full",
                              },
                              [
                                _c("fa-icon", {
                                  staticClass: "mr-6",
                                  attrs: { icon: ["fal", "repeat"] },
                                }),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.repeatText) +
                                    "\n          "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.meeting.location
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "content pb-10 flex items-center w-full",
                              },
                              [
                                _c("fa-icon", {
                                  staticClass: "mr-6",
                                  attrs: { icon: ["fal", "map-marker-alt"] },
                                }),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.meeting.location) +
                                    "\n          "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.meeting.agendaPoints.length
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "content pb-10 flex items-center w-full",
                                on: { click: _vm.showAgenda },
                              },
                              [
                                _c("fa-icon", {
                                  staticClass: "mr-6",
                                  attrs: { icon: ["fal", "list"] },
                                }),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  { staticClass: "font-semibold flex-1" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("meeting.agendaPoints", {
                                            amount:
                                              _vm.meeting.agendaPoints.length,
                                          })
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("fa-icon", {
                                  staticClass: "text-5xl",
                                  attrs: { icon: ["fal", "angle-right"] },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "content pb-10 flex items-center w-full",
                            on: { click: _vm.showSuggestions },
                          },
                          [
                            _c("fa-icon", {
                              staticClass: "mr-8",
                              attrs: { icon: ["fal", "lightbulb"] },
                            }),
                            _vm._v(" "),
                            _c("p", { staticClass: "font-semibold flex-1" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$tc(
                                      "meeting.suggestionItems",
                                      _vm.meeting.suggestions.length
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("fa-icon", {
                              staticClass: "text-5xl",
                              attrs: { icon: ["fal", "angle-right"] },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.meeting.participants.length
                          ? _c("people", {
                              attrs: {
                                text: "global.participantsPlural",
                                "module-color": _vm.moduleColor,
                                profiles: _vm.meeting.participants,
                              },
                              on: {
                                "show-people": function ($event) {
                                  return _vm.showProfiles("participants")
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.meeting.departments.length
                          ? _c("departments", {
                              attrs: { departments: _vm.meeting.departments },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("institution"),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "meetingActions flex w-full sticky justify-end",
                },
                [
                  _vm.signupPossible
                    ? _c("button-el", {
                        staticClass: "mr-5 shadow-xsm",
                        attrs: {
                          text: "global.signUp",
                          icon: "check",
                          "background-color": "success",
                          "text-color": "white",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.optInOrOut("in")
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.optoutPossible && _vm.signupPossible
                    ? _c("button-el", {
                        staticClass: "shadow-xsm",
                        attrs: {
                          text: "global.optOut",
                          icon: "minus-circle",
                          "background-color": "error",
                          "text-color": "white",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.optInOrOut("out")
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }