var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "galleryEntry" },
    [
      _vm.hasSelectedMediaItem
        ? _c("media-preview", {
            attrs: {
              "selected-item-index": _vm.selectedMediaIndex,
              "media-type": _vm.mediaType,
              items: _vm.mediaItems,
            },
            on: {
              "close-preview": function ($event) {
                _vm.selectedMediaIndex = -1
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.galleries.length
        ? _c(
            "cover-view",
            {
              attrs: {
                "image-url": _vm.galleryEntry.coverImage.source,
                gradient: false,
                "margin-top": 60,
              },
            },
            [
              _c(
                "div",
                [
                  _c("media-icons", {
                    attrs: {
                      icons: _vm.mediaIcons,
                      "media-type": _vm.mediaType,
                    },
                    on: {
                      "media-selected": function ($event) {
                        _vm.mediaType = $event
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "largeListItemHeader p-20 pb-0" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.galleryEntry.title) + "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("media-list", {
                    staticClass: "px-20 pt-20",
                    attrs: {
                      items: _vm.mediaItems,
                      "media-type": _vm.mediaType,
                    },
                    on: {
                      "item-selected": function ($event) {
                        _vm.selectedMediaIndex = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }