var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "profile-select h-full overflow-y-scroll" }, [
    _c(
      "div",
      { staticClass: "content h-full flex flex-col" },
      [
        _c("div", { staticClass: "mb-12 mx-20" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("profileSelect.description")) + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("profiles-list", {
          staticClass: "h-full mb-48",
          attrs: {
            "can-close": true,
            "employee-only": _vm.employeeOnly,
            "selected-profile-type": _vm.selectedProfileType,
            "shared-departments": _vm.activeOverlay.data.sharedDepartments,
            "select-bottom": "bottom-96",
          },
          on: { "profile-selected": _vm.profileSelected, close: _vm.close },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }