<template>
  <div class="bg-white startPage">
    <div
      v-if="widgets.length"
      class="startPageGrid grid px-1 gap-2 h-full"
    >
      <div
        v-for="(widget, index) in widgets"
        :key="index"
        :class="[`grid-${settings.gridSize}`, { 'hasSelector': hasDepartmentSelector(widget.name)}, `frWidth${widget.frWidth}`]"
        class="h-full relative"
        :style="getGridStyles(widget)"
      >
        <component
          :is="widgetData[widget.type].component"
          v-if="widgetData[widget.type].hasContent"
          :widget="widget"
          @slide-clicked="$router.push($event)"
        />

        <empty-widget
          v-else
          :widget-type="widget.type"
          :color="widget.colorId"
          :icon="widget.icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';
import Activities from '@/components/widgets/Activities';
import Booking from '@/components/widgets/Bookings';
import Gallery from '@/components/widgets/Gallery';
import Slideshow from '@/components/widgets/Slideshow';
import JourneyPlanner from '@/components/widgets/JourneyPlanner';
import MealPlan from '@/components/widgets/MealPlan';
import Meetings from '@/components/widgets/Meetings';
import News from '@/components/widgets/News';
import Wayfinder from '@/components/widgets/Wayfinder';
import Weather from '@/components/widgets/Weather';
import WorkSchedule from '@/components/widgets/WorkSchedule';
import EmptyWidget from '@/components/widgets/EmptyWidget';
import { getModuleData } from '@/utils/modules';

export default {
  components: {
    Activities,
    Booking,
    Gallery,
    Slideshow,
    JourneyPlanner,
    MealPlan,
    Meetings,
    News,
    Wayfinder,
    Weather,
    WorkSchedule,
    EmptyWidget
  },
  data() {
    return {
      standardWidgetIds: [ 3, 4, 5, 6, 7, 10 ]
    }
  },
  computed: {
    ...mapGetters({
      settings: 'department/settings'
    }),
    widgets() {
      return get(this, 'settings.screenConfig.modules', []);
    },
    widgetData() {
      return getModuleData();
    }
  },
  methods: {
    getGridStyles(widget) {
      return {
        'grid-column': widget.col.start,
        'grid-column-end': widget.col.end,
        'grid-row-start': widget.row.start,
        'grid-row-end': widget.row.end
      }
    },
    isStandardWidget(widget) {
      return this.standardWidgetIds.indexOf(widget.id) > -1;
    },
    hasDepartmentSelector(widget) {
      return [ 'meals', 'workShedule' ].includes(widget);
    },
  }
}
</script>

<style lang="scss">

  .startPage {
    .startPageGrid {
      height: calc(100% - 68px);
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-rows: minmax(0, 1fr);
      grid-auto-flow: column;

      .grid-4 {
        grid-template: 1fr 1fr / 1fr 1fr;
      }

      .grid-9 {
        grid-template: 1fr 1fr 1fr / 1fr 1fr 1fr;
      }

      .hasSelector {

        .departmentSelector {
          filter: drop-shadow(0 5px 4px rgba(0, 0, 0, 0.25));
          right: 24px;
        }

        &.frWidth1 {
          .VueCarousel-pagination {
            width: 60%;
          }
        }

        &.frWidth2 {
          .departmentSelector {
            width: 42%;
          }

          .VueCarousel-pagination {
            width: 45%;
          }

          .VueCarousel-dot-container {
            justify-content: flex-end !important;
          }
        }
      }

      .VueCarousel-wrapper {
        height: 100%;

        .VueCarousel-inner {
          height: 100% !important;

          .VueCarousel-slide {
            height: 100%;
          }
        }
      }

      .VueCarousel-pagination {
        position: absolute;
        bottom: 30px;

        .VueCarousel-dot-container {
          margin-top: 0 !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
        }

        .VueCarousel-dot {
          margin-top: 0 !important;
          margin-left: 14px !important;
        }

        .VueCarousel-dot--active {
          width: 18px !important;
          height: 18px !important;
          margin-top: 0 !important;
          filter: drop-shadow(0 5px 4px rgba(0, 0, 0, 0.25));
        }

        .VueCarousel-dot:focus {
          outline: none;
        }
      }
    }
  }
  
</style>