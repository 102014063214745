var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-28 flex" },
    _vm._l(_vm.times, function (time, index) {
      return _c("div", { key: index }, [
        _c("div", { staticClass: "column text-3xl h-full flex items-center" }, [
          _c("p", { staticClass: "w-12 text-center font-normal" }, [
            _vm._v("\n        " + _vm._s(time) + "\n      "),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }