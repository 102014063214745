var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "px-12 relative" }, [
    _c(
      "div",
      { staticClass: "flex-2 h-full px-10 pb-12" },
      [
        _c("keyboard-simple", {
          attrs: { placeholder: "meeting.suggestion" },
          on: {
            onChange: function ($event) {
              _vm.suggestion = $event
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex w-full sticky justify-center bottom-80" },
      [
        _c("button-el", {
          staticClass: "bold",
          attrs: {
            text: "meeting.writeSuggestion",
            icon: "pen-alt",
            "background-color": "success",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.addSuggestion.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }