<template>
  <div
    class="w-96 text-3xl rounded-lg shadow-lg p-3 font-bold bg-white absolute"
    @click.stop
  >
    <div class="overflow-y-scroll max-h-96">
      <div
        v-for="(option, index) in options"
        v-show="showOptionSelect"
        :key="index"
        v-clickoutside="{ excludeString: '.selectDepartment, .selectDepartment>*', handler: onClickoutside }"
        @click="optionSelected(option)"
      >
        <div
          v-if="option.name !== selectedOption.name"
          class="relative w-full inline-flex items-center p-2 mb-2 rounded rounded-sm border border-gray-300"
        >
          <span
            v-if="icon"
            class="icon rounded-lg mr-3 flex justify-center items-center w-16 h-16"
            :style="[{ backgroundColor: option.color }]"
          >
            <fa-icon
              class="text-white text-2xl"
              :icon="['far', `${icon}`]"
            />
          </span>

          <span class="truncate flex items-center text-2xl">{{ $t(option.name) }}</span>
        </div>
      </div>
    </div>

    <div
      class="rounded-lg p-2 flex items-center justify-between h-20"
      :style="[{ backgroundColor: getBackgroundColorOpaqueHex(selectedOption) }]"
      @click="showOptionSelect = !showOptionSelect"
    >
      <div class="flex">
        <span
          v-if="icon"
          class="icon rounded-lg mr-3 flex justify-center items-center w-16 h-16"
          :style="[{ backgroundColor: selectedOption.color }]"
        >
          <fa-icon
            class="text-white text-2xl"
            :icon="['far', `${icon}`]"
          />
        </span>

        <span class="truncate flex items-center text-2xl">{{ $t(selectedOption.name) }}</span>
      </div>

      <span
        class="icon flex justify-center items-center"
        :style="[{ color: selectedOption.color }]"
      >
        <fa-icon
          class="text-2xl"
          :icon="['far', 'check']"
        />
      </span>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { hexToRgbA } from '@/utils/helpers.js';

export default {
	props: {
		icon: {
			type: String,
			'default': ''
		},
		options: {
			type: Array,
			'default': (() => [])
		},
    selectedOption: {
      type: Object,
      'default': (() => {})
    }
	},
	data() {
		return {
			showOptionSelect: false
		}
	},
	computed: {
		...mapGetters({
			isSaving: 'general/isSaving'
		}),
	},
	methods: {
		onClickoutside() {
      if (!this.showOptionSelect) return;
      this.showOptionSelect = false;
    },
		filterByDepartment(index) {
      this.selectedDepartment = this.departments[index];
      this.showOptionSelect = false;
			this.$emit('department-selected', this.selectedDepartment);
    },
		optionSelected(option) {
			this.showOptionSelect = false;
			this.$emit('option-selected', option);
		},
		getBackgroundColorOpaqueHex(option) {
      return hexToRgbA(option.color, 0.05);
		}
	}
};
</script>