import { cloneDeep } from 'lodash';
import { isFuture } from 'date-fns';
import { formatTime } from '@/utils/helpers.js';
import store from '@/store';

export function getBookings(allBookingResources) {
  return cloneDeep(allBookingResources)
      .filter(resource => resource.bookings.length)
      .map(resource => resource.bookings).
      flat()
      .filter(booking => isFuture(new Date(booking.end)))
      .map(booking => {
        return {
          ...booking,
          coverImage: booking.resource.coverImage,
          title: booking.description,
          startDate: booking.start,
          endDate: booking.end,
          meta: {
            icon: 'clock',
            text: formatTime(new Date(booking.start))
          }
        }
      }).sort((a, b) => new Date(a.start) - new Date(b.start));
}

export function getBookingById(id) {
  const allBookingResources = cloneDeep(store.getters['bookingResources/all']);
  
  return allBookingResources
      .map(resource => resource.bookings)
      .flat()
      .filter(booking => booking.id === id)
      .map(booking => {
        return {
          ...booking,
          coverImage: booking.resource.coverImage
        }
      })[0]
}