var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "flex flex-col relative",
      class: { isLoading: _vm.isLoading },
      attrs: { id: "app" },
    },
    [
      _vm.onStartPage
        ? _c("header-el", { staticClass: "flex-none" })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "div",
            {
              staticClass:
                "relative overflow-y-scroll overflow-x-hidden route-wrapper",
              class: _vm.routeClasses,
              style: { height: "1620px" },
            },
            [
              _c("router-view", {
                key: _vm.$route.path,
                staticClass: "h-full bg-white",
                class: { contentArea: !_vm.meta.subView },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$route.path !== "/"
        ? _c("content-views", {
            style: { opacity: !!_vm.activeOverlay.name ? 0 : 1 },
            attrs: { "content-views": _vm.meta.contentViews },
            on: {
              "filter-selected": function ($event) {
                return _vm.updateFilters($event)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("module-list", {
        staticClass: "flex-none bg-white shadow-sm pt-10 z-40",
        on: { "close-overlay": _vm.closeOverlay },
      }),
      _vm._v(" "),
      _c("footer-el", {
        staticClass: "flex-none z-40 bg-white",
        attrs: { "dimmer-opacity": _vm.dimmer },
        on: { dimScreen: _vm.setDimmer, "close-overlay": _vm.closeOverlay },
      }),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            "enter-active-class": "animate__animated animate__slideInUp",
            "leave-active-class": "animate__animated animate__slideOutDown",
            duration: 500,
          },
        },
        [
          _c("keyboard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.keyboardInput !== null,
                expression: "keyboardInput !== null",
              },
            ],
            attrs: { input: _vm.keyboardInput },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("overlay", {
        attrs: { show: _vm.overlayHasValue && _vm.showOverlay },
        on: { "close-overlay": _vm.closeOverlay },
      }),
      _vm._v(" "),
      _vm.dimmer > 0
        ? _c("div", {
            staticClass:
              "screenDimmer absolute inset-0 z-50 bg-gray-900 pointer-events-none",
            style: { opacity: _vm.dimmer / 100 },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }