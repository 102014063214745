var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "fixed w-80 right-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2",
    },
    [
      _c("img", {
        staticClass: "w-60 m-auto",
        attrs: {
          src: `../../../assets/img/emptyViews/${_vm.viewType}.svg`,
          alt: "No data",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "text-center mt-32 text-3xl font-semibold",
          class: `text-${_vm.color}`,
        },
        [
          _c("fa-icon", {
            staticClass: "text-4xl pb-4",
            attrs: { icon: ["fas", _vm.icon(_vm.viewType) || "clock"] },
          }),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.$t(`emptyViews.${_vm.viewType}`)))]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }