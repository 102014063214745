import store from '@/store';
import { get } from 'lodash';
export default class Swipe {
  constructor(element, threshold, refName) {
    this.element = element;
    this.positionXStart = 0;
    this.threshold = threshold;
    this.refName = refName
  }
  
  detect() {
    document.addEventListener('touchstart', event => {
      let refName;

      try {
        refName = event.target.closest(('[ref-name]')).getAttribute('ref-name');
      } catch(error) {
        return;
      }
      
      store.dispatch('general/setSwipeRef', refName);
      store.dispatch('general/setSwipeDirection', '');
      if (!event.target.closest(this.element)) return;
      this.positionXStart = event.touches[0].pageX;
    });

    document.addEventListener('touchmove', event => {
      this.positionXEnd = event.touches[0].pageX;
    });

    document.addEventListener('touchend', event => {
      if (this.positionXEnd + this.threshold < this.positionXStart) {
        store.dispatch('general/setSwipeDirection', 'left');
      }
      
      if (this.positionXEnd - this.threshold > this.positionXStart) {
        store.dispatch('general/setSwipeDirection', 'right');
      }

    });
  }
}