<template>
  <div
    class="radioListItem option border-2 h-20 mb-4 rounded rounded-xl last:mb-0 flex px-6"
    :class="{ 'border-darkOcean600': isSelected, 'border-neutral300': !isSelected, 'bg-darkOcean100': isSelected }"
    @click="$emit('option-selected')"
  >
    <div class="flex items-center justify-between w-full">
      <div class="flex items-center">
        <div
          class="mr-4 rounded rounded-full h-12 w-12 flex items-center justify-center"
          :style="{ backgroundColor: option.color }"
        >
          <fa-icon
            class="text-2xl text-white"
            :icon="['fas', 'home']"
          />
        </div>

        <p class="h600">
          {{ option.name }}
        </p>
      </div>

      <div
        v-if="isSelected"
        class="selected rounded-full w-8 h-8 flex items-center justify-center"
      >
        <div class="rounded-full w-3 h-3 bg-white" />
      </div>

      <div
        v-else
        class="rounded-full w-8 h-8 bg-white border border-darkOcean600"
      />
    </div>
  </div>
</template>

<script>
export default {
    name: 'RadioListItem',
    props: {
        option: {
            type: Object,
            default: (() => { })
        },
        isSelected: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>
    .radioListItem {
        .selected {
            background: linear-gradient(268.75deg, #00434E 2.18%, #186571 95.98%);
        }
    }
</style>