var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showMediaPreview
        ? _c("media-preview", {
            attrs: {
              "selected-item-index": _vm.selectedMediaIndex,
              "media-type": _vm.mediaType,
              items: _vm.documents,
            },
            on: {
              "close-preview": function ($event) {
                _vm.selectedMediaIndex = -1
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "cover-view",
            {
              attrs: {
                gradient: true,
                "image-url": _vm.coverImage,
                "module-color": _vm.moduleColor,
                "margin-top": 60,
              },
            },
            [
              _c(
                "div",
                [
                  _c("media-icons", {
                    attrs: {
                      icons: _vm.mediaIcons,
                      "media-type": _vm.mediaType,
                      amount: _vm.documents.length,
                    },
                    on: { "media-selected": _vm.mediaIconSelected },
                  }),
                  _vm._v(" "),
                  !_vm.showMediaList
                    ? _c(
                        "div",
                        { staticClass: "h-full flex flex-col" },
                        [
                          _c(
                            "div",
                            { staticClass: "mx-20" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "largeListItemHeader pt-20 mb-10",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.selectedDepartment.name) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("description", {
                                attrs: { description: _vm.description },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("profiles-list", {
                            attrs: {
                              "can-close": false,
                              "selected-profile-type": _vm.selectedProfile.type,
                              "shared-departments": _vm.departments,
                            },
                            on: {
                              "profile-selected": function ($event) {
                                return _vm.showSingleProfile($event)
                              },
                              "department-selected": _vm.switchDepartment,
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "p-20" },
                        [
                          _c("media-list", {
                            staticClass: "pt-20",
                            attrs: {
                              items: _vm.documents,
                              "media-type": _vm.mediaType,
                              "can-close": true,
                            },
                            on: {
                              close: _vm.closeMediaList,
                              "item-selected": function ($event) {
                                _vm.selectedMediaIndex = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }