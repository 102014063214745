<template>
  <div>
    <div class="content w-full mb-12 mx-20">
      {{ $t('booking.selecttParticipantsDescription') }}
    </div>

    <profiles-list
      :can-save="true"
      :multiselect="true"
      :shared-departments="departments"
      select-bottom="bottom-48"
      @save="save"
    />
  </div>
</template>

<script>
  import { getBookingById } from '@/utils/bookings.js';
  import ProfilesList from '@/components/shared/ProfilesList';
  import { mapGetters } from 'vuex';
  import { get } from 'lodash';

  export default {
    components: {
      ProfilesList
    },
    data() {
      return {
        description: ''
      }
    },
    computed: {
      ...mapGetters({
        booking: 'bookingResources/selectedBookingResource',
      }),
      departments() {
        console.log(this.booking);
        return get(this, 'booking.departments', []);
      }
    },
    methods: {
      async save(participants) {
        await this.$store.dispatch('general/setSaving', true);
        await this.$store.dispatch('bookingResources/setNewBooking', {
          participants
        });

        await this.$store.dispatch('bookingResources/createNewBooking')
          .then(async (id) => {
            await this.$store.dispatch('bookingResources/getBookingResources');
            await this.$store.dispatch('general/setSaving', false);
            await this.$router.push('/bookings');
            const booking = getBookingById(id);

            await this.$store.dispatch('bookingResources/setSelectedBookingEntry', booking);
            this.$emit('close-overlay');

            setTimeout(() => {
              this.$router.push(`/bookings/booking/${id}`);
            }, 1000);
          })
          .catch(() => {
            this.$store.dispatch('general/setNextActiveOverlay', { name: 'error', data: {
              title: 'global.error',
              descriptionOne: 'booking.doubleBooking',
              descriptionTwo: 'booking.chooseDifferentTime',
              buttonText: 'global.tryAgain'
            } });
            this.$emit('close-overlay');
          });
      }
    }
  }
</script>
