var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "h-36 w-full mb-8 flex flex-row relative",
      class: { "mt-12": _vm.index === 0 },
      style: { width: _vm.viewWidth },
    },
    [
      _c(
        "div",
        {
          staticClass: "image absolute z-20",
          style: { marginLeft: `${_vm.imageLeft}px` },
        },
        [
          _c("img", {
            staticClass:
              "w-36 h-36 rounded rounded-full border-8 border-white shadow-bottom z-20 object-cover",
            class: [
              { "filter grayscale": _vm.shiftOver || _vm.shiftCancelled },
            ],
            attrs: { src: _vm.shift.employee.displayImage, alt: "Employee" },
          }),
          _vm._v(" "),
          _vm.shift.nightShift
            ? _c(
                "div",
                {
                  staticClass:
                    "bg-charcoal z-10 absolute w-12 h-12 rounded rounded-full flex items-center justify-center -mt-12 ml-28 border-4 border-white shadow-bottom",
                },
                [
                  _c("fa-icon", {
                    staticClass: "text-white text-xl",
                    attrs: { icon: ["fas", "moon-stars"] },
                  }),
                ],
                1
              )
            : _vm.shift.cancelled
            ? _c(
                "div",
                {
                  staticClass:
                    "bg-warning z-10 absolute w-12 h-12 rounded rounded-full flex items-center justify-center -mt-12 ml-28 border-4 border-white shadow-bottom",
                },
                [
                  _c("fa-icon", {
                    staticClass: "text-white text-xl",
                    attrs: { icon: ["fas", "times"] },
                  }),
                ],
                1
              )
            : _vm.unavailableNow
            ? _c("div", {
                staticClass:
                  "z-10 absolute w-12 h-12 rounded rounded-full flex items-center justify-center -mt-12 ml-28 border-4 border-white shadow-bottom bg-orange",
              })
            : _vm.shiftStarted
            ? _c("div", {
                staticClass:
                  "z-10 absolute w-12 h-12 rounded rounded-full flex items-center justify-center -mt-12 ml-28 border-4 border-white shadow-bottom",
                class: `bg-${_vm.moduleColor}`,
              })
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "shift h-full z-10 rounded rounded-lg shadow-bottom flex items-center",
          class: _vm.backgroundColor,
          style: {
            width: `${_vm.shiftWidth}px`,
            "margin-left": _vm.shiftMarginLeft,
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "shiftData absolute flex flex-col justify-center z-10",
              style: { marginLeft: `${_vm.shiftDetailsLeft}px` },
            },
            [
              _c(
                "p",
                {
                  staticClass: "w-max text-3xl font-semibold name",
                  class: [
                    _vm.textColor,
                    { "opacity-0": !_vm.showName, "opacity-1": _vm.showName },
                  ],
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.shift.employee.displayName) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "w-max text-3xl font-light time",
                  class: [
                    _vm.textColor,
                    { "opacity-0": !_vm.showTime, "opacity-1": _vm.showTime },
                  ],
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.startTime) +
                      " - " +
                      _vm._s(_vm.endTime) +
                      "\n      "
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.hasUnavailability && !_vm.shiftOver
            ? _c("div", {
                staticClass: "h-full bg-orange absolute",
                class: {
                  "rounded-l-lg": _vm.unavailabilityMarginLeft === 0,
                  "rounded-r-lg":
                    _vm.unavailabilityWidth + _vm.unavailabilityMarginLeft ===
                    _vm.shiftWidth,
                },
                style: {
                  "margin-left": `${_vm.unavailabilityMarginLeft}px`,
                  width: `${_vm.unavailabilityWidth}px`,
                },
              })
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }