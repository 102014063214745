import { apolloClient } from '../../utils/apollo';
import store from '@/store';
import { cloneDeep } from 'lodash';
import { format } from '@/utils/date-fns';
import { ls } from '@/utils/storage';

const state = {
  all: [],
  selected: {}
};

const mutations = {
  SET_ALL_MEALS(state, meals) {
    state.all = meals;
  },
  SET_SELECTED_MEAL(state, meal) {
    state.selected = meal;
  }
};

const actions = {
  async getMeals({commit}) {
    const departmentId = store.getters['department/id'];
    
    if (!departmentId) {
      throw new Error('No department id provided');
    }
    
    const dateNow = new Date();
    const future90days = dateNow.setDate(dateNow.getDate() + 90);
    const dateEnd = new Date(future90days).toISOString().split('T')[0];

    await apolloClient
      .query({
        query: require('@/graphql/queries/mealRange').default,
        variables: {
          filter: {
            departments: departmentId
          },
          dateFrom: new Date().toISOString().split('T')[0],
          dateTo: dateEnd,
          timezone: 'Europe/Copenhagen'
        },
        fetchPolicy: 'no-cache'
      })
      .then(response => {
        commit('SET_ALL_MEALS', response.data.mealRange);
      })
      .catch(error => {
        console.log('Error getting meals', error);
      })
  },
  async getPcdMeals({commit}) {
    const departmentId = store.getters['department/id'];
    
    if (!departmentId) {
      throw new Error('No department id provided');
    }
    
    await apolloClient
      .query({
        query: require('@/graphql/queries/pcdMeals').default,
        fetchPolicy: 'no-cache',
        variables: {
          date: format(new Date(), 'yyyy-MM-dd'),
        }
      })
      .then(response => {
        const meals = response.data.pcdMeals.reduce((acc, meal) => {
          const courses = meal.courses.map(course => {
            return {
              isPcd: true,
              id: course.id,
              title: course.title,
              category: course.category,
              startDate: meal.date,
              coverImage: {
                source: '../../assets/img/external/external-meal.jpg'
              }
            };
          });
    
          acc.push(...courses)
          return acc;
        }, []);

        commit('SET_ALL_MEALS', meals);
      })
      .catch(error => {
        console.log('Error getting meals', error);
      })
  },
  async getSelectedMeal({commit}, mealId) {
    if (store.getters['institution/settings'].useExternalMeals) {
      const meal = state.all.find(meal => meal.id === mealId);
      commit('SET_SELECTED_MEAL', meal);
      return;
    }
    
    commit('SET_SELECTED_MEAL', {});
    await apolloClient
      .query({
        query: require('@/graphql/queries/mealEntry').default,
        variables: {
          id: mealId
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        commit('SET_SELECTED_MEAL', response.data.mealEntry);
      }).catch(error => {
        console.log('Could not get meal entry: ', error);
      });
  },
  async mealSignupScreen({commit}, { subscription, profile, opt }) {
    const mutation = opt === 'in' ? 'mealSignupScreen' : 'mealCancelSignupScreen';

    await apolloClient
      .mutate({
        mutation: require(`@/graphql/mutations/${mutation}`).default,
        variables: {
          subscription,
          profile
        },
      })
      .then(async () => {
        await store.dispatch('profiles/setPinValidation', { validating: false, validPin: true });
        store.dispatch('meals/getSelectedMeal', subscription.id);
      })
      .catch(error => {
        store.dispatch('profiles/setPinValidation', { validating: false, validPin: false });
        throw new Error('Error signing up to meal', error);
      });
  },
  async updateParticipants({commit}, mealId) {
    await apolloClient
      .query({
        query: require('@/graphql/queries/mealEntry').default,
        variables: {
          id: mealId
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const participants = response.data.mealEntry.participants;
        
        const allMeals = cloneDeep(state.all);
        const mealIndex = allMeals.findIndex(meal => meal.id === mealId);
        
        if (mealIndex > -1) {
          allMeals[mealIndex].participants = participants;
          commit('SET_ALL_MEALS', allMeals);
        }
      }).catch(error => {
        console.log('Error: ', error);
      });
  }
};

const getters = {
  all: state => state.all,
  allPcd: state => state.allPcd,
  selected: state => state.selected
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
