import gql from 'graphql-tag';

export default gql`
  mutation shiftUpdate ($shift: ScheduleShiftInput!) {
    shift {
      update(shift: $shift) {
        id
        success
        message
    }
  }
}`