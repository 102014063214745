var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-views z-50" },
    _vm._l(_vm.contentViews, function (filter, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "content-view rounded-full p-4 mr-5 text-4xl shadow-lg",
          class: {
            [`bg-${_vm.moduleColor}`]:
              _vm.activeContentView.name === filter.name,
            "bg-white": _vm.activeContentView.name !== filter.name,
            "text-white": _vm.activeContentView.name === filter.name,
            separate: filter.position === "right",
          },
          on: {
            click: function ($event) {
              return _vm.selectFilter(index)
            },
          },
        },
        [_c("fa-icon", { attrs: { icon: ["fal", `${filter.icon}`] } })],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }