import { apolloClient } from '../../utils/apollo';

const state = {
  all: []
};

const mutations = {
  SET_DEFAULT_SPEECH(state, speech) {
    state.all = speech;
  }
};

const actions = {
  async getSpeech({commit}, {id, type}) {
    await apolloClient
      .query({
        query: require('@/graphql/queries/ttsEntity').default,
        variables: {
          entityId: id,
          entityType: type,
          speakingRate: 'MEDIUM',

        },
        fetchPolicy: 'no-cache'
      })
      .then(response => {
        commit('SET_DEFAULT_SPEECH', response.data.ttsEntity);
      })
      .catch(error => {
        console.log('Error getting speech', error);
      })
  }
};

const getters = {
  all: state => state.all
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
