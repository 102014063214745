<template>
  <carousel
    :name="refName"
    :ref-name="refName"
    :slide-duration="widgetSlideDuration"
    @slide-clicked="slideClicked"
  >
    <slide
      v-for="( activity, index ) in activities"
      :key="index"
      :class="[slideStyles, widget.frHeight > 1 ? 'justify-center' : 'justify-end']"
      :style="getBackgroundStyles(index)"
    >
      <status
        v-if="activity.cancelled"
        class="mb-6"
        icon="ban"
        color="error"
        text="global.cancelled"
      />
      <status
        v-if="isItemNow(activity)"
        class="mb-6"
      />
      <fa-icon
        class="text-2xl mb-4 text-white"
        :icon="['fas', `${widget.icon}`]"
      />
      <p class="text-2xl mb-6">
        {{ activity.title }}
      </p>
      <date-time
        v-if="widget.frHeight > 1"
        class="mb-6"
        :start-date="activity.startDate"
      />
      <div
        v-if="widget.frHeight > 1"
        class="flex items-center justify-center"
      >
        <fa-icon
          class="text-xl text-white mr-2"
          :icon="['fas', 'user' ]"
        />
        <p>
          {{ participantsLength(index) }} {{ $t('global.participants') }}
        </p>
      </div>
    </slide>
  </carousel>
</template>
<script>

import Status from '@/components/shared/infoLabels/Status';
import DateTime from '@/components/shared/infoLabels/DateTime';
import coverImageGradient from '@/utils/coverImageGradient.js';
import constants from '@/utils/constants.js';
import Carousel from '@/components/shared/Carousel';
import { mapGetters } from 'vuex';
import { Slide } from 'vue-carousel';
import { get } from 'lodash';
import { isItemNow } from '@/utils/helpers.js';

export default {
  components: {
    Status,
    DateTime,
    Carousel,
    Slide
  },
  props: {
    widget: {
      type: Object,
      'default': (() => {})
    }
  },
  computed: {
    ...mapGetters({
      'internalActivities': 'activities/internal',
      'externalActivities': 'activities/external',
      'departmentSettings': 'department/settings'
    }),
    widgetSlideDuration() {
      return this.departmentSettings.screenConfig.carouselDuration * 1000;
    },
    activities() {
      const activities = this.widget.type === 'ACTIVITIES' ? this.internalActivities : this.externalActivities;
      return activities.slice(0, this.widget.frWidth * 5);
    },
    refName() {
      return this.widget.type === 'ACTIVITIES' ? 'activities' : 'external-activities';
    },
    slideStyles() {
      return constants.slider.slideStyles;
    }
  },

  methods: {
    isItemNow(item) {
      return isItemNow(item.startDate, item.endDate);
    },
    getBackgroundStyles(index) {
      const height = this.widget.frHeight > 1 ? 50 : 70;
      const coverImage = get(this.activities[index], 'coverImage.source', require('@assets/img/placeholder.png'));
      return coverImageGradient.get(this.widget.colorHex, height, coverImage);
    },
    slideClicked(slideIndex) {
      const itemId = this.activities[slideIndex].id;
      this.$emit('slide-clicked', `/${this.widget.name}/${itemId}`);
    },
    participantsLength(index) {
      return get(this.activities[index], 'participants.length', 0);
    }
  }
}
</script>