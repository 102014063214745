<template>
  <div class="booking">
    <cover-view
      v-if="resource.id"
      :image-url="imageUrl"
      :gradient="true"
      :module-color="moduleColor"
      :margin-top="60"
    >
      <div class="bookingContent pt-20">
        <div class="largeListItemHeader pt-12 mb-12">
          {{ resource.title }}
        </div>

        <description :description="resource.description" />
        
        <institution />

        <div
          v-for="(booking, index) in resource.bookings"
          :key="index"
          class="relative content mb-14 w-full"
        >
          <date-time
            :start="booking.start"
            :end="booking.end"
          />

          <description
            :description="booking.description"
          />

          <people
            v-if="booking.participants.length"
            :module-color="moduleColor"
            :profiles="booking.participants"
            @show-people="showProfiles('participants', index)"
          />
        </div>

        <div
          v-if="canCreateNewBooking"
          class="newBooking flex w-full sticky justify-end"
        >
          <button-el
            text="booking.newBooking"
            icon="plus"
            background-color="success"
            text-color="white"
            class="mr-5 shadow-xsm"
            @click.native="newBooking"
          />
        </div>
      </div>
    </cover-view>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Button from '@/components/shared/Button';
import { get, capitalize, isEmpty } from 'lodash';
import CoverView from '@/views/shared/CoverView';
import { getStartDay, formatTime } from '@/utils/helpers.js';
import PinFlow from '@/utils/pinFlow';
import People from '@/components/shared/blocks/People';
import DateTime from '@/components/shared/blocks/DateTime';
import Description from '@/components/shared/blocks/Description';
import Institution from '@/components/shared/blocks/Institution';

export default {
  components: {
    ButtonEl: Button,
    CoverView,
    People,
    DateTime,
    Description,
    Institution
  },
  data() {
    return {
      pinData: {}
    }
  },
  computed: {
    ...mapGetters({
      allBookingResources: 'bookingResources/all',
      resource: 'bookingResources/selectedBookingResource',
      institutionColor: 'institution/color',
      activeProfile: 'profiles/activeProfile',
      pinValidation: 'profiles/pinValidation',
      opt: 'bookingResources/opt'
    }),
    moduleColor() {
      return this.$route?.meta?.color;
    },
    imageUrl() {
      return get(this.resource, 'coverImage.source', require('@assets/img/placeholder.png'));
    },
    canCreateNewBooking() {
      return this.resource.authentication !== 'EMPLOYEE_ONLY';
    },
    pinRequired() {
      return [ 'EMPLOYEE_ONLY', 'PASSWORD' ].includes(this.resource.authentication);
    }
  },
  watch: {
    allBookingResources(resources) {
      const resource = resources.find(resource => resource.id === this.resource.id);
      this.$store.dispatch('bookingResources/setSelectedBookingResource', resource);
    },
    pinValidation(pinData) {
      if (!pinData.validPin) return;

      if (this.resource.slotted) {
        this.bookSlot();
      } else {
        this.startNewBooking();
      }
    }
  },
  mounted() {
    this.$matomo.trackEvent('BookingEntry', 'Viewed', 'BookingEntryViewed');
  },
  methods: {
    getStartDay(dateString) {
      return capitalize(this.$t(getStartDay(dateString)));
    },
    formatTime(activity) {
      return formatTime(activity);
    },
    showProfiles(profileType, index) {
      this.$store.dispatch('general/setActiveOverlay', { name: 'participants-list', data: { title: `global.${profileType}`, profiles: this.resource.bookings[index].participants } })
    },
    async validatePin() {
      await this.$store.dispatch('profiles/validatePin', { profile: this.activeProfile, pin: this.pinData.pin });
    },
    async startNewBooking() {
      this.$store.dispatch('bookingResources/setNewBooking', { resourceId: this.resource.id });

      await this.$store.dispatch('general/setActiveOverlay', {
        name: 'new-booking',
        data: {
          title: `${capitalize(this.$t('booking.book'))} ${this.resource.title}`
        }
      });
    },
    async startNewSlottedBooking() {
      await this.$store.dispatch('general/setActiveOverlay', {
        name: 'new-slotted-booking',
        data: {
          title: `${capitalize(this.$t('booking.book'))} ${this.resource.title}`
        }
      });
    },
    async newBooking() {
      if (this.resource.slotted) {
        await this.$store.dispatch('bookingResources/setSelectedSlottedWeek');
        this.startNewSlottedBooking();
        return;
      }

      const pinFlow = new PinFlow({
        entity: 'booking',
        type: 'create',
        title: this.resource.title
      });

      switch (this.resource.authentication) {
        case 'NONE':
          this.startNewBooking();
          break;
        case 'PASSWORD':
          pinFlow.startCreateFlow('booking.toBook');
          break;
        case 'EMPLOYEE_ONLY':
          pinFlow.startCreateFlow('booking.toBook', true);
          break;
      }
    },
    async bookSlot() {
      if (this.opt === 'in') {
        await this.$store.dispatch('bookingResources/createNewSlottedBooking');
      } else {
        await this.$store.dispatch('bookingResources/cancelSlottedBooking');
      }
      
      this.startNewSlottedBooking();
    }
  },
};
</script>

<style lang="scss">
  .booking {

    .bookingContent {
  
      &.participants {
        margin-top: 700px;
      }
  
      > *:not(.mediaIcons) {
        padding: 0 100px;
      }
    }
  
    .newBooking {
      bottom: 150px;
      padding-right: 80px;
    }
  }
  
</style>
