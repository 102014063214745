var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "relative content flex items-center w-full",
      class: _vm.margin,
    },
    [
      _c("fa-icon", {
        staticClass: "mr-6 self-start",
        class: {
          "mt-2": _vm.formatDate(_vm.start) !== _vm.formatDate(_vm.end),
        },
        attrs: { icon: ["fal", "clock"] },
      }),
      _vm._v(" "),
      _vm.formatDate(_vm.start) !== _vm.formatDate(_vm.end)
        ? _c("div", { staticClass: "flex flex-col" }, [
            _c("span", [
              _vm._v("\n      " + _vm._s(_vm.formatDate(_vm.start)) + " - "),
              _c("span", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.formatTime(_vm.start))),
              ]),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v("\n      " + _vm._s(_vm.formatDate(_vm.end)) + " - "),
              _c("span", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.formatTime(_vm.end))),
              ]),
            ]),
          ])
        : _c("div", { staticClass: "flex flex-col" }, [
            _c("span", [_vm._v(_vm._s(_vm.formatDate(_vm.start)))]),
            _vm._v(" "),
            _c("span", { staticClass: "font-bold" }, [
              _vm._v(
                _vm._s(_vm.formatTime(_vm.start)) +
                  " - " +
                  _vm._s(_vm.formatTime(_vm.end))
              ),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }