<template>
  <div 
    class="activityResidents relative content pb-10 flex items-center w-full"
    @click="hideParticipants ? '' : $emit('show-people')"
  >
    <fa-icon
      class="mr-6"
      :icon="['fal', 'user-friends']"
    />
    <p
      :class="hideParticipants ? 'font-light' : 'font-semibold'"
      class="flex-1"
    >
      {{ profiles.length }} 
      {{ $tc(text, profiles.length) }}
    </p>
    <fa-icon
      v-if="!hideParticipants"
      class="text-5xl"
      :icon="['fal', 'angle-right']"
    />
  </div>
</template>

<script>

export default {
  props: {
    profiles: {
      type: Array,
      'default': (() => [])
    },
    moduleColor: {
      type: String,
      'default': ''
    },
    text: {
      type: String,
      'default': 'global.participantsPlural'
    },
    hideParticipants: {
      type: Boolean,
      'default': false
    }
  }
}
</script>