var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "list grid-cols-1 items" }, [
    !_vm.sortByDay
      ? _c(
          "div",
          { staticClass: "flex flex-col justify-center" },
          _vm._l(_vm.items, function (item, index) {
            return _c("card-advanced", {
              key: index,
              staticClass: "shadow-m",
              attrs: {
                "card-type": _vm.activeContentView.cardType,
                title: item.title,
                image: _vm.getCoverImage(item),
                gradient: true,
                meta: { icon: item.meta.icon, text: item.meta.text },
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.$emit("item-selected", item)
                },
              },
            })
          }),
          1
        )
      : _c(
          "div",
          _vm._l(Object.values(_vm.dayItems), function (itemDay, index) {
            return _c(
              "div",
              { key: index, staticClass: "itemDay" },
              [
                _c("div", { staticClass: "dayLabelWrapper sticky z-20" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "dayLabel p-4 text-3xl text-white left-0 rounded-r-lg",
                      class: `bg-${_vm.moduleColor}`,
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getStartDay(itemDay.meta.startDate)) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm._l(itemDay, function (item, index) {
                  return _c("card-advanced", {
                    key: index,
                    staticClass: "shadow-m",
                    attrs: {
                      "card-type": _vm.activeContentView.cardType,
                      title: item.title,
                      "start-date": item.startDate,
                      meta: !_vm.useExternalMeals
                        ? { icon: item.meta.icon, text: item.meta.text }
                        : null,
                      "is-now": _vm.isItemNow(item),
                      "is-cancelled": item.cancelled,
                      image: _vm.getCoverImage(item),
                      gradient: true,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.$emit("item-selected", item)
                      },
                    },
                  })
                }),
              ],
              2
            )
          }),
          0
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }