import { apolloClient } from '../../utils/apollo';
import store from '@/store';
import { isAfter, isBefore, endOfDay } from 'date-fns';
import { format } from '@/utils/date-fns';
import { toDate } from 'date-fns-tz';
import { get } from 'lodash';
import constants from '@/utils/constants.js';

const state = {
    all: [],
    workingNow: [],
    comingLater: [],
    isLoading: false,
    type: ''
};

const mutations = {
    SET_WORKSCHEDULE_ALL(state, schedule) {
        state.all = schedule;
    },
    SET_WORKSCHEDULE_NOW(state, schedule) {
        state.workingNow = schedule;
    },
    SET_WORKSCHEDULE_LATER(state, schedule) {
        state.comingLater = schedule;
    },
    SET_ISLOADING(state, isLoading) {
        state.isLoading = isLoading;
    },
    SET_WORKSCHEDULE_TYPE(state, type) {
        state.type = type;
    }
};

const getFilteredShifts = ((schedule, filter) => {
    return schedule.filter(shift => {
        if (filter === 'later') {
          if (isAfter(new Date(shift.start), new Date()) && isBefore(new Date(shift.start), endOfDay(new Date()))) return true;
          return false;
        }

        if (isAfter(new Date(), new Date(shift.end))) return false;
        if (isAfter(new Date(shift.start), new Date())) return false;
        return true;
      })
    .map(shift => {
        return {
            ...shift,
            start: toDate(shift.start, { timeZone: 'Europe/Copenhagen' }),
            end: toDate(shift.end, { timeZone: 'Europe/Copenhagen' })
        }
    });
});

const actions = {
    async getWorkSchedules({ commit }, dates) {
        commit('SET_ISLOADING', true);
        const departmentId = store.getters['department/id']
        await apolloClient
            .query({
                query: require('@/graphql/queries/schedule').default,
                variables: {
                    filter: {
                        departments: departmentId
                    },
                    dateFrom: format(dates.from, 'yyyy-MM-dd'),
                    dateTo: format(dates.to, 'yyyy-MM-dd'),
                    timezone: 'Europe/Copenhagen'
                },
                fetchPolicy: 'no-cache'
            })
            .then(response => {
                const shifts = get(response, 'data.schedule.shifts', []);
                shifts.map(shift => {
                    if (!shift.employee.showImage) {
                        shift.employee.displayImage = constants.avatarPlaceholder;
                    }
                    return shift;
                })
                
                commit('SET_WORKSCHEDULE_ALL', shifts);
                commit('SET_WORKSCHEDULE_NOW', getFilteredShifts(shifts, 'now'));
                commit('SET_WORKSCHEDULE_LATER', getFilteredShifts(shifts, 'later'));

                commit('SET_ISLOADING', false);
            })
            .catch(error => {
                console.log('Error getting work schedule', error);
                commit('SET_ISLOADING', false);
            });
    },
    setType({commit}) {
        const modules = store.getters['department/settings'].screenConfig.modules.map(module => module.type);

        const type = modules.includes('SIMPLE_WORK_SCHEDULE') ? 'SIMPLE_WORK_SCHEDULE' : 'WORK_SCHEDULE';
        
        commit('SET_WORKSCHEDULE_TYPE', type);
    },
    async shiftUpdate({commit}, shiftData ) {
        await apolloClient
          .mutate({
            mutation: require('@/graphql/mutations/shiftUpdate').default,
            variables: {
                shift: {
                    id: shiftData.shiftId,
                    employeeId: shiftData.employeeId,
                    cancelled: shiftData.cancel
                }
            },
          })
          .then(async () => {
            store.dispatch('workSchedule/getWorkSchedules', shiftData.dates);            
            await store.dispatch('profiles/setPinValidation', { validating: false, validPin: false });
          })
          .catch(error => {
            store.dispatch('profiles/setPinValidation', { validating: false, validPin: false });
            throw new Error('Error updating shift', error);
          });
      },
      async shiftDelete({commit}, shiftData ) {
        await apolloClient
          .mutate({
            mutation: require('@/graphql/mutations/shiftDelete').default,
            variables: {
                id: shiftData.shiftId
            },
          })
          .then(async () => {
            store.dispatch('workSchedule/getWorkSchedules', shiftData.dates);            
            await store.dispatch('profiles/setPinValidation', { validating: false, validPin: false });
          })
          .catch(error => {
            store.dispatch('profiles/setPinValidation', { validating: false, validPin: false });
            throw new Error('Error deleting shift', error);
          });
      }
};

const getters = {
    all: state => state.all,
    workingNow: state => state.workingNow,
    comingLater: state => state.comingLater,
    isLoading: state => state.isLoading,
    type: state => state.type
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};