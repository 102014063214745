var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "flex justify-center items-center text-base text-3xl rounded-lg h-18",
      class: {
        border:
          _vm.backgroundColorHex === "#fff" ||
          _vm.backgroundColorHex === "#ffffff",
        "px-10 py-6": _vm.padding,
        "shadow-xsm": _vm.shadow,
      },
      style: [
        { backgroundColor: _vm.backgroundColorHex, color: _vm.textColorHex },
      ],
    },
    [
      _vm.icon
        ? _c(
            "span",
            [
              _vm.isSave && _vm.isSaving
                ? _c("fa-icon", {
                    staticClass: "mr-3 fa-spin",
                    attrs: { icon: ["fal", "spinner"] },
                  })
                : _c("fa-icon", {
                    class: { "mr-3": _vm.text.length },
                    attrs: { icon: ["fal", `${_vm.icon}`] },
                  }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.$t(_vm.text)))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }