var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "newsEntry" },
    [
      _vm.showMediaPreview
        ? _c("media-preview", {
            attrs: {
              "selected-item-index": _vm.selectedMediaIndex,
              "media-type": _vm.mediaType,
              items: _vm.mediaItems,
            },
            on: {
              "close-preview": function ($event) {
                _vm.selectedMediaIndex = -1
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.newsEntry
        ? _c(
            "cover-view",
            {
              attrs: {
                "image-url": _vm.newsEntry.coverImage.source,
                gradient: true,
                "module-color": _vm.moduleColor,
                "margin-top": 60,
              },
            },
            [
              _c(
                "div",
                { staticClass: "newsEntryContent pt-20" },
                [
                  _c("media-icons", {
                    attrs: {
                      icons: _vm.mediaIcons,
                      "media-type": _vm.mediaType,
                      amount: _vm.mediaItems.length,
                    },
                    on: { "media-selected": _vm.mediaIconSelected },
                  }),
                  _vm._v(" "),
                  _c("tts", {
                    attrs: {
                      "entity-id": _vm.newsEntry.id,
                      "entity-type": "NEWS",
                    },
                  }),
                  _vm._v(" "),
                  !_vm.showMediaList
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "largeListItemHeader pt-20 pb-6" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.newsEntry.title) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-3xl font-light" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("news.published")) +
                              " " +
                              _vm._s(_vm.getStartDay(_vm.newsEntry.startDate)) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "pictograms inline-flex w-full mb-20",
                          },
                          _vm._l(
                            _vm.newsEntry.pictograms,
                            function (pictogram, index) {
                              return _c("img", {
                                key: index,
                                staticClass: "w-20 mr-5",
                                attrs: {
                                  src: _vm.newsEntry.pictograms[index].source,
                                },
                              })
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "newsDetails" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "activityDescription col-span-2 pl-16 relative content pb-10",
                            },
                            [
                              _c("fa-icon", {
                                staticClass: "absolute top-1 left-0",
                                attrs: { icon: ["fal", "align-left"] },
                              }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.newsEntry.body) +
                                  "\n          "
                              ),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _c(
                        "div",
                        [
                          _c("media-list", {
                            staticClass: "pt-20",
                            attrs: {
                              items: _vm.mediaItems,
                              "media-type": _vm.mediaType,
                              "can-close": true,
                            },
                            on: {
                              close: _vm.closeMediaList,
                              "item-selected": function ($event) {
                                _vm.selectedMediaIndex = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }