<template>
  <div>
    <media-preview
      v-if="showMediaPreview"
      :selected-item-index="selectedMediaIndex"
      :media-type="mediaType"
      :items="documents"
      @close-preview="selectedMediaIndex = -1"
    />

    <cover-view
      v-if="!isLoading"
      :gradient="true"
      :image-url="coverImage"
      :module-color="moduleColor"
      :margin-top="60"
    >
      <div>
        <media-icons
          :icons="mediaIcons"
          :media-type="mediaType"
          :amount="documents.length"
          @media-selected="mediaIconSelected"
        />
        <div
          v-if="!showMediaList"
          class="h-full flex flex-col"
        >
          <div class="mx-20">
            <div class="largeListItemHeader pt-20 mb-10">
              {{ selectedDepartment.name }}
            </div>

            <description :description="description" />
          </div>

          <profiles-list
            :can-close="false"
            :selected-profile-type="selectedProfile.type"
            :shared-departments="departments"
            @profile-selected="showSingleProfile($event)"
            @department-selected="switchDepartment"
          />
        </div>

        <div
          v-else
          class="p-20"
        >
          <media-list
            :items="documents"
            :media-type="mediaType"
            class="pt-20"
            :can-close="true"
            @close="closeMediaList"
            @item-selected="selectedMediaIndex = $event"
          />
        </div>
      </div>
    </cover-view>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';

import CoverView from '@/views/shared/CoverView';
import Description from '@/components/shared/blocks/Description';
import MediaIcons from '@/components/shared/MediaIcons';
import MediaList from '@/views/shared/MediaList';
import MediaPreview from '@/views/overlays/MediaPreview';
import ProfilesList from '@/components/shared/ProfilesList';

export default {
  components:{
    CoverView,
    Description,
    MediaIcons,
    MediaList,
    MediaPreview,
    ProfilesList
  },
  data() {
    return {
      showSelectDepartment: false,
      selectedProfiles: [],
      showMediaList: false,
      mediaType: '',
      isLoading: true,
      selectedMediaIndex: -1,
      hideMediaPreview: false,
      selectedDepartmentId: -1
    }
  },
  computed: {
    ...mapGetters({
      departments: 'institution/departments',
      mainDepartmentPresentation: 'department/presentation',
      mainDepartmentId: 'department/id',
      mainDepartmentName: 'department/name',
      profiles: 'profiles/all',
      myArchive: 'profiles/myArchive',
      selectedProfile: 'profiles/activeProfile'
    }),
    moduleColor() {
      return this.$route?.meta?.color;
    },
    selectedDepartment() {
      return this.departments.find(department => parseInt(department.id) === parseInt(this.selectedDepartmentId));
    },
    coverImage() {
      return get(this.selectedDepartment, 'presentation.image', require('@assets/img/placeholder.png'));
    },
    description() {
      return get(this.selectedDepartment, 'presentation.description', '');
    },
    shownProfiles() {
      const profilesInDepartment = this.profiles.filter(profile => this.getDepartmentIds(profile).includes(parseInt(this.selectedDepartmentId)));
      return profilesInDepartment.filter(profile => profile.type === this.selectedProfile.type);
    },
    size() {
      if (this.shownProfiles.length > 12) return 'small';
      return 'large';
    },
    documents() {
      return get(this.myArchive, 'documents', []);
    },
    mediaIcons() {
      return [
        {
          name: 'documents',
          fa: 'file-alt',
          amount: this.documents.length
        }
      ];
    },
    showMediaPreview() {
      return this.selectedMediaIndex > -1 && !this.hideMediaPreview;
    },
  },
  async mounted() {
    await this.$store.dispatch('general/setActiveContentView', {});
    this.switchDepartment({
      id: this.mainDepartmentId,
      type: 'DEPARTMENT'
    })
    this.isLoading = false;
  },
  methods: {
     closeMediaList() {
      this.mediaType = '';
      this.selectedMediaIndex = -1;
      this.showMediaList = false;
    },
    toggleShowSelectDepartment() {
      this.showSelectDepartment = !this.showSelectDepartment;
    },
    async switchDepartment(department) {
      const departmentId = parseInt(department.id);
      this.selectedDepartmentId = departmentId;
      await this.$store.dispatch('profiles/getMyArchive', { id: departmentId, type: 'DEPARTMENT' });
      this.showSelectDepartment = false;
    },
    getDepartmentIds(profile) {
      return profile.departments.map(department => parseInt(department.id));
    },
    onClickoutside() {
      if (!this.showSelectDepartment) return;
      this.showSelectDepartment = false;
    },
    async showSingleProfile(profile) {
      await this.$store.dispatch('profiles/setActiveProfile', profile);
      this.$router.push(`/ourHome/${profile.id}`);
    },
    mediaIconSelected(mediaType) {
      this.mediaType = mediaType;

      if (this.documents.length === 1) {
        // if only one item, go straight to media-preview
        this.selectedMediaIndex = 0;
        this.hideMediaPreview = false;
        return;
      }

      this.showMediaList = true;
    }
  }
}
</script>

<style lang="scss">
  .profile {
    .profileImage {
      &.small {
        width: 96px;
        height: 96px;
      }

      &.large {
        width: 180px;
        height: 180px;
      }
    }
  }
</style>