<template>
  <carousel
    :ref="refName"
    v-bind="sliderOptions"
    v-touch="slideClicked"
    :autoplay-timeout="slideDuration"
    :autoplay="autoplay"
    :pagination-enabled="pagination"
    :loop="loop"
    class="h-full w-full"
    @page-change="slideChange"
  >
    <slot />
  </carousel>
</template>
<script>

import constants from '@/utils/constants.js';
import { Carousel } from 'vue-carousel';
import Swipe from '@/utils/swipe';
import { mapGetters } from 'vuex';

export default {
  components: {
    Carousel
  },
  props: {
    name: {
      type: String,
      'default': ''
    },
    autoplay: {
      type: Boolean,
      'default': true
    },
    pagination: {
      type: Boolean,
      'default': true
    },
    loop: {
      type: Boolean,
      'default': true
    },
    startIndex: {
      type: Number,
      'default': 0
    },
    slideDuration: {
      type: Number,
      'default': 30000
    }
  },
  computed: {
    ...mapGetters({
      swipeDirection: 'general/swipeDirection',
      swipeRef: 'general/swipeRef',
    }),
    sliderOptions() {
      return constants.slider.sliderOptions;
    },
    refName() {
      return `carousel-${this.name}`;
    },
    currentSlide() {
      return this.$refs[this.refName].currentPage;
    }
  },
  watch: {
    startIndex(index) {
      this.$refs[this.refName].goToPage(index);
    },
    swipeDirection(direction) {
      if (this.refName !== `carousel-${this.swipeRef}`) return;
      const currentPage = this.$refs[this.refName].currentPage;
      const pageCount = this.$refs[this.refName].pageCount;
      let newPage;

      if (direction === 'left') {
        if (!this.loop && currentPage === pageCount - 1) return;
        newPage = currentPage === pageCount - 1 ? 0 : currentPage + 1;
        this.$refs[this.refName].goToPage(newPage);
        this.$emit('swipe', newPage);
      }

      if (direction === 'right') {
        if (!this.loop && currentPage === 0) return;
        newPage = currentPage === 0 ? pageCount - 1 : currentPage - 1;
        this.$refs[this.refName].goToPage(newPage);
        this.$emit('swipe', newPage);
      }
    }
  },
  mounted() {
    const swipe = new Swipe('.VueCarousel', 50, this.refName);
    swipe.detect();

    if (!this.startIndex || !this.$refs) return;
  },
  created() {
    if (this.autoplay) {
      this.startAutoplay();
    }
  },
  methods: {
    slideChange() {
      this.$emit('slide-change', this.currentSlide);
    },
    slideClicked(event) {
      if (event.target.closest('.VueCarousel-pagination')) return;
      this.$emit('slide-clicked', this.currentSlide);
    },
    startAutoplay() {
      if (!this.$refs[this.refName]) return;
      this.$refs[this.refName].pauseAutoplay();
      
      setTimeout(() => {
        this.$refs[this.refName].startAutoplay();
      }, Math.floor(Math.random() * (5000 - 1000 + 1) + 1000));
    }
  }
}
</script>