var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "module-list text-center flex items-center justify-between px-8",
    },
    _vm._l(_vm.modules, function (module, index) {
      return _c(
        "router-link",
        {
          key: index,
          staticClass:
            "module rounded-lg text-white justify-center items-center flex flex-col border border-opacity-25",
          class: [
            _vm.activePath === module.path
              ? `bg-${module.meta.color}`
              : `bg-${module.meta.color} bg-opacity-5 border-${module.meta.color}`,
          ],
          attrs: { to: module.path, "active-class": "active" },
          nativeOn: {
            click: function ($event) {
              return _vm.routerLinkClicked.apply(null, arguments)
            },
          },
        },
        [
          !module.meta.isExternal
            ? _c("fa-icon", {
                staticClass: "text-3xl bg-red",
                class: {
                  [`text-${module.meta.color}`]: _vm.activePath !== module.path,
                },
                attrs: { icon: module.meta.icon },
              })
            : _c("img", {
                staticClass: "w-8 pb-2",
                attrs: {
                  src: _vm.getModuleData(index).src,
                  alt: _vm.getModuleData(index).alt,
                },
              }),
          _vm._v(" "),
          _c("p", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: _vm.getModuleData(index).name,
                expression: "getModuleData(index).name",
              },
            ],
            staticClass: "text-sm mt-1 font-bold",
            class: {
              [`text-${module.meta.color}`]: _vm.activePath !== module.path,
            },
          }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }