<template>
  <carousel
    name="slideshow"
    ref-name="slideshow"
    :autoplay="false"
    :loop="true"
    :slide-duration="widgetSlideDuration"
    :start-index="currentGallerySlide"
    @slide-clicked="slideClicked"
  >
    <slide
      v-for="( gallery, index ) in galleries"
      :key="index"
      :class="[widget.frHeight > 1 ? 'justify-center' : 'justify-end p-0']"
      class="w-full text-white flex items-center h-full flex-col pb-16 text-center"
      :style="backgroundStylesOuter"
    >
      <carousel
        :name="'pictures' + index"
        :ref-name="'pictures' + index"
        :autoplay="currentGallerySlide === index ? true : false"
        :loop="true"
        class="w-full"
        :pagination="false"
      >
        <slide
          v-for="( pictures, picturesIndex ) in gallery.pictures"
          :key="picturesIndex"
          :class="[widget.frHeight > 1 ? 'justify-center' : 'justify-end']"
          class="bg-cover bg-center bg-no-repeat w-full text-white flex items-center h-full px-16 pb-16 flex-col text-center"
          :style="{ 
            backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 50%, ${ widget.colorHex} 95%, ${ widget.colorHex } 10%), url('${pictures.source}')`
          }"
        />
      </carousel>
      
      <fa-icon
        class="text-2xl mb-4 text-white"
        :icon="['fas', `${widget.icon}`]"
      />
      <p class="text-2xl mb-6">
        {{ gallery.title }}
      </p>
    </slide>
  </carousel>
</template>

<script>
import { mapGetters } from 'vuex';
import Carousel from '@/components/shared/Carousel';
import { get, isEmpty } from 'lodash';
import coverImageGradient from '@/utils/coverImageGradient.js';

export default {
  components: {
    Carousel
  },
  props: {
    widget: {
      type: Object,
      'default': (() => {})
    }
  },
  data() {
    return {
      currentGallerySlide: 0,
    }
  },
  computed: {
    ...mapGetters({
      allGalleries: 'galleries/all',
      institutionSettings: 'institution/settings',
      departmentSettings: 'department/settings'
    }),
    useExternalMedia() {
      return get(this.institutionSettings, 'useExternalMedia', false);
    },
    widgetSlideDuration() {
      return this.departmentSettings.screenConfig.carouselDuration * 1000;
    },
    backgroundStylesOuter() {
      return coverImageGradient.get(this.widget.colorHex, true);
    },
    galleries() {
      return this.allGalleries.filter(gallery => {
        if (this.useExternalMedia) {
          return !isEmpty(gallery.settings.dreambrokerUrl)
        }
        return this.allGalleries;
      }).slice(0, this.widget.frWidth * 5)
    }
  },
  methods: {
    slideClicked(slideIndex) {
      const itemId = this.galleries[slideIndex].id;
      this.$emit('slide-clicked', `/galleries/${itemId}`);
    }
  }
}
</script>