<template>
  <div
    class="content mb-14 flex items-center w-full"
  >
    <fa-icon
      class="mr-6"
      :icon="['fal', 'house']"
    />
    <div>
      <p
        v-for="(department, index) in departments"
        :key="index"
        class="font-bold inline-flex mr-4"
        :style="{ color: department.color }"
      >
        {{ department.name }}
      </p>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    departments: {
      type: Array,
      'default': (() => [])
    }
  }
}
</script>