var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-auto" }, [
    _c(
      "div",
      {
        staticClass:
          "flex justify-between self-center px-20 pb-48 -mt-32 rounded-t-3xl",
      },
      [
        _c("div", { staticClass: "largeListItemHeader" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t("scheduleShift.replaceWith") +
                  _vm.data.selectedProfile.displayName
              ) +
              "?\n    "
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "flex justify-center items-center py-24" }, [
      _c(
        "div",
        { staticClass: "profile flex flex-col items-center opacity-60" },
        [
          _c("img", {
            staticClass:
              "profileImage rounded-full shadow-lg object-cover border-8 border-white mb-4 h-60 w-60",
            attrs: { src: _vm.data.selectedShift.employee.displayImage },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "text-2xl font-bold text-center" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.data.selectedShift.employee.displayName) +
                "\n      "
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "profile flex flex-col items-center -ml-12 z-10" },
        [
          _c("img", {
            staticClass:
              "profileImage rounded-full shadow-lg object-cover border-8 border-white mb-4 h-60 w-60",
            attrs: { src: _vm.data.selectedProfile.displayImage },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "text-2xl font-bold text-center" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.data.selectedProfile.displayName) +
                "\n      "
            ),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex justify-center pt-72 gap-3" },
      [
        _c("button-el", {
          staticClass: "self-center",
          attrs: {
            text: "scheduleShift.replace",
            icon: "fa-exchange",
            shadow: true,
            "background-color": "success",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.swap()
            },
          },
        }),
        _vm._v(" "),
        _c("button-el", {
          staticClass: "self-center",
          attrs: {
            text: "global.cancel",
            icon: "undo",
            shadow: true,
            "background-color": "white",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.back()
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }