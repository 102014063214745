var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-row items-center justify-between w-full px-32" },
    [
      _c("button-el", {
        staticClass: "content bold",
        attrs: {
          text: "global.play",
          icon: "play",
          backgroundColor: "success",
        },
        nativeOn: {
          click: function ($event) {
            return _vm.$emit("playVideo")
          },
        },
      }),
      _vm._v(" "),
      _c("button-el", {
        staticClass: "content bold",
        attrs: {
          text: "global.pause",
          icon: "pause",
          backgroundColor: "error",
        },
        nativeOn: {
          click: function ($event) {
            return _vm.$emit("pauseVideo")
          },
        },
      }),
      _vm._v(" "),
      _c("button-el", {
        staticClass: "content bold",
        attrs: {
          text: "global.restart",
          icon: "undo-alt",
          backgroundColor: "warning",
        },
        nativeOn: {
          click: function ($event) {
            return _vm.$emit("resetView")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }