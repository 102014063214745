var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "coverView w-full h-full", class: `bg-${_vm.moduleColor}` },
    [
      _c("div", {
        staticClass:
          "backgroundWrap bg-center bg-no-repeat bg-cover bg-inherit fixed w-full animate__animated animate__fadeIn animate__delay-1s animate__fast",
        style: _vm.styles,
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "viewContent w-full animate__animated animate__slideInUp animate__fast",
          style: { marginTop: `${_vm.marginTop}vh` },
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }