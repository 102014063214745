<template>
  <header :class="{ onStartPage }" class="flex items-center justify-center">
    <img class="logo h-20 w-auto" :src="logoSource" />
  </header>
</template>
<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';
export default {
  computed: {
    ...mapGetters({
      logo: 'institution/logo',
      name: 'institution/name'
    }),
    onStartPage() {
      return this.$route.path === '/';
    },
    logoSource() {
      return get(this, 'logo.source', '');
    }
  }
}
</script>
<style lang="scss">
  header{
    height: 80px;
    &.onStartPage{
      height: 140px;
    }
    .logo{
      max-height: 120px;
    }
  }
</style>
