var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "activity" },
    [
      _vm.showMediaPreview
        ? _c("media-preview", {
            attrs: {
              "selected-item-index": _vm.selectedMediaIndex,
              "media-type": _vm.mediaType,
              items: _vm.mediaItems,
            },
            on: { "close-preview": _vm.closeMediaList },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.activity.id
        ? _c(
            "cover-view",
            {
              attrs: {
                "image-url": _vm.imageUrl,
                gradient: true,
                "module-color": _vm.moduleColor,
                "margin-top": 65,
              },
            },
            [
              _c(
                "div",
                { staticClass: "activityContent pt-20" },
                [
                  _c("media-icons", {
                    attrs: {
                      icons: _vm.mediaIcons,
                      "media-type": _vm.mediaType,
                      amount: _vm.mediaItems.length,
                    },
                    on: { "media-selected": _vm.mediaIconSelected },
                  }),
                  _vm._v(" "),
                  _c("tts", {
                    attrs: {
                      "entity-id": _vm.activity.id,
                      "entity-type": "ACTIVITY",
                    },
                  }),
                  _vm._v(" "),
                  !_vm.showMediaList
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "largeListItemHeader pt-12 mb-10" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.activity.title) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "pictograms inline-flex w-full mb-20",
                          },
                          _vm._l(
                            _vm.activity.pictograms,
                            function (pictogram, index) {
                              return _c("img", {
                                key: index,
                                staticClass: "w-20 mr-5",
                                attrs: {
                                  src: _vm.activity.pictograms[index].source,
                                },
                              })
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "activityDetails" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "activityDescription relative content pb-10 flex items-center w-full",
                              },
                              [
                                _c("fa-icon", {
                                  staticClass: "mr-6 self-start",
                                  attrs: { icon: ["fal", "align-left"] },
                                }),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  { staticClass: "whitespace-pre-line -mt-2" },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.activity.description)),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("date-time", {
                              attrs: {
                                start: _vm.activity.startDate,
                                end: _vm.activity.endDate,
                              },
                            }),
                            _vm._v(" "),
                            _vm.signupExpired
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "relative content pb-10 flex items-center w-full",
                                  },
                                  [
                                    _c("fa-icon", {
                                      staticClass: "mr-6 text-error",
                                      attrs: { icon: ["fal", "alarm-clock"] },
                                    }),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "text-error" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("global.signUpExpired")
                                          ) +
                                          "\n            "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "relative content pb-10 flex items-center w-full",
                                  },
                                  [
                                    _c("fa-icon", {
                                      staticClass: "mr-6",
                                      attrs: { icon: ["fal", "alarm-clock"] },
                                    }),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.formatDeadline(_vm.activity))
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                            _vm._v(" "),
                            !!_vm.activity.price
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "activityPrice relative content pb-10 flex items-center w-full",
                                  },
                                  [
                                    _c("fa-icon", {
                                      staticClass: "mr-6",
                                      attrs: { icon: ["fal", "coins"] },
                                    }),
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$n(
                                            _vm.activity.price[0].price / 100,
                                            "currency"
                                          )
                                        ) +
                                        "\n          "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.activity.participants.length
                              ? _c("people", {
                                  attrs: {
                                    text: "global.participantsPlural",
                                    "hide-participants":
                                      _vm.activityHideSignups,
                                    "module-color": _vm.moduleColor,
                                    profiles: _vm.activity.participants,
                                  },
                                  on: {
                                    "show-people": function ($event) {
                                      return _vm.showProfiles("participants")
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.activity.responsible.length
                              ? _c("people", {
                                  attrs: {
                                    text: "global.responsiblePlural",
                                    "module-color": _vm.moduleColor,
                                    profiles: _vm.activity.responsible,
                                  },
                                  on: {
                                    "show-people": function ($event) {
                                      return _vm.showProfiles("responsible")
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.activity.departments.length
                              ? _c("departments", {
                                  attrs: {
                                    departments: _vm.activity.departments,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.maxSignupsReached
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "relative content pb-10 flex items-center w-full",
                                  },
                                  [
                                    _c("fa-icon", {
                                      staticClass: "mr-6 text-error",
                                      attrs: { icon: ["fal", "user-slash"] },
                                    }),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "text-error" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("global.maxSignupsReached")
                                          ) +
                                          "\n            "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.activity.club
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "activityClub relative content pb-10 flex items-center w-full",
                                  },
                                  [
                                    _c("fa-icon", {
                                      staticClass: "mr-6",
                                      attrs: { icon: ["fal", "icons"] },
                                    }),
                                    _vm._v(" "),
                                    _c("img", {
                                      staticClass:
                                        "w-10 h-10 mr-2 rounded-full inline-block align-middle border border-gray-200",
                                      attrs: {
                                        src: _vm.activity.club.logo.source,
                                      },
                                    }),
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.activity.club.name) +
                                        "\n          "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "relative label flex items-center w-full",
                              },
                              [
                                _c("fa-icon", {
                                  staticClass: "mr-6",
                                  attrs: { icon: ["fal", "hotel"] },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "font-light" }, [
                                  _vm._v(_vm._s(_vm.activity.institution.name)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _c(
                        "div",
                        [
                          _c("media-list", {
                            staticClass: "pt-20",
                            attrs: {
                              items: _vm.mediaItems,
                              "media-type": _vm.mediaType,
                              "can-close": true,
                            },
                            on: {
                              close: _vm.closeMediaList,
                              "item-selected": function ($event) {
                                _vm.selectedMediaIndex = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.showMediaList
                ? _c(
                    "div",
                    {
                      staticClass:
                        "activityActions flex w-max left-full sticky",
                    },
                    [
                      _vm.signupPossible
                        ? _c("button-el", {
                            staticClass: "mr-5 shadow-xsm",
                            attrs: {
                              text: "global.signUp",
                              icon: "check",
                              "background-color": "success",
                              "text-color": "white",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.optInOrOut("in")
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.optoutPossible
                        ? _c("button-el", {
                            staticClass: "shadow-xsm",
                            attrs: {
                              text: "global.optOut",
                              icon: "minus-circle",
                              "background-color": "error",
                              "text-color": "white",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.optInOrOut("out")
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }