var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "media-list",
      class: {
        galleries:
          _vm.data.mediaType === "galleries" ||
          _vm.data.mediaType === "externalMedia",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "flex w-full h-12 mb-16 justify-between",
          class: { "px-10": _vm.data.mediaType === "galleries" },
        },
        [
          _c("div", { staticClass: "largeListItemHeader" }, [
            _vm.data.mediaType === "pictures"
              ? _c("p", [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("global.images")) + "\n      "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.data.mediaType === "galleries"
              ? _c("p", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("ourHome.galleries")) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.data.mediaType === "videos"
              ? _c("p", [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("ourHome.videos")) + "\n      "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.data.mediaType === "documents"
              ? _c("p", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("ourHome.documents")) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.canClose
            ? _c("fa-icon", {
                staticClass: "text-5xl h-auto font-bold",
                attrs: { icon: ["fal", "times"] },
                on: { click: _vm.close },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.data.mediaType === "pictures"
        ? _c(
            "div",
            { staticClass: "grid grid-cols-2 gap-3 object-cover" },
            [
              !_vm.data.items.length
                ? _c("div", { staticClass: "header" }, [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("media.noImages")) + "\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.data.items, function (image, index) {
                return _c("img", {
                  key: index,
                  staticClass: "object-cover h-80 w-full",
                  attrs: { src: image.source },
                  on: {
                    click: function ($event) {
                      return _vm.mediaSelected(index)
                    },
                  },
                })
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.data.mediaType === "galleries" ||
      _vm.data.mediaType === "externalMedia"
        ? _c(
            "div",
            [
              !_vm.data.items.length
                ? _c("div", { staticClass: "header" }, [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("media.noImages")) + "\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("grid-view", {
                attrs: {
                  items: _vm.addMetaToGalleries(_vm.data.items),
                  "item-type": "gallery",
                },
                on: {
                  "item-selected": function ($event) {
                    return _vm.showSingleGallery($event)
                  },
                },
              }),
            ],
            1
          )
        : _vm.data.mediaType === "videos"
        ? _c(
            "div",
            { staticClass: "w-full" },
            [
              !_vm.data.items.length
                ? _c("div", { staticClass: "header" }, [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("media.noVideos")) + "\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.data.items, function (video, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "video w-full mb-3 relative",
                    on: {
                      click: function ($event) {
                        return _vm.mediaSelected(index)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "rounded-full bg-white absolute w-20 h-20 right-10 bottom-10 flex items-center justify-center",
                      },
                      [
                        _c("fa-icon", {
                          staticClass: "text-5xl",
                          attrs: { icon: ["fal", "caret-right"] },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "w-full h-full object-cover",
                      attrs: { src: video.thumbnail },
                    }),
                  ]
                )
              }),
            ],
            2
          )
        : _vm.mediaType === "documents"
        ? _c(
            "div",
            { staticClass: "object-cover" },
            [
              !_vm.data.items.length
                ? _c("div", { staticClass: "header" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("media.noDocuments")) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.data.items, function (document, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "flex items-center justify-between mb-12",
                    on: {
                      click: function ($event) {
                        return _vm.mediaSelected(index)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex items-center" },
                      [
                        _c("fa-icon", {
                          staticClass: "mr-12 text-4xl",
                          attrs: { icon: ["fal", "file-alt"] },
                        }),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass: "font-bold",
                            class: `text-${_vm.moduleColor}`,
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(document.title) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("fa-icon", {
                      staticClass: "text-4xl self-end",
                      attrs: { icon: ["fal", "angle-right"] },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _c("div", [
            _vm.data.mediaType === "media"
              ? _c(
                  "div",
                  { staticClass: "grid grid-cols-2 gap-3 object-cover" },
                  [
                    !_vm.data.items.length
                      ? _c("div", { staticClass: "header" }, [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("media.noImages")) +
                              "\n      "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.data.items, function (item, index) {
                      return _c("div", { key: index }, [
                        item.__typename === "GalleryPicture"
                          ? _c("img", {
                              staticClass: "object-cover h-80 w-full",
                              attrs: { src: item.source },
                              on: {
                                click: function ($event) {
                                  return _vm.mediaSelected(index)
                                },
                              },
                            })
                          : _c("div", { staticClass: "relative" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rounded-full bg-white absolute w-20 h-20 right-10 bottom-10 flex items-center justify-center",
                                },
                                [
                                  _c("fa-icon", {
                                    staticClass: "text-5xl",
                                    attrs: { icon: ["fal", "caret-right"] },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "w-full object-cover h-80",
                                attrs: { src: item.thumbnail },
                                on: {
                                  click: function ($event) {
                                    return _vm.mediaSelected(index)
                                  },
                                },
                              }),
                            ]),
                      ])
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }