<template>
  <footer class="flex charcoal px-8 py-6">
    <footer-date-time v-if="!useGlobalDepartmentSwitcher" />
    <department-switcher v-if="useGlobalDepartmentSwitcher" />
    
    <div class="actions flex-grow flex justify-center items-center text-3xl gap-3">
      <div
        v-if="!onStartPage"
        class="action flex justify-center items-center rounded-lg border border-charcoal border-opacity-10"
        @click="goBackInHistory"
      >
        <fa-icon :icon="['fal', 'arrow-left']" />
      </div>

      <router-link
        :class="{['bg-charcoal']: onStartPage }"
        to="/"
        class="action flex justify-center items-center rounded-lg border bg-opacity-5 border-charcoal border-opacity-10"
        @click.native="trackEvent"
      >
        <img
          src="../../../assets/img/ibg_logo.svg"
          class="w-10 h-10"
        >
      </router-link>

      <div
        class="dimmer action flex justify-center items-center border border-charcoal rounded-lg bg-opacity-5 border-opacity-10" 
        :class="{['bg-charcoal']: showDimmerSlider }"
        @click="showDimmerSlider = !showDimmerSlider"
      >
        <div
          v-if="showDimmerSlider"
          class="dimmerSlider absolute w-96 -mt-40 bg-charcoal px-6 py-4 rounded-xl z-40 shadow-lg"
        >
          <vue-slider
            v-clickoutside="{ excludeString: '.dimmer, .dimmer>*', handler: onClickoutside }"
            v-bind="sliderOptions"
            :value="dimmerOpacity"
            :rail-style="{ backgroundColor: 'white'}"
            :process-style="{ backgroundColor: 'grey'}"
            direction="rtl"
            @change="dimScreen"
          />
        </div>

        <div
          v-if="showDimmerSlider"
          class="w-96 h-12 -mt-28 absolute flex justify-center"
        >
          <span class="w-12 h-12 bg-charcoal absolute transform rotate-45 shadow-lg" />
        </div>

        <fa-icon :icon="['far', 'lightbulb-slash']" />
      </div>
    </div>

    <video
      v-show="false"
      ref="video"
    />

    <footer-date-time
      v-if="useGlobalDepartmentSwitcher"
      class="text-right"
    />
    <div class="clock flex-none text-right flex flex-col justify-center">
      <flip-clock />
    </div>

    <feedback-dialog
      v-if="showFeedbackDialog"
      @cancel="showFeedbackDialog=false"
      @startRecording="startFeedbackCapture"
    />
    <stop-feedback-dialog
      v-if="showStopFeedbackDialog"
      @cancel="showStopFeedbackDialog=false"
      @stopRecording="stopFeedbackCapture"
    />
    <recording-notification v-if="isRecording" />
  </footer>
</template>
<script>

import { mapGetters } from 'vuex';
import { format } from '@/utils/date-fns';
import { capitalize, get } from 'lodash';
import FlipClock from '@/components/shared/FlipClock';
import FeedbackDialog from '@/components/shared/FeedbackDialog';
import StopFeedbackDialog from '@/components/shared/StopFeedbackDialog';
import RecordingNotification from '@/components/shared/RecordingNotification';
import FooterDateTime from '@/components/shared/FooterDateTime';
import DepartmentSwitcher from '@/components/shared/DepartmentSwitcher';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
import colors from '@/utils/colors';

export default {
  components: {
    FlipClock,
    FeedbackDialog,
    StopFeedbackDialog,
    RecordingNotification,
    VueSlider,
    FooterDateTime,
    DepartmentSwitcher
  },
  props: {
    dimmerOpacity: {
      type: Number,
      'default': 0
    }
  },
  data() {
   return {
      showFeedbackDialog: false,
      showStopFeedbackDialog: false,
      isRecording: false,
      mediaRecorder: null,
      recordedChunks: [],
      recordingNote: '',
      showDimmerSlider: false,
      sliderOptions: {
        dotSize: 30,
        width: 'auto',
        height: 10,
        min: 0,
        max: 90,
        interval: 1,
        clickable: true,
        duration: 0.5,
        tooltip: 'none',
        railStyle: { 'background-color': colors['charcoal-light'] },
        processStyle: { 'background-color': 'white' },
        dotStyle: { 'border-color': 'white' }
      }
    }
  },
  computed: {
    ...mapGetters({
      activeOverlay: 'general/activeOverlay',
      institutionSettings: 'institution/settings',
      departmentSettings: 'department/settings'
    }),
    useGlobalDepartmentSwitcher() {
      return get(this.departmentSettings, 'screenConfig.departmentSwitcher', false);
    },
    onStartPage() {
      return this.$route.path === '/';
    },
    hasActiveOverlay() {
      return !!this.activeOverlay.name;
    },
    canGoBackInHistory() {
      return (window.history.length && !this.onStartPage) || this.hasActiveOverlay;
    },
    dayOfWeek() {
      return capitalize(format(new Date(), 'EEEE'));
    },
    date() {
      return format(new Date(), 'd MMMM');
    }
  },
  methods: {
    goBackInHistory() {
      if (this.hasActiveOverlay) {
        this.$matomo.trackEvent('Navigation', 'GoBack', 'BackButtonOverlayOpen');
        this.$emit('close-overlay', true);
      } else {
        this.$matomo.trackEvent('Navigation', 'GoBack', 'BackButton');
        if (this.canGoBackInHistory) this.$router.back();
      }
    },
    feedbackAction() {
      if (this.isRecording) {
        this.showStopFeedbackDialog = true;
        return;
      }

      this.showFeedbackDialog = true;
    },
    async startFeedbackCapture() {
      this.$matomo.trackEvent('Feedback', 'Started', 'FeedbackStart');
      this.showFeedbackDialog = false;
      if(!window.ipc) return;

      const inputSources = await window.desktopCapturer.getSources({ types: ['window'] });

      const sourceId = inputSources[0].id;
      const constraints = {
        audio: false,
        video: {
          mandatory: {
            chromeMediaSource: 'desktop',
            chromeMediaSourceId: sourceId
          }
        }
      };
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        await this.handleAudioStream(stream);
        // Create the Media Recorder
        const options = { mimeType: 'video/webm; codecs=vp9' };
        this.mediaRecorder = new MediaRecorder(stream, options);

        // Register Event Handlers
        this.mediaRecorder.ondataavailable = e => {
          this.recordedChunks.push(e.data);
        };

        this.mediaRecorder.onstop = this.handleStop;

        this.recordedChunks = [];
        this.recordingNote = '';
        this.mediaRecorder.start();
        this.isRecording = true;
      } catch(e) {
        console.log(e);
      }
    },
    async handleAudioStream(stream) {
      try {
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false});
        const audioTracks = audioStream.getAudioTracks();

        // mix audio tracks
        if(audioTracks.length > 0) {
          var mixAudioTrack = this.mixTracks(audioTracks);
          stream.addTrack(mixAudioTrack);
        }
      } catch(e){
        console.log(e);
      }
    },
    async stopFeedbackCapture(note) {
      this.showStopFeedbackDialog = false;
      this.recordingNote = note;
      this.mediaRecorder.stop();
      this.mediaRecorder = null;
      this.isRecording = false;
    },
    async handleStop(e) {
      try {
        const blob = new Blob(this.recordedChunks, {
          type: 'video/webm; codecs=vp9'
        });

        this.recordedChunks = [];
        const buffer = Buffer.from(await blob.arrayBuffer());
        window.ipc.send('save-video', { buffer, note: this.recordingNote });
        this.recordingNote = '';
      } catch(e) {
        console.log(e);
      }
    },
    mixTracks(tracks) {
      const ac = new AudioContext();
      const dest = ac.createMediaStreamDestination();
      for(var i=0;i<tracks.length;i++) {
        const source = ac.createMediaStreamSource(new MediaStream([tracks[i]]));
        source.connect(dest);
      }
      return dest.stream.getTracks()[0];
    },
    trackEvent() {
      this.$matomo.trackEvent('Navigation', 'GoToStartPage', 'IBGLogo');
    },
    dimScreen(opacity) {
      this.$emit('dimScreen', opacity);
    },
    accessibilityClicked() {
      this.$matomo.trackEvent('Accessibility', 'Clicked', 'AccessibilityOpened');
    },
    onClickoutside() {
      if (this.showDimmerSlider) {
        this.showDimmerSlider = false;
      }
    },
  }
}
</script>
<style lang="scss">

  footer {
    height: 140px;

    .date {
      letter-spacing: -0.03em;
      width: 150px;
    }

    .clock {
      width: 150px;
    }

    .action {
      width: 80px;
      height: 80px;

      &.disabled{
        opacity: 0.15;
      }
    }

    .profileImage {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
</style>


098poiælk
