import Vue from 'vue';
import VueRouter from 'vue-router';

import StartPage from '@/views/start-page/StartPage';
import WorkSchedule from '@/views/workSchedule/WorkSchedule';
import SimpleWorkSchedule from '@/views/workSchedule/SimpleWorkSchedule';
import Bookings from '@/views/booking/Bookings';
import BookingEntry from '@/views/booking/BookingEntry';
import BookingResourceEntry from '@/views/booking/BookingResourceEntry';
import MealPlan from '@/views/meals/MealPlan';
import MealEntry from '@/views/meals/MealEntry';
import Activities from '@/views/activities/Activities';
import ActivityEntry from '@/views/activities/ActivityEntry';
import Galleries from '@/views/gallery/Galleries';
import GalleryEntry from '@/views/gallery/GalleryEntry';
import Meetings from '@/views/meetings/Meetings';
import MeetingEntry from '@/views/meetings/MeetingEntry';
import News from '@/views/news/News';
import NewsEntry from '@/views/news/NewsEntry';
import OurHome from '@/views/ourHome/OurHome';
import ProfileEntry from '@/views/ourHome/ProfileEntry';

import { getModuleData } from '@/utils/modules';

Vue.use(VueRouter);

const moduleData = getModuleData();

const routes = [
  {
    path: '/',
    name: 'Start',
    component: StartPage
  },
  {
    path: '/workSchedule',
    name: 'WORK_SCHEDULE',
    component: WorkSchedule,
    meta: {
      color: moduleData.WORK_SCHEDULE.colorId,
      icon: moduleData.WORK_SCHEDULE.icon,
      scrollUp: false
    }
  },
  {
    path: '/simpleWorkSchedule',
    name: 'SIMPLE_WORK_SCHEDULE',
    component: SimpleWorkSchedule,
    meta: {
      color: moduleData.SIMPLE_WORK_SCHEDULE.colorId,
      icon: moduleData.WORK_SCHEDULE.icon,
      scrollUp: true
    }
  },
  {
    path: '/bookings',
    name: 'BOOKING',
    component: Bookings,
    meta: {
      color: moduleData.BOOKING.colorId,
      icon: moduleData.BOOKING.icon,
      scrollUp: true,
      contentViews: [
        {
          name: 'list',
          icon: 'list',
          cardType: 'horizontal',
          position: 'center'
        },
        {
          name: 'grid',
          icon: 'th',
          cardType: 'vertical',
          position: 'center'
        },
        {
          name: 'category',
          icon: 'calendar-plus',
          cardType: 'square',
          position: 'right'
        }
      ]
    }
  },
  {
    path: '/bookings/booking/:id',
    name: 'BOOKING_ENTRY',
    component: BookingEntry,
    meta: {
      subView: true,
      hideInModuleList: true,
      color: moduleData.BOOKING.colorId,
      scrollUp: false
    }
  },
  {
    path: '/bookings/resource/:id',
    name: 'BOOKING_RESOURCE_ENTRY',
    component: BookingResourceEntry,
    meta: {
      subView: true,
      hideInModuleList: true,
      color: moduleData.BOOKING.colorId,
      scrollUp: false
    }
  },
  {
    path: '/ourHome',
    name: 'OUR_HOME',
    component: OurHome,
    meta: {
      color: moduleData.OUR_HOME.colorId,
      icon: moduleData.OUR_HOME.icon,
      scrollUp: false
    }
  },
  {
    path: '/ourHome/:id',
    name: 'PROFILE',
    component: ProfileEntry,
    meta: {
      color: moduleData.OUR_HOME.colorId,
      subView: true,
      hideInModuleList: true,
      scrollUp: false
    }
  },
  {
    path: '/mealPlan',
    name: 'MEAL_PLAN',
    component: MealPlan,
    meta: {
      color: moduleData.MEAL_PLAN.colorId,
      icon: moduleData.MEAL_PLAN.icon,
      scrollUp: true,
      contentViews: [
        {
          name: 'list',
          icon: 'list',
          cardType: 'horizontal',
          position: 'center'
        },
        {
          name: 'grid',
          icon: 'th',
          cardType: 'vertical',
          position: 'center'
        }
      ]
    }
  },
  {
    path: '/mealPlan/:id',
    name: 'MEAL_PLAN_ENTRY',
    component: MealEntry,
    meta: {
      subView: true,
      hideInModuleList: true,
      color: moduleData.MEAL_PLAN.colorId,
      scrollUp: false
    }
  },
  {
    path: '/activities',
    name: 'ACTIVITIES',
    component: Activities,
    meta: {
      color: moduleData.ACTIVITIES.colorId,
      icon: moduleData.ACTIVITIES.icon,
      scrollUp: true,
      contentViews: [
        {
          name: 'list',
          icon: 'list',
          cardType: 'horizontal',
          position: 'center'
        },
        {
          name: 'grid',
          icon: 'th',
          cardType: 'vertical',
          position: 'center'
        }
      ]
    }
  },
  {
    path: '/activities/:id',
    name: 'ACTIVITIES_ENTRY',
    component: ActivityEntry,
    meta: {
      subView: true,
      hideInModuleList: true,
      color: moduleData.ACTIVITIES.colorId,
      scrollUp: false
    }
  },
  {
    path: '/externalActivities',
    name: 'EXTERNAL_ACTIVITIES',
    component: Activities,
    meta: {
      isExternal: true,
      connectionIndex: 0,
      color: moduleData.EXTERNAL_ACTIVITIES.colorId,
      icon: moduleData.EXTERNAL_ACTIVITIES.icon,
      scrollUp: true,
      contentViews: [
        {
          name: 'list',
          icon: 'list',
          cardType: 'horizontal',
          position: 'center'
        },
        {
          name: 'grid',
          icon: 'th',
          cardType: 'vertical',
          position: 'center'
        }
      ]
    }
  },
  {
    path: '/externalActivities/:id',
    name: 'ACTIVITIES_ENTRY',
    component: ActivityEntry,
    meta: {
      subView: true,
      hideInModuleList: true,
      color: moduleData.EXTERNAL_ACTIVITIES.colorId,
      scrollUp: false
    }
  },
  {
    path: '/galleries',
    name: 'GALLERY',
    component: Galleries,
    meta: {
      color: moduleData.GALLERY.colorId,
      icon: moduleData.GALLERY.icon,
      scrollUp: true,
      contentViews: [
        {
          name: 'list',
          icon: 'list',
          cardType: 'horizontal',
          position: 'center'
        },
        {
          name: 'grid',
          icon: 'th',
          cardType: 'vertical',
          position: 'center'
        }
      ]
    }
  },
  {
    path: '/galleries/:id',
    name: 'GALLERY_ENTRY',
    component: GalleryEntry,
    meta: {
      subView: true,
      hideInModuleList: true,
      color: moduleData.GALLERY.colorId,
      scrollUp: false
    }
  },
  {
    path: '/meetings',
    name: 'MEETINGS',
    component: Meetings,
    meta: {
      color: moduleData.MEETINGS.colorId,
      icon: moduleData.MEETINGS.icon,
      scrollUp: true,
      contentViews: [
        {
          name: 'list',
          icon: 'list',
          cardType: 'horizontal',
          position: 'center'
        },
        {
          name: 'grid',
          icon: 'th',
          cardType: 'vertical',
          position: 'center'
        }
      ]
    }
  },
  {
    path: '/meetings/:id',
    name: 'MEETING_ENTRY',
    component: MeetingEntry,
    meta: {
      subView: true,
      hideInModuleList: true,
      color: moduleData.MEETINGS.colorId,
      scrollUp: false
    }
  },
  {
    path: '/news',
    name: 'NEWS',
    component: News,
    meta: {
      color: moduleData.NEWS.colorId,
      icon: moduleData.NEWS.icon,
      scrollUp: true,
      contentViews: [
        {
          name: 'list',
          icon: 'list',
          cardType: 'horizontal',
          position: 'center'
        },
        {
          name: 'grid',
          icon: 'th',
          cardType: 'vertical',
          position: 'center'
        }
      ]
    }
  },
  {
    path: '/news/:id',
    name: 'NEWS_ENTRY',
    component: NewsEntry,
    meta: {
      subView: true,
      hideInModuleList: true,
      color: moduleData.NEWS.colorId,
      scrollUp: false
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
