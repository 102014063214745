<template>
  <div class="carouselControls flex flex-row items-center justify-between w-full px-32 h-32">
    <!-- Autoplay -->
    <div class="text-white w-2/5">
      <div v-if="mediaType === 'pictures'">
        <!-- Autoplay started -->
        <div
          v-if="autoplay"
          class="w-48"
        >
          <div
            class="rounded-md h-5 p-1 w-full mb-2"
            :style="{ backgroundColor: progressBackColor }"
          >
            <div
              class="rounded-tl-md rounded-bl-md h-full transition-all"
              :class="progressPercent === '100%' ? 'rounded-tr-md rounded-br-md': 'rounded-tr-none rounded-br-none'"
              :style="{ backgroundColor: progressFrontColor, width: progressPercent }"
            />
          </div>
          <button-el
            class="content bold"
            text="global.stop"
            icon="square"
            background-color="error"
            @click.native="$emit('autoplay-toggled')"
          />
        </div>

        <!-- Autoplay stopped -->
        <div
          v-else
          class="flex items-center"
          @click="$emit('autoplay-toggled')"
        >
          <fa-icon
            class="text-white text-3xl mr-2"
            :icon="['far', 'play-circle']"
          />
          <span class="content bold">{{ $t('global.autoplay') }}</span>
        </div>
      </div>
    </div>

    <!-- Navigation -->
    <div
      v-if="!hideNavigation"
      class="text-white text-center w-1/5 flex justify-between items-center"
    >
      <fa-icon
        class="text-white content light text-2xl"
        :style="{ 'opacity': !canGoPrevious ? 0.5 : 1 }"
        :icon="['fa', 'chevron-left']"
        @click="goToPrevious"
      />

      <span class="content">{{ currentSlide }} / {{ totalSlides }}</span>

      <fa-icon
        class="text-white text-2xl content light"
        :style="{ 'opacity': !canGoNext ? 0.5 : 1 }"
        :icon="['fa', 'chevron-right']"
        :disabled="currentSlide === totalSlides"
        @click="goToNext"
      />
    </div>
      
    <!-- Close -->
    <div class="w-2/5 flex justify-end">
      <button-el
        class="content bold"
        text="global.close"
        icon="times"
        :padding="false"
        text-color="white"
        @click.native="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/shared/Button';
import { hexToRgbA } from '@/utils/helpers.js';

export default {
  components: {
    ButtonEl: Button
  },
  props: {
    totalSlides: {
      type: Number,
      'default': 0
    },
    currentSlide: {
      type: Number,
      'default': 1
    },
    mediaType: {
      type: String,
      'default': ''
    },
    autoplay: {
      type: Boolean,
      'default': true
    },
    hideNavigation: {
      type: Boolean,
      'default': false
    }
  },
  computed: {
    canGoPrevious() {
      return this.currentSlide > 1;
    },
    canGoNext() {
      return this.currentSlide < this.totalSlides;
    },
    progressBackColor() {
      return hexToRgbA('#fff', 0.1);
    },
    progressFrontColor() {
      return hexToRgbA('#fff', 0.2);
    },
    progressPercent() {
      return `${this.currentSlide / this.totalSlides * 100}%`;
    }
  },
  methods: {
    goToPrevious() {
      if (!this.canGoPrevious) return;
      this.$emit('previous');
    },
    goToNext() {
      if (!this.canGoNext) return;
      this.$emit('next');
    }
  }
}
</script>