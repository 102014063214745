import { apolloClient } from '../../utils/apollo';
import store from '@/store';
import { ls } from '@/utils/storage';
import constants from '@/utils/constants';
import { get } from 'lodash';

const state = {
  all: [],
  internal: [],
  external: [],
  selected: {}
};

const mutations = {
  SET_ALL_ACTIVITIES(state, activities) {
    state.all = activities;
  },
  SET_INTERNAL_ACTIVITIES(state, activities) {
    state.internal = activities;
  },
  SET_EXTERNAL_ACTIVITIES(state, activities) {
    state.external = activities;
  },
  SET_SELECTED_ACTIVITY(state, activity) {
    state.selected = activity;
  }
};

const actions = {
  async getActivities({commit}) {
    const departmentId = store.getters['department/id'];
    
    if (!departmentId) {
      throw new Error('No department id provided');
    }
    
    const dateNow = new Date();
    const future90days = dateNow.setDate(dateNow.getDate() + 90);
    const dateEnd = new Date(future90days).toISOString().split('T')[0];

    await apolloClient
      .query({
        query: require('@/graphql/queries/activityRange').default,
        variables: {
          filter: {
            departments: departmentId
          },
          dateFrom: new Date().toISOString().split('T')[0],
          dateTo: dateEnd,
          timezone: 'Europe/Copenhagen',
        },
        fetchPolicy: 'no-cache'
      })
      .then(response => {
        const allActivities = response.data.activityRange;
        commit('SET_ALL_ACTIVITIES', allActivities);

        const externalConnections = store.getters['institution/externalConnections'];

        if (externalConnections.length) {
          const externalActivityInstitutionId = get(externalConnections, '[0.institution.id', -1);

          const externalActivities = allActivities.filter(activity => activity.institution.id === externalActivityInstitutionId);
          commit('SET_EXTERNAL_ACTIVITIES', externalActivities);

          const internalActivities = allActivities.filter(activity => activity.institution.id !== externalActivityInstitutionId);
          commit('SET_INTERNAL_ACTIVITIES', internalActivities);

          return;
        }

        commit('SET_INTERNAL_ACTIVITIES', allActivities);
        
      })
      .catch(error => {
        console.log('Error getting activities', error);
      })
  },
  async getSelectedActivity({commit}, activityId) {
    commit('SET_SELECTED_ACTIVITY', {});
    await apolloClient
      .query({
        query: require('@/graphql/queries/activityEntry').default,
        variables: {
          id: activityId
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const activityEntry = response.data.activityEntry;

        const updatedResponse = {
          data: {
            activityEntry: {
              ...activityEntry,
              participants: activityEntry.participants.map(participant => {
                if (!participant.showImage) participant.displayImage = constants.avatarPlaceholder;
                return participant;
              })
            }
          }
        }
        
        commit('SET_SELECTED_ACTIVITY', updatedResponse.data.activityEntry);
      }).catch(error => {
        console.log('Could not get activity entry: ', error);
      });
  },
  async activitySignupScreen({commit}, { subscription, profile, opt }) {
    const mutation = opt === 'in' ? 'activitySignupScreen' : 'activityCancelSignupScreen';

    await apolloClient
      .mutate({
        mutation: require(`@/graphql/mutations/${mutation}`).default,
        variables: {
          subscription,
          profile
        },
      })
      .then(async () => {
        await store.dispatch('profiles/setPinValidation', { validating: false, validPin: true });
        store.dispatch('activities/getSelectedActivity', subscription.id);
      })
      .catch(error => {
        store.dispatch('profiles/setPinValidation', { validating: false, validPin: false });
        throw new Error('Error signing up to activity', error);
      });
  }
};

const getters = {
  all: state => state.all,
  internal: state => state.internal,
  external: state => state.external,
  selected: state => state.selected
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
