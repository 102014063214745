import gql from 'graphql-tag';

export default gql`
query bookingSlots ($timezone: TimeZone!, $bookingResourceId: ID!, $dateFrom: Date!, $dateTo: Date!) {
    bookingSlots (bookingResourceId: $bookingResourceId, dateFrom: $dateFrom, dateTo: $dateTo, timezone: $timezone) {
        description
        end
        id
        participants {
            ... on Employee {
                id
                displayImage
            }
            ... on Resident {
                id
                displayImage
            }
        }
        participating
        start
        state
    }
}`;