var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "text-white rounded-lg px-6 py-4 flex items-center justify-center",
      class: [{ "shadow-m": _vm.shadow }, `bg-${_vm.color}`],
    },
    [
      _c("fa-icon", {
        staticClass: "text-white text-2xl mr-4",
        attrs: { icon: ["fal", `${_vm.icon}`] },
      }),
      _vm._v(" "),
      _c("p", { staticClass: "font-bold text-2xl" }, [
        _vm._v("\n    " + _vm._s(_vm.$t(_vm.text)) + "\n  "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }