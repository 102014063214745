import general from '@/store/modules/general';
import activities from '@/store/modules/activities';
import institution from '@/store/modules/institution';
import profiles from '@/store/modules/profiles';
import galleries from '@/store/modules/galleries';
import news from '@/store/modules/news';
import department from '@/store/modules/department';
import meals from '@/store/modules/meals';
import workSchedule from '@/store/modules/workSchedule';
import meetings from '@/store/modules/meetings';
import bookingResources from '@/store/modules/bookingResources';
import tts from '@/store/modules/tts';
import ourHome from '@/store/modules/ourHome';
import journeyPlanner from '@/store/modules/journeyPlanner';

export const config = {
  modules: {
    general,
    institution,
    activities,
    profiles,
    galleries,
    news,
    department,
    meals,
    workSchedule,
    meetings,
    bookingResources,
    tts,
    ourHome,
    journeyPlanner
  }
};
