
<template>
  <div
    class="activitiesWrapper"
    :class="{ 'verticalCenter flex items-center flex-col justify-center items-stretch': verticalCenterGrid || verticalCenterList }"
  >
    <div
      v-if="activities.length"
      class="activities bg-white"
    >
      <list-view
        v-if="activeContentView.name === 'list'"
        :day-items="activityDays"
        :width-percent="80"
        :sort-by-day="true"
        @item-selected="showSingleActivity($event)"
      />

      <grid-view
        v-if="activeContentView.name === 'grid'"
        :items="activities"
        item-type="activity"
        @item-selected="showSingleActivity($event)"
      />
    </div>

    <empty-views
      v-else
      view-type="Activities"
      :color="moduleColor"
    />
  </div>
</template>

<script>
import { isFuture } from 'date-fns';
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import ListView from '@/components/shared/ListView';
import GridView from '@/components/shared/GridView';
import { isItemToday, formatTime } from '@/utils/helpers.js';
import EmptyViews from '@/views/shared/EmptyViews';

export default {
  components: {
    ListView,
    GridView,
    EmptyViews
  },
  data() {
    return {
      selectedClubIndex: -1,
      topVisibleDay: null,
      singleActivityShown: false,
      selectedDate: new Date()
    };
  },
  computed: {
    ...mapGetters({
      internalActivities: 'activities/internal',
      externalActivities: 'activities/external',
      activeViewType: 'general/activeViewType',
      activeContentView: 'general/activeContentView',
      activeItemFilter: 'general/activeItemFilter',
      institutionId: 'institution/id'
    }),
    moduleColor() {
      return this.$route?.meta?.color;
    },
    contentViews() {
      return this.$route?.meta?.contentViews;
    },
    activityDays() {
      const activities = cloneDeep(this.activities);
      return activities.reduce((accumulator, current, index) => {
        const uniqueDate = current.startDate.split('T')[0];

        if (accumulator[uniqueDate] || (accumulator[uniqueDate]=[])) {
          accumulator[uniqueDate].push(current);

          accumulator[uniqueDate].meta = {
            startDate: current.startDate,
            showTodayLabel: index === 0 && isItemToday(current.startDate)
          }
        }

        return accumulator;
      }, {});
    },
    activities() {
      const activities = this.$route.path === '/activities' ? this.internalActivities : this.externalActivities;

      return activities.filter(activity => isFuture(new Date(activity.endDate)))
        .map(activity => {
          return {
            ...activity,
            meta: {
              icon: 'clock',
              text: formatTime(activity.startDate)
            }
          }
        });
    },
    verticalCenterList() {
      return this.activeViewType === 'list' && this.activities.length < 3;
    },
    verticalCenterGrid() {
      return (this.activeViewType === 'grid' && this.activities.length < 10) || (this.activeContentView.name === 'filter');
    }
  },
  async mounted() {
    this.$store.dispatch('general/setActiveContentView', this.$route.meta?.contentViews[this.activities.length > 3 ? 1 : 0]);
    await this.$store.dispatch('activities/getActivities'),
    await this.$store.dispatch('general/setActiveViewType', this.activities.length > 3 ? 'grid' : 'list');
  },
  methods: {
    showSingleActivity(activity) {
      const dayLabels = document.getElementsByClassName('dayLabelWrapper');

      for (let item of dayLabels) {
        item.style.zIndex = '0';
      }

      if (this.$route.path === '/activities') {
        this.$router.push(`/activities/${activity.id}`);
      } else {
        this.$router.push(`/externalActivities/${activity.id}`);
      }
    },
    clubSelected(index) {
      this.selectedClubIndex = index;
      const contentView = this.contentViews.find(filter => filter.name === this.activeViewType);
      this.$store.dispatch('general/setActiveContentView', contentView);
    },
    formatTime(activity) {
      if(activity.cancelled) return this.$t('global.cancelled');
      return formatTime(activity);
    },
  }
};
</script>

<style lang="scss">
  .activitiesWrapper {
    position: relative;
    background: none;
    padding: 130px 0;

    &.verticalCenter .dayLabelWrapper {
      padding: 0;
    }

    &.gridView {
      padding-top: 15px;
    }
  }

</style>
