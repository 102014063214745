<template>
  <div class="profile">
    <media-preview
      v-if="showMediaPreview"
      :selected-item-index="selectedMediaIndex"
      :media-type="mediaType"
      :items="mediaItems"
      :is-in-my-archive="true"
      @close-preview="selectedMediaIndex = -1"
    />
    <cover-view
      v-if="!isLoading"
      :image-url="profile.displayImage"
      :module-color="moduleColor"
      :margin-top="60"
    >
      <div class="profileContent pt-20">
        <media-icons
          v-if="showResInfoDefault"
          :icons="mediaIcons"
          :media-type="mediaType"
          :amount="documents.length"
          :color="moduleColor"
          @media-selected="mediaIconSelected"
        />

        <div v-if="!showMediaList">
          <div class="largeListItemHeader pt-12 mb-20">
            {{ profile.displayName }}
          </div>
  
          <!-- Title -->
          <basic-block
            v-if="isEmployee"
            :text="profile.title"
            icon="tag"
          />
  
          <!-- Description -->
          <description
            v-if="profile.description"
            :description="profile.description"
          />
  
          <!-- Birthday -->
          <basic-block
            v-if="profile.showBirthday && profile.birthday"
            :text="getBirthday(profile.birthday)"
            icon="birthday-cake"
          />
  
          <!-- Departments -->
          <departments
            v-if="profile.departments.length"
            :departments="profile.departments"
          />
  
          <!-- Phone -->
          <basic-block
            v-if="profile.showMobile && profile.mobile"
            :text="profile.mobile"
            icon="mobile"
          />
  
          <!-- Email -->
          <basic-block
            v-if="profile.showEmail && profile.email"
            :text="profile.email"
            icon="envelope"
          />
  
          <!-- Home days -->
          <basic-block
            v-if="profile.type === 'RESIDENT' && profile.homeDays.length"
            class="capitalize"
            :text="homeDays"
            icon="street-view"
          />
        </div>

        <media-list
          v-else
          :items="mediaItems"
          :media-type="mediaType"
          class="pt-20"
          :can-close="true"
          @close="closeMediaList"
          @item-selected="selectedMediaIndex = $event"
        />
      </div>
    </cover-view>
  </div>
</template>

<script>
import CoverView from '@/views/shared/CoverView';
import { mapGetters } from 'vuex';
import { get } from 'lodash';
import { format } from '@/utils/date-fns';
import MediaIcons from '@/components/shared/MediaIcons';
import Description from '@/components/shared/blocks/Description';
import BasicBlock from '@/components/shared/blocks/BasicBlock';
import Departments from '@/components/shared/blocks/Departments';
import MediaPreview from '@/views/overlays/MediaPreview';
import MediaList from '@/views/shared/MediaList';
import PinFlow from '@/utils/pinFlow';

export default {
  components: {
    CoverView,
    MediaIcons,
    Description,
    BasicBlock,
    Departments,
    MediaPreview,
    MediaList
  },
  data() {
    return {
      mediaType: '',
      selectedMediaIndex: -1,
      isLoading: true,
      showMediaList: false,
      hideMediaPreview: false,
      selectedItemIndex: -1
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profiles/activeProfile',
      myArchive: 'profiles/myArchive',
      institutionSettings: 'institution/settings',
      pinValidation: 'profiles/pinValidation',
      departments: 'institution/departments'
    }),
    moduleColor() {
      return this.$route?.meta?.color;
    },
    galleryPasswordProtected() {
      return get(this.institutionSettings, 'screen.galleryPasswordProtected', false);
    },
    useExternalMedia() {
      return get(this.institutionSettings, 'useExternalMedia', false);
    },
    showResInfoDefault() {
      return get(this.institutionSettings, 'showResInfoDefault', false);
    },
    mediaIcons() {
      if (this.useExternalMedia) {
        return [
          {
            name: 'externalMedia',
            fa: 'photo-video',
            amount: get(this.myArchive, 'galleries.length', 0)
          },
        ]
      }
      return [
        {
          name: 'pictures',
          fa: 'image',
          amount: get(this.myArchive, 'pictures.length', 0)
        },
        {
          name: 'galleries',
          fa: 'photo-video',
          amount: get(this.myArchive, 'galleries.length', 0)
        },
        {
          name: 'videos',
          fa: 'film',
          amount: get(this.myArchive, 'videos.length', 0)
        },
        {
          name: 'documents',
          fa: 'file-alt',
          amount: get(this.myArchive, 'documents.length', 0)
        }
      ];
    },
    dreambrokerGallery() {
      return get(this.myArchive, 'galleries', [])
    },
    galleries() {
      return get(this.myArchive, 'galleries', []);
    },
    pictures() {
      return get(this.myArchive, 'pictures', []);
    },
    videos() {
      return get(this.myArchive, 'videos', []);
    },
    documents() {
      return get(this.myArchive, 'documents', []);
    },
    mediaItems() {
      let items = [];

      switch(this.mediaType) {
        case 'externalMedia':
          if (this.dreambrokerGallery.length) items = this.dreambrokerGallery;
          break;
        case 'galleries':
          if (this.galleries.length) items = this.galleries;
          break;
        case 'pictures':
          if (this.pictures.length) items = this.pictures;
          break;
        case 'videos':
        if (this.videos.length) items = this.videos;
          break;
        case 'documents':
        if (this.documents.length) items = this.documents;
          break;
        default:
      }

      return items;
    },
    showMediaPreview() {
      return this.selectedMediaIndex > -1 && !this.hideMediaPreview;
    },
    isEmployee() {
      return this.profile.type === 'EMPLOYEE' && this.profile.title;
    },
    homeDays() {
      const daysTranslated = [];
      this.profile.homeDays.forEach(day => daysTranslated.push(this.$t(`global.${day}`)));
      return `${this.$t('resident.homeDays')}: ${daysTranslated.join(', ')}`;
    }
  },
  watch: {
    pinValidation(pinData) {
      if (!pinData.validPin || !pinData.pin) return;
      this.showMedia();
    }
  },
  async mounted() {
    await this.$store.dispatch('profiles/setActiveProfile', this.profile);
    await this.$store.dispatch('profiles/getMyArchive', { id: this.profile.id, type: this.profile.type });

    this.isLoading = false;
  },
  methods: {
    getBirthday(day) {
      return format(new Date(day), 'd MMMM, Y')
    },
    getDepartment(departmentId) {
      return this.departments.find(department => department.id == departmentId);
    },
    closeMediaList() {
      this.mediaType = '';
      this.selectedMediaIndex = -1;
      this.showMediaList = false;
    },
    showMedia() {
      if (this.mediaItems.length === 1) {
          // if only one item, go straight to media-preview
          this.selectedMediaIndex = 0;
          this.hideMediaPreview = false;
          return;
        }

        this.showMediaList = true;
    },
    mediaIconSelected(mediaType) {
      if (this.galleryPasswordProtected) {
        const flow = new PinFlow({
          entity: 'gallery',
          sharedDepartments: this.departments
        });

        flow.grantAccessToGallery();
        this.mediaType = mediaType;
      } else {
        this.mediaType = mediaType;
        this.showMedia();
      }
    }
  }
}
</script>

<style lang="scss">
  .profile {

    .profileContent {
  
      > *:not(.mediaIcons) {
        padding: 0 100px;
      }
      .media-list.galleries {
        padding: 0px;
      }
    }
  }
  
</style>