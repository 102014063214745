var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "carousel",
    {
      attrs: {
        name: "slideshow",
        "ref-name": "slideshow",
        autoplay: false,
        loop: true,
        "slide-duration": _vm.widgetSlideDuration,
        "start-index": _vm.currentGallerySlide,
      },
      on: { "slide-clicked": _vm.slideClicked },
    },
    _vm._l(_vm.galleries, function (gallery, index) {
      return _c(
        "slide",
        {
          key: index,
          staticClass:
            "w-full text-white flex items-center h-full flex-col pb-16 text-center",
          class: [
            _vm.widget.frHeight > 1 ? "justify-center" : "justify-end p-0",
          ],
          style: _vm.backgroundStylesOuter,
        },
        [
          _c(
            "carousel",
            {
              staticClass: "w-full",
              attrs: {
                name: "pictures" + index,
                "ref-name": "pictures" + index,
                autoplay: _vm.currentGallerySlide === index ? true : false,
                loop: true,
                pagination: false,
              },
            },
            _vm._l(gallery.pictures, function (pictures, picturesIndex) {
              return _c("slide", {
                key: picturesIndex,
                staticClass:
                  "bg-cover bg-center bg-no-repeat w-full text-white flex items-center h-full px-16 pb-16 flex-col text-center",
                class: [
                  _vm.widget.frHeight > 1 ? "justify-center" : "justify-end",
                ],
                style: {
                  backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 50%, ${_vm.widget.colorHex} 95%, ${_vm.widget.colorHex} 10%), url('${pictures.source}')`,
                },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("fa-icon", {
            staticClass: "text-2xl mb-4 text-white",
            attrs: { icon: ["fas", `${_vm.widget.icon}`] },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "text-2xl mb-6" }, [
            _vm._v("\n      " + _vm._s(gallery.title) + "\n    "),
          ]),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }