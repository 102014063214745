<template>
  <div class="text-center text-white">
    <p class="font-thin">{{ startDay }}</p>
    <div class="flex items-center justify-center">
      <fa-icon class="text-white text 3xl mr-2" :icon="['fal', 'clock']"/>
      <p class="text-xl">{{ startTime }}</p>
    </div>
  </div>
</template>

<script>
import { getStartDay, formatTime } from '@/utils/helpers.js';
import { capitalize } from 'lodash';

export default {
  props: {
    startDate: {
      type: String,
      'default': 'white'
    },
  },
  computed: {
    startDay() {
      return capitalize(this.$t(getStartDay(this.startDate)));
    },
    startTime() {
      return formatTime(this.startDate);
    }
  }
}
</script>