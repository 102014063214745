<template>
  <div
    class="newsWrapper"
    :class="{ 'verticalCenter flex flex-col justify-center items-stretch': verticalCenterGrid || verticalCenterList }"
  >
    <div
      v-if="news.length"
      class="news bg-white"
    >
      <list-view
        v-if="activeContentView.name === 'list'"
        :items="news"
        :width-percent="80"
        :sort-by-day="false"
        @item-selected="showSingleNewsEntry($event)"
      />

      <grid-view
        v-if="activeContentView.name === 'grid'"
        :items="news"
        item-type="news"
        @item-selected="showSingleNewsEntry($event)"
      />
    </div>

    <empty-views
      v-else
      view-type="News"
      :color="moduleColor"
    />
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import ListView from '@/components/shared/ListView';
import GridView from '@/components/shared/GridView';
import EmptyViews from '@/views/shared/EmptyViews';

export default {
  components: {
    ListView,
    GridView,
    EmptyViews
  },
  computed: {
    ...mapGetters({
      allNews: 'news/all',
      activeViewType: 'general/activeViewType',
      activeContentView: 'general/activeContentView'
    }),
    news() {
      return this.allNews.map(newsEntry => {
        return {
          ...newsEntry,
          meta: {
            icon: '',
            text: ''
          }
        }
      });
    },
    verticalCenterList() {
      return this.activeViewType === 'list' && this.news.length < 3;
    },
    verticalCenterGrid() {
      return (this.activeViewType === 'grid' && this.news.length < 10);
    },
    contentViews() {
      return this.$route?.meta?.contentViews;
    },
    moduleColor() {
      return this.$route?.meta?.color;
    }
  },
  async mounted() {
    await this.$store.dispatch('general/setActiveContentView', this.$route.meta?.contentViews[this.news.length >= 3 ? 1 : 0]);
    await this.$store.dispatch('news/getNews');
    await this.$store.dispatch('general/setActiveViewType', this.news.length > 3 ? 'grid' : 'list');
  },
  methods: {
    showSingleNewsEntry(newsEntry) {
      this.$router.push(`/news/${newsEntry.id}`);
    },
    getMeta(newsEntry) {
      const text = !newsEntry.videos.length ? this.$t('global.images') : this.$t('global.imagesAndVideos');

      return  {
        icon: 'images',
        text: `${newsEntry.pictures.length} ${text}`
      }
    }
  }
}

</script>

<style lang="scss">
  .newsWrapper {
    position: relative;
    background: none;
    padding: 130px 0;

    &.gridView {
      padding-top: 15px;
    }
  }
</style>
