
<template>
  <div
    class="meetingsWrapper"
    :class="{ 'verticalCenter flex items-center flex-col justify-center items-stretch': verticalCenterGrid || verticalCenterList }"
  >
    <div
      v-if="meetings.length"
      class="activities bg-white"
    >
      <list-view
        v-if="activeContentView.name === 'list'"
        :day-items="meetingDays"
        :width-percent="80"
        :sort-by-day="true"
        @item-selected="showSingleMeeting($event)"
      />

      <grid-view
        v-if="activeContentView.name === 'grid'"
        :items="meetings"
        item-type="meeting"
        @item-selected="showSingleMeeting($event)"
      />
    </div>

    <empty-views
      v-else
      view-type="Meetings"
      :color="moduleColor"
    />
  </div>
</template>

<script>
import { isFuture } from 'date-fns';
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import ListView from '@/components/shared/ListView';
import GridView from '@/components/shared/GridView';
import { isItemToday, formatTime } from '@/utils/helpers.js';
import EmptyViews from '@/views/shared/EmptyViews';

export default {
  components: {
    ListView,
    GridView,
    EmptyViews
  },
  data() {
    return {
      selectedClubIndex: -1,
      lastScrollTop: 0,
      topVisibleDay: null,
      singleMeetingShown: false,
      selectedDate: new Date()
    };
  },
  computed: {
    ...mapGetters({
      allMeetings: 'meetings/all',
      activeViewType: 'general/activeViewType',
      activeContentView: 'general/activeContentView',
      activeItemFilter: 'general/activeItemFilter',
      externalConnections: 'institution/externalConnections',
      institutionId: 'institution/id'
    }),
    moduleColor() {
      return this.$route?.meta?.color;
    },
    contentViews() {
      return this.$route?.meta?.contentViews;
    },
    meetingDays() {
      const meetings = cloneDeep(this.meetings);
      return meetings.reduce((accumulator, current, index) => {
        const uniqueDate = current.startDate.split('T')[0];

        if (accumulator[uniqueDate] || (accumulator[uniqueDate]=[])) {
          accumulator[uniqueDate].push(current);

          accumulator[uniqueDate].meta = {
            startDate: current.startDate,
            showTodayLabel: index === 0 && isItemToday(current.startDate)
          }
        }

        return accumulator;
      }, {});
    },

    meetings() {
      return this.allMeetings.filter( meeting => {
        return isFuture(new Date(meeting.startDate)) || isFuture(new Date(meeting.endDate));
      }).map(meeting => {
        return {
          ...meeting,
          meta: {
            icon: 'clock',
            text: formatTime(meeting.startDate)
          }
        }
      });
    },
    verticalCenterList() {
      return this.activeViewType === 'list' && this.meetings.length < 3;
    },
    verticalCenterGrid() {
      return (this.activeViewType === 'grid' && this.meetings.length < 10) || (this.activeContentView.name === 'filter');
    }
  },
  async mounted() {
    await this.$store.dispatch('meetings/getMeetings'),
    await this.$store.dispatch('general/setActiveViewType', this.meetings.length > 3 ? 'grid' : 'list');
    await this.$store.dispatch('general/setActiveContentView', this.$route.meta?.contentViews[this.meetings.length >= 3 ? 1 : 0]);
  },
  methods: {
    showSingleMeeting(meeting) {
      if (meeting.cancelled) return;

      const dayLabels = document.getElementsByClassName('dayLabelWrapper');

      for (let item of dayLabels) {
        item.style.zIndex = '0';
      }

      this.$router.push(`/meetings/${meeting.id}`);
    },
    clubSelected(index) {
      this.selectedClubIndex = index;
      const contentView = this.contentViews.find(filter => filter.name === this.activeViewType);
      this.$store.dispatch('general/setActiveContentView', contentView);
    },
    formatTime(meeting) {
      if(meeting.cancelled) return this.$t('global.cancelled');
      return formatTime(meeting);
    },
  }
};
</script>

<style lang="scss">
  .meetingsWrapper {
    position: relative;
    background: none;
    padding: 130px 0;

    &.verticalCenter .dayLabelWrapper {
      padding: 0;
    }

    &.gridView {
      padding-top: 15px;
    }
  }

</style>
