<template>
  <div
    class="flex flex-col"
  >
    <div
      class="overflow-x-auto schedule relative h-full z-10"
    >
      <div
        class="flex flex-col overflow-x-scroll h-full absolute"
        :style="{ width: viewWidth }"
      >
        <div class="w-full h-full absolute z-10 inline-flex">
          <div
            v-for="index in times.length"
            :key="index"
            class="h-full"
            :style="{ 'flex-basis': `${hourWidth}px` }"
          >
            <div class="w-6 h-full border-r border-charcoal-xxlight" />
          </div>
        </div>

        <div
          class="overflow-y-scroll overflow-x-none flex flex-col relative h-full"
        >
          <timeline
            class="w-max border-b-2 border-charcoal-xxlight fit-content z-20 bg-white"
            :times="times"
          />

          <div
            v-if="isToday && shifts.length"
            class="flex flex-col space-between w-2 absolute h-full z-30"
            :style="{ marginLeft: `${offsetLeft}px` }"
          >
            <div class="bg-charcoal w-20 h-12 mt-6 flex items-center justify-center rounded rounded-lg relative -ml-9 shadow-bottomRight">
              <p class="text-white text-2xl">
                {{ currentTime }}
              </p>
            </div>

            <div class="w-2 h-full">
              <div class="shadow-bottomRight indicator bg-charcoal w-full h-full" />
            </div>

            <div class="bg-charcoal w-20 h-12 mb-6 flex items-center justify-center rounded rounded-lg relative -ml-9 shadow-bottomRight">
              <p class="text-white text-2xl">
                {{ currentTime }}
              </p>
            </div>
          </div>

          <empty-views
            v-if="!shifts.length"
            view-type="WorkSchedule"
            :color="moduleColor"
          />

          <div class="flex flex-row relative w-full overflow-x-hidden h-full">
            <div
              class="overflow-y-scroll flex flex-col items-center justify-center z-30"
              :class="{ absolute: shifts.length > 5 }"
            >
              <shift
                v-for="(shift, index) in shifts"
                :key="index"
                :index="index"
                :shift="shift"
                :module-color="moduleColor"
                :view-width="viewWidth"
                :selected-day="selectedDay"
                type-of-shift="normal"
                @click.native="showSingleShift(shift)"
              />
            </div>
          </div>
          <timeline
            class="w-max border-t-2 border-charcoal-xxlight fit-content z-20 bg-white"
            :times="times"
            :style="{ width: viewWidth }"
          />
        </div>
      </div>
    </div>

    <div
      v-if="isToday"
      class="bottom-1/3 w-full fixed -mb-6 flex items-center justify-center z-20"
    >
      <button-el
        v-if="isOutOfBounds && shifts.length > 0"
        text="global.backToNow"
        icon="angle-right"
        background-color="charcoal"
        @click.native="scrollToNow()"
      />
    </div>

    <daySelector
      @handle-selected-day="handleSelectedDay"
    />
  </div>
</template>

<script>

import Timeline from '@/components/workSchedule/Timeline';
import Shift from '@/components/workSchedule/Shift';
import DaySelector from '@/components/workSchedule/DaySelector';
import { addDays, subDays, isSameDay } from 'date-fns';
import { format } from '@/utils/date-fns';
import { mapGetters } from 'vuex';
import { get, capitalize } from 'lodash';
import Button from '@/components/shared/Button';
import EmptyViews from '@/views/shared/EmptyViews';

export default {
  components: {
    Timeline,
    Shift,
    ButtonEl: Button,
    DaySelector,
    EmptyViews
  },
  data() {
    return {
      currentTime: '00:00',
      interval: {},
      times: [ '', '', '', '', '', '', '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '00', '', '' ],
      offsetLeft: 0,
      isOutOfBounds: false,
      selectedDay: new Date(),
      hourWidth: 90,
      today: new Date(),
      selectedShift: {},
    }
  },
  computed: {
    ...mapGetters({
      workSchedules: 'workSchedule/all',
      isLoading: 'workSchedule/isLoading',
      settings: 'department/settings',
      pinValidation: 'profiles/pinValidation',
      activeOverlay: 'general/activeOverlay'
    }),
    moduleColor() {
      return this.$route.meta.color;
    },
    formattedSelectedDate() {
      if (isSameDay(this.selectedDay, this.today)) return this.$t('global.today');
      return capitalize(format(this.selectedDay, 'EEEE do LLLL'));
    },
    shownDays() {
      return get(this.settings, 'shift.daysForward', 7);
    },
    shifts() {
      return this.workSchedules.filter(schedule => {
        return isSameDay(new Date(schedule.start), this.selectedDay) || isSameDay(new Date(schedule.end), this.selectedDay);
      })
    },
    viewWidth() {
      return `${this.hourWidth * this.times.length}px`;
    },
    isToday() {
      return isSameDay(this.today, this.selectedDay);
    },
    dateInterval() {
      return {
        from: subDays(this.today, this.shownDays - 1),
        to: addDays(this.today, this.shownDays - 1)}
    },
    editAction(){
      return get(this, 'activeOverlay.data.action', '');
    }
  },
  watch: {
    pinValidation(pinData) {
      if (!pinData.validPin || !pinData.pin) return;

      if (this.editAction === 'cancel') {
        this.$store.dispatch('workSchedule/shiftUpdate', {shiftId: this.selectedShift.id , dates: this.dateInterval, cancel: true});
      } else if (this.editAction === 'reactivate') {
        this.$store.dispatch('workSchedule/shiftUpdate', {shiftId: this.selectedShift.id , dates: this.dateInterval, cancel: false});
      }

      if (this.editAction === 'swap') {
        this.$store.dispatch('workSchedule/shiftUpdate', {shiftId: this.selectedShift.id , dates: this.dateInterval, cancel: false, employeeId: this.activeOverlay.data.employee.id});
      }

      if (this.editAction === 'delete') {
        this.$store.dispatch('workSchedule/shiftDelete', {shiftId: this.selectedShift.id , dates: this.dateInterval});
      }
    },
    workSchedules() {
      if (!this.selectedShift.id) return;

      this.shifts.find(shift => {
        if (shift.id === this.selectedShift.id) {
          this.$store.dispatch('general/setActiveOverlay', { name: 'work-schedule-entry', data: { ...shift } });
        }
      })
    },
    selectedDay(){
      document.querySelector('.schedule').scrollLeft += 1;
    }
  },
  async mounted() {
    await this.getWorkSchedules();
    this.isOutOfBounds = false;
    const secondsUntilNextMinute = (60 - format(this.today, 'ss')) * 1000;
    this.setCurrentTime();
    this.setOffsetLeft();
    
    setTimeout(() => {
      document.querySelector('.schedule').addEventListener('scroll', event => {
        this.isOutOfBounds = event.target.scrollLeft > this.offsetLeft || event.target.scrollLeft + window.innerWidth < this.offsetLeft;
      });
    }, 1000)
    
    setTimeout(() => {
      this.setCurrentTime();
      this.interval = setInterval(() => {
        this.setCurrentTime();
        this.setOffsetLeft();
      }, 60000);
    }, secondsUntilNextMinute);

    setTimeout(() => {
      this.scrollToNow();
    }, 200);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    setCurrentTime() {
      this.today = new Date();
      this.currentTime = format(this.today, 'HH:mm');
    },
    showSingleShift(shift) {
      this.selectedShift = shift;
      this.$store.dispatch('general/setActiveOverlay', { name: 'work-schedule-entry', data: { ...shift } });
    },
    getWorkSchedules() {
      this.$store.dispatch('workSchedule/getWorkSchedules', this.dateInterval);
    },
    setOffsetLeft() {
      const split = format(this.today, 'H:mm').split(':');
      const hours = parseInt(split[0]) + 6;
      const minutes = ( hours * 60 ) + parseInt(split[1]);
      const minuteWidth = window.innerWidth / 12 / 60;
      this.offsetLeft = (parseInt(minutes) * minuteWidth) + 10;
    },
    scrollToNow() {
      if (!this.shifts.length) return;
      document.querySelector('.indicator').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    },
    scrollToInterval() {
      // Based on user input the screen should scroll to the 06 - 17 interval by default on future and past days.
      document.querySelector('.schedule').scroll({
        behavior: 'smooth',
        left: 1060,
        top: 0
      });
    },
    scrollIntoPlace() {
      setTimeout(() => {
        if (isSameDay(this.today, this.selectedDay)) {
          this.scrollToNow();
        } else {
          this.scrollToInterval();
        }
      }, 200);
    },
    handleSelectedDay(selectedDay) {
      this.selectedDay = selectedDay;
      this.scrollIntoPlace();
    }
  },
}
</script>

<style lang="scss">
.backToNow {
  bottom: 28vh;
}
</style>