<template>
  <div
    id="app"
    :class="{ isLoading }"
    class="flex flex-col relative"
  >
    <header-el
      v-if="onStartPage"
      class="flex-none"
    />

    <div
      v-if="!isLoading"
      class="relative overflow-y-scroll overflow-x-hidden route-wrapper"
      :style="{ height: '1620px' }"
      :class="routeClasses"
    >
      <router-view
        :key="$route.path"
        :class="{ 'contentArea': !meta.subView }"
        class="h-full bg-white"
      />
    </div>

    <content-views
      v-if="$route.path !== '/'"
      :content-views="meta.contentViews"
      :style="{ opacity: !!activeOverlay.name ? 0 : 1 }"
      @filter-selected="updateFilters($event)"
    />
    
    <module-list
      class="flex-none bg-white shadow-sm pt-10 z-40"
      @close-overlay="closeOverlay"
    />

    <footer-el
      class="flex-none z-40 bg-white"
      :dimmer-opacity="dimmer"
      @dimScreen="setDimmer"
      @close-overlay="closeOverlay"
    />

    <transition
      enter-active-class="animate__animated animate__slideInUp"
      leave-active-class="animate__animated animate__slideOutDown"
      :duration="500"
    >
      <keyboard
        v-show="keyboardInput !== null"
        :input="keyboardInput"
      />
    </transition>

    <overlay
      :show="overlayHasValue && showOverlay"
      @close-overlay="closeOverlay"
    />

    <div
      v-if="dimmer > 0"
      class="screenDimmer absolute inset-0 z-50 bg-gray-900 pointer-events-none"
      :style="{ opacity: dimmer / 100 }"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { isEmpty, get, cloneDeep } from 'lodash';
import { subDays, addDays } from 'date-fns';
import Header from '@/components/shared/Header';
import ModuleList from '@/components/shared/ModuleList';
import Footer from '@/components/shared/Footer';
import Keyboard from '@/components/shared/Keyboard';
import Overlay from '@/views/overlays/Overlay';
import contentViews from '@/components/shared/ContentViews';
import i18n from '@/utils/lang';
import { useIdle } from '@vueuse/core';
import { ss } from '@/utils/storage';

export default {
  components: {
    HeaderEl: Header,
    ModuleList,
    FooterEl: Footer,
    Keyboard,
    Overlay,
    contentViews
  },
  data() {
    return {
      showOverlay: false,
      idle: {}
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'general/isLoading',
      keyboardInput: 'general/keyboardInput',
      activeOverlay: 'general/activeOverlay',
      nextActiveOverlay: 'general/nextActiveOverlay',
      activeViewType: 'general/activeViewType',
      activecontentView: 'general/activecontentView',
      activeItemFilter: 'general/activeItemFilter',
      dimmer: 'general/dimmer',
      activePinFlow: 'general/activePinFlow',
      institutionSettings: 'institution/settings',
      institutionLocale: 'institution/locale',
      departmentSettings: 'department/settings',
      institutionId: 'institution/id',
      departmentId: 'department/id'
    }),
    onStartPage() {
      return this.$route.path === '/';
    },
    meta() {
      return this.$route.meta || {};
    },
    routeClasses() {
      return {
        'scroll-up': this.meta.scrollUp && !this.meta.subView,
        [`bg-${this.meta.color}`]: !!this.meta.scrollUp
      };
    },
    overlayHasValue() {
      return !isEmpty(this.activeOverlay);
    },
    timeout() {
      return get(this.departmentSettings, 'screenConfig.timeout', 600);
    },
    workScheduleRanges() {
      const daysForward = get(this.institutionSettings, 'shift[0].daysForward', 7);

      return {
        from: subDays(new Date(), daysForward),
        to: addDays(new Date(), daysForward)
      }
    },
    availableModules() {
      return cloneDeep(this.departmentSettings).screenConfig.modules.map(module => module.type);
    }
  },
  watch: {
    activeOverlay(newValue) {
      if (!isEmpty(newValue)) {
        this.showOverlay = true;
      }
    },
    idle(idleValue) {
      if (idleValue) {
        this.onIdle();
      }
      this.reset();
    },
    '$route'(newValue) {
      this.$nextTick(async() => {
        this.closeOverlay(true);
        if (newValue.meta.scrollUp) {
          this.scrollToContent('scroll-up', 1200);
        } else {
          this.scrollToContent('route-wrapper', 150);
        }

        if (newValue.path === '/') {
          this.fetchData();
          this.$store.dispatch('profiles/setActiveProfile', {})
        }
      });
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch('institution/getInstitution');
      await this.$store.dispatch('department/getDepartment');
      this.fetchData();
      
      i18n.locale = this.institutionLocale;
      
    } catch (err) {
      throw new Error('Could not get institution/department data, retrying');
    }

    const { idle, reset } = useIdle(this.timeout * 1000);
    this.idle = idle;
    this.reset = reset;

    const DIMENSION_INSTITUTION = 1;
    const DIMENSION_DEPARTMENT = 2;

    this.$matomo.setCustomDimension(DIMENSION_INSTITUTION, this.institutionId);
    this.$matomo.setCustomDimension(DIMENSION_DEPARTMENT, this.departmentId);

    this.setDimmer();

    this.$store.dispatch('general/setLoading', false);
  },
  methods: {
    async onIdle() {
      this.$store.dispatch('general/setReInitClock', true);

      if (ss.get('temp_login_key')) {
        await this.$store.dispatch('department/switchDepartment');
        return;
      }

      if (this.$route.path !== '/') {
        this.$router.push('/');
      } else {
        this.fetchData();
      }
    },
    async fetchData() {
      await Promise.all([
        this.$store.dispatch('institution/getExternalConnections'),
        this.$store.dispatch('profiles/getProfiles'),
      ]);

      const promises = [];

      if (this.availableModules.includes('GALLERY')) {
        promises.push(this.$store.dispatch('galleries/getGalleries'));
      }

      if (this.availableModules.includes('SLIDESHOW')) {
        promises.push(this.$store.dispatch('galleries/getGalleries'));
      }

      if (this.availableModules.includes('ACTIVITIES') || this.availableModules.includes('EXTERNAL_ACTIVITIES')) {
        promises.push(this.$store.dispatch('activities/getActivities'));
      }

      if (this.availableModules.includes('NEWS')) {
        promises.push(this.$store.dispatch('news/getNews'));
      }

      if (this.availableModules.includes('WEATHER')) {
        promises.push(this.$store.dispatch('department/getWeather'));
      }

      if (this.availableModules.includes('MEAL_PLAN')) {
        if (get(this.institutionSettings, 'useExternalMeals', false)) {
          promises.push(this.$store.dispatch('meals/getPcdMeals'));
        } else {
          promises.push(this.$store.dispatch('meals/getMeals'));
        }
      }

      if (this.availableModules.includes('BOOKING')) {
        promises.push(this.$store.dispatch('bookingResources/getBookingResources'));
      }

      if (this.availableModules.includes('MEETINGS')) {
        promises.push(this.$store.dispatch('meetings/getMeetings'));
      }

      promises.push(this.$store.dispatch('workSchedule/getWorkSchedules', this.workScheduleRanges));
      promises.push(this.$store.dispatch('workSchedule/setType'));

      if (this.availableModules.includes('JOURNEY_PLANNER')) {
        promises.push(this.$store.dispatch('journeyPlanner/getJourneyPlan'));
      }

      await Promise.all(promises);
    },
    async closeOverlay(force = false) {
      if (force) {
        await this.$store.dispatch('general/setActiveOverlay', {});
        await this.$store.dispatch('general/setNextActiveOverlay', {});
        await this.$store.dispatch('profiles/setPinValidation', {});
        await this.$store.dispatch('general/setActivePinFlow', '');
      }

      const hasNextOverlay = !isEmpty(this.nextActiveOverlay);
      this.showOverlay = false;

      if (hasNextOverlay) {
        setTimeout(() => {
          this.$store.dispatch('general/setActiveOverlay', this.nextActiveOverlay);
          this.showOverlay = true;
          this.$store.dispatch('general/setNextActiveOverlay', {});
        }, this.$constants.animationDuration); // wait for the transition to end before we change the store value
      }

    },
    updateFilters(index) {
      const filter = this.meta.contentViews[index];
      this.$store.dispatch('general/setActiveContentView', filter);

      if ([ 'list', 'grid' ].indexOf(filter.name) > -1) {
        this.$store.dispatch('general/setActiveViewType', filter.name);
      }
    },
    scrollToContent(className, xValue) {
      const selector = document.querySelector(`.${className}`);
      if (selector) selector.scrollTop = xValue;
    },
    async setDimmer(opacity = undefined) {
      if (opacity) {
        this.$matomo.trackEvent('Dimmer', 'Clicked', 'ScreenDimmer');
      }

      await this.$store.dispatch('general/setDimmer', opacity);
    }
  },
};
</script>
<style lang="scss">
  .tick-credits {
    display: none !important;
    opacity: 0 !important;
  }

  #app {
    width: 1080px;
    height: 1920px;
    margin: auto;
    background: white;

    .screenDimmer {
      transition: opacity 500ms;
    }

    .content-views {
      transition: opacity 500ms;
    }

    .route-wrapper {
      margin-bottom: -60px;

      .contentArea {
        animation-duration: 1.3s;

        .largeListItemHeader {
          border-radius: 25px 25px 0 0;
        }
      }
      .simple-keyboard {
         animation-duration: 0.5s;
      }

      &.scroll-up .contentArea {
        margin-top: 1200px;
      }
    }
  }
</style>
