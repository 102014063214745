<template>
  <div class="px-12 relative">
    <div class="flex-2 h-full px-10 pb-12">
      <keyboard-simple
        placeholder="meeting.suggestion"
        @onChange="suggestion = $event"
      />
    </div>

    <div class="flex w-full sticky justify-center bottom-80">
      <button-el
        class="bold"
        text="meeting.writeSuggestion"
        icon="pen-alt"
        background-color="success"
        @click.native="addSuggestion"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/shared/Button';
import KeyboardSimple from '@/components/shared/KeyboardSimple';
import PinFlow from '@/utils/pinFlow';

export default {
  components: {
    ButtonEl: Button,
    KeyboardSimple
  },
  data() {
    return {
      suggestion: ''
    }
  },
  computed: {
    ...mapGetters({
      activeOverlay: 'general/activeOverlay'
    }),
    data() {
      return this.activeOverlay.data;
    }
  },
  methods: {
    async addSuggestion() {
      const pinFlow = new PinFlow({
        entity: 'meeting-suggest',
        title: '',
        sharedDepartments: this.data.sharedDepartments
      });
      
      this.$store.dispatch('meetings/setSuggestion', this.suggestion);
      pinFlow.startCreateFlow('meeting.toSuggest');
    }
  }
}
</script>

<style lang="scss">


</style>
