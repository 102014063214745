var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "px-12 relative" }, [
    _c(
      "div",
      { staticClass: "flex-2 h-full overflow-y-scroll pb-80" },
      _vm._l(_vm.data.suggestions, function (suggestion, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "participants content inline-flex w-full mb-10 px-20",
          },
          [
            _c("img", {
              staticClass:
                "w-24 h-24 rounded-full border border-gray-200 mr-1 shadow-xsm",
              class: { "-m-1": index !== 0 },
              attrs: { src: suggestion.suggester.displayImage },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "suggestion relative p-8 -mt-1 ml-1 bg-white shadow-xsm rounded-xl",
              },
              [
                _c("p", { staticClass: "label" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(suggestion.suggester.displayName) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(suggestion.title))]),
              ]
            ),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex w-full sticky justify-center bottom-80" },
      [
        _c("button-el", {
          staticClass: "bold",
          attrs: {
            text: "meeting.writeSuggestion",
            icon: "pen-alt",
            "background-color": "success",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.addSuggestion.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }