import { apolloClient } from '../../utils/apollo';

const state = {
    id: null,
    name: null,
    color: null,
    locale: null,
    logo: {},
    departments: [],
    externalConnections: [],
    settings: {}
};

const mutations = {
    SET_INSTITUTION(state, institution) {
        Object.assign(state, institution);
    },
    SET_EXTERNAL_CONNECTIONS(state, externalConnections) {
        state.externalConnections = externalConnections;
    }
};

const actions = {
    async getInstitution({ commit }) {
        await apolloClient
            .query({
                query: require('@/graphql/queries/institution').default
            })
            .then(response => {
                commit('SET_INSTITUTION', response.data.institution);
            })
            .catch(error => {
                throw new Error('Error getting institution', error);
            });
    },
    async getExternalConnections({ commit }) {
        await apolloClient
            .query({
                query: require('@/graphql/queries/appConnectionsScreen').default
            })
            .then(response => {
                commit('SET_EXTERNAL_CONNECTIONS', response.data.appConnectionsScreen);
            })
            .catch(error => {
                console.log('Error getting external connections', error);
            });
    },
};

const getters = {
    id: state => state.id,
    name: state => state.name,
    color: state => state.color,
    locale: state => state.locale,
    departments: state => state.departments,
    externalConnections: state => state.externalConnections,
    logo: state => state.logo,
    settings: state => state.settings
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};