<template>
  <div class="booking">
    <cover-view
      v-if="booking.id"
      :image-url="imageUrl"
      :gradient="true"
      :module-color="moduleColor"
      :margin-top="60"
    >
      <div class="bookingContent pt-20">
        <tts
          :entity-id="booking.id"
          entity-type="BOOKING_BOOKING"
        />

        <div class="largeListItemHeader pt-12 mb-10">
          {{ booking.title || booking.description }}
        </div>

        <date-time
          :start="booking.start"
          :end="booking.end"
        />

        <people
          v-if="booking.participants.length"
          :module-color="moduleColor"
          :profiles="booking.participants"
          @show-people="showProfiles('participants')"
        />
      </div>
    </cover-view>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';
import CoverView from '@/views/shared/CoverView';
import DateTime from '@/components/shared/blocks/DateTime';
import People from '@/components/shared/blocks/People';
import Tts from '@/components/shared/Tts';

export default {
  components: {
    CoverView,
    DateTime,
    People,
    Tts
  },
  data() {
    return {
      opt: ''
    }
  },
  computed: {
    ...mapGetters({
      allBookingResources: 'bookingResources/all',
      booking: 'bookingResources/selectedBookingEntry',
      institutionColor: 'institution/color',
      institutionName: 'institution/name'
    }),
    moduleColor() {
      return this.$route?.meta?.color;
    },
    imageUrl() {
      return get(this.booking, 'coverImage.source', require('@assets/img/placeholder.png'));
    }
  },
  mounted() {
    this.$matomo.trackEvent('BookingEntry', 'Viewed', 'BookingEntryViewed');
  },
  methods: {
    showProfiles(profileType) {
      this.$store.dispatch('general/setActiveOverlay', { name: 'participants-list', data: { title: `global.${profileType}`, profiles: this.booking.participants } })
    },
  }
};
</script>

<style lang="scss">
  .booking {

    .bookingContent {
  
      &.participants {
        margin-top: 700px;
      }
  
      > *:not(.mediaIcons) {
        padding: 0 100px;
      }
    }
  
    .bookingActions {
      bottom: 70px;
      padding-right: 80px;
    }
  }
  
</style>
