var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "enter-pin h-full overflow-hidden" }, [
    _c("div", { staticClass: "content flex flex-col h-full items-center" }, [
      _c(
        "div",
        {
          staticClass: "absolute -mt-96 w-96 flex flex-col items-center mb-20",
        },
        [
          _c("img", {
            staticClass:
              "rounded rounded-full w-60 h-60 border-8 border-white shadow-m object-cover bg-white mb-8",
            attrs: { src: _vm.activeProfile.displayImage },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "largeListItemHeader text-center" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.activeProfile.displayName) + "\n      "
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mt-12 w-full flex justify-center" }, [
        _c("div", { staticClass: "w-full h-1 bg-charcoal-xxlight" }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "w-60 rounded rounded-md bg-white -mt-10 p-6 flex items-center justify-center shadow-lg absolute",
          },
          [
            _c("fa-icon", {
              staticClass: "text-3xl mr-3",
              attrs: { icon: ["far", "sign-out"] },
            }),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "font-bold text-2xl",
                on: {
                  click: function ($event) {
                    return _vm.switchUser()
                  },
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("global.switchUser")) +
                    "\n        "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      !_vm.loginSuccessful
        ? _c(
            "div",
            {
              staticClass: "flex flex-col flex-1 items-center p-10",
              class: { validating: _vm.pinValidation.validating },
            },
            [
              !_vm.lockPinCode
                ? _c(
                    "div",
                    {
                      staticClass:
                        "largeListItemHeader mb-10 text-center error mt-8",
                      style: { opacity: _vm.wrongPin ? 1 : 0 },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("modals.wrongPassword")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.lockPinCode
                ? _c("div", { staticClass: "content text-center mb-20" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.activeOverlay.data.title) +
                        "\n        "
                    ),
                    _vm.activeOverlay.data.entityName
                      ? _c("span", { staticClass: "font-bold" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.activeOverlay.data.entityName) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.lockPinCode
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "largeListItemHeader mb-10 text-center error mt-8",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("modals.tooManyAttempts")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-center" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("modals.passwordLocked", {
                              amount: _vm.displayedCountDown,
                            })
                          ) +
                          " \n        "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.lockPinCode
                ? _c(
                    "div",
                    { staticClass: "inline-flex mb-10 ml-12" },
                    [
                      _vm._l(4, function (item, index) {
                        return _c("div", {
                          key: index,
                          staticClass:
                            "w-14 h-14 border border-charcoal border-b-2 rounded-full",
                          class: {
                            "mr-3": index !== 4,
                            "bg-charcoal": _vm.pin.length >= item,
                            "bg-error": _vm.wrongPin,
                          },
                          style: { opacity: 0.25 },
                        })
                      }),
                      _vm._v(" "),
                      _c("fa-icon", {
                        staticClass: "text-3xl self-center",
                        attrs: { icon: ["fal", "backspace"] },
                        on: {
                          click: function ($event) {
                            return _vm.pin.pop()
                          },
                        },
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.lockPinCode
                ? _c(
                    "div",
                    {
                      staticClass: "flex flex-wrap mb-10 justify-center w-1/3",
                    },
                    _vm._l(_vm.numbers, function (number, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "w-20 h-20 shadow-lg rounded-full mb-3 text-5xl flex items-center justify-center text-white",
                          class: { "mr-3": ![3, 6, 9, 0].includes(number) },
                          style: { backgroundColor: _vm.colors[index] },
                          on: {
                            click: function ($event) {
                              return _vm.numberPressed(number)
                            },
                          },
                        },
                        [_vm._v("\n          " + _vm._s(number) + "\n        ")]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]
          )
        : _c("div", { staticClass: "flex flex-col flex-1 items-center" }, [
            _c(
              "div",
              {
                staticClass:
                  "largeListItemHeader mb-10 pt-20 text-center success",
              },
              [
                _c("fa-icon", { attrs: { icon: ["fa", "check"] } }),
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("global.completed")) + "\n      "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "content text-center mb-20" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.activeOverlay.data.titleSuccess) +
                  "\n        "
              ),
              _c("span", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.activeOverlay.data.entityName)),
              ]),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }