var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-full flex flex-col justify-between" }, [
    _c("div", { staticClass: "flex flex-col items-center" }, [
      _c(
        "div",
        { staticClass: "largeListItemHeader pt-20 pb-8 px-10 text-center" },
        [
          _vm._v(
            _vm._s(_vm.$t("profileDetails.header")) +
              " " +
              _vm._s(_vm.activeProfile.displayName)
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "content mb-24 text-center" }, [
        _vm._v(_vm._s(_vm.$t("profileDetails.description"))),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex flex-col items-center" }, [
      _c("img", {
        staticClass: "profileImage w-44 h-44 mb-4 rounded-full bg-cover",
        attrs: { src: _vm.activeProfile.displayImage },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "content mb-28" }, [
        _vm._v(_vm._s(_vm.activeProfile.displayName)),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex flex-col items-center" },
      [
        _c("button-el", {
          staticClass: "shadow-xsm mb-10",
          attrs: {
            text: "global.logOut",
            icon: "sign-out",
            backgroundColor: "error",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.logout.apply(null, arguments)
            },
          },
        }),
        _vm._v(" "),
        _c("button-el", {
          staticClass: "shadow-xsm mb-10",
          attrs: { text: "global.close", icon: "times" },
          nativeOn: {
            click: function ($event) {
              return _vm.close.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }