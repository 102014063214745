<template>
  <div>
    <div class="content w-full mb-12 mx-20">
      {{ $t('scheduleShift.replaceShiftChooseText') }}
    </div> 

    <profiles-list
      :can-save="false"
      :multiselect="false"
      :employee-only="true"
      :shared-departments="departments"
      selected-profile-type="EMPLOYEE"
      select-bottom="bottom-48"
      @profile-selected="profileSelected"
    />
  </div>
</template>

<script>
  import ProfilesList from '@/components/shared/ProfilesList';
  import { mapGetters } from 'vuex';

  export default {
    components: {
      ProfilesList
    },
    computed: {
      ...mapGetters({
        activeOverlay: 'general/activeOverlay',
        departments: 'institution/departments'
      }),
      data() {
        return this.activeOverlay.data;
      }
    },
    methods: {
      async profileSelected(profile) {
        this.$store.dispatch('general/setNextActiveOverlay', { 
          name: 'swap-shift-confirmation', 
          data: {
            selectedProfile: profile,
            selectedShift: this.data.selectedShift,
            action: this.data.action 
          }
        });
        this.$emit('close-overlay');
      }
    }
  }
</script>