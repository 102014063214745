var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid grid-cols-3 p-10 gap-14" },
    _vm._l(_vm.items, function (item, index) {
      return _c("card-basic", {
        key: index,
        staticClass: "shadow-xsm",
        attrs: {
          title: _vm.getItemTitle(item),
          "image-url": item.coverImage.source,
          active: _vm.activeItemIndex === index,
        },
        nativeOn: {
          click: function ($event) {
            return _vm.$emit("category-selected", item)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }