var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "departmentSwitcherContainer" }, [
    _vm.showOptions
      ? _c("div", {
          staticClass: "w-full h-full absolute backdrop bottom-0 left-0 z-10",
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "clickoutside",
            rawName: "v-clickoutside",
            value: {
              excludeString: ".options, .options>*, .selection, .selection>*",
              handler: _vm.onClickoutside,
            },
            expression:
              "{ excludeString: '.options, .options>*, .selection, .selection>*', handler: onClickoutside }",
          },
        ],
      },
      [
        _vm.showOptions
          ? _c(
              "div",
              {
                staticClass:
                  "z-20 absolute bottom-32 bg-white rounded rounded-lg border border-neutral300 pl-6 pr-4 pt-6 pb-2",
              },
              [
                _c("p", { staticClass: "h600 text-center mb-4" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("global.selectDepartment")) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "options overflow-y-scroll pr-4" },
                  [
                    _c("radio-list-item", {
                      attrs: {
                        option: _vm.selectedDepartment,
                        "is-selected": true,
                      },
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.availableDepartments, function (department, i) {
                      return _c("radio-list-item", {
                        key: i,
                        attrs: { option: department },
                        on: {
                          "option-selected": function ($event) {
                            return _vm.switchDepartment(department)
                          },
                        },
                      })
                    }),
                  ],
                  2
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "selection flex items-center absolute z-50",
            on: {
              click: function ($event) {
                _vm.showOptions = !_vm.showOptions
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "border border-charcoal border-opacity-10 bg-white rounded-xl h-full flex items-center justify-between p-6",
              },
              [
                _c("div", { staticClass: "flex items-center" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mr-4 rounded rounded-full h-10 w-10 flex items-center justify-center",
                      style: { backgroundColor: _vm.selectedDepartment.color },
                    },
                    [
                      _c("fa-icon", {
                        staticClass: "text-xl text-white",
                        attrs: { icon: ["fas", "home"] },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.selectedDepartment.name))]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex items-center" },
                  [
                    _c("fa-icon", {
                      staticClass: "text-lg",
                      attrs: {
                        icon: [
                          "far",
                          _vm.showOptions ? "chevron-up" : "chevron-down",
                        ],
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }