var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "w-96 text-3xl rounded-lg shadow-lg p-3 font-bold bg-white absolute",
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "overflow-y-scroll max-h-96" },
        _vm._l(_vm.options, function (option, index) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showOptionSelect,
                  expression: "showOptionSelect",
                },
                {
                  name: "clickoutside",
                  rawName: "v-clickoutside",
                  value: {
                    excludeString: ".selectDepartment, .selectDepartment>*",
                    handler: _vm.onClickoutside,
                  },
                  expression:
                    "{ excludeString: '.selectDepartment, .selectDepartment>*', handler: onClickoutside }",
                },
              ],
              key: index,
              on: {
                click: function ($event) {
                  return _vm.optionSelected(option)
                },
              },
            },
            [
              option.name !== _vm.selectedOption.name
                ? _c(
                    "div",
                    {
                      staticClass:
                        "relative w-full inline-flex items-center p-2 mb-2 rounded rounded-sm border border-gray-300",
                    },
                    [
                      _vm.icon
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "icon rounded-lg mr-3 flex justify-center items-center w-16 h-16",
                              style: [{ backgroundColor: option.color }],
                            },
                            [
                              _c("fa-icon", {
                                staticClass: "text-white text-2xl",
                                attrs: { icon: ["far", `${_vm.icon}`] },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "truncate flex items-center text-2xl" },
                        [_vm._v(_vm._s(_vm.$t(option.name)))]
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "rounded-lg p-2 flex items-center justify-between h-20",
          style: [
            {
              backgroundColor: _vm.getBackgroundColorOpaqueHex(
                _vm.selectedOption
              ),
            },
          ],
          on: {
            click: function ($event) {
              _vm.showOptionSelect = !_vm.showOptionSelect
            },
          },
        },
        [
          _c("div", { staticClass: "flex" }, [
            _vm.icon
              ? _c(
                  "span",
                  {
                    staticClass:
                      "icon rounded-lg mr-3 flex justify-center items-center w-16 h-16",
                    style: [{ backgroundColor: _vm.selectedOption.color }],
                  },
                  [
                    _c("fa-icon", {
                      staticClass: "text-white text-2xl",
                      attrs: { icon: ["far", `${_vm.icon}`] },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "truncate flex items-center text-2xl" }, [
              _vm._v(_vm._s(_vm.$t(_vm.selectedOption.name))),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "icon flex justify-center items-center",
              style: [{ color: _vm.selectedOption.color }],
            },
            [
              _c("fa-icon", {
                staticClass: "text-2xl",
                attrs: { icon: ["far", "check"] },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }