var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "flex justify-between self-center px-20 py-20 rounded-t-3xl",
        },
        [
          _c("div", { staticClass: "largeListItemHeader" }, [
            _vm._v("\n      " + _vm._s(_vm.$t(_vm.data.title)) + "\n    "),
          ]),
          _vm._v(" "),
          _c("fa-icon", {
            staticClass: "text-5xl",
            attrs: { icon: ["fal", "times"] },
            on: {
              click: function ($event) {
                return _vm.$emit("close-overlay", true)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.data.profiles, function (profile, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "participants content inline-flex w-full pb-10 px-20",
          },
          [
            _c("img", {
              staticClass:
                "w-24 h-24 rounded-full border border-gray-200 mr-10",
              class: { "-m-1": index !== 0 },
              attrs: { src: profile.displayImage },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "details" }, [
              _c("p", { staticClass: "label" }, [
                _vm._v("\n        " + _vm._s(profile.displayName) + "\n      "),
              ]),
              _vm._v(" "),
              _c(
                "span",
                [
                  _c("fa-icon", { attrs: { icon: ["fal", "house-user"] } }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.getDepartmentName(profile)) +
                      "\n      "
                  ),
                ],
                1
              ),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }