import { render, staticRenderFns } from "./BasicBlock.vue?vue&type=template&id=ab6cb868"
import script from "./BasicBlock.vue?vue&type=script&lang=js"
export * from "./BasicBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports