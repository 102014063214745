<template>
  <carousel
    name="booking"
    ref-name="booking"
    :slide-duration="widgetSlideDuration"
    @slide-clicked="slideClicked"
  >
    <slide
      v-for="( booking, index ) in bookings"
      :key="index"
      :class="[slideStyles, widget.frHeight > 1 ? 'justify-center' : 'justify-end']"
      :style="getBackgroundStyles(index)"
    >
      <fa-icon
        class="text-2xl mb-4 text-white"
        :icon="['fas', `${widget.icon}`]"
      />
      <p class="text-2xl mb-6">
        {{ booking.title }}
      </p>
    </slide>
  </carousel>
</template>
<script>

import coverImageGradient from '@/utils/coverImageGradient.js';
import constants from '@/utils/constants.js';
import { mapGetters } from 'vuex';
import Carousel from '@/components/shared/Carousel';
import { Slide } from 'vue-carousel';
import { getBookings } from '@/utils/bookings';
import { get } from 'lodash';

export default {
  components: {
    Carousel,
    Slide
  },
  props: {
    widget: {
      type: Object,
      'default': (() => {})
    }
  },
  computed: {
    ...mapGetters({
      allBookingResources: 'bookingResources/all',
      departmentSettings: 'department/settings'
    }),
    widgetSlideDuration() {
      return this.departmentSettings.screenConfig.carouselDuration * 1000;
    },
    bookings() {
      return getBookings(this.allBookingResources).slice(0, this.widget.frWidth * 5);
    },
    slideStyles() {
      return constants.slider.slideStyles;
    }
  },
  methods: {
    getBackgroundStyles(index) {
      const height = this.widget.frHeight > 1 ? 50 : 70;
      const coverImage = get(this.bookings[index], 'coverImage.source', require('@assets/img/placeholder.png'));
      return coverImageGradient.get(this.widget.colorHex, height, coverImage);
    },
    async slideClicked(slideIndex) {
      const booking = this.bookings[slideIndex];
      await this.$store.dispatch('bookingResources/setSelectedBookingEntry', booking);
      this.$router.push(`/bookings/booking/${booking.id}`);
    }
  }
}
</script>