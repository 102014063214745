var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col", style: { height: "1175px" } },
    [
      _c(
        "div",
        { staticClass: "content flex flex-col h-full" },
        [
          !_vm.employeeOnly
            ? _c("tab-group", {
                attrs: { "selected-profile-type": _vm.profileType },
                on: {
                  "tab-selected": function ($event) {
                    _vm.profileType = $event
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "grid grid-cols-3 gap-y-10 pt-12 pb-72 px-16 overflow-scroll mb-32 h-full max-h-full",
              class: {
                "grid-cols-3 gap-x-4": _vm.size === "large",
                "grid-cols-5 gap-x-0": _vm.size !== "large",
              },
            },
            _vm._l(_vm.shownProfiles, function (profile, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "profile flex flex-col items-center",
                  class: {
                    "h-36": _vm.size !== "large",
                    "h-60": _vm.size === "large",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.selectProfile(profile)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass:
                      "profileImage rounded-full shadow-lg object-cover border-8 border-white mb-4",
                    class: [
                      _vm.size,
                      { "border-8 border-success": _vm.isSelected(profile) },
                    ],
                    attrs: { src: profile.displayImage },
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "text-2xl font-bold text-center",
                      class: {
                        "text-success font-bold": _vm.isSelected(profile),
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(profile.displayName) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "sticky flex flex-col items-center justify-end pt-4 w-96 z-20 self-center",
          class: [_vm.selectBottom],
        },
        [
          _c("select-el", {
            attrs: {
              icon: "house-user",
              options: _vm.filterSharedDepartments,
              "selected-option": _vm.selectedDepartment,
            },
            on: { "option-selected": _vm.departmentSelected },
          }),
          _vm._v(" "),
          _vm.canSave
            ? _c("button-el", {
                staticClass: "w-full self-center mb-32",
                attrs: {
                  text: "booking.confirmBooking",
                  icon: "check",
                  "is-save": true,
                  shadow: true,
                  "background-color": "success",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.save()
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.canClose
            ? _c("button-el", {
                staticClass: "w-auto inline-block self-center mb-4",
                attrs: { text: "global.close", icon: "times", shadow: true },
                nativeOn: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }