var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "booking" },
    [
      _vm.resource.id
        ? _c(
            "cover-view",
            {
              attrs: {
                "image-url": _vm.imageUrl,
                gradient: true,
                "module-color": _vm.moduleColor,
                "margin-top": 60,
              },
            },
            [
              _c(
                "div",
                { staticClass: "bookingContent pt-20" },
                [
                  _c(
                    "div",
                    { staticClass: "largeListItemHeader pt-12 mb-12" },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.resource.title) + "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("description", {
                    attrs: { description: _vm.resource.description },
                  }),
                  _vm._v(" "),
                  _c("institution"),
                  _vm._v(" "),
                  _vm._l(_vm.resource.bookings, function (booking, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "relative content mb-14 w-full",
                      },
                      [
                        _c("date-time", {
                          attrs: { start: booking.start, end: booking.end },
                        }),
                        _vm._v(" "),
                        _c("description", {
                          attrs: { description: booking.description },
                        }),
                        _vm._v(" "),
                        booking.participants.length
                          ? _c("people", {
                              attrs: {
                                "module-color": _vm.moduleColor,
                                profiles: booking.participants,
                              },
                              on: {
                                "show-people": function ($event) {
                                  return _vm.showProfiles("participants", index)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _vm.canCreateNewBooking
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "newBooking flex w-full sticky justify-end",
                        },
                        [
                          _c("button-el", {
                            staticClass: "mr-5 shadow-xsm",
                            attrs: {
                              text: "booking.newBooking",
                              icon: "plus",
                              "background-color": "success",
                              "text-color": "white",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.newBooking.apply(null, arguments)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }