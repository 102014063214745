<template>
  <div
    class="mediaIcons absolute right-10 h-20 flex justify-end w-full"
    :class="{ '-top-28': iconsPositionType === 'route', 'top-80': iconsPositionType === 'overlay' }"
  >
    <div
      v-for="(icon, index) in filteredIcons"
      :key="index"
      class="rounded-full bg-white w-20 h-20 ml-6 flex justify-center items-center relative"
      :class="{
        [`bg-${moduleColor}`]: mediaType === icon.name,
        ['border-2 border-white']: true
      }"
    >
      <fa-icon
        :icon="['fal', icon.fa]" 
        class="text-4xl"
        :class="{ 'text-white': mediaType === icon.name, [`text-${moduleColor}`]: mediaType !== icon.name }"
        @click="$emit('media-selected', icon.name)"
      />

      <div
        class="rounded-full border-white border-2 text-white absolute -right-1 -bottom-1 w-8 h-8 flex items-center justify-center text-xl font-bold"
        :class="`bg-${moduleColor}`"
      >
        {{ icon.amount }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      icons: {
        type: Array,
        'default': (() => [])
      },
      mediaType: {
        type: String,
        'default': 'pictures'
      },
      color: {
        type: String,
        'default': ''
      },
      iconsPositionType: {
        type: String,
        'default': 'route'
      },
    },
    computed: {
      moduleColor() {
        return this.color.length ? this.color : this.$route.meta?.color;
      },
      filteredIcons() {
        return this.icons.filter(icon => icon.amount > 0);
      }
    }
  }
</script>