<template>
  <div class="galleryEntry">
    <media-preview
      v-if="hasSelectedMediaItem"
      :selected-item-index="selectedMediaIndex"
      :media-type="mediaType"
      :items="mediaItems"
      @close-preview="selectedMediaIndex = -1"
    />

    <cover-view
      v-if="galleries.length"
      :image-url="galleryEntry.coverImage.source"
      :gradient="false"
      :margin-top="60"
    >
      <div>
        <media-icons
          :icons="mediaIcons"
          :media-type="mediaType"
          @media-selected="mediaType = $event"
        />

        <div class="largeListItemHeader p-20 pb-0">
          {{ galleryEntry.title }}
        </div>

        <media-list
          class="px-20 pt-20"
          :items="mediaItems"
          :media-type="mediaType"
          @item-selected="selectedMediaIndex = $event"
        />
      </div>
    </cover-view>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import { isEmpty, get } from 'lodash';
import CoverView from '@/views/shared/CoverView';
import MediaIcons from '@/components/shared/MediaIcons';
import MediaPreview from '@/views/overlays/MediaPreview';
import MediaList from '@/views/shared/MediaList';

export default {
  components: {
    CoverView,
    MediaIcons,
    MediaPreview,
    MediaList
  },
  data() {
    return {
      mediaType: '',
      selectedMediaIndex: -1
    }
  },
  computed: {
    ...mapGetters({
      galleries: 'galleries/all',
      activeProfile: 'profiles/activeProfile',
      myArchive: 'profiles/myArchive',
      institutionSettings: 'institution/settings'
    }),
    moduleColor() {
      return this.$route?.meta?.color;
    },
    galleryEntry() {
      return this.galleries.find(gallery => gallery.id === this.$route.params.id);
    },
    useExternalMedia() {
      return get(this.institutionSettings, 'useExternalMedia', false);
    },
    mediaIcons() {
      if (this.useExternalMedia) {
        return [
          {
            name: 'media',
            fa: 'photo-video',
            amount: this.galleryEntry.media.length
          },
        ]
      }
      return [
        {
          name: 'pictures',
          fa: 'image',
          amount: this.galleryEntry.pictures.length
        },
        {
          name: 'videos',
          fa: 'film',
          amount: this.galleryEntry.videos.length
        }
      ];
    },
    mediaItems() {
      if (this.useExternalMedia) return this.galleryEntry.media;
      return this.mediaType === 'pictures' ? this.galleryEntry.pictures : this.galleryEntry.videos;
    },
    selectedMediaItem() {
      if (this.useExternalMedia) return this.galleryEntry.media[this.selectedMediaIndex];
      return this.galleryEntry.pictures[this.selectedMediaIndex];
    },
    hasSelectedMediaItem() {
      return !isEmpty(this.selectedMediaItem);
    }
  },
  watch: {
    activeProfile(newValue) {
      if (!isEmpty(newValue)) {
        this.$store.dispatch('profiles/getMyArchive', { id: this.activeProfile.id, type: this.activeProfile.type });
      }
    }
  },
  mounted() {
    this.$matomo.trackEvent('GalleryEntry', 'Viewed', 'GalleryEntryViewed');
    this.mediaType = this.useExternalMedia ? 'media' : 'pictures';
  },
};
</script>

<style lang="scss">
  .video {
    height: 495px;
  }
</style>