import gql from 'graphql-tag';

export default gql`
query institution {
    institution {
        id
        name
        color
        locale
        logo {
            source
        }
        departments {
            id
            name
            color
            loginKey
            presentation {
                description
                image
            }
        }
        settings {
            screen {
                activityHideSignups
            }
            shift {
                departmentId
                dayDisplay
                daysForward
                gfx
                showCancelledFp
            }
            screen {
                galleryPasswordProtected
                passwordProtection {
                  isProtected
                  maxFailed
                  userLockedOutInMinutes
                }
              }
            useExternalMedia
            useExternalMeals
            showResInfoDefault
        }
    }
}`;