var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.itemType === "activity" || _vm.itemType === "meal"
      ? _c(
          "div",
          { staticClass: "grid-view grid grid-cols-3 p-10 gap-14" },
          _vm._l(_vm.items, function (item, index) {
            return _c("card-advanced", {
              key: index,
              staticClass: "shadow-m",
              attrs: {
                "card-type": _vm.activeContentView.cardType,
                title: item.title,
                "start-date": item.startDate,
                meta: !_vm.useExternalMeals
                  ? { icon: item.meta.icon, text: item.meta.text }
                  : null,
                "is-now": _vm.isItemNow(item),
                "is-cancelled": item.cancelled,
                image: _vm.getCoverImage(item),
                gradient: true,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.$emit("item-selected", item)
                },
              },
            })
          }),
          1
        )
      : _c(
          "div",
          { staticClass: "grid-view grid grid-cols-3 p-10 gap-14" },
          _vm._l(_vm.items, function (item, index) {
            return _c("card-advanced", {
              key: index,
              staticClass: "shadow-m",
              attrs: {
                "card-type":
                  _vm.itemType === "gallery"
                    ? "vertical"
                    : _vm.activeContentView.cardType,
                title: item.title,
                meta: { icon: item.meta.icon, text: item.meta.text },
                image: _vm.getCoverImage(item),
                gradient: true,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.$emit("item-selected", item)
                },
              },
            })
          }),
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }