var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content mb-14 flex items-center w-full" },
    [
      _c("fa-icon", { staticClass: "mr-6", attrs: { icon: ["fal", "house"] } }),
      _vm._v(" "),
      _c(
        "div",
        _vm._l(_vm.departments, function (department, index) {
          return _c(
            "p",
            {
              key: index,
              staticClass: "font-bold inline-flex mr-4",
              style: { color: department.color },
            },
            [_vm._v("\n      " + _vm._s(department.name) + "\n    ")]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }