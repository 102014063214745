import { apolloClient } from '../../utils/apollo';
import store from '@/store';
import { ls } from '@/utils/storage';
import { endOfWeek, startOfWeek } from 'date-fns';
import { format } from '@/utils/date-fns';
import { get } from 'lodash';

const state = {
  all: [],
  selectedBookingEntry: {},
  selectedBookingResource: {},
  selectedBookingSlots: [],
  selectedSlottedWeek: {
    weekStart: null,
    weekEnd: null,
  },
  opt: null,
  newBooking: {
    selectedStartDate: '',
    selectedStartTime: '',
    selectedEndDate: '',
    selectedEndTime: '',
    title: '',
    resourceId: null
  },
  newSlottedBooking: {
    participants: [],
    bookingSlot: {
      bookingResourceId: null,
      bookingResourceSlotId: null,
      date: ''
    }
  }
};

const mutations = {
  SET_ALL_BOOKINGS(state, bookings) {
    state.all = bookings;
  },
  SET_SELECTED_BOOKING_ENTRY(state, bookingEntry) {
    state.selectedBookingEntry = bookingEntry;
  },
  SET_SELECTED_BOOKING_SLOTS(state, slots) {
    state.selectedBookingSlots = slots;
  },
  SET_SELECTED_BOOKING_RESOURCE(state, bookingResource) {
    state.selectedBookingResource = bookingResource;
  },
  SET_SELECTED_SLOTTED_WEEK(state, week) {
    if (!week) {
      const currentWeek = {
        weekStart: startOfWeek(new Date(), { weekStartsOn: 1 }),
        weekEnd: endOfWeek(new Date(), { weekStartsOn: 1 })
      };

      state.selectedSlottedWeek = currentWeek;
      return;
    }
    state.selectedSlottedWeek = week;
  },
  SET_NEW_BOOKING(state, booking) {
    Object.assign(state.newBooking, booking);
  },
  SET_NEW_SLOTTED_BOOKING(state, booking) {
    Object.assign(state.newSlottedBooking, booking);
  },
  RESET_NEW_BOOKING(state) {
    state.newBooking = {};
    state.newSlottedBooking = {};
  },
  SET_OPT(state, opt) {
    state.opt = opt;
  }
};

const actions = {
  async getBookingResources({commit}) {
    const departmentId = store.getters['department/id'];
    
    if (!departmentId) {
      throw new Error('No department id provided');
    }

    await apolloClient
      .query({
        query: require('@/graphql/queries/bookingResources').default,
        variables: {
          filter: {
            departments: departmentId
          },
          timezone: 'Europe/Copenhagen'
        },
        fetchPolicy: 'no-cache'
      })
      .then(response => {
        commit('SET_ALL_BOOKINGS', response.data.bookingResources);
      })
      .catch(error => {
        console.log('Error getting bookings', error);
      })
  },
  async getBookingSlots({commit}, { id, dateFrom, dateTo }) {
    await apolloClient
      .query({
        query: require('@/graphql/queries/bookingSlots').default,
        variables: {
          bookingResourceId: id,
          dateFrom,
          dateTo,
          timezone: 'Europe/Copenhagen',
        },
        fetchPolicy: 'no-cache'
      })
      .then(response => {
        commit('SET_SELECTED_BOOKING_SLOTS', response.data.bookingSlots);
      })
      .catch(error => {
        console.log(`Error getting bookingSlots from id ${id}`, error);
      })
  },
  async setSelectedBookingEntry({commit}, bookingEntry) {
    commit('SET_SELECTED_BOOKING_ENTRY', bookingEntry);
  },
  async setSelectedBookingResource({commit}, bookingResource) {
    commit('SET_SELECTED_BOOKING_RESOURCE', bookingResource);
  },
  async setSelectedSlottedWeek({commit}, week) {
    commit('SET_SELECTED_SLOTTED_WEEK', week);
  },
  async setNewBooking({commit}, booking) {
    commit('SET_NEW_BOOKING', booking);
  },
  async setNewSlottedBooking({commit}, booking) {
    commit('SET_NEW_SLOTTED_BOOKING', booking);
  },
  async createNewBooking() {
    const participants = state.newBooking.participants.map(participant => {
      return {
        id: participant.id,
        type: participant.type
      }
    });

    const now = new Date();
    const timezone = format(now, 'XXX');
    const end = `${state.newBooking.selectedEndDate}T${state.newBooking.selectedEndTime}:00${timezone}`;
    const start = `${state.newBooking.selectedStartDate}T${state.newBooking.selectedStartTime}:00${timezone}`;

    const booking = {
      bookingResourceId: state.newBooking.resourceId,
      end,
      start,
      title: state.newBooking.title
    }

    let newBookingId;

    await apolloClient
      .mutate({
        mutation: require('@/graphql/mutations/bookResourceScreen').default,
        variables: {
          participants,
          booking
        },
      })
      .then(async response => {
        newBookingId = get(response, 'data.booking.bookResourceScreen.id', undefined);

        if (!newBookingId) throw new Error('Error creating booking. No bookingId provided');
      })
      .catch(error => {
        throw new Error('Error saving new booking', error);
      });

      return newBookingId;
  },
  async setOpt({commit}, opt) {
    commit('SET_OPT', opt);
  },
  async createNewSlottedBooking() {
    const activeProfile = store.state.profiles.activeProfile;

    await apolloClient
      .mutate({
        mutation: require('@/graphql/mutations/bookSlottedResourceScreen').default,
        variables: {
          participants: [
            {
              id: activeProfile.id,
              type: activeProfile.type
            }
          ],
          bookingSlot: state.newSlottedBooking.bookingSlot
        },
      })
      .then(() => {
        store.dispatch('profiles/setPinValidation', {});
      })
      .catch(error => {
        throw new Error('Error creating slotted booking', error);
      });
  },
  async cancelSlottedBooking() {
    const activeProfile = store.state.profiles.activeProfile;

    await apolloClient
      .mutate({
        mutation: require('@/graphql/mutations/cancelSlottedBookingScreen').default,
        variables: {
          profile: {
            profileId: activeProfile.id,
            profileType: activeProfile.type,
            pin: store.state.profiles.pinValidation.pin
          },
          bookingSlot: state.newSlottedBooking.bookingSlot
        },
      })
      .then(() => {
        store.dispatch('profiles/setPinValidation', {});
      })
      .catch(error => {
        store.dispatch('profiles/setPinValidation', { validating: false, validPin: false });
        throw new Error('Error cancelling booking', error);
      });
  }
};

const getters = {
  all: state => state.all,
  selectedBookingEntry: state => state.selectedBookingEntry,
  selectedBookingResource: state => state.selectedBookingResource,
  selectedBookingSlots: state => state.selectedBookingSlots,
  selectedSlottedWeek: state => state.selectedSlottedWeek,
  newBooking: state => state.newBooking,
  newSlottedBooking: state => state.newBooking,
  opt: state => state.opt
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
