var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "booking" },
    [
      _vm.booking.id
        ? _c(
            "cover-view",
            {
              attrs: {
                "image-url": _vm.imageUrl,
                gradient: true,
                "module-color": _vm.moduleColor,
                "margin-top": 60,
              },
            },
            [
              _c(
                "div",
                { staticClass: "bookingContent pt-20" },
                [
                  _c("tts", {
                    attrs: {
                      "entity-id": _vm.booking.id,
                      "entity-type": "BOOKING_BOOKING",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "largeListItemHeader pt-12 mb-10" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.booking.title || _vm.booking.description) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("date-time", {
                    attrs: { start: _vm.booking.start, end: _vm.booking.end },
                  }),
                  _vm._v(" "),
                  _vm.booking.participants.length
                    ? _c("people", {
                        attrs: {
                          "module-color": _vm.moduleColor,
                          profiles: _vm.booking.participants,
                        },
                        on: {
                          "show-people": function ($event) {
                            return _vm.showProfiles("participants")
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }