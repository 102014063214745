<template>
  <carousel
    name="gallery"
    ref-name="gallery"
    :slide-duration="widgetSlideDuration"
    @slide-clicked="slideClicked"
  >
    <slide
      v-for="( gallery, index ) in galleries"
      :key="index"
      :class="[slideStyles, widget.frHeight > 1 ? 'justify-center' : 'justify-end']"
      :style="getBackgroundStyles(index)"
    >
      <fa-icon
        class="text-2xl mb-4 text-white"
        :icon="['fas', `${widget.icon}`]"
      />
      <p class="text-2xl mb-6">
        {{ gallery.title }}
      </p>
    </slide>
  </carousel>
</template>

<script>
import constants from '@/utils/constants.js';
import { mapGetters } from 'vuex';
import Carousel from '@/components/shared/Carousel';
import { cloneDeep, get, isEmpty } from 'lodash';
import coverImageGradient from '@/utils/coverImageGradient.js';

export default {
  components: {
    Carousel
  },
  props: {
    widget: {
      type: Object,
      'default': (() => {})
    }
  },
  computed: {
    ...mapGetters({
      allGalleries: 'galleries/all',
      institutionSettings: 'institution/settings',
      departmentSettings: 'department/settings'
    }),
    slideStyles() {
      return constants.slider.slideStyles;
    },
    useExternalMedia() {
      return get(this.institutionSettings, 'useExternalMedia', false);
    },
    widgetSlideDuration() {
      return this.departmentSettings.screenConfig.carouselDuration * 1000;
    },
    galleries() {
      return this.allGalleries.filter(gallery => {
        if (this.useExternalMedia) {
          return !isEmpty(gallery.settings.dreambrokerUrl)
        }
        return this.allGalleries;
      }).slice(0, this.widget.frWidth * 5)
    }
  },
  methods: {
    getBackgroundStyles(index) {
      const height = this.widget.frHeight > 1 ? 50 : 70;
      const images = cloneDeep(this.galleries).map(gallery => gallery.coverImage.source);
      return coverImageGradient.get(this.widget.colorHex, height, images[index]);
    },
    slideClicked(slideIndex) {
      const itemId = this.galleries[slideIndex].id;
      this.$emit('slide-clicked', `/galleries/${itemId}`);
    }
  }
}
</script>