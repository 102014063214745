<template>
  <div>    
    <media-preview
      v-if="hasSelectedMediaItem"
      :selected-item-index="selectedMediaIndex"
      :media-type="mediaType"
      :items="mediaItems"
      @close-preview="selectedMediaIndex = -1"
    />
    
    <media-icons
      :icons="mediaIcons"
      :media-type="mediaType"
      icons-position-type="overlay"
      @media-selected="mediaType = $event"
    />

    <div class="largeListItemHeader px-20 pb-0">
      {{ activeOverlay.data.gallery.title }}
    </div>
    
    <media-list
      class="px-20 pt-20"
      :items="mediaItems"
      :media-type="mediaType"
      @item-selected="selectedMediaIndex = $event"
    />
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import { isEmpty } from 'lodash';
import MediaList from '@/views/shared/MediaList';
import MediaIcons from '@/components/shared/MediaIcons';
import MediaPreview from '@/views/overlays/MediaPreview';

export default {
  components: {
    MediaList,
    MediaPreview,
    MediaIcons
  },
  data() {
    return {
      mediaType: '',
      selectedMediaIndex: -1
    }
  },
  computed: {
    ...mapGetters({
      activeOverlay: 'general/activeOverlay'
    }),
    mediaIcons() {
      return [
        {
          name: 'pictures',
          fa: 'image',
          amount: this.galleryItem.pictures.length
        },
        {
          name: 'videos',
          fa: 'film',
          amount: this.galleryItem.videos.length
        }
      ];
    },
    galleryItem(){
      return this.activeOverlay.data.gallery;
    },
    mediaItems() {
      return this.mediaType === 'pictures' ? this.galleryItem.pictures : this.galleryItem.videos
    },
    selectedMediaItem() {
      return this.mediaItems[this.selectedMediaIndex];
    },
    hasSelectedMediaItem() {
      return !isEmpty(this.selectedMediaItem);
    }
  },
  mounted(){
    this.mediaType = this.useExternalMedia ? 'media' : 'pictures';
  }
}
</script>