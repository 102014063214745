<template>
  <div class="meal">
    <media-preview
      v-if="showMediaPreview"
      :selected-item-index="selectedMediaIndex"
      :media-type="mediaType"
      :items="mediaItems"
      @close-preview="selectedMediaIndex = -1"
    />

    <cover-view
      v-if="meal && meal.id"
      :image-url="imageUrl"
      :gradient="true"
      :module-color="moduleColor"
      :margin-top="60"
    >
      <div class="mealContent pt-20">
        <media-icons
          v-if="!useExternalMeals"
          :icons="mediaIcons"
          :media-type="mediaType"
          :amount="mediaItems.length"
          @media-selected="mediaIconSelected"
        />
        
        <tts
          v-if="!useExternalMeals"
          :entity-id="meal.id"
          entity-type="MEAL"
        />

        <div v-if="!showMediaList">
          <div class="largeListItemHeader pt-20 mb-10">
            {{ meal.title }}
          </div>

          <div class="mealDetails">
            <!-- When -->
            <date-time
              v-if="!useExternalMeals"
              :start="meal.startDate"
              :end="meal.endDate"
            />

            <!-- Dishes -->
            <div
              v-for="(part, index) in meal.parts"
              :key="index"
              class="content pb-10 flex items-center w-full"
            >
              <fa-icon
                class="mr-6"
                :icon="['fal', 'soup']"
              />
              <div>
                <p class="mb-4">
                  {{ part.title }}
                </p>
                <img
                  :src="part.picture.source"
                  class="mealPartImage rounded-2xl"
                >
              </div>
            </div>
            <div v-if="!useExternalMeals">
              <description
                v-if="meal.description.length"
                :description="meal.description"
                icon="align-left"
              />
            </div>
            <description
              v-else
              :description="meal.category"
              icon="align-left"
            />
            <!-- Signup -->
            <div
              v-if="meal.signupPossible"
              class="content pb-10 flex items-center w-full"
            >
              <fa-icon
                class="mr-6"
                :icon="['fal', 'alarm-clock']"
              />
              {{ formatDeadline(meal) }}
            </div>

            <div v-if="!useExternalMeals">
              <people
                v-if="meal.participants.length"
                text="global.participantsPlural"
                :module-color="moduleColor"
                :profiles="meal.participants"
                @show-people="showProfiles('participants')"
              />
            </div>

            <div v-if="!useExternalMeals">
              <departments
                v-if="meal.departments.length "
                :departments="meal.departments"
              />
              <institution />
            </div>
          </div>
        </div>

        <div v-else>
          <media-list
            :items="mediaItems"
            :media-type="mediaType"
            :can-close="true"
            @close="closeMediaList"
            @media-selected="selectedMediaIndex = $event"
          />
        </div>
      </div>

      <div
        v-if="!showMediaList"
        class="mealActions flex w-max left-full sticky"
      >
        <button-el
          v-if="signupPossible"
          text="global.signUp"
          icon="check"
          background-color="success"
          text-color="white"
          class="mr-5 shadow-xsm"
          @click.native="optInOrOut('in')"
        />

        <button-el
          v-if="optoutPossible && signupPossible"
          text="global.optOut"
          icon="minus-circle"
          background-color="error"
          text-color="white"
          class="shadow-xsm"
          @click.native="optInOrOut('out')"
        />
      </div>
    </cover-view>
  </div>
</template>
<script>
import { isFuture } from 'date-fns';
import { formatDistanceToNow } from '@/utils/date-fns';
import { mapGetters } from 'vuex';
import Button from '@/components/shared/Button';
import { get, isEmpty, cloneDeep } from 'lodash';
import CoverView from '@/views/shared/CoverView';
import MediaIcons from '@/components/shared/MediaIcons';
import MediaPreview from '@/views/overlays/MediaPreview';
import MediaList from '@/views/shared/MediaList';
import DateTime from '@/components/shared/blocks/DateTime';
import PinFlow from '@/utils/pinFlow';
import Description from '@/components/shared/blocks/Description';
import Institution from '@/components/shared/blocks/Institution';
import Departments from '@/components/shared/blocks/Departments';
import People from '@/components/shared/blocks/People';
import Tts from '@/components/shared/Tts';

export default {
  components: {
    ButtonEl: Button,
    CoverView,
    MediaIcons,
    MediaPreview,
    MediaList,
    DateTime,
    Description,
    Institution,
    Departments,
    People,
    Tts
  },
  data() {
    return {
      opt: '',
      mediaType: '',
      selectedMediaIndex: -1,
      hideMediaPreview: false,
      showMediaList: false
    }
  },
  computed: {
    ...mapGetters({
      allMeals: 'meals/all',
      selectedMeal: 'meals/selected',
      activeProfile: 'profiles/activeProfile',
      pinValidation: 'profiles/pinValidation',
      institutionColor: 'institution/color',
      institutionName: 'institution/name',
      institutionSettings: 'institution/settings',
      departmentSettings: 'department/settings'
    }),
    moduleColor() {
      return this.$route?.meta?.color;
    },
    mealEntry() {
      return this.$route.params.meal;
    },
    meal() {
      return this.selectedMeal;
    },
    useExternalMeals() {
      return get(this.institutionSettings, 'useExternalMeals', false);
    },
    imageUrl() {
      return get(this.meal, 'coverImage.source', require('@assets/img/placeholder.png'));
    },
    signupPossible() {
      if (!get(this.departmentSettings, 'meal.signup', true)) return false;
      return this.meal.signupPossible && !this.meal.cancelled;
    },
    optoutPossible() {
      if (!get(this.departmentSettings, 'meal.signup', true)) return false;
      return isFuture(new Date(this.meal.startDate));
    },
    mediaIcons() {
      return [
        {
          name: 'pictures',
          fa: 'image',
          amount: this.meal.pictures.length
        },
        {
          name: 'videos',
          fa: 'film',
          amount: this.meal.videos.length
        }
      ];
    },
    mediaItems() {
      return this.mediaType === 'pictures' ? this.meal.pictures : this.meal.videos;
    },
    selectedMediaItem() {
      return this.allMeals[this.selectedMediaIndex];
    },
    showMediaPreview() {
      return !isEmpty(this.selectedMediaItem) && !this.hideMediaPreview;
    },
    isAlreadyOptedInOut() {
      const participantIds = this.meal.participants.map(participant => participant.id);

      return participantIds.includes(this.activeProfile.id);
    }
  },
  watch: {
    pinValidation(pinData) {
      if (!pinData.validPin || !pinData.pin) return;

      const signupData = {
        subscription: {
          id: this.meal.id
        },
        profile: {
          profileId: this.activeProfile.id,
          profileType: this.activeProfile.type,
          pin: pinData.pin.toString()
        },
        opt: this.opt
      };

      if ((this.opt === 'in' && !this.isAlreadyOptedInOut) || (this.opt === 'out' && this.isAlreadyOptedInOut)) {
        this.$store.dispatch('meals/mealSignupScreen', signupData);  
      }
    }
  },
  mounted() {
    this.$store.dispatch('meals/getSelectedMeal', this.$route.params.id);
    this.$matomo.trackEvent('MealEntry', 'Viewed', 'MealEntryViewed');
  },
  methods: {
    formatDeadline(meal) {
      return `${this.$t('global.signUpDeadline')} ${formatDistanceToNow(new Date(meal.signupEnd), { addSufix: true })}`;
    },
    showProfiles(profileType) {
      this.$matomo.trackEvent('ProfilesList', 'Clicked', profileType);
      this.$store.dispatch('general/setActiveOverlay', { name: 'participants-list', data: { title: `global.${profileType}`, profiles: this.meal[profileType] } })
    },
    async optInOrOut(opt) {
      this.opt = opt;

      const flow = new PinFlow({
        entity: 'meals',
        text: 'meals.theMeal',
        opt,
        title: this.meal.title,
        participants: this.meal.participants,
        sharedDepartments: this.meal.departments
      });

      flow.startSignupFlow('meals');
    },
    closeMediaList() {
      this.mediaType = '';
      this.selectedMediaIndex = -1;
      this.showMediaList = false;
    },
    mediaIconSelected(mediaType) {
      this.mediaType = mediaType;

      if (this.mediaItems.length === 1) {
        // if only one item, go straight to media-preview
        this.selectedMediaIndex = 0;
        this.hideMediaPreview = false;
        return;
      }

      this.showMediaList = true;
    },
  }
};
</script>

<style lang="scss">
  .meal {

    .mealContent {
  
      &.participants {
        margin-top: 700px;
      }
  
      > *:not(.mediaIcons) {
        padding: 0 100px;
      }

      .mealPartImage {
        width: 480px;
        height: 270px;
        object-fit: cover;
      }
    }
  
    .mealActions {
      bottom: 70px;
      padding-right: 80px;
    }
  }
  
</style>
